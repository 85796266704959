import { useRef, useReducer, useEffect } from 'react'
import CheckboxListControl from '../../control/CheckboxListControl'
import { useSelector } from 'react-redux'
import Header from '../../header/Header'
import UserDropdown from '../../header/UserDropdown'
import { AppMenuRouteData, AppMobileMenuRenderer, AppMenuRenderer, getPageTitle } from '../../menu/Menu'
import { PipelinesByCustomer, Direction } from 'gadget'
import FaIcon from '../../icon/FaIcon';
import { TUtil as T, Config } from '@trellisenergy/common-ui-core';
import './Preferences.css';
import useCurrentWidth from '../../../hook/useCurrentWidth';

import {
  BranchesOutlined,
  DashboardOutlined,
} from '@ant-design/icons';

import { message as AntdMessage, Collapse, Divider, Button, Radio, Space, Form, Row, Col, Layout } from 'antd';
const { INFOHUB_CONTEXT } = Config;

const { Panel } = Collapse;
const { Content } = Layout;

const Preferences = ({ pipelineData, saveConfig, themeData, accountData }) => {

  const { message } = useSelector((state) => state.message)
  let myPipelineRef = useRef(this);
  const dirtyMap = new Set();
  const [form] = Form.useForm();

  /**
   * 
   * Return only the favorite pipeline data
   */
  const getQuickConfigData = () => {
    let list = [];
    Object.entries(pipelineData.activePipelines).forEach(([key, p]) => {
      list.push({
        "id": p.id + '-checkbox',
        "name": 'favorite-pipelines',
        "label": p.name,
        "type": "checkbox",
        "defaultValue": p.favorite,
        "val": p.id,
        "filtered": false,
      });
    });

    return list;
  }

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const list = getQuickConfigData();
  const getSelectedPipelines = function () {
    let checkboxData = {};
    if (myPipelineRef.current) {
      let checkboxListState = myPipelineRef.current.state;
      Object.entries(checkboxListState.checkboxList).forEach(([key, p]) => {
        checkboxData[p.val * 1] = { favorite: p.defaultValue }
      });
    }
    return checkboxData;
  }

  const save = (e) => {
    const checkboxData = getSelectedPipelines();

    saveConfig({
      pipelineData:
        {
          activePipelines: checkboxData
        },
      themeData: { currentTheme: e.themes }
    });
  }

  const applyPreferences = (e) => {
    console.log("Finish", e);
    AntdMessage
      .loading(message['ih.pleaseWait'], 2.5)
      .then(() => save(e))
      .then(() => AntdMessage.success('Preferences saved.', 3.0))
  };

  /**
   * Disable buttons if there are no changes
   */
  const renderButtons = (e) => {
    document.getElementById('applyBtn').disabled = dirtyMap.size <= 0;
    document.getElementById('cancelBtn').disabled = dirtyMap.size <= 0;
  }

  /**
   * 
   * when window resizes set the width of the apply/cancel button div to be equal to infopage
   */
  let footerWidth = useCurrentWidth(document.querySelector('.infopage'));
  useEffect(() => {
    renderButtons();
    // set the width of the apply/cancel button div to be equal to infopage
    let infopage = document.querySelector('.infopage');
    let preferenceFooter = document.querySelector('.preference-footer');
    console.log('infopage.offsetWidth: ', infopage.offsetWidth);
    preferenceFooter.style.width = infopage.offsetWidth + 'px';
  }, [])

  const onCancel = (e) => {
    // for the favorite pipelines
    dirtyMap.clear();
    forceUpdate();

    // for the themes
    form.resetFields();
    const elm = document.getElementById('theme_preview');
    if (elm)
      elm.className = themeData.currentTheme;
    document.body.className = themeData.currentTheme;

    renderButtons();
  };

  const resetThemeSelection = (e) => {
    form.setFieldsValue( { 'themes': themeData.currentTheme } );
    const elm = document.getElementById('theme_preview');
    dirtyMap.delete('visual-theme');
    if (elm)
      elm.className = themeData.currentTheme;
    document.body.className = themeData.currentTheme;
    renderButtons(e);
  };

  const onFormChange = (e) => {
    let id = e.target.value;
    let isChanged = false;
    // if new change then save to dirtyMap
    // else remove from dirtyMap 
    if (e.target.name === "favorite-pipelines") {
      if (e.target.checked !== pipelineData.activePipelines[e.target.value].favorite)
        isChanged = true;
    } else if (e.target.name === "visual-theme") {
      if (id !== themeData.currentTheme) {
        isChanged = true;
      }
      id = e.target.name;
    } else if (e.target.name === "selectAll-pipelines") {
      // if user select none then select 1 checkbox, apply doesn't show, just set it to show the apply whenever they use select all box for simplicity
      isChanged = true;
      id = e.target.name;
    }

    if (isChanged) {
      dirtyMap.add(id);
    } else {
      if (dirtyMap.has(id)) {
        dirtyMap.delete(id)
      }
    }
    renderButtons();
  }

  const themePreview =
    <Layout style={{ minHeight: '200px' }}>
      <AppMenuRenderer authorities={accountData && accountData.authorities}
        data={AppMenuRouteData} onCollapse={null} collapsed={true} style={{ position: 'relative', }} trigger={null} />
      <Layout className="site-layout" style={{ marginLeft: 0 }} >
        <Header> {getPageTitle(`${INFOHUB_CONTEXT}/dashboard`, AppMenuRouteData, message)}
          <UserDropdown accountData={accountData} data={[]} onClick={null} />
        </Header>
        <Content style={{ margin: '0px' }}>
          <PipelinesByCustomer />
          <Direction />
        </Content>
      </Layout>
    </Layout>

  const mobileThemePreview =
    <Layout style={{ minHeight: '200px' }}>
      <AppMobileMenuRenderer authorities={accountData && accountData.authorities}
        data={AppMenuRouteData} onCollapse={null} collapsed={true} />

      <Layout className="site-layout" style={{ marginLeft: 0 }} >
        <Header> <div style={{ float: 'left' }}><FaIcon name="bars" /></div>{getPageTitle(`${INFOHUB_CONTEXT}/dashboard`, AppMenuRouteData, message)}
          <UserDropdown accountData={accountData} data={[]} onClick={null} />
        </Header>
        <Content style={{ margin: '0px' }}>
          <PipelinesByCustomer />
        </Content>
      </Layout>
    </Layout>

  return (
    <div className='infopage' style={{ height: '100%' }} onBlur={(e) => {
      if (e.currentTarget.contains(e.relatedTarget)) {
        return;
      }
      // only reset theme section if blur, prevents pipelines from being reset if they click elsewhere
      resetThemeSelection(e);
      // onCancel(e);
    }}>
      <Form initialValues={{ themes: themeData.currentTheme }}
        form={form}
        onFinish={applyPreferences}
        onChange={onFormChange}
      >
        <Collapse accordion style={{ padding: '0px 10px' }} expandIconPosition='right' /*defaultActiveKey={['1']}*/ ghost>
          {/*<Panel header={message['ih.preferences.favorite']} key="1" extra={<BranchesOutlined />}>*/}
          {/*  <div id='pipeline'>*/}
          {/*    <CheckboxListControl key={_} isPreference={true} checkboxList={list} ref={myPipelineRef} />*/}
          {/*  </div>*/}
          {/*</Panel>*/}
          {/*<Divider />*/}
          {/*<Panel header="Menu Notification" key="2" extra={<BellOutlined />}>
            <p>
              <Radio.Group >
                <Radio value={1}>On</Radio>
                <Radio value={2}>Off</Radio>
              </Radio.Group>
            </p>
            </Panel>
            <Divider />*/}
          <Panel header={message['ih.preferences.theme']} key="3" extra={<DashboardOutlined />}>
            <Row>
              <Col span={6}>
                <Form.Item name="themes" label="">
                  <Radio.Group name="visual-theme">
                    <Space direction="horizontal" onChange={(e) => {
                      const theme = e.target.value;
                      const elm = document.getElementById('theme_preview');
                      elm.className = theme;
                      document.body.className = theme;
                    }}>
                      <Radio value={'trellis'}>Default </Radio>
                      <Radio value={'dark'}>Cool</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div id='theme_preview' className={themeData.currentTheme} style={{ backgroundColor: 'white' }}>
                  <div style={{ zoom: '70%', border: '1px solid #d9d9d9', pointerEvents: 'none', maxWidth: '1080px' }}>
                    {T.isSmallScreen ? mobileThemePreview : themePreview}
                  </div>
                </div>
              </Col>
            </Row>
          </Panel>
          <Divider />
          {/*
          <Panel header="Account" key="4" extra={<UserOutlined />}>
            <p>{text}</p>
          </Panel>
          <Divider />*/}
        </Collapse>
        <div className='preference-footer' style={{ width: footerWidth }}>
          <Button id="applyBtn" type="primary" style={{ marginRight: 8 }} htmlType="submit" >{message['ih.apply']}</Button>
          <Button id="cancelBtn" onClick={onCancel}>{message['ih.cancel']}</Button>
        </div>
      </Form >
    </div >
  )
}

export default Preferences;