import {
  UserLoggedInIcon, UserLoggedOutIcon, HubHeaderDividerIcon,
} from '../../util/InfoIconLoader';

import { Menu, Dropdown, Divider } from 'antd';
import { useSelector } from 'react-redux';

const UserDropdown = ({ data, onClick, accountData }) => {
  const { message } = useSelector((state) => state.message)
  const userLoggedIn = accountData && accountData.name;

  const menuItemRenderer = (item) => {
    if (item.skipMenu) return '';
    if (item.id === 'divider') return <Menu.Divider key={Math.random()} />

    return <Menu.Item key={item.name} icon={item.icon ? <item.icon /> : ''}>
      {item.skipI18 ? <span style={{ cursor: 'default' }} className='secondary-color'>{item.title}</span> : message[item.title]}
    </Menu.Item>
  }

  const menuHeaderDropdown = () => (
    <Menu onClick={onClick}>
      {data.map(menuItemRenderer)}
    </Menu>
  );

  return (
    <Dropdown overlay={menuHeaderDropdown()}>
      <span style={{ float: 'right' }} className='secondary-color'>
        <HubHeaderDividerIcon style={{ margin: '0px 12px' }} />
        {!userLoggedIn ? <UserLoggedOutIcon /> : <UserLoggedInIcon />}
      </span>
    </Dropdown>)
}

export default UserDropdown;