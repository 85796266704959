import { Modal } from 'antd';
import { TUtil as T } from '@trellisenergy/common-ui-core';

let previousModalShowing = false;

const ShowRedirectDialog = () => {
  return {
    showRedirectModal: () => {
      if (!previousModalShowing) {
        Modal.destroyAll();
        Modal.info({
          closable: false,
          maskClosable: false,
          keyboard: false,
          okText: null,
          footer: {},
          //width: 500,
          onOk() {
            previousModalShowing = false;
            window.location.replace(T.getAuthenticationUrls().loginUrl);
          },
          title: 'Your session has expired.',
          content: 'Please click OK to login.'
        });
        previousModalShowing = true;
      }
    }
  };
};

export default ShowRedirectDialog;