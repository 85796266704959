import React, { useEffect, useReducer, useState, useRef } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import { setPageErrorAlert } from 'redux/reducer/info';

import ControlFactory from 'infocontrol';
import { useDispatch, useSelector } from 'react-redux'
import CapacityReleaseApi from 'api/v1/CapacityReleaseApi';
import { Skeleton, Collapse } from 'antd'
import BackTop from '../../backtop/BackTop';
import { CaretRightOutlined } from '@ant-design/icons';
import ColumnTypes from "../../infogrid/ColumnTypes";

const { Panel } = Collapse;

const AllOffer = (props) => {
  const gadgetId = 200;
  const { configs, searchDateSettings } = useSelector((state) => state.info);
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDatePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const dispatch = useDispatch();
  const formRef = useRef(this);

  const messageBundle = useSelector((state) => state.message).message;

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [offerLocationNames, setOfferLocationNames] = useState([]);
  const [offerGeneralLoading, setOfferGeneralLoading] = useState(false);
  const [offerRateLoading, setOfferRateLoading] = useState(false);
  const [offerLocationLoading, setOfferLocationLoading] = useState(false);

  const [offerGeneralData, setOfferGeneralData] = useState([]);
  const [offerRateData, setOfferRateData] = useState([]);
  const [offerLocationData, setOfferLocationData] = useState([]);

  const [fullRangeOnlyDisabled, setFullRangeOnlyDisabled] = useState(false);
  const [biddingStillOpenDisabled, setBiddingStillOpenDisabled] = useState(false);
  const [minimumOfferQtyLocationDisabled, setMinimumOfferQtyLocationDisabled] = useState(true);
  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    // load options for dropdown
    CapacityReleaseApi.getAllOfferLocationNames().then(data => {
      setOfferLocationNames(data)
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    });
    initalQueryAll();
  }, [])

  let offerGeneralNameMap = [
    //1
    'tspName',
    'bidDeal',
    'rtSchedName',
    'maximumOfferQtyContract',
    'releaseTermStartDate',
    // 6
    'releaseTermEndDate',
    'rfqOffrNbr',
    'releaserName',
    'relContractNumber',
    'postingDateTime',
    // 11
    'status',
    'bidPeriodStartDate',
    'bidPeriodEndDate',
    'prearrangedDealCode',
    'bidderName',
    // 16
    'rtFormTypCode',
    'releaseType',
    'permanentReleaseCode',
    'shorterTermInd',
    'minTermDays',
    'relShiprLesserQtyInd',
    'minimumOfferQtyContract',
    'measBasis',
    'pressureBase',
    'pressureBaseCode',
    'recallReputCode',
    'allowableReReleaseInd',
    'previouslyReleasedCode',
    'marketBasedRateInd',
    'ibrIndCapRelInd',
    'discountInd',
    'discountIndNonReservation',
    'indemnificationClause',
    'overrunResponsibilityCode',
    'waiveBidderCreditCode',
    'rightToAmendPrimPtsCode',
    'rappLimit',
    // 'rappTerms',
    'rofrInd',
    // 'roftTerms',
    'rolloverInd',
    'rolloverTerms',
    'storageInvCondReleaseInd',
    // 'termsNotesStorage',
    'relSrContng',
    'contingencyEndDate',
    // 'relSrContngTerms',
    'standAloneOfferInd',
    'offrPkgIdentifier',
    'noStandAlnOfferTc',
    // 'recallReputTerms',
    'businessDayInd',
    'recallNotifPerTimInd',
    'recallNotifPerEeveInd',
    'recallNotifPerEveInd',
    'recallNotifPerId1Ind',
    'recallNotifPerId2Ind',
    'recallNotifPerId3Ind',
    // 'termsNotes',
    'duns',
    'tspProp',
    'releaser',
    'releaserContactName',
    'releaserContactPhone',
    'releaserContactFax',
    'releaserContactEmail',
    'bidder',
    'afflCode',
    'replShipperRoleCode',
    // 'termsNotesAma',
    'relDesAcceptableBidBasis',
    'bidEvalMthdInd',
    'bidEval',
    'bidTieBreakingMthd',
    'minVolCommitPctEvalInd',
    'minAccpVolCommitmentQty',
    'minAccpVolCommitmentPct',
    'minAccpVolLfPct',
    'prearrangedDealMatchDate',
    'capacityAwardDate',
    'cycleIndicator',
    'offerRates',
    'offerLocations',
  ];

  let offerRateNameMap = [
    // 1
    'tspName',
    'rfqOffrNbr',
    'offerRates',
    'rtFormTypCode',
    'rateId',
    // 6
    'reservationRtBasis',
    'relDesAcceptableBidBasis',
    'maxTrfRate',
    'minAcptRate',
    'minAcptPct',
    'maximumOfferQtyContract',
    'seasonalStartDate',
    'seasonalEndDate',
    'marketBasedRateInd',
    'discountInd',
    'discountIndNonReservation',
    'ibrIndCapRelInd',
    'ibrFormulaIdentifier',
    'ibrIndexReference1Mult',
    'ibrIdxReference1',
    'ibrIdxMathOpCode',
    'ibrIndexReference2Mult',
    'ibrIdxReference2',
    'ibrVariableMathOpCode',
    'ibrFormulaVariable',
    'ibrRateDefault',
    'ibrRateFloor',
    'ibrUniqueFormulaSpecTerms',
    'surchargeCode',
    'surchgId',
    'totSurchg',
    'bidDeal',
    'bidPeriodStartDate',
    'bidPeriodEndDate',
    'bidEvalMthdInd',
    'bidEval',
    'bidTieBreakingMthd',
    'minVolCommitPctEvalInd',
    'minAccpVolCommitmentQty',
    'minAccpVolCommitmentPct',
    'minAccpVolLfPct',
    'prearrangedDealCode',
    'bidderName',
    'bidder',
    'duns',
    'tspProp',
    'releaserName',
    'releaser',
    'relContractNumber',
    'rtSchedName',
    'releaseTermStartDate',
    'releaseTermEndDate',
    'postingDateTime',
    'status'
  ];

  let offerLocationNameMap = [
    //1
    'tspName',
    'rfqOffrNbr',
    'offerLocations',
    //'locQti',
    'locQtiName',
    //'purpose',
    'purposeName',
    // 6
    'locName',
    'loc',
    'maximumOfferQtyLocation',
    'locZone',
    'locOba',
    // 11
    'capacityTypeLocationCode',
    'route',
    'relShiprLesserQtyInd',
    'minimumOfferQtyLocation',
    'systemMgtServiceQty',
    'maximumOfferQtyContract',
    'measBasis',
    'pressureBase',
    'pressureBaseCode',
    'duns',
    'tspProp',
    'releaserName',
    'releaser',
    'relContractNumber',
    'rtSchedName',
    'releaseTermStartDate',
    'releaseTermEndDate',
    'bidDeal',
    'bidPeriodStartDate',
    'bidPeriodEndDate',
    'prearrangedDealCode',
    'bidderName',
    'bidder',
    'postingDateTime',
    'status',
  ];

  const processOfferGeneralNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['bidDeal', 'desc'],
      ['rtSchedName', 'asc'],
      ['maximumOfferQtyContract', 'desc'],
      ['rfqOffrNbr', 'asc']
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    cols.push({
      field: 'terms',
      headerName: message['ih.terms'],
      ...ColumnTypes.TermsLink,
      cellRendererParams: {
        url: 'api/v1/crls/getOfferTerms?offerId='
      }
    });

    return cols;
  }

  const processRateNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['releaserName', 'asc'],
      ['rfqOffrNbr', 'asc'],
      ['rateId', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  const processLocationNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['releaserName', 'asc'],
      ['rfqOffrNbr', 'asc'],
      ['locQti', 'asc'],
      ['purpose', 'desc'],
      ['locName', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  let offerGeneralColumnDefs = processOfferGeneralNameMap(getNameModelMap(offerGeneralNameMap));
  let offerRateColumnDefs = processRateNameMap(getNameModelMap(offerRateNameMap));
  let offerLocationColumnDefs = processLocationNameMap(getNameModelMap(offerLocationNameMap));

  const reset = (e) => {
    formRef.current?.resetFields();
    setResultFiltered(false);
  }

  const initalQueryAll = () => {
    const data = formRef.current?.getFieldsValue(true);
    data['releaseTermStartDate'] = T.getDateTimeHHmmStr(data.releaseTermDateRange[0]);
    data['releaseTermEndDate'] = T.getDateTimeHHmmStr(data.releaseTermDateRange[1]);
    queryAll(data);
  }

  const queryAll = (data) => {
    function extractPostAfterDate (data) {
      if(data.postedAfter == null || data.postedAfter === undefined){
        return  null;
      }
      else{
        return data.postedAfter + " 00:00";
      }
    }

    let postData = {
      releaseTermStart: data.releaseTermStartDate,
      releaseTermEnd: data.releaseTermEndDate.replace("00:00", "23:59"),
      postedAfter: extractPostAfterDate(data),
      //biddingStillOpen: data.biddingStillOpen,
      minimumOfferQtyContract: data.minimumOfferQtyContract,
      minimumOfferQtyLocation: data.minimumOfferQtyLocation,
      offerNo: data.rfqOffrNbr,
      biddable: data.biddable,
      fullRangeOnly: data.fullRangeOnly,
      //latestPostOnly: data.latestPostOnly,
      locationName: data.locationName,
    };

    setOfferGeneralLoading(true);
    setOfferRateLoading(true);
    setOfferLocationLoading(true);
    setResultFiltered(false);

    // CapacityReleaseApi.getOfferHeaders(postData).then(data => {
    //   setOfferGeneralData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setOfferGeneralLoading(false);
    // });
    //
    // CapacityReleaseApi.getOfferLocations(postData).then(data => {
    //   setOfferLocationData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setOfferLocationLoading(false);
    // });
    //
    // CapacityReleaseApi.getOfferRates(postData).then(data => {
    //   setOfferRateData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setOfferRateLoading(false);
    // });

    Promise.allSettled([
      CapacityReleaseApi.getOfferHeaders(postData),
      CapacityReleaseApi.getOfferRates(postData),
      CapacityReleaseApi.getOfferLocations(postData)
    ]).then(result => {
      const data = result.map(r => r.status === 'fulfilled' ? r.value : [])
      setOfferGeneralData(data[0])
      setOfferRateData(data[1]);
      setOfferLocationData(data[2]);
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    }).finally(() => {
      setOfferGeneralLoading(false);
      setOfferRateLoading(false);
      setOfferLocationLoading(false);
    });

  };

  const controls = ControlFactory.createControls({
    controls: [
      {
        id: 1, name: 'releaseTermDateRange', type: 'datepickerAsTime',
        label: messageBundle['ih.releaseTermDateRange'],
        rules: [{ required: true, message: message['ih.pleaseInputDateRange'] }]
      },
      {
        id: 7, name: 'fullRangeOnly', type: 'singleCheckbox',
        //values: [{ label: '', value: true }],
        disabled: fullRangeOnlyDisabled,
        label: messageBundle['ih.fullRangeOnly'],
        tooltip: messageBundle['ih.tooltip.fullRangeOnly'],
      },
      {
        id: 2, name: 'postedAfter', type: 'singleDatePicker', format: 'MM/DD/YYYY',
        label: messageBundle['ih.postedEndDateTime']
      },
      /*{
        id: 8, name: 'latestPostOnly', type: 'singleCheckbox',
        values: [{ label: '', value: true }],
        label: messageBundle['ih.latestPostOnly'],
        tooltip: messageBundle['ih.tooltip.latestPostOnly'],
      },*/
      {
        id: 6, name: 'biddable', type: 'select',
        label: messageBundle['ih.biddable'],
        style: { width: 168 },
        values: [
          { label: 'All', value: 0 },
          { label: 'Only biddable', value: 1 },
          { label: 'Only non-biddable', value: 2 },
        ]
      },
      /*{
        id: 3, name: 'biddingStillOpen', type: 'singleCheckbox', values: [{ label: '', value: true }],
        disabled: biddingStillOpenDisabled,
        tooltip: messageBundle['ih.tooltip.biddingStillOpen'],
        label: messageBundle['ih.biddingStillOpen'],
      },*/
      {
        id: 5, name: 'rfqOffrNbr', type: 'string',
        label: messageBundle['ih.offerNumber']
      },
      {
        id: 4, name: 'minimumOfferQtyContract', type: 'integer',
        label: messageBundle['ih.minimumOfferQtyContract']
      },
      {
        id: 9, name: 'locationName', type: 'select',
        values: T.createSelectOptionFromArray(offerLocationNames),
        label: messageBundle['ih.locationName'],
      },
      {
        id: 10, name: 'minimumOfferQtyLocation', type: 'integer',
        disabled: minimumOfferQtyLocationDisabled,
        tooltip: messageBundle['ih.tooltip.minimumOfferQtyLocation'],
        label: messageBundle['ih.minimumOfferQtyLocation'],
      },
    ],
    initialValues: {
      'releaseTermDateRange': [datePeriod.startDate, datePeriod.endDate],
      'biddable': 1,
      //'biddingStillOpen': false,
      'fullRangeOnly': false,
      //'latestPostOnly': false,
      'locationName': null,
    },
    onValuesChange: (changedValues, allValues) => {
      if (!T.isUndefined(changedValues['biddable'])) {
        setBiddingStillOpenDisabled(changedValues['biddable'] === 2);
        if (changedValues['biddable'] === 2) {
          formRef.current?.setFieldsValue({ 'biddingStillOpen': false });
        }
      } else if (!T.isUndefined(changedValues['releaseTermDateRange'])) {
        setFullRangeOnlyDisabled(
          T.isEmpty(changedValues['releaseTermDateRange']) ||
          T.isEmpty(changedValues['releaseTermDateRange'][0]) ||
          T.isEmpty(changedValues['releaseTermDateRange'][1])
        )
      } else if (!T.isUndefined(changedValues['locationName'])) {
        setMinimumOfferQtyLocationDisabled(T.isEmpty(changedValues['locationName']))
        if (T.isEmpty(changedValues['locationName'])) {
          formRef.current?.setFieldsValue({ 'minimumOfferQtyLocation': '' });
        }
      }
    },
    settings: { colSpan: 2, },
    formRef,
    reset,
    submit: queryAll
  });

  const [isResultFiltered, setResultFiltered] = useState(false);

  const filterResults = (row, isFilter) => {
    const filterFunction = r => {
      if (!isFilter) {
        r.isHidden = false
      }
      else {
        r.isHidden = !(r.rfqOffrNbr === row.rfqOffrNbr && r.tsp === row.tsp);
      }
      return r;
    }
    setOfferGeneralLoading(true);
    setOfferRateLoading(true);
    setOfferLocationLoading(true);

    let generalData = offerGeneralData?.map(filterFunction);
    let rateData = offerRateData?.map(filterFunction);
    let locationData = offerLocationData?.map(filterFunction);

    setOfferGeneralData(generalData?.map(filterFunction));
    setOfferRateData(rateData?.map(filterFunction));
    setOfferLocationData(locationData?.map(filterFunction));

    setResultFiltered(isFilter);
    forceUpdate();
    //setTimeout(() => {
    setOfferGeneralLoading(false);
    setOfferRateLoading(false);
    setOfferLocationLoading(false);
    //}, 1000);
  }

  const menuItems = configs.focusOfferRows ? [
    'separator',
    {
      name: messageBundle['ih.focusOfferRows.on'],
      disabled: isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, true)
      }
    }, {
      name: messageBundle['ih.focusOfferRows.off'],
      disabled: !isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, false)
      }
    },
  ] : null;

  const gridOptions = { height: 600, contextMenuItems: menuItems, autoResizeHeight: true };
  const offerGeneralGrid = <InfoGrid id="AllOffer_General" key={_ + 1} columnDefs={offerGeneralColumnDefs} gridOptions={gridOptions} data={offerGeneralData} ></InfoGrid>;
  const offerRateGrid = <InfoGrid id="AllOffer_General" key={_ + 2} columnDefs={offerRateColumnDefs} gridOptions={gridOptions} data={offerRateData} ></InfoGrid>;
  const offerLocationGrid = <InfoGrid id="AllOffer_General" key={_ + 3} columnDefs={offerLocationColumnDefs} gridOptions={gridOptions} data={offerLocationData} ></InfoGrid>;

  return (
    <div className='gadget full-width'>
      <BackTop />
      <div className='gadget-container'>
        <div className="gadget-header">
          <div className="gadget-title"> {messageBundle['ih.widget.capacityRelease.allOffer']}</div>
        </div>
        <div className='gadget-controls'>
          {controls}
        </div>
        <div className="gadget-item-content" style={{padding: '0px', marginLeft: '-6px', marginRight: '-6px'}}>
          <Collapse ghost={true}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={['1', '2', '3']}>
            <Panel header={messageBundle['ih.offer.general']} key='1'>
              <Skeleton active loading={offerGeneralLoading}>
                {offerGeneralGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.offer.location']} key='2'>
              <Skeleton active loading={offerLocationLoading}>
                {offerLocationGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.offer.rate']} key='3'>
              <Skeleton active loading={offerRateLoading}>
                {offerRateGrid}
              </Skeleton>
            </Panel>
          </Collapse >
        </div>
      </div>
    </div >
  );

};

export default AllOffer;
