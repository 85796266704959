import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

export default class CapacityReleaseApi {

  static getOfferHeaders(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crls/getOfferHeaders', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getOfferHeaders');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getOfferRates(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crls/getOfferRates', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getOfferRates');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getOfferLocations(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crls/getOfferLocations', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getOfferLocations');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAllOfferLocationNames(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crls/getAllOfferLocationNames', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getAllOfferLocationNames');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getBidHeaders(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsBid/getBidHeaders', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getBidHeaders');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getBidRates(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsBid/getBidRates', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getBidRates');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getBidLocations(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsBid/getBidLocations', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getBidLocations');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAwardHeaders(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsAward/getAwardHeaders', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getAwardHeaders');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAwardRates(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsAward/getAwardRates', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getAwardRates');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAwardLocations(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsAward/getAwardLocations', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getAwardLocations');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getWithdrawalIndOptions(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/crlsWithdrawal/getWithdrawalIndOptions', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getWithdrawalIndOptions');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
}