import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

export default class NoticesApi {
    static async callOptionsRequest(optionsLoadingSetter, optionsSetter, getOptionsRequest) {
        optionsLoadingSetter(true);

        let response = await fetch(getOptionsRequest)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                return responseJson?.data;
            })
            .catch(error => {
                return null;
            });

        optionsLoadingSetter(false);

        let opts = [];

        if (response) {
            let mappedOptions = response.map((i) => ({
                label: i,
                value: i
            }));
            opts = mappedOptions;
        }

        optionsSetter(opts);
    }

    /**
     * Common call from global and ai/ml notice search to get possible notice type options
     */
    static async fetchNoticeTypeOptions(optionsLoadingSetter, optionsSetter) {
        let getOptionsRequest = ApiRequest.composePostJsonRequest('api/v1/notices/getNoticeTypeOptions',
            null, {});

        NoticesApi.callOptionsRequest(optionsLoadingSetter, optionsSetter, getOptionsRequest)
    }

    static getNoticeTypes(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/notices/getNoticeTypeOptions', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t get notice types');
                    return null;
                } else {
                    //
                }
                return responseJson?.data;
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }

    static getNoticeCategories(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/notices/getNoticeCategoryOptions', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t get notice categories');
                    return null;
                } else {
                    //
                }
                return responseJson?.data;
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }

    static getKeywordGlossary(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/notices/getKeywordGlossary', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t get keyword glossary');
                    return null;
                } else {
                    //
                }
                return responseJson?.data;
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }
}