import './TariffSearchPage.css'
import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {useSelector, useDispatch} from 'react-redux'
import { Input, message, Collapse, Skeleton, Modal, Select } from 'antd';
import {
    MessageOutlined,
    SendOutlined,
    PlusCircleOutlined,
    DeleteOutlined,
    LinkOutlined,
    ExclamationCircleFilled,
} from '@ant-design/icons';
import {TUtil as T} from '@trellisenergy/common-ui-core';

import TrellisAIApi from "../../../api/v1/TrellisAIApi";
import BackTop from "../../backtop/BackTop";
import Tooltip from "../../tooltip/Tooltip";
import { setPageErrorAlert } from "../../../redux/reducer/info";
const { Panel } = Collapse;
const { Search} = Input;

const TariffSearchPage = (props) => {
    const NEW_ROOM_ID = -1;
    const rootState = useSelector((state) => state.message);
    const dispatch = useDispatch();
    const trellisMessages = rootState.message;
    
    const [pipelines, setPipelines] = useState([]);
    const [selectedPipelines, setSelectedPipelines] = useState([]);
    const [modal, contextHolder] = Modal.useModal();
    const [chatHistoryLoading, setChatHistoryLoading] = useState(false);
    const [answerLoading, setAnswerLoading] = useState(false);
    const [question, setQuestion] = useState('');
    const [chatRooms, setChatRooms] = useState([]);
    const [activeChatRoomId, setActiveChatRoomId] = useState(NEW_ROOM_ID);
    const [chatHistory, setChatHistory] = useState( []);

    /**
     * sort chat based on time before setting
     * @param rooms
     */
    const updateChatRooms = (rooms)=> {
        rooms?.sort((a,b)=> b.updated_at - a.updated_at)
        setChatRooms(rooms);
    }
    
    /**
     * scroll to bottom of chat history after updating the chat
     * @param chatHistory
     */
    const updateChatHistory = (chatHistory) => {
        chatHistory.forEach(c=> console.debug(c.text, c.created_time))
        chatHistory?.sort((a,b)=> a.created_time - b.created_time)
        setChatHistory(chatHistory);
        scrollBottomOfChatHistory();
    }
    
    const initChatRooms = async () => {
        try {
            fetchPipelines();
            const rooms = await TrellisAIApi.getAllRooms()
            updateChatRooms(rooms);
        } catch(e) {
            console.warn(e);
            dispatch(setPageErrorAlert({ show: true, message: trellisMessages['ih.trellisSearch.chat.load.failure'] }));
        }
    }
    const onLoad = () => {
        // get all rooms
        initChatRooms()
    }
    useEffect(() => {
        onLoad();
    }, [])
    
    const scrollBottomOfChatHistory  = () => {
        const elm = document.querySelector('.chat-container');
        const height = elm.scrollHeight;
        elm.scrollTo(0, height);
    }
    const askQuestion = (text) => {
        setAnswerLoading(true);
        scrollBottomOfChatHistory(); // to show the loading indicator
        TrellisAIApi.askQuestion(activeChatRoomId, text, selectedPipelines).then(result => {
            // new chat
            if(activeChatRoomId === NEW_ROOM_ID) {
                TrellisAIApi.getAllRooms().then(rooms=> {
                    setActiveChatRoomId(result[0].room_id)
                    updateChatRooms(rooms);
                })
            }
            updateChatHistory(chatHistory.concat(result));
            setQuestion('');
            setAnswerLoading(false);
        }).catch(e=> {
            dispatch(setPageErrorAlert({ show: true, message: trellisMessages['ih.trellisSearch.ask.load.failure'] }));
            setAnswerLoading(false)
        })
    }
    
    const reset = () => {
        setActiveChatRoomId(NEW_ROOM_ID);
        setChatHistory([]);
        setQuestion('');
    }
    
    const deleteRoom = (roomId) => {
        Modal.confirm({
            title: trellisMessages['ih.confirm'],
            icon: <ExclamationCircleFilled />,
            content: trellisMessages['ih.trellisSearch.chat.delete.confirm'],
            onOk() {
                return TrellisAIApi.deleteRoom(roomId).then((result)=> {
                    if (result.success) {
                        const newChatRooms = chatRooms.filter(room=> {
                            return room.room_id != roomId;
                        })
                        updateChatRooms(newChatRooms);
                        reset();
                        message.success(trellisMessages['ih.trellisSearch.chat.delete.success'], 1.5)
                    }
                }).catch(()=> {
                    message.warn(trellisMessages['ih.trellisSearch.chat.delete.failure'], 1.5)
                });
            },
            onCancel() {},
        });
    }
    
    const fetchPipelines = () => {
        setPipelines([]);
        T.delay(100).then(() => TrellisAIApi.getAllPipelines().then(result => {
            setPipelines(result);
        }).catch(console.warn));
    }
    
    /**
     * Retrieve chats for a room
     * @param roomId
     */
    const fetchRoomChats = (roomId) => {
        setChatHistory([]);
        setActiveChatRoomId(roomId);
        
        // new chat
        if (roomId === NEW_ROOM_ID) {
            return;
        }
        setChatHistoryLoading(true);
        T.delay(100).then(() => TrellisAIApi.getRoomChat(roomId).then(result => {
            updateChatHistory(result)
            setChatHistoryLoading(false);
        }).catch(e=> {
            console.warn(e);
            setChatHistoryLoading(false);
        }));
    }
    
    const getPipelines = (pipelines) => {
        const options = pipelines.map(p => {
            return {"label": p.pipeline_name, "value": p.pipeline_id}
        });

        const filterOption = (inputValue, option) => {
            return option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        }
        
        return <div style={{ padding: "10px 20px", marginBottom: '15px'}}>
            <Select
              style={{width: "100%"}}
              mode="multiple"
              allowClear
              placeholder="select pipelines to query on"
              onChange={setSelectedPipelines}
              options={options}
              dropdownMatchSelectWidth={false}
              filterOption={filterOption}
            />
        </div>
    }
    const getRooms = (rooms) => {
        const allRooms = rooms?.map(room => {
            return <li key={Math.random()} className={'chat-room-container ' + (activeChatRoomId == room.room_id ? 'active-room': '')}
                       onClick={()=> { fetchRoomChats(room.room_id); }}>
                <MessageOutlined /><span className={'chat-room'}>{room.preview_text}</span>
                {activeChatRoomId == room.room_id ?
                  <Tooltip title={'Delete chat'}>
                <DeleteOutlined onClick={(event) => {
                deleteRoom(room.room_id);
                event.stopPropagation();
            }} style={{color: '#DC143C'}}/>
                </Tooltip>
            :''}
            </li>
        })
       
        return <div className={'chat-history'}>
            <div style={{width: "inherit"}}>
                {getPipelines(pipelines)}
                <ul>
                    <li key={Math.random()} className={'chat-room-container ' + (activeChatRoomId === NEW_ROOM_ID ? 'active-room': '')}
                        onClick={()=> {
                            fetchRoomChats(NEW_ROOM_ID);
                        }}
                    ><PlusCircleOutlined /><span className={'chat-room'}>New Chat</span></li>
                    {allRooms}
                </ul>
            </div>
        </div>
    }

    const getChats = (chatResult) => {
        const items = !T.isEmpty(chatResult.references) && chatResult.references?.map((reference,idx) => {
            const header = <div>{reference.title} {reference.link ? <a href={reference.link} target={"_blank"}><LinkOutlined />Open Link</a> : ''}</div>
            const itemList =  <Panel key={idx} header={header}>
                <div className={'chat-message-text'}>
                    <ReactMarkdown remarkPlugins={[remarkGfm]} children={reference.text} />
                </div>
            </Panel>
            
            return !T.isEmpty(itemList) ?
              <Collapse accordion>
                  {itemList}
              </Collapse>
              :''
        })
        const content =<div>
            <div className="chat-message-text"><ReactMarkdown remarkPlugins={[remarkGfm]} children={chatResult.text} /></div>
            {items}
        </div>

        /*
        return <Badge.Ribbon
                className={"chat-message " + (chatResult.is_user_question ? 'from-me' : 'from-them')}
                color={chatResult.is_user_question ? '#54A1A8' : '#C17E50'} placement={(chatResult.is_user_question ? 'end': 'start')} text={content}>
            </Badge.Ribbon>
         */

        return <div key={Math.random()} className={"chat-message " + (chatResult.is_user_question ? 'from-me' : 'from-them')}>{content}</div>
    }
    
    const getChatHistory = (history) => {
       return history.map(entry=> {
           return getChats(entry)
       })
    }
    
    const getChatHistorySkeleton = () => (
      <>
          <Skeleton active={true} loading={chatHistoryLoading} avatar={false} paragraph={{ rows: 2}}/>
          <Skeleton active={true} loading={chatHistoryLoading}  avatar={false} paragraph={{ rows: 2}}/>
          <Skeleton active={true} loading={chatHistoryLoading}  avatar={false} paragraph={{ rows: 2}}/>
      </>
    )
    
    const getAnswerLoadingSkeleton = () => (
       <Skeleton active={true} loading={ answerLoading} avatar={false} paragraph={{ rows: 2}}/>
    )
    
    const getNewChatMessage = () => (<span className={'intro-message'} style={{display: activeChatRoomId === NEW_ROOM_ID && !answerLoading ? 'inline': 'none'}}>Hello...How can I help you?</span>);
    
    const chat =
      <div className="chat-container">
          <div className="chat">
              {getNewChatMessage()}
              {getChatHistorySkeleton()}
              {getChatHistory(chatHistory)}
              {getAnswerLoadingSkeleton()}
          </div>
      </div>;

    
    const form =
        <div className={'chat-source'}>
            {getRooms(chatRooms)}
            <div className={'content-container'}>
                {chat}
                <div>
                    <Search disabled={answerLoading} autoFocus={true} className='input-container' placeholder="ask me anything" allowClear value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            onSearch={askQuestion} enterButton={<SendOutlined
                      style={{
                          color: 'rgba(0,0,0,.45)',
                      }}
                    />} />
                </div>
            </div>
        </div>;

    const gadget =
      <div className='gadget full-width' style={{height: '100%'}}>
        <BackTop/>
        <div className='gadget-container' style={{height: "inherit"}}>
            <div className="gadget-header">
                <div className="gadget-title"> {trellisMessages['ih.menu.tariffSearch']}</div>
            </div>
            <div className="gadget-item-content" style={{height: "inherit", padding: '0px'}}>
                {form}
            </div>
        </div>
    </div>
    
    return(gadget);
}
export default  TariffSearchPage;