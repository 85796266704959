import React, { useRef, useState } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';

function MDQsByCustomerDetail(props) {
    const location = useLocation();
    const mdq = location.state?.mdq;
    const isTransport = () => {
        return mdq === 'TRANSPORT';
    };

    const isStorage = () => {
        return mdq === 'STORAGE';
    };
    const { message } = useSelector((state) => state.message);
    const [postData, setPostData] = useState({
        'entityId': location.state?.entityId,
        'mdq': mdq
    });
    // const [postDataMaxCalendarQuarter] = useState({});

    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/ioc/getMDQsByCustomerDetail', postData, refreshCount);
    const queryForm = useRef();
    const gridRef = useRef();

    const refresh = () => {
        setRefresh(Math.random())
    };

    const onGridReady = (params) => {
        let gridApi = params.api;
        let mdqColumnFilter = location.state?.mdqColumnFilter;
        if (!mdqColumnFilter) {
            return;
        }
        gridApi.getFilterInstance(mdqColumnFilter)?.setModel({
            values: ['Dth', 'MMBtu']
        });
        gridApi.onFilterChanged();
    };

    let nameMap = [
        'pipelineName',
        'shipperName',
        'firstDateCalendarQuarter',
        'rateSchedule',
        'contractNumber',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'shipperAffiliateIndicator',
        'daysUntilExpire',
        'negativeRatesIndicator',
        'transportMdq',
        'transportMdqUom',
        'storageMdq',
        'storageMdqUom',
        'footnoteItemList',
    ];

    let nameMapTransport = [
        'pipelineName',
        'shipperName',
        'firstDateCalendarQuarter',
        'contractNumber',
        'transportMdq',
        'transportMdqUom',
        'rateSchedule',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'shipperAffiliateIndicator'
    ];

    let nameMapStorage = [
        'pipelineName',
        'shipperName',
        'firstDateCalendarQuarter',
        'contractNumber',
        'storageMdq',
        'storageMdqUom',
        'rateSchedule',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'shipperAffiliateIndicator'
    ];

    let columnDefs = getNameModelMap(isTransport() ? nameMapTransport : isStorage() ? nameMapStorage : nameMap);

    const processColumns = (cols) => {
        // let groupingCols = [
        //     'firstDateCalendarQuarter',
        //     'shipperName'
        // ].map(field => {
        //     return T.find(cols, (c) => c.field === field)
        // });
        //
        // groupingCols.forEach(colModel => {
        //     if (colModel) {
        //         if (colModel.field === 'firstDateCalendarQuarter') {
        //             colModel.rowGroupIndex = 0;
        //         } else if (colModel.field === 'shipperName') {
        //             colModel.rowGroupIndex = 1;
        //         }
        //         colModel.rowGroup = true;
        //         colModel.hide = true;
        //     }
        // });

        let mdqCol = T.find(cols, (c) => c.field === 'transportMdq' || c.field === 'storageMdq');
        mdqCol.sort = 'desc';

        let sumColumns = ['transportMdq', 'storageMdq'].map(field => {
            return T.find(cols, (c) => c.field === field)
        });
        sumColumns.forEach(colModel => {
            if (colModel) {
                colModel.aggFunc = 'sum';
            }
        });
        return cols;
    };

    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'mdq', type: 'hidden', label: '' },
            { id: 2, name: 'entityId', type: 'hidden', label: '' }
        ],
        initialValues: {
            'entityId': location.state?.entityId,
            'mdq': mdq
        },
        submit: function (params) {
            setPostData(params)
        },
        formRef: queryForm
    });

    const gadgetItem = {
        grid: <InfoGrid id="MDQsByCustomerDetail" gridRef={gridRef} gridOptions={{ events: { onGridReady: onGridReady } }} columnDefs={processColumns(columnDefs)} data={output.iocContractViewList}></InfoGrid>
    };

    const getTitle = () => {
        if (isTransport()) {
            return output.firstDayCalendarQuarter ? message['ih.widget.ioc.mdqsByCustomerTransport'] + ' Q' + output.quarterOfYear + ' ' + output.quarterYear : message['ih.widget.ioc.mdqsByCustomerTransport'] + " N/A";
        } else if (isStorage()) {
            return output.firstDayCalendarQuarter ? message['ih.widget.ioc.mdqsByCustomerStorage'] + + ' Q' + output.quarterOfYear + ' ' + output.quarterYear : message['ih.widget.ioc.mdqsByCustomerStorage'] + " N/A";
        }
        return message['ih.widget.ioc.mdqsByCustomerDetail'];
    };

    const gadget = {
        loading: loading,
        refresh,
        id: 'Gadget1',
        title: getTitle(),
        views: ['grid'], // grid
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        dataSourceId: 1,
        width: props.width || 100
    };

    return (
        <Gadget {...gadget} />
    );
}

export default MDQsByCustomerDetail;
