import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from "infogrid";
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import NoticesUtil from "./noticesUtil";
import SharedColDefs from 'asset/SharedColDefs';

const AllNotices = (props) => {
  const gadgetId = 302;
  const {searchDateSettings} = useSelector((state) => state.info)
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const location = useLocation();
  const caller = location.state?.caller;
  const startDate = location.state?.startDate;
  const endDate = location.state?.endDate;
  const datasetNm = location.state?.datasetNm;

  const customTitle = location.state?.title;

  const { message } = useSelector((state) => state.message)
  const [postData, setPostData] = useState({ startDate: startDate ? startDate : caller ? T.getYesterdayDateTimeHHmmStr() : T.getLast30DaysDateHHmmStr(), endDate: endDate ? endDate : T.getTodayDateTimeHHmmStr() });
  const [refreshCount, setRefresh] = useState(0);
  let [loading, output, loadTimestamp] = useFetchData('api/v1/notices/getAllNotices', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  };

  let newNameMap = [...SharedColDefs.NoticesData.All];

  // special order when coming from Widget
  // insert noticeTypeDesc after criticalNoticeDescription
  // if (caller === 'summary') {
  //   const index1 = newNameMap.indexOf('criticalNoticeDescription');
  //   const index2 = newNameMap.indexOf('noticeTypeDesc');
  //   // delete for master
  //   newNameMap.splice(index2, 1);
  //   // insert new
  //   newNameMap.splice(index1 + 1, 0, 'noticeTypeDesc');
  // }
  let columnDefs = getNameModelMap(newNameMap);

  // const onGridReady = (params) => {
  //   // https://www.ag-grid.com/react-grid/filter-api/
  //   if (datasetNm) {
  //     setTimeout(function() {
  //       var filter = {
  //         'noticeType': {
  //           values: [datasetNm],
  //           type: 'set',
  //         },
  //       };
  //       params.api.filterManager.setFilterModel(filter);
  //     },1000)
  //
  //   }
  // }

  const controls = ControlFactory.createControls({
    controls: [{ id: 1, name: 'dateRange', type: 'dateTimepicker', label: message['ih.postedDateRange'] }],
    initialValues: {
      'dateRange': [
        startDate ? T.getDateTimeHHmm(startDate) : caller ? T.getYesterdayDate() : datePeriod.startDate,
        endDate ? T.getDateTimeHHmm(endDate) : caller ? T.getDateTimeHHmm() : datePeriod.endDate
      ],
    },
    submit: setPostData
  });

  const processCols = (cols) => {
    if (caller === 'summary') {
      let groupColumn = T.find(cols, (c) => c.field === 'noticeType');
      groupColumn.rowGroup = groupColumn.hide = true;
      groupColumn.aggFunc = 'count';
      groupColumn.sort = 'desc';
    }

     else if (caller === 'critical') {
      let groupColumn = T.find(cols, (c) => c.field === 'datasetNm');
      groupColumn.rowGroup = groupColumn.hide = true;
      output = output.filter( function(r) {
        return (r.criticalIndicator === 'Y');
      });
    }

    //return NoticesUtil.setSortingCols(cols);
    return cols;
  };

  const gadgetItem = { grid: <InfoGrid id={"AllNotices_" + caller} columnDefs={processCols(columnDefs)} data={output} ></InfoGrid> }
  const gadget = {
    id: gadgetId,
    name: 'AllNotices',
    loading,
    refresh,
    title: customTitle || message['ih.widget.notices.all'],
    views: ['grid'],
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget}></Gadget>
  );
}

export default AllNotices;