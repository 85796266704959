import { Route, Link } from 'react-router-dom'
import {
  PieChartOutlined,
  FileOutlined,
  TeamOutlined,
  FundOutlined,
  ProfileOutlined,
  MenuFoldOutlined,
  HomeOutlined,
  GlobalOutlined,
  SolutionOutlined,
  DatabaseOutlined,
  TableOutlined,
  ControlOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  CloseOutlined,
  FieldTimeOutlined,
  ShareAltOutlined,
  FilePdfOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import {
  AnalyticalHubIcon, DataHubIcon, InfoHubIcon, TransactionHubIcon, AgoraConnectHubIcon,
  HomeActiveIcon, InfoHubHeaderActiveIcon, TransactionHubHeaderActiveIcon, AnalyticalHubHeaderActiveIcon, DataHubHeaderActiveIcon, AgoraConnectHubHeaderActiveIcon,
  HomeInactiveIcon, InfoHubHeaderInactiveIcon, TransactionHubHeaderInactiveIcon, AnalyticalHubHeaderInactiveIcon, DataHubHeaderInactiveIcon, AgoraConnectHubHeaderInactiveIcon,
  InfoHubHeaderDisabledIcon, TransactionHubHeaderDisabledIcon, AnalyticalHubHeaderDisabledIcon, DataHubHeaderDisabledIcon, AgoraConnectHubHeaderDisabledIcon,
  UserLoggedInIcon, UserLoggedOutIcon, HubHeaderDividerIcon, LoginIcon, LogoutIcon, SettingIcon,
  InfoHubMenuIcon,
} from 'util/InfoIconLoader';

import {Space, Dropdown, Button, message as antdMessage} from 'antd';

import HomePage from '../page/home/Home'
import DashboardPage from "../page/dashboard/Dashboard"
import CapacityPage from '../page/capacity/Capacity';
import CapacityReleasePage from '../page/capacityRelease/CapacityRelease';
import ImbalancePage from '../page/imbalance/Imbalance';
import LocationPage from '../page/locations/Locations';
import NoticePage from '../page/notices/Notices';
import IOCPage from '../page/ioc/IOC';
import PreferencesPage from '../page/preferences/Preferences';
import TariffSearchPage from '../page/tariffSearch/TariffSearchPage';
import AdminPage from '../page/admin/Admin';
import Tooltip from '../tooltip/Tooltip'

import { TUtil as T, Config } from '@trellisenergy/common-ui-core';
import './Menu.css'
import { Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SupportIcon } from '../../util/InfoIconLoader';

const { INFOHUB_CONTEXT, AGORA_CONTEXT, CONTEXT } = Config;
const { Sider } = Layout;
const { SubMenu } = Menu;

const HubData = [
  {
    id: 100,
    name: 'agoraHome',
    title: 'Agora Home',
    icon: HomeActiveIcon,
    headerIcons: {
      active: HomeActiveIcon,
      inactive: HomeInactiveIcon,
      disabled: HomeInactiveIcon,
    },
    defaultActive: true,
    path: AGORA_CONTEXT,
    onlyHeader: true, // Only in Header
    description: null,
  },
  {
    id: 334401,
    name: 'transactionHub',
    title: 'Transact',
    icon: TransactionHubIcon,
    headerIcons: {
      active: TransactionHubHeaderActiveIcon,
      inactive: TransactionHubHeaderInactiveIcon,
      disabled: TransactionHubHeaderDisabledIcon,
    },
    path: null,
    isExternal: true,
    description: 'Manage your energy assets, logistics, throughput and revenue, and customer-facing services.',
  },
  {
    id: 334405,
    name: 'agoraHub',
    title: 'Connect',
    icon: AgoraConnectHubIcon,
    headerIcons: {
      active: AgoraConnectHubHeaderActiveIcon,
      inactive: AgoraConnectHubHeaderInactiveIcon,
      disabled: AgoraConnectHubHeaderDisabledIcon,
    },
    path: null,
    isExternal: true,
    description: 'Safely and securely exchange nominations, confirmations, settlement, and other electronic data with multiple providers.',
  },
  {
    id: 334402,
    name: 'infoHub',
    title: 'Inform',
    icon: InfoHubIcon,
    headerIcons: {
      active: InfoHubHeaderActiveIcon,
      inactive: InfoHubHeaderInactiveIcon,
      disabled: InfoHubHeaderDisabledIcon,
    },
    path: INFOHUB_CONTEXT + '/dashboard',
    description: 'Easily view, search, filter, and analyze aggregated market information.',
  },
  {
    id: 334403,
    name: 'analyticalHub',
    title: 'Analytical Hub',
    icon: AnalyticalHubIcon,
    headerIcons: {
      active: AnalyticalHubHeaderActiveIcon,
      inactive: AnalyticalHubHeaderInactiveIcon,
      disabled: AnalyticalHubHeaderDisabledIcon,
    },
    path: null,
    isExternal: true,
    description: 'Gain valuable analytical, operational, and predictive insights from your data.',
    isComing: true,
    skipHeader: true, // Do not include in Header
    isInvisible: true,
  },
  {
    id: 334404,
    name: 'dataHub',
    title: 'Data Hub',
    icon: DataHubIcon,
    headerIcons: {
      active: DataHubHeaderActiveIcon,
      inactive: DataHubHeaderInactiveIcon,
      disabled: DataHubHeaderDisabledIcon,
    },
    path: null,
    isExternal: true,
    description: 'Access and download meaningful data in a business format.',
    isComing: true,
    skipHeader: true, // Do not include in Header
    isInvisible: true,
  },
  {
    id: 1000,
    name: 'support',
    title: 'Support',
    icon: SupportIcon,
    headerIcons: {
      active: SupportIcon,
      inactive: SupportIcon,
      disabled: SupportIcon,
    },
    path: null,
    isExternal: false,
    skipHeader: true, // Do not include in Header
    defaultActive: true,
    isInvisible: true,
    description: 'Need help? Reach out to our Customer Support team.',
  },
]

const AppMenuRouteData = [
  {
    id: 'divider',
  },
  {
    id: 1,
    name: 'home',
    title: 'ih.menu.home',
    icon: HomeOutlined,
    path: 'agora',
    component: HomePage,
    skipMenu: true,
    skipRoute: true,
  },
  {
    id: 2,
    name: 'information',
    title: 'ih.menu.infohub',
    icon: InfoHubMenuIcon,
    submenu: [
      {
        id: 21,
        name: 'dashboard',
        title: 'ih.menu.dashboard',
        tabName: 'ih.tab.dashboard',
        icon: PieChartOutlined,
        path: INFOHUB_CONTEXT + '/dashboard',
        component: DashboardPage,
        exact: true,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
      {
        id: 22,
        name: 'capacity',
        title: 'ih.menu.capacity',
        icon: DashboardOutlined,
        submenu: [
          {
            id: 221,
            name: 'allOperAvailCap',
            title: 'ih.widget.capacity.allOperAvailCap',
            tabName: 'ih.tab.allOperAvailCap',
            path: INFOHUB_CONTEXT + '/capacity/AllOperAvailCap',
            component: CapacityPage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
          {
            id: 222,
            name: 'allUnsubbedCap',
            title: 'ih.widget.capacity.allUnsubbedCap',
            tabName: 'ih.tab.allUnsubbedCap',
            path: INFOHUB_CONTEXT + '/capacity/AllUnsubbedCap',
            component: CapacityPage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
        ]
      },
      {
        id: 23,
        name: 'capacityRelease',
        title: 'ih.menu.capacityRelease',
        icon: ShareAltOutlined,
        submenu: [
          {
            id: 231,
            name: 'allOffer',
            title: 'ih.widget.capacityRelease.allOffer',
            tabName: 'ih.tab.capacityReleaseAllOffer',
            path: INFOHUB_CONTEXT + '/capacityRelease/AllOffer',
            component: CapacityReleasePage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
          {
            id: 232,
            name: 'allBid',
            title: 'ih.widget.capacityRelease.allBid',
            tabName: 'ih.tab.capacityReleaseAllBid',
            path: INFOHUB_CONTEXT + '/capacityRelease/AllBid',
            component: CapacityReleasePage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
          {
            id: 233,
            name: 'allAward',
            title: 'ih.widget.capacityRelease.allAward',
            tabName: 'ih.tab.capacityReleaseAllAward',
            path: INFOHUB_CONTEXT + '/capacityRelease/AllAward',
            component: CapacityReleasePage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
          {
            id: 234,
            name: 'allWithdrawal',
            title: 'ih.widget.capacityRelease.allWithdrawal',
            tabName: 'ih.tab.capacityReleaseAllWithdrawal',
            path: INFOHUB_CONTEXT + '/capacityRelease/AllWithdrawal',
            component: CapacityReleasePage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
          {
            id: 235,
            name: 'allApprovedBidder',
            title: 'ih.widget.capacityRelease.allApprovedBidder',
            tabName: 'ih.tab.capacityReleaseAllPreApprovedBidder',
            path: INFOHUB_CONTEXT + '/capacityRelease/AllPreApprovedBidder',
            component: CapacityReleasePage,
            authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
          },
        ]
      },
      {
        id: 28,
        name: 'tariffSearch',
        title: 'ih.menu.tariffSearch',
        icon: SearchOutlined,
        path: INFOHUB_CONTEXT + '/TariffSearch',
        component: TariffSearchPage,
        authorities: ['VIEW_AI_TARIFF'],
      },
      {
        id: 24,
        name: 'ioc',
        title: 'ih.menu.ioc',
        tabName: 'ih.tab.ioc',
        icon: TeamOutlined,
        path: INFOHUB_CONTEXT + '/ioc',
        component: IOCPage,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
      {
        id: 2499,
        name: 'ioc',
        title: 'ih.menu.ioc',
        tabName: 'ih.tab.transportMDQs',
        path: INFOHUB_CONTEXT + '/ioc/MDQsByCustomerDetail',
        component: IOCPage,
        authorities: ['DO_NOT_SHOW_IN_MENU'],
      },
      {
        id: 25,
        name: 'locations',
        title: 'ih.menu.locations',
        tabName: 'ih.tab.locations',
        icon: GlobalOutlined,
        path: INFOHUB_CONTEXT + '/locations',
        component: LocationPage,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
      {
        id: 26,
        name: 'notices',
        title: 'ih.menu.notices',
        tabName: 'ih.tab.notices',
        icon: FileOutlined,
        path: INFOHUB_CONTEXT + '/notices',
        component: NoticePage,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
      {
        id: 27,
        name: 'imbalance',
        title: 'ih.menu.imbalance',
        tabName: 'ih.tab.imbalance',
        icon: FundOutlined,
        path: INFOHUB_CONTEXT + '/imbalance',
        component: ImbalancePage,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
      /*
      {
        id: 27,
        name: 'transactionalReporting',
        title: 'ih.menu.transactionalReporting',
        icon: FilePdfOutlined,
        path: INFOHUB_CONTEXT + '/transactionalReporting',
        component: TransactionalReporting,
        authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
      },
       */
    ]
  },
  {
    id: 'divider',
  },
  {
    id: 3,
    name: 'preferences',
    title: 'ih.menu.preferences',
    icon: ProfileOutlined,
    path: INFOHUB_CONTEXT + '/preferences',
    component: PreferencesPage,
    authorities: ['MANAGE_INFOHUB_PREFERENCES'],
  },
  {
    id: 4,
    name: 'admin',
    title: 'ih.menu.admin',
    icon: SolutionOutlined,
    authorities: [
      'APPROVE_INFOHUB_DATA',
      'PUBLISH_INFOHUB_DATA',
      'MANAGE_INFOHUB_DATASET_STATUS',
      'MANAGE_INFOHUB_SOURCE_FILE',
      'VIEW_INFOHUB_DATASET_STATUS',
      'VIEW_INFOHUB_SOURCE_FILE',
      'VIEW_INFOHUB_CACHE',
      'MANAGE_INFOHUB_CACHE',
      'VIEW_INFOHUB_DATA',
      'VIEW_INFOHUB_UNMAPPED_CUST',
      'MANAGE_INFOHUB_UNMAPPED_CUST'
    ],
    submenu: [{
      id: 41,
      name: 'information',
      title: 'ih.menu.infohub',
      icon: InfoHubMenuIcon,
      submenu: [
        {
          id: 411,
          name: 'dataCollectionDashboard',
          title: 'ih.menu.dataCollectionDashboard',
          tabName: 'ih.tab.adminDataCollectionDashboard',
          icon: TableOutlined,
          path: INFOHUB_CONTEXT + '/admin/dataCollectionDashboard',
          component: AdminPage,
          authorities: ['VIEW_INFOHUB_SOURCE_FILE', 'MANAGE_INFOHUB_SOURCE_FILE', 'VIEW_INFOHUB_DATASET_STATUS', 'MANAGE_INFOHUB_DATASET_STATUS', 'VIEW_INFOHUB_UNMAPPED_CUST', 'MANAGE_INFOHUB_UNMAPPED_CUST'],
        },
        {
          id: 412,
          name: 'manageCache',
          title: 'ih.menu.manageCache',
          tabName: 'ih.tab.adminCache',
          icon: ControlOutlined,
          path: INFOHUB_CONTEXT + '/admin/cache',
          component: AdminPage,
          authorities: ['VIEW_INFOHUB_CACHE', 'MANAGE_INFOHUB_CACHE'],
        },
        {
          id: 413,
          name: 'manageData',
          title: 'ih.menu.manageData',
          tabName: 'ih.tab.adminManageData',
          icon: DatabaseOutlined,
          path: INFOHUB_CONTEXT + '/admin/manageData',
          component: AdminPage,
          authorities: ['VIEW_INFOHUB_DATA', 'APPROVE_INFOHUB_DATA', 'PUBLISH_INFOHUB_DATA'],
        },
        {
          id: 414,
          name: 'manageLogs',
          title: 'ih.menu.manageLogs',
          tabName: 'ih.tab.adminLogs',
          icon: FileSearchOutlined,
          path: INFOHUB_CONTEXT + '/admin/logs',
          component: AdminPage,
          authorities: ['VIEW_INFOHUB_LOG'],
        },
        {
          id: 415,
          name: 'manageScheduledTasks',
          title: 'ih.menu.manageScheduledTasks',
          tabName: 'ih.tab.adminManageScheduledTasks',
          icon: FieldTimeOutlined,
          path: INFOHUB_CONTEXT + '/admin/manageScheduledTasks',
          component: AdminPage,
          authorities: ['MANAGE_INFOHUB_SCHEDULER', 'VIEW_INFOHUB_SCHEDULER'],
        },
      ]
    },
    ]
  },
];

const UserMenuData = [
  {
    id: 1,
    name: 'loggedUser',
    title: 'ih.menu.login',
    skipI18: true,
    icon: null,
    //authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
    appendDivider: true
  },
  {
    id: 30,
    name: 'quickConfig',
    title: 'ih.menu.quickConfig',
    icon: SettingIcon,
    authorities: ['VIEW_INFOHUB_WIDGET_DATA'],
    appendDivider: true
  },
  {
    id: 10,
    name: 'login',
    title: 'ih.menu.login',
    icon: LoginIcon,
    appendDivider: true,
  },
  {
    id: 100,
    name: 'about',
    title: 'ih.menu.about',
    appendDivider: true
    //icon: LoginIcon,
  },
  {
    id: 101,
    name: 'disclaimer',
    title: 'ih.menu.disclaimer',
    //icon: LoginIcon,
    isExternal: true,
    URL: 'https://trellisenergy.com/hubfs/2023.03%20Trellis%20Energy%20Software%20-%20Disclaimer%20Notice.pdf',
    appendDivider: true
  },
  {
    id: 102,
    name: 'privacy',
    title: 'ih.menu.privacy',
    //icon: LoginIcon,
    isExternal: true,
    URL: 'https://trellisenergy.com/hubfs/2023.03%20Trellis%20Energy%20Software%20-%20Privacy%20Policy.pdf',
    appendDivider: true
  },
  {
    id: 20,
    name: 'logout',
    title: 'ih.menu.logout',
    icon: LogoutIcon,
  }
];

// if on certain page, hide menu items
const skipMenuItemOnPage = (currentPageKeys = [], menuItemIdsToHide = [], currMenuId, menuTriggerHideIds = ['100']) => {
  return currentPageKeys.some(key => menuTriggerHideIds.indexOf(key) !== -1) && T.includes(menuItemIdsToHide, currMenuId);
};

// if don't have subscription, hide menu items
const skipMenuItemOnPageSubscription = (currMenuId, currSubProductIds = []) => {
  // hide infohub admin submenu if they don't have infohub subscription
  return currMenuId === 50 && !T.includes(currSubProductIds, 334402);
};

const getAppMenu = (data, message, authorities, selectedKeys, accountData) => {
  /**
 * 
 * Create a menu Item
 * @param {*} item 
 * @returns 
 */
  let subProductIds = accountData?.subscriptions?.map(item => item.productId);

  const menuItemRenderer = (item) => {
    if (item.skipMenu) return '';
    if (item.id === 'divider') return <Menu.Divider key={Math.random()} />;

    if (skipMenuItemOnPage(selectedKeys, [2, 3], item.id)) {
      return '';
    }

    if (skipMenuItemOnPageSubscription(item.id, subProductIds)) {
      return '';
    }

    if (!T.isEmpty(item.authorities)) {
      if (T.isEmpty(authorities)) return '';

      let isAuthorized = item.authorities.some(e => authorities.indexOf(e) > -1);
      if (!isAuthorized) return '';
    }
    if (item.submenu) return subMenuItemRenderer(item);

    return <Menu.Item disabled={!item.path} key={item.id} icon={item.icon ? <item.icon />: null}>
      <Link to={T.buildURL(item.path)}>{message[item.title]}</Link>
    </Menu.Item>
  }

  /**
   * Create a submenu item
   * @param {*} item 
   * @returns 
   */
  const subMenuItemRenderer = (item) => {
    return (<SubMenu key={item.id} icon={<item.icon style={{ width: 'auto' }} />} title={message[item.title]}>
      {item.submenu.map(menuItemRenderer)}
    </SubMenu>)
  }
  let ret = data.map(menuItemRenderer)
  return ret.filter(i => i != null);
}

const redirectUrl = (accountData) => {
  if (accountData && accountData.redirectUrl) {
    if (accountData.redirectUrl !== "") {
      window.location.href = accountData.redirectUrl;
    }
  }
}

const UserDropdownRenderer = ({ data, onClick, accountData, authorities, selectedKeys }) => {
  const { message } = useSelector((state) => state.message)
  const userLoggedIn = accountData && accountData.name;

  const getUserMenu = (data, message, authorities) => {
    const menuItemRenderer = (item) => {
      if (item.skipMenu) return '';

      if (skipMenuItemOnPage(selectedKeys, [30], item.id, ['1', '100'])) {
        return '';
      }

      // check authority
      if (!T.isEmpty(item.authorities)) {
        if (T.isEmpty(authorities)) return '';

        let isAuthorized = item.authorities.some(e => authorities.indexOf(e) > -1);
        if (!isAuthorized) return '';
      }

      if (userLoggedIn) {
        if (item.name === 'privacy') {
          item.appendDivider = true;
        }
        else if (item.name === 'login') {
          return ''; // do not show
        }
      } else {
        if (item.name === 'loggedUser') {
          item.title = 'Welcome!';
        }
        else if (item.name === 'privacy') {
          item.appendDivider = false;
        }
        // logout should not be dependent on workflow
        // should be hidden when logged out
        else if (item.name === 'logout') {
          return ''; // do not show
        }
      }

      // associated divider with menu item since we need to hide menu items and divider together
      return <> <Menu.Item key={item.name} className={item.name === 'loggedUser' ? 'loggedUser' : ''} icon={/*item.icon ? <item.icon /> : */''} onClick={() => {
        if (item.name === 'about') {
          document.getElementById('about_link')?.click();
        } else if (item.isExternal) {
          window.open(item.URL, '_blank')
        }
      }}>
        {item.skipI18 ? <span style={{ cursor: 'default' }} className='secondary-color'>{item.title}</span> : message[item.title]}
      </Menu.Item>
        {item.appendDivider ? <Menu.Divider key={Math.random()} /> : null} </>
    };
    let ret = data.map(menuItemRenderer)

    return ret;
  }

  const menuHeaderDropdown = () => (
    <Menu id="user-menu" onClick={onClick}>
      {[<div className='hubicon-userdropdown' key={Math.random()} >
        <HubIconRenderer accountData={accountData} isHeader={true} key={Math.random()} />
      </div>,
      <Menu.Divider className='hubicon-userdropdown-divider' key={Math.random()} />,
      getUserMenu(data, message, authorities)]}
    </Menu>
  );

  return (
    <Dropdown overlay={menuHeaderDropdown()}>
      <span style={{ float: 'right' }} className='secondary-color'>
        <HubHeaderDividerIcon className='hubicon-header-divider' />
        {!userLoggedIn ? <UserLoggedOutIcon /> : <UserLoggedInIcon />}
      </span>
    </Dropdown>)
}

const RouteRenderer = (props) => {
  const authorities = (props.accountData && props.accountData.authorities) || [];
  const routeItemRenderer = (item) => {
    if (item.skipRoute) return null;
    if (item.id === 'divider') return null;
    if (item.submenu) return item.submenu.map(routeItemRenderer);

    // check authority
    if (!T.isEmpty(item.authorities)) {
      if (T.isEmpty(authorities)) return null;
      let isAuthorized = item.authorities.some(e => authorities.indexOf(e) > -1);
      if (!isAuthorized) return null;
    }

    return <Route key={item.id} path={T.buildURL(item.path)}>
      <item.component {...props} />
    </Route>
  }

  let ret = AppMenuRouteData.flatMap(routeItemRenderer).filter(i => i != null);
  return (<>{ret}</>)
}

const  AppMenuRenderer = (props) => {
  const { message } = useSelector((state) => state.message)
  const history = useHistory(false);

  const onLogoClick = () => {
    history.push(T.buildURL(AGORA_CONTEXT));
  };

  return (
    <Sider collapsible collapsed={props.collapsed} width={250}
      onCollapse={props.onCollapse}
      trigger={<MenuFoldOutlined />}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        backgroundColor: 'white',
      }}
      {...props}
    >
      <div className={'logo' + (props.collapsed ? ' logo-small' : "")} onClick={onLogoClick} />

      <Menu
        selectedKeys={props.selectedKeys}
        style={{ maxWidth: 250 }}
        mode="inline"
      >
        {getAppMenu(props.data, message, props.authorities, props.selectedKeys, props.accountData)}
      </Menu>
    </Sider>
  )
}


const AppMobileMenuRenderer = (props) => {
  const { message } = useSelector((state) => state.message)
  var screenWidth = T.getScreenWidth();

  const onMenuClose = () => {
    props.onCollapse(true);
    console.log('onMenuClose');
  };
  return (
    <Sider collapsible collapsed={props.collapsed} width={screenWidth}
      onCollapse={props.onCollapse}
      defaultCollapsed={false}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        backgroundColor: 'white',
        display: props.collapsed ? 'none' : 'inline',
      }}
      {...props}
    >
      <CloseOutlined onClick={onMenuClose} style={{
        margin: '5px',
        fontSize: '15px'
      }} />
      <Menu
        selectedKeys={props.selectedKeys}
        style={{ maxWidth: screenWidth }}
        mode="inline"
      >
        {getAppMenu(props.data, message, props.authorities, props.selectedKeys, props.accountData)}
      </Menu>
    </Sider>
  )
}

const HubIconRenderer = (props) => {

  const { message } = useSelector((state) => state.message)

  const getHeaderIcon = (item, enabled, isAgora) => {
    if (enabled) {
      if (isAgora) {
        if (item.id === 100) { // only home icon active
          return <item.headerIcons.active />
        } else {
          return <item.headerIcons.inactive />
        }
      } else if (item.id === 334402) { // only info hub active. TODO: will have to change when other hubs are live
        return <item.headerIcons.active />
      } else {
        return <item.headerIcons.inactive />
      }
    } else {
      return <item.headerIcons.disabled />
    }
  }
  const isHeader = props.isHeader;
  const location = useLocation();
  const history = useHistory();
  const accountData = props.accountData;
  const subscriptions = (accountData && accountData.subscriptions) || [];

  const showLoginPage = () => {
    window.location.assign(T.getAuthenticationUrls().loginUrl);
    const hide = antdMessage.loading(message['ih.loggingIn'], 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 20000);
  }

  const generateLoginCard = () => {
    return (
       <div id='login' className='hub'>
         <div className='tileicon'>

         </div>
         {
           T.isEmpty(accountData) && (
             <div className='hub-description'>
              <Button type="primary" size='large' onClick={showLoginPage}>Sign in</Button>
             </div>
           )
         }
        </div>
    )
  }

  const generateHubs = () => {
    let ret = [];
    HubData.forEach(item => {
      let enabled = false;
      if (item.defaultActive || subscriptions.find(s => s.productId === item.id)) {
        enabled = true;
      }
      let pathname = location.pathname;
      let isAgora = false;
      if (pathname.indexOf('agora') > -1)
        isAgora = true;

      if (!isHeader) {
        if (item.onlyHeader) return; // skip putting in body
        ret.push
          (
            <div id={'datahub-' + item.id} datahubid={item.id} name={item.name} key={Math.random()}
              className={`hub hub-item ${enabled ? 'hub-enabled' : 'hub-disabled'} ${item.isInvisible ? 'hub-hidden' : ''}`} onClick={onClick}>
              <div className='hub-icon'>
                <item.icon style={{ fontSize: '3em' }} className={enabled ? 'icon-enabled' : 'icon-disabled'} />
              </div>
              <div className='hub-text'>
                <div className='hubname'>{item.title}</div>
                <div className='hub-description'>{item.description}
                  {item.isComing ? <div className='hub-iscoming'>Coming Soon!</div> : ''}
                </div>
              </div>
            </div>
          )
      }
      else {
        if (item.skipHeader) return; // skip putting in header
        ret.push((<span id={'datahub-' + item.id} datahubid={item.id} name={item.name} key={Math.random()}
          className={`hubicon-header ${enabled ? 'icon-enabled' : 'icon-disabled'}`}
          onClick={onClick}>
          <Tooltip title={item.title}>
            {getHeaderIcon(item, enabled, isAgora)}
            {/*enabled ? (isAgora ? <item.headerIcons.inactive /> : item.id === 334402 ? <item.headerIcons.active /> : <item.headerIcons.inactive />) : < item.headerIcons.disabled />*/}
          </Tooltip>
        </span >))
      }

    })
    return ret;
  }

  /**
   * 
   * Check if item is disabled.
   * If enabled open new tab for external hub or change path for internal.
   * 
   */
  const onClick = (e) => {
    const isDisabled = e.currentTarget.getAttribute('class').indexOf('disabled') > -1;
    if (isDisabled) {
      e.stopPropagation();
      return;
    }
    let hubid = e.currentTarget.getAttribute('id').split('-')[1] * 1;
    const hub = HubData.find(h => h.id === hubid);
    if (hub.isExternal) {
      let subscription = subscriptions.find(s => s.productId === hub.id);
      if (!subscription && hub.id === 334401) {
        let publicUrls = JSON.parse(localStorage.getItem('publicUrls') || ''); // todo use redux to get public urls
        subscription = { productUrl: publicUrls.trnxHub };
      }
      if (subscription)
        // open in current tab instead of new tab to keep it consistent with other trellis sites
        window.open(subscription.productUrl, '_self');
      else
        console.warn('no subscription found')
    } else if (!hub.path) {
      e.stopPropagation();
      return;
    } else {
      // TODO: open in new tab ?
      history.push(T.buildURL(hub.path));
    }
  }

  const loginCard = generateLoginCard();
  const hubs = generateHubs();
  return (<>
    {isHeader ? <Space style={{ float: 'right', marginRight: '-8px' }} size='0'>{hubs}</Space> :
      <div className='home'>
        <div className='login-card'>
          {loginCard}
        </div>
        <div className='hubs'>
          {hubs}
        </div>
      </div>
    }
  </>)
}

/**
 * 
 * Recursively parse the routeData for pathnaame and
 * if found
 *  return the key
 * else
 *  return null
 * 
 * @param {*} pathname
 * @param {*} routeData 
 * 
 */
const getPageMenuKey = (pathname, routeData) => {
  const getPageMenuKeyHelper = (routeData, i) => {
    let route = {};
    if (T.isEmpty(pathname) || T.isEmpty(routeData)) return null;
    for (; i < routeData.length; i++) {
      route = routeData[i];
      if ((pathname + '/').indexOf('/' + route.path + '/') > -1) {
        return [route.id + ''];
      }
      if (!T.isEmpty(route.submenu)) {
        let r = getPageMenuKeyHelper(route.submenu, 0);
        if (!T.isEmpty(r)) {
          return r;
        }
      }
    }
    return null;
  }
  let key = getPageMenuKeyHelper(routeData, 0);
  return key;
}

/**
 * 
 * Recursively parse the routeData for pathname and 
 * if found
 *   return the nested title, simple title
 * else
 *   return []
 * 
 * @param {*} pathname
 * @param {*} routeData
 * @param {*} message
 * 
 */
const getPageTitle = (pathname, routeData, message) => {
  // remove base context
  // '/inform/dashboard' becomes 'dashboard'
  pathname = pathname.replace(CONTEXT, '');
  console.log('getPageTitle: ,', pathname);
  if (pathname.indexOf('agora') > -1)
    return <div className='logo-home-page'></div>

  let stack = [];
  const getPageTitleHelper = (routeData, i, stack) => {
    let ret = [], route = {};
    if (T.isEmpty(pathname) || T.isEmpty(routeData)) return ret;
    for (; i < routeData.length; i++) {
      route = routeData[i];
      if (('/' + pathname + '/').indexOf(route.path + '/') > -1) {
        stack.push(route.title);
        return stack;
      }

      if (!T.isEmpty(route.submenu)) {
        //If route has no path then check for paths in the children.
        if(route.path === undefined) {
          let isCorrectMenuHierarchy = false;

          //Make sure the correct menu hierarchy is used by checking later in the chain for the correct path.
          for (let j=0; j<route.submenu.length; j++) {
            //Check for two layer menu hierarchy paths.
            let outerSubmenuPath = ('' + route.submenu[j].path).replace('/', '');

            if(!T.isEmpty(route.submenu[j].submenu)) {
              for (let k=0; k<route.submenu[j].submenu.length; k++) {
                let innerSubmenuPath = route.submenu[j].submenu[k].path.replace('/', '');
                if(innerSubmenuPath === pathname) {
                  isCorrectMenuHierarchy = true;
                  break;
                }
              }
            }
            //Check for one layer menu hierarchy paths.
            else if(outerSubmenuPath === pathname) {
              isCorrectMenuHierarchy = true;
              break;
            }
          }
          //Don't allow continuing down an incorrect menu hierarchy.
          if(!isCorrectMenuHierarchy)
            continue;
        }

        stack.push(route.title);
        let r = getPageTitleHelper(route.submenu, 0, stack);
        if (!T.isEmpty(r)) {
          return r;
        }
      }
    }
    stack.pop();
    return stack;
  }

  let titles = getPageTitleHelper(routeData, 0, stack);
  if (titles.length === 1) {
    return <span className='page-title'>{message[titles[0]]}</span>;
  }
  // TODO: if further nested, rely on recursion
  return <>
    <span className='page-title-prefix'>{message[titles[0]]}</span><span className='page-title-prefix '>|</span>
    <span className='page-title'>{message[titles[1]]}</span>
  </>
}

const getPageTabName = (pathname, routeData, message) => {
  pathname = '/'+pathname.replace(CONTEXT, '');

  const findTabName = (path, data) => {
    for (const item of data) {
      if (item.path === path && item.tabName) {
        return message[item.tabName];
      }
      if (item.submenu) {
        const tabName = findTabName(path, item.submenu);
        if (tabName) {
          return tabName;
        }
      }
    }
    return null;
  };

  return findTabName(pathname, routeData) || 'Trellis: Agora';
};


export {
  AppMenuRenderer, AppMobileMenuRenderer, RouteRenderer, HubIconRenderer, UserDropdownRenderer,
  AppMenuRouteData, UserMenuData, getPageTitle, getPageMenuKey, getPageTabName
};
