import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from "infogrid";
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { useDispatch, useSelector } from 'react-redux'
import { CacheApi } from 'api/v1/index'
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import { message as AntdMessage, Modal, Form, Space, Button } from 'antd';
import { setPageSuccessAlert, setPageErrorAlert, clearPageAlert } from 'redux/reducer/info';
import moment from 'moment';

const CacheClient = (props) => {

  const gridRef = useRef(this);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message)
  const { accountData } = useSelector((state) => state.info);
  //const { cacheStats } = useSelector((state) => state.cache);
  const [refreshCount, setRefresh] = useState(0);
  //const [loading, output, loadTimestamp] = useFetchData('api/v1/cache/getAllCacheStats', postData, refreshCount);
  const [output, setOutput] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadTimestamp, setLoadTimestamp] = useState(new Date());
  const refresh = () => {
    setRefresh(Math.random())
  }

  const isManageEnabled = T.checkAuthority(['MANAGE_INFOHUB_CACHE'], accountData.authorities);
  const cacheNamePrefix = 'cache-';

  let nameMap = [
    'cacheName',
    'cacheTime',
    'cacheValue',
  ];

  let columnDefs = getNameModelMap(nameMap);

  /**
   * 
   * Storage
   */
  const performQuery = () => {
    const allData = [];
    Object.keys(localStorage).forEach(key => {
      if (key.indexOf(cacheNamePrefix) === 0) {
        const cacheName = key.substring(cacheNamePrefix.length);
        const cacheValueString = localStorage.getItem(key);
        let cacheTime = "N/A", cacheValue = "N/A", cacheValueObj = {};
        if (!T.isEmpty(cacheValueString)) {
          try {
            cacheValueObj = JSON.parse(cacheValueString);
          } catch (e) {

          }
        }

        allData.push({
          cacheName: cacheName,
          cacheValue: cacheValueObj?.values || cacheValue,
          cacheTime: cacheValueObj.timestamp ? moment(cacheValueObj.timestamp).local().format('YYYY-MM-DD HH:mm:ss') : cacheTime
        })
      }
    })
    setOutput(allData);

    dispatch(clearPageAlert({}));
    refresh()
  }

  useEffect(() => {
    performQuery();
  }, [])

  const performClearCache = () => {
    dispatch(clearPageAlert({}));
    const postData = getSelectedData();
    if (T.isEmpty(postData.cacheNames)) {
      Modal.warn({
        content: 'Please select at least one Cache'
      });
    } else {
      AntdMessage
        .loading('Please wait...', 2.5)
        .then(() => {
          // assumed it always works
          postData.cacheNames.forEach(cacheName => {
            localStorage.removeItem(cacheNamePrefix + cacheName);
          })
          dispatch(setPageSuccessAlert({ show: true, message: 'Cache(s) cleared successfully' }));
          performQuery();
        })
    }
  }



  const getSelectedData = (action) => {
    const postData = {
      cacheNames: [],
    };
    if (gridRef.current) {
      let selectedNodes = gridRef.current.gridApi.getSelectedNodes();
      if (selectedNodes) {
        let selectedData = selectedNodes.map(node => node.data);
        postData.cacheNames = selectedData.map(r => r.cacheName);
      }
    }
    return postData;
  }

  const controls =
    <Form layout='inline' style={{ justifyContent: 'flex-end' }}>
      <Form.Item style={{ textAlign: 'right' }}>
        <Space>
          {isManageEnabled ?
            <Button type="primary" name='clearCache' htmlType="submit" /*disabled={!state.isApproveEnabled}*/ onClick={performClearCache}>Clear Cache</Button>
            : ''}
          <Button type="primary" name='query' htmlType="submit" onClick={performQuery}>Query</Button>
        </Space>
      </Form.Item>
    </Form >

  const gadgetItem = {
    grid: <InfoGrid id="CacheClient" gridRef={gridRef}
      gridOptions={{
        headerCheckbox: isManageEnabled,
      }}
      columnDefs={columnDefs} data={output}></InfoGrid>
  }
  const gadget = {
    loading,
    //refresh,
    //id: 'Gadget1',
    title: message['ih.widget.cache.client.detail'],
    views: ['grid'], // grid, chart
    gadgetItem,
    loadTimestamp,
    controls,
    //dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget}></Gadget>
  );
}

export default CacheClient;
