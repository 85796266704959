import { createSlice } from '@reduxjs/toolkit'

export const messageResourceSlice = createSlice({
  name: 'message',
  initialState: {
    message: {},
  },
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setMessage } = messageResourceSlice.actions

export default messageResourceSlice.reducer