import React, {useState} from "react";
import { ApiRequest } from '@trellisenergy/common-ui-core';
import {Col, Popover, Row, Skeleton, Tooltip} from "antd";
// import Highlighter from "react-highlight-words";
import { TUtil } from '@trellisenergy/common-ui-core';
import {BASEURL_DEV} from "../../config";
import { EyeOutlined, CloseOutlined, DownloadOutlined} from '@ant-design/icons';
import NoticeDetails from "./NoticeDetails";

const NoticeTxtFileDownloadLinkRenderer = (props) => {
    // const {message} = useSelector((state) => state.message);
    let [loading, setLoading] = useState(false);
    let [noticeTextContent, setNoticeTextContent] = useState("");
    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });
    
    if (!props?.data?.id ) {
        return null;
    }
    
    let viewTextUrl = 'api/v1/notices/downloadNoticeText?noticeId=' + props?.data?.id;
    let previewTextUrl = viewTextUrl + '&truncateText=true';
    let fullViewTextUrl = TUtil.isDevEnv() ? BASEURL_DEV + viewTextUrl : TUtil.buildURL(viewTextUrl);
    let viewTextLink = <a href={fullViewTextUrl} target={"_blank"} rel="noreferrer">{<DownloadOutlined />}</a>;
    let noticeKeywords = props?.data?.noticeKeywords || [];
    
    let getNoticeContent = async () => {
        let getNoticeText = () => {
            const request = ApiRequest.composeGetRequest(previewTextUrl);
            return fetch(request)
                .then(response => response.text())
                .then(responseText => {
                    return responseText;
                }).catch(error => {
                    return null;
                });
        };

        setLoading(true);
        let noticeTextResponse = await getNoticeText();
        setLoading(false);

        if (noticeTextResponse) {
            if (noticeKeywords && noticeKeywords.length) {
                setNoticeTextContent(noticeTextResponse);
            } else {
                setNoticeTextContent(noticeTextResponse);
            }
        } else {
            setNoticeTextContent("No data available.");
        }
    };

    let handleHoverChange = (visible) => {
        if (visible) {
            getNoticeContent();
        }
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    };

    let handleClickChange = visible => {
        if (visible) {
            getNoticeContent();
        }

        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };

    let hide = () => {
        setPopoverState({
            hovered: false,
            clicked: false
        });
    };

    let content = <Skeleton active loading={loading}>
        <NoticeDetails
            searchWords={noticeKeywords}
            noticeTextContent={noticeTextContent}/>
    </Skeleton>;

    let title = <>
        <Row>
            <Col span={20}>{props?.data?.subject}</Col>
            <Col span={4} style={{textAlign: 'right'}}>
                <a onClick={hide}><CloseOutlined/></a>
            </Col>
        </Row>
    </>;

    return <>
        <div className="flex-center-justify-left">
            <Popover mouseEnterDelay={.5} trigger="hover"
                     visible={popoverState.hovered}
                     overlayStyle={{
                         width: "50vw"
                     }}
                     onVisibleChange={handleHoverChange} content={content} title={title}>
                <Popover mouseEnterDelay={.5} trigger="click"
                         visible={popoverState.clicked}
                         overlayStyle={{
                             width: "50vw"
                         }}
                         onVisibleChange={handleClickChange} content={content} title={title}>
                    <EyeOutlined style={{fontSize: '16px'}}/>
                </Popover>
            </Popover>&nbsp;&nbsp;<Tooltip title="Click to download the notice text.">{viewTextLink}</Tooltip>
        </div>
    </>;
};

export default NoticeTxtFileDownloadLinkRenderer;
