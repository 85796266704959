import { Route } from 'react-router-dom';
import {
  ContractsByAgentDetail,
  CustomersByPipelineDetail,
  MDQsByCustomerDetail,
  PipelinesByCustomerDetail,
  AllContracts,
  PointQuantityDetail
} from 'gadget';
import { TUtil as T } from '@trellisenergy/common-ui-core';

function IOC() {
  const path = '/ioc';

  return (
    <div>
      <Route exact path={T.buildURL(path)}>
        <AllContracts/>
      </Route>
      <Route exact path={T.buildURL(path + '/MDQsByCustomerDetail')}>
        <MDQsByCustomerDetail></MDQsByCustomerDetail>
      </Route>
      <Route exact path={T.buildURL(path + '/PipelinesByCustomerDetail')}>
        <PipelinesByCustomerDetail></PipelinesByCustomerDetail>
      </Route>
      <Route exact path={T.buildURL(path + '/CustomersByPipelineDetail')}>
        <CustomersByPipelineDetail></CustomersByPipelineDetail>
      </Route>
      <Route exact path={T.buildURL(path + '/ContractsByAgentDetail')}>
        <ContractsByAgentDetail></ContractsByAgentDetail>
      </Route>
      <Route exact path={T.buildURL(path + '/AllContracts')}>
        <AllContracts></AllContracts>
      </Route>
      <Route exact path={T.buildURL(path + '/PointQuantityDetail')}>
        <PointQuantityDetail></PointQuantityDetail>
      </Route>

    </div>
  );
}

export default IOC;
