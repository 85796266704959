import React from 'react'
import { Pie } from '@ant-design/charts';
import ChartConfig from './config';
import { TUtil as T } from '@trellisenergy/common-ui-core';

const PieChart = (props) => {
  var data = props.data || [];
  var customChartConfig = props.customChartConfig || {};
  var config = {
    chartRef: props.chartRef,
    appendPadding: 10,
    data: data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'spider',
      content: '{name}',
    },
    tooltip: {
      formatter: (data) => {
        return {name: data.type, value: data.value.toLocaleString()};
      },
    },
    legend: {
      layout: 'vertical',
      position: T.isSmallScreen ? 'bottom' : 'right',
    },
    // label: {
    //   type: 'inner',
    //   offset: '-40%',
    //   content: '{name}\n {percentage}',
    //   style: {
    //     fontSize: 12,
    //     textAlign: 'center',
    //   },
    // },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.65,
        },
      },
    },
    interactions: [{ type: 'element-selected' }, { type: 'pie-legend-active' }, { type: 'element-active' }],
    ...ChartConfig,
    ...customChartConfig,
  };
  let total = 0;
  data.forEach(function (d) {
    total += d.value || 0;
  });
  return total === 0 ? <h3 style={{ textAlign: "center", marginTop: "155px" }}>No data available</h3> : <Pie {...config} />;
};

export default PieChart;