/**
 * 
 * Container for Internalization message resource.
 * 
 */
export default class GlobalMessages {
  static messages = []

  static getMessages() {
    return this.messages;
  }

  static init(m) {
    this.messages = m;
  }
}
