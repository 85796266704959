import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';
import './DateEditorComponent.css';
import moment from 'moment';

export default forwardRef((props, ref) => {
    const [date, setDate] = useState(props.value);

    const onDateChanged = (selectedDates) => {
        if (selectedDates) {
            setDate(selectedDates.toDate());
            setTimeout(function () {
                props.stopEditing();
                props.node.setSelected(false)
            }, 100);
        }
    };
    
    useImperativeHandle(ref, () => {
        return {
            // //*********************************************************************************
            // //          METHODS REQUIRED BY AG-GRID
            // //*********************************************************************************
            // the final value to send to the grid, on completion of editing
            getValue() {
                return moment.utc(date)
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false
            },

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                return false
            },
            
            isPopup() {
                return false
            }
        }
    });
    
    const initDate = date ? moment.utc(date) : ""

    return (
        <DatePicker dropdownClassName={"ok-enabled-date-time-picker"} onChange={onDateChanged} format={"MM/DD/YYYY HH:mm"} ref={ref} defaultValue={initDate} showTime />
    );
});
