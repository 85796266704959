import React, { useState } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap'
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import { Collapse } from 'antd';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import './AllFootnotes.css';
const { Panel } = Collapse;


const FootnotesAll = (props) => {
  const [refreshCount, setRefresh] = useState(0);

  const [postDataIocPipeline, setPostDataIocPipeline] = useState({});
  const [postDataIocContract, setPostDataIocContract] = useState({ startDate: T.getLast30DaysDateStr(), endDate: T.getTodayDateStr() });
  const [postDataIocContractAgent, setPostDataIocContractAgent] = useState({ startDate: T.getLast30DaysDateStr(), endDate: T.getTodayDateStr() });
  const [postDataIocContractPoint, setPostDataIocContractPoint] = useState({ startDate: T.getLast30DaysDateStr(), endDate: T.getTodayDateStr() });

  const [loadingIocPipeline, outputIocPipeline, loadTimestampIocPipeline] = useFetchData('api/v1/ioc/getPipelineFootnotes', postDataIocPipeline, refreshCount);
  const [loadingIocContract, outputIocContract, loadTimestampIocContract] = useFetchData('api/v1/ioc/getContractFootnotes', postDataIocContract, refreshCount);
  const [loadingIocContractAgent, outputIocContractAgent, loadTimestampIocContractAgent] = useFetchData('api/v1/ioc/getContractAgentFootnotes', postDataIocContractAgent, refreshCount);
  const [loadingIocContractPoint, outputIocContractPoint, loadTimestampIocContractPoint] = useFetchData('api/v1/ioc/getContractPointFootnotes', postDataIocContractPoint, refreshCount);

  const refresh = () => {
    setRefresh(Math.random())
  };

  let nameMapPipelineFootnotes = [
    'pipelineName',
    'footnoteItemList',
    'footnoteText'
  ];
  let commonFootnoteColumns = [
    'footnoteText'
  ];
  let commonContractColumns = [
    'pipelineName',
    'contract',
    'rateSchedule',
    'shipperName',
    'contractEffectiveDate'
  ];
  let nameMapContractFootnotes = commonContractColumns.concat(commonFootnoteColumns);
  let nameMapContractAgentFootnotes = commonContractColumns.concat([
    'agentName'
  ], commonFootnoteColumns);
  let nameMapContractPointFootnotes = commonContractColumns.concat([
    'pointName'
  ], commonFootnoteColumns);

  const processColumns = (cols) => {
    let groupingCols = [
      'pipelineName',
      'contract',
      'rateSchedule',
      'shipperName',
      'agentName',
      'pointName'
    ].map(field => {
      return T.find(cols, (c) => c.field === field)
    });

    groupingCols.forEach(colModel => {
      if (colModel) {
        colModel.rowGroup = true;
        colModel.hide = true;
      }
    });

    return cols;
  };

  let columnDefsPipelineFootnotes = getNameModelMap(nameMapPipelineFootnotes);
  let columnDefsContractFootnotes = getNameModelMap(nameMapContractFootnotes);
  let columnDefsContractAgentFootnotes = getNameModelMap(nameMapContractAgentFootnotes);
  let columnDefsContractPointFootnotes = getNameModelMap(nameMapContractPointFootnotes);

  const controlsPipeline = ControlFactory.createControls({
    controls: [],
    initialValues: {},
    submit: setPostDataIocPipeline
  })

  const controlsContract = ControlFactory.createControls({
    controls: [{ id: 1, name: 'dateRange', type: 'datepicker', label: 'Posted Date Range' }],
    //initialValues: { 'dateRange': [T.getDate('04/13/2018'), T.getTodayDate()], },
    initialValues: { 'dateRange': [T.getLast30DaysDate(), T.getTodayDate()], },
    submit: setPostDataIocContract
  })

  const controlsContractAgent = ControlFactory.createControls({
    controls: [{ id: 1, name: 'dateRange', type: 'datepicker', label: 'Posted Date Range' }],
    initialValues: { 'dateRange': [T.getLast30DaysDate(), T.getTodayDate()], },
    submit: setPostDataIocContractAgent
  })

  const controlsContractPoint = ControlFactory.createControls({
    controls: [{ id: 1, name: 'dateRange', type: 'datepicker', label: 'Posted Date Range' }],
    initialValues: { 'dateRange': [T.getLast30DaysDate(), T.getTodayDate()], },
    submit: setPostDataIocContractPoint
  })

  const gadgetPipelineFootnotes = {
    loading: loadingIocPipeline,
    refresh,
    //id: 'Gadget1',
    title: 'Pipeline Footnotes',
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="FootnotesAll_Pipeline" columnDefs={processColumns(columnDefsPipelineFootnotes)} data={outputIocPipeline}
      ></InfoGrid>
    },
    loadTimestamp: loadTimestampIocPipeline,
    controls: controlsPipeline,
    //dataSourceId: 1,
    width: props.width || 100
  };

  const gadgetContractFootnotes = {
    loading: loadingIocContract,
    refresh,
    //id: 'Gadget2',
    title: 'Contract Footnotes',
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="FootnotesAll_Contract" columnDefs={processColumns(columnDefsContractFootnotes)} data={outputIocContract} ></InfoGrid>
    },
    loadTimestamp: loadTimestampIocContract,
    controls: controlsContract,
    //dataSourceId: 2,
    width: props.width || 100
  };

  const gadgetContractAgentFootnotes = {
    loading: loadingIocContractAgent,
    refresh,
    //id: 'Gadget3',
    title: 'Contract Agent Footnotes',
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="FootnotesAll_Agent" columnDefs={processColumns(columnDefsContractAgentFootnotes)} data={outputIocContractAgent}></InfoGrid>
    },
    loadTimestamp: loadTimestampIocContractAgent,
    controls: controlsContractAgent,
    //dataSourceId: 3,
    width: props.width || 100
  };

  const gadgetContractPointFootnotes = {
    loading: loadingIocContractPoint,
    refresh,
    //id: 'Gadget4',
    title: 'Contract Point Footnotes',
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="FootnotesAll_Point"  columnDefs={processColumns(columnDefsContractPointFootnotes)} data={outputIocContractPoint}></InfoGrid>
    },
    loadTimestamp: loadTimestampIocContractPoint,
    controls: controlsContractPoint,
    //dataSourceId: 4,
    width: props.width || 100
  };

  return (
    <Collapse className={'footnotesCollapseContainer'} defaultActiveKey={['']}>
      <Panel header='Pipeline Footnotes' key='1'>
        <Gadget {...gadgetPipelineFootnotes} {...props}> </Gadget>
      </Panel>
      <Panel header='Contract Footnotes' key='2'>
        <Gadget {...gadgetContractFootnotes} {...props}> </Gadget>
      </Panel>
      <Panel header='Contract Agent Footnotes' key='3'>
        <Gadget {...gadgetContractAgentFootnotes} {...props}> </Gadget>
      </Panel>
      <Panel header='Contract Point Footnotes' key='4'>
        <Gadget {...gadgetContractPointFootnotes} {...props}> </Gadget>
      </Panel>
    </Collapse>
  );
};

export default FootnotesAll;
