import ColumnTypes from "../../component/infogrid/ColumnTypes";

// Capacity Release

const NameModelMap = {

  tspName: {
    field: 'tspName',
    headerName: 'ih.tspName', // TSP Name
    filter: true,
  },
  bidDeal: {
    field: 'bidDeal',
    headerName: 'ih.bidDeal', // Bid Deal
    filter: 'agSetColumnFilter', // same as filter: true
  },
  rtSchedName: {
    field: 'rtSchedName',
    headerName: 'ih.rtSchedName', // Rate Sch
    filter: 'agSetColumnFilter', // same as filter: true
  },
  maximumOfferQtyContract: {
    field: 'maximumOfferQtyContract',
    headerName: 'ih.maximumOfferQtyContract', // Max Offer Qty-K
    ...ColumnTypes.Double,
  },
  releaserName: {
    field: 'releaserName',
    headerName: 'ih.releaserName', // Releaser Name
  },
  relContractNumber: {
    field: 'relContractNumber',
    headerName: 'ih.relContractNumber', // Rel K
    filter: 'agSetColumnFilter', // same as filter: true
  },
  rfqOffrNbr: {
    field: 'rfqOffrNbr',
    headerName: 'ih.rfqOffrNbr', // Offer No
  },
  offerNumber: {
    field: 'offerNumber',
    headerName: 'ih.offerNumber', // Offer Number
  },
  offerNo: {
    field: 'offerNumber',
    headerName: 'ih.rfqOffrNbr', // Offer Number
  },
  status: {
    field: 'status',
    headerName: 'ih.status', // Status
    filter: 'agSetColumnFilter', // same as filter: true
  },
  postingDateTime: {
    field: 'postingDate',
    headerName: 'ih.postingDateTime', // Post Date/Time
    ...ColumnTypes.DateTime,
  },
  releaseTermStartDate: {
    field: 'releaseTermStartDate',
    headerName: 'ih.releaseTermStartDate', // Rel St Date
    ...ColumnTypes.Date,
  },
  releaseTermEndDate: {
    field: 'releaseTermEndDate',
    headerName: 'ih.releaseTermEndDate', // Rel End Date
    ...ColumnTypes.Date,
  },
  bidPeriodStartDate: {
    field: 'bidPeriodStartDate',
    headerName: 'ih.bidPeriodStartDate', // Bid Per St Date/Time
    ...ColumnTypes.DateTime,
  },
  bidPeriodEndDate: {
    field: 'bidPeriodEndDate',
    headerName: 'ih.bidPeriodEndDate', // Bid Per End Date/Time
    ...ColumnTypes.DateTime,
  },
  rtFormTypCode: {
    field: 'rtFormTypCode',
    headerName: 'ih.rtFormTypCode', // Rate Form/Type
    filter: 'agSetColumnFilter', // same as filter: true
  },
  releaseType: {
    field: 'releaseType',
    headerName: 'ih.releaseType', // Rel Type
    filter: 'agSetColumnFilter', // same as filter: true
  },
  prearrangedDealCode: {
    field: 'prearrangedDealCode',
    headerName: 'ih.prearrangedDealCode', // Prearr Deal
    filter: 'agSetColumnFilter', // same as filter: true
  },
  bidderName: {
    field: 'bidderName',
    headerName: 'ih.bidderName', // Bidder Name
  },
  permanentReleaseCode: {
    field: 'permanentReleaseCode',
    headerName: 'ih.permanentReleaseCode', // Perm Rel
  },
  shorterTermInd: {
    field: 'shorterTermInd',
    headerName: 'ih.shorterTermInd', // Shorter Term
  },
  minTermDays: {
    field: 'minTermDays',
    headerName: 'ih.minTermDays', // Min Term
  },
  relShiprLesserQtyInd: {
    field: 'relShiprLesserQtyInd',
    headerName: 'ih.relShiprLesserQtyInd', // Rel SR Less Qty
  },
  minimumOfferQtyContract: {
    field: 'minimumOfferQtyContract',
    headerName: 'ih.minimumOfferQtyContract', // Min Offer Qty-K
    ...ColumnTypes.Double,
  },
  /*
  measBasis: {
    field: 'measBasis',
    headerName: 'ih.measBasis', // Meas Basis
  },*/
  pressureBase: {
    field: 'pressureBase',
    headerName: 'ih.pressureBase', // Press Base
    ...ColumnTypes.Double,
  },
  pressureBaseCode: {
    field: 'pressureBaseCode',
    headerName: 'ih.pressureBaseCode', // Press Base Ind
  },
  pressBaseInd: {
    field: 'pressBaseInd',
    headerName: 'ih.pressBaseInd', // Press Base Ind
  },
  recallReputCode: {
    field: 'recallReputCode',
    headerName: 'ih.recallReputCode', // Recall/Reput
  },
  allowableReReleaseInd: {
    field: 'allowableReReleaseInd',
    headerName: 'ih.allowableReReleaseInd', // All Re-rel
  },
  previouslyReleasedCode: {
    field: 'previouslyReleasedCode',
    headerName: 'ih.previouslyReleasedCode', // Prev Rel
  },
  marketBasedRateInd: {
    field: 'marketBasedRateInd',
    headerName: 'ih.marketBasedRateInd', // Mkt Based Rate Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  ibrIndCapRelInd: {
    field: 'ibrIndCapRelInd',
    headerName: 'ih.ibrIndCapRelInd', // IBR Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  discountInd: {
    field: 'discountInd',
    headerName: 'ih.discountInd', // Disc Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  discountdiscountIndDescInd: {
    field: 'discountIndDesc',
    headerName: 'ih.discountIndDesc', // Disc Ind Desc
  },
  discountIndNonReservation: {
    field: 'discountIndNonReservation',
    headerName: 'ih.discountIndNonReservation', // Disc Ind Non-res
  },
  indemnificationClause: {
    field: 'indemnificationClause',
    headerName: 'ih.indemnificationClause', // Indemn
  },
  overrunResponsibilityCode: {
    field: 'overrunResponsibilityCode',
    headerName: 'ih.overrunResponsibilityCode', // Ovr Resp Ind
  },
  waiveBidderCreditCode: {
    field: 'waiveBidderCreditCode',
    headerName: 'ih.waiveBidderCreditCode', // Waive Bidder Credit Ind
  },
  rightToAmendPrimPtsCode: {
    field: 'rightToAmendPrimPtsCode',
    headerName: 'ih.rightToAmendPrimPtsCode', // RAPP
  },
  rappLimit: {
    field: 'rappLimit',
    headerName: 'ih.rappLimit', // RAPP Limit
  },
  rapp: {
    field: 'rapp',
    headerName: 'ih.rapp', // RAPP
  },
  rappTerms: {
    field: 'rappTerms',
    headerName: 'ih.rappTerms', // RAPP Terms
  },
  rofrInd: {
    field: 'rofrInd',
    headerName: 'ih.rofrInd', // ROFR Ind
  },
  roftTerms: {
    field: 'roftTerms',
    headerName: 'ih.roftTerms', // ROFR Terms
  },
  rolloverInd: {
    field: 'rolloverInd',
    headerName: 'ih.rolloverInd', // Rollover Ind
  },
  rolloverTerms: {
    field: 'rolloverTerms',
    headerName: 'ih.rolloverTerms', // Rollover Terms
  },
  storageInvCondReleaseInd: {
    field: 'storageInvCondReleaseInd',
    headerName: 'ih.storageInvCondReleaseInd', // SICR Ind
  },
  termsNotesStorage: {
    field: 'termsNotesStorage',
    headerName: 'ih.termsNotesStorage', // Terms/Notes – Storage
  },
  relSrContng: {
    field: 'relSrContng',
    headerName: 'ih.relSrContng', // Rel SR Contng
  },
  contingencyEndDate: {
    field: 'contingencyEndDate',
    headerName: 'ih.contingencyEndDate', // Contng End Date/Time
    ...ColumnTypes.DateTime,
  },
  relSrContngTerms: {
    field: 'relSrContngTerms',
    headerName: 'ih.relSrContngTerms', // Rel SR Contng Terms
  },
  standAloneOfferInd: {
    field: 'standAloneOfferInd',
    headerName: 'ih.standAloneOfferInd', // Stand-aln Offer
  },
  offrPkgIdentifier: {
    field: 'offrPkgIdentifier',
    headerName: 'ih.offrPkgIdentifier', // Offer Pkg ID
  },
  noStandAlnOfferTc: {
    field: 'noStandAlnOfferTc',
    headerName: 'ih.noStandAlnOfferTc', // No Stand-aln Offer T&C
  },
  recallReputTerms: {
    field: 'recallReputTerms',
    headerName: 'ih.recallReputTerms', // Recall/Reput Terms
  },
  businessDayInd: {
    field: 'businessDayInd',
    headerName: 'ih.businessDayInd', // Bus Day Ind
  },
  recallNotifPerTimInd: {
    field: 'recallNotifPerTimInd',
    headerName: 'ih.recallNotifPerTimInd', // Recall Notif Timely
  },
  recallNotifPerEeveInd: {
    field: 'recallNotifPerEeveInd',
    headerName: 'ih.recallNotifPerEeveInd', // Recall Notif EE
  },
  recallNotifPerEveInd: {
    field: 'recallNotifPerEveInd',
    headerName: 'ih.recallNotifPerEveInd', // Recall Notif Eve
  },
  recallNotifPerId1Ind: {
    field: 'recallNotifPerId1Ind',
    headerName: 'ih.recallNotifPerId1Ind', // Recall Notif ID1
  },
  recallNotifPerId2Ind: {
    field: 'recallNotifPerId2Ind',
    headerName: 'ih.recallNotifPerId2Ind', // Recall Notif ID2
  },
  recallNotifPerId3Ind: {
    field: 'recallNotifPerId3Ind',
    headerName: 'ih.recallNotifPerId3Ind', // Recall Notif ID3
  },
  termsNotes: {
    field: 'termsNotes',
    headerName: 'ih.termsNotes', // Terms/Notes
  },
  splTermsMiscNotes: {
    field: 'splTermsMiscNotes',
    headerName: 'ih.termsNotes', // Terms/Notes
  },
  tsp: {
    field: 'tsp',
    headerName: 'ih.tsp', // TSP
    filter: 'agSetColumnFilter', // same as filter: true
  },
  tspProp: {
    field: 'tspProp',
    headerName: 'ih.tspProp', // TSP Prop
  },
  releaser: {
    field: 'releaser',
    headerName: 'ih.releaser', // Releaser or Releaser Prop
  },
  releaserDunNbr: {
    field: 'releaserDunNbr',
    headerName: 'ih.releaserDunNbr', // Releaser Prop
  },
  releaserContactName: {
    field: 'releaserContactName',
    headerName: 'ih.releaserContactName', // Rel Contact
  },
  releaserContactPhone: {
    field: 'releaserContactPhone',
    headerName: 'ih.releaserContactPhone', // Rel Phone
  },
  releaserContactFax: {
    field: 'releaserContactFax',
    headerName: 'ih.releaserContactFax', // Rel Fax
  },
  releaserContactEmail: {
    field: 'releaserContactEmail',
    headerName: 'ih.releaserContactEmail', // Rel E-mail
  },
  bidder: {
    field: 'bidder',
    headerName: 'ih.bidder', // Bidder
  },
  bidNumber: {
    field: 'bidNumber',
    headerName: 'ih.bidNo', // Bid No 
  },
  bidderDunNbr: {
    field: 'bidderDunNbr',
    headerName: 'ih.bidderDunNbr', // Bidder Prop
  },
  afflCode: {
    field: 'afflCode',
    headerName: 'ih.afflCode', // Affil
  },
  affil: {
    field: 'affil',
    headerName: 'ih.afflCode', // Affil
  },
  replShipperRoleCode: {
    field: 'replShipperRoleCode',
    headerName: 'ih.replShipperRoleCode', // Repl SR Role Ind
  },
  replShipperContractNbr: {
    field: 'replShipperContractNbr',
    headerName: 'ih.replShipperContractNbr', // Repl SR K
  },
  termsNotesAma: {
    field: 'termsNotesAma',
    headerName: 'ih.termsNotesAma', // Terms/Notes – AMA
  },
  relDesAcceptableBidBasis: {
    field: 'relDesAcceptableBidBasis',
    headerName: 'ih.relDesAcceptableBidBasis', // Rel Acpt Bid Basis
    filter: 'agSetColumnFilter', // same as filter: true
  },
  bidEvalMthdInd: {
    field: 'bidEvalMthdInd',
    headerName: 'ih.bidEvalMthdInd', // Bid Eval Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  bidEval: {
    field: 'bidEval',
    headerName: 'ih.bidEval', // Bid Eval
  },
  bidMinQtyLocation: {
    field: 'bidMinQtyLocation',
    headerName: 'ih.bidMinQtyLocation', // Bid Min Qty - Loc
    ...ColumnTypes.Double,
  },
  bidMinQtyContract: {
    field: 'bidMinQtyContract',
    headerName: 'ih.bidMinQtyContract', // Bid Min Qty - K 
    ...ColumnTypes.Double,
  },
  locPurpose: {
    field: 'locPurpose',
    headerName: 'ih.locPurpose', // Location Purpose
  },
  locPurposeName: {
    field: 'purposeName',
    headerName: 'ih.locPurpose', // Location Purpose
  },
  rateBid: {
    field: 'rateBid',
    headerName: 'ih.rateBid', // Rate Bid,
    ...ColumnTypes.Double,
  },
  relAcptBidBasis: {
    field: 'relAcptBidBasis',
    headerName: 'ih.relAcptBidBasis', // Rel Acpt Bid Basis
  },
  bidTieBreakingMthd: {
    field: 'bidTieBreakingMthd',
    headerName: 'ih.bidTieBreakingMthd', // Bid T-Brk
  },
  minVolCommitPctEvalInd: {
    field: 'minVolCommitPctEvalInd',
    headerName: 'ih.minVolCommitPctEvalInd', // Min Vol Pct Eval
  },
  minAccpVolCommitmentQty: {
    field: 'minAccpVolCommitmentQty',
    headerName: 'ih.minAccpVolCommitmentQty', // Min Acpt Vol Qty
    ...ColumnTypes.Double,
  },
  minAccpVolCommitmentPct: {
    field: 'minAccpVolCommitmentPct',
    headerName: 'ih.minAccpVolCommitmentPct', // Min Acpt Vol Pct
    ...ColumnTypes.Double,
  },
  minAccpVolLfPct: {
    field: 'minAccpVolLfPct',
    headerName: 'ih.minAccpVolLfPct', // Min Acpt Vol LF Pct
    ...ColumnTypes.Double,
  },
  prearrangedDealMatchDate: {
    field: 'prearrangedDealMatchDate',
    headerName: 'ih.prearrangedDealMatchDate', // Prearr Match Date/Time
    ...ColumnTypes.DateTime,
  },
  capacityAwardDate: {
    field: 'capacityAwardDate',
    headerName: 'ih.capacityAwardDate', // Cap Awd Date/Time
    ...ColumnTypes.DateTime,
  },
  cycleIndicator: {
    field: 'cycleIndicator',
    headerName: 'ih.cycleIndicator', // Cycle
  },
  offerRates: {
    field: 'offerRates',
    headerName: 'ih.offerRates', // Offer Rates
    filter: 'agSetColumnFilter', // same as filter: true
  },
  offerLocations: {
    field: 'offerLocations',
    headerName: 'ih.offerLocations', // Offer Locations
  },
  rateId: {
    field: 'rateId',
    headerName: 'ih.rateId', // Rate ID
    filter: 'agSetColumnFilter', // same as filter: true
  },
  reservationRtBasis: {
    field: 'reservationRtBasis',
    headerName: 'ih.reservationRtBasis', // Res Rate Basis
    filter: 'agSetColumnFilter', // same as filter: true
  },
  maxTrfRate: {
    field: 'maxTrfRate',
    headerName: 'ih.maxTrfRate', // Max Trf Rate
    ...ColumnTypes.Double,
  },
  minAcptRate: {
    field: 'minAcptRate',
    headerName: 'ih.minAcptRate', // Min Acpt Rate
    ...ColumnTypes.Double,
  },
  minAcptPct: {
    field: 'minAcptPct',
    headerName: 'ih.minAcptPct', // Min Acpt Pct
    ...ColumnTypes.Double,
  },
  seasonalStartDate: {
    field: 'seasonalStartDate',
    headerName: 'ih.seasonalStartDate', // Seasnl St
    ...ColumnTypes.DateMonthDay,
  },
  seasonalEndDate: {
    field: 'seasonalEndDate',
    headerName: 'ih.seasonalEndDate', // Seasnl End
    ...ColumnTypes.DateMonthDay,
  },
  ibrFormulaIdentifier: {
    field: 'ibrFormulaIdentifier',
    headerName: 'ih.ibrFormulaIdentifier', // IBR Formula ID
  },
  ibrIndexMathOpCode: {
    field: 'ibrIndexMathOpCode',
    headerName: 'ih.ibrIndexMathOpCode', // IBR Math Oper Ind 
  },
  ibrIndexReference1Mult: {
    field: 'ibrIndexReference1Mult',
    headerName: 'ih.ibrIndexReference1Mult', // IBR Ref 1 Mult
    ...ColumnTypes.Double,
  },
  ibrIdxReference1: {
    field: 'ibrIdxReference1',
    headerName: 'ih.ibrIdxReference1', // IBR Ref 1
  },
  ibrIdxMathOpCode: {
    field: 'ibrIdxMathOpCode',
    headerName: 'ih.ibrIdxMathOpCode', // IBR Math Oper Ind
  },
  ibrIndexReference2Mult: {
    field: 'ibrIndexReference2Mult',
    headerName: 'ih.ibrIndexReference2Mult', // IBR Ref 2 Mult
    ...ColumnTypes.Double,
  },
  ibrIdxReference2: {
    field: 'ibrIdxReference2',
    headerName: 'ih.ibrIdxReference2', // IBR Ref 2
  },
  ibrVariableMathOpCode: {
    field: 'ibrVariableMathOpCode',
    headerName: 'ih.ibrVariableMathOpCode', // IBR Var Oper Ind
  },
  ibrFormulaVariable: {
    field: 'ibrFormulaVariable',
    headerName: 'ih.ibrFormulaVariable', // IBR Form Var
    ...ColumnTypes.Double,
  },
  ibrRateDefault: {
    field: 'ibrRateDefault',
    headerName: 'ih.ibrRateDefault', // IBR Rate Default
    ...ColumnTypes.Double,
  },
  ibrRateFloor: {
    field: 'ibrRateFloor',
    headerName: 'ih.ibrRateFloor', // IBR Rate Floor
    ...ColumnTypes.Double,
  },
  ibrUniqueFormulaSpecTerms: {
    field: 'ibrUniqueFormulaSpecTerms',
    headerName: 'ih.ibrUniqueFormulaSpecTerms', // IBR Unique Formula Spec
  },
  surchargeCode: {
    field: 'surchargeCode',
    headerName: 'ih.surchargeCode', // Surchg Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  surchgInd: {
    field: 'surchgInd',
    headerName: 'ih.surchargeCode', // Surchg Ind
    filter: 'agSetColumnFilter', // same as filter: true
  },
  surchgId: {
    field: 'surchgId',
    headerName: 'ih.surchgId', // Surchg ID
    filter: 'agSetColumnFilter', // same as filter: true
  },
  totSurchg: {
    field: 'totSurchg',
    headerName: 'ih.totSurchg', // Tot Surchg
    ...ColumnTypes.Double,
  },
  purpose: {
    field: 'purpose',
    headerName: 'ih.purpose', // Loc Purp
  },
  purposeName: {
    field: 'purposeName',
    headerName: 'ih.purpose', // Loc Purp
  },
  locOba: {
    field: 'locOba',
    headerName: 'ih.locOba', // Loc OBA
  },
  maximumOfferQtyLocation: {
    field: 'maximumOfferQtyLocation',
    headerName: 'ih.maximumOfferQtyLocation', // Max Offer Qty-Loc
    ...ColumnTypes.Double
  },
  capacityTypeLocationCode: {
    field: 'capacityTypeLocationCode',
    headerName: 'ih.capacityTypeLocationCode', // Cap Type Loc
  },
  route: {
    field: 'route',
    headerName: 'ih.route', // Rte
  },
  minimumOfferQtyLocation: {
    field: 'minimumOfferQtyLocation',
    headerName: 'ih.minimumOfferQtyLocation', // Min Offer Qty-Loc
    ...ColumnTypes.Double
  },
  systemMgtServiceQty: {
    field: 'systemMgtServiceQty',
    headerName: 'ih.systemMgtServiceQty', // Sys Mgmt Svc Qty
  },
  bidderContingencyInd: {
    field: 'bidderContingencyInd',
    headerName: 'ih.bidderContingencyInd', // Bidder Contng
  },
  bidderLesserQtyCode: {
    field: 'bidderLesserQtyCode',
    headerName: 'ih.bidderLesserQtyCode', // Bidder Less Qty
  },
  bidQtyContract: {
    field: 'bidQtyContract',
    headerName: 'ih.bidQtyContract', // Bid Qty-K
    ...ColumnTypes.Double,
  },
  bidderContngTerms: {
    field: 'bidderContngTerms',
    headerName: 'ih.bidderContngTerms', // Bidder Contng Terms
  },
  standAlnBidCode: {
    field: 'standAlnBidCode',
    headerName: 'ih.standAlnBidCode', // Stand-aln Bid
  },
  noStandAlnBidTc: {
    field: 'noStandAlnBidTc',
    headerName: 'ih.noStandAlnBidTc', // No Stand-aln Bid T&C
  },
  bidPackageIdentifier: {
    field: 'bidPackageIdentifier',
    headerName: 'ih.bidPackageIdentifier', // Bid Pkg ID
  },
  prearrangedBidCode: {
    field: 'prearrangedBidCode',
    headerName: 'ih.prearrangedBidCode', // Prearr Bid
  },
  bidderReleaseTermStartDate: {
    field: 'bidderReleaseTermStartDate',
    headerName: 'ih.bidderReleaseTermStartDate', // Bidder Rel Term St
    ...ColumnTypes.DateTime,
  },
  bidderReleaseTermEndDate: {
    field: 'bidderReleaseTermEndDate',
    headerName: 'ih.bidderReleaseTermEndDate', // Bidder Rel Term End
    ...ColumnTypes.DateTime,
  },
  minVolCommitmentQty: {
    field: 'minVolCommitmentQty',
    headerName: 'ih.minVolCommitmentQty', // Min Vol Qty
    ...ColumnTypes.Double,
  },
  minVolPct: {
    field: 'minVolPct',
    headerName: 'ih.minVolPct', // Min Vol Pct
    ...ColumnTypes.Double,
  },
  bidReceiptDate: {
    field: 'bidReceiptDate',
    headerName: 'ih.bidReceiptDate', // Bid Rec Date/Time
    ...ColumnTypes.DateTime,
  },
  bidRates: {
    field: 'bidRates',
    headerName: 'ih.bidRates', // Bid Rates
  },
  bidLocations: {
    field: 'bidLocations',
    headerName: 'ih.bidLocations', // Bid Locations
  },
  bidValue: {
    field: 'bidValue',
    headerName: 'ih.bidValue', // Bid Value
    ...ColumnTypes.Double,
  },
  pctMaxTrfRateBid: {
    field: 'pctMaxTrfRateBid',
    headerName: 'ih.pctMaxTrfRateBid', // Pct Max Trf Rate Bid
    ...ColumnTypes.Double,
  },
  ibrBidDiff: {
    field: 'ibrBidDiff',
    headerName: 'ih.ibrBidDiff', // IBR Bid Diff
    ...ColumnTypes.Double,
  },
  ibrBidDiffFloor: {
    field: 'ibrBidDiffFloor',
    headerName: 'ih.ibrBidDiffFloor', // IBR Bid Diff Floor
    ...ColumnTypes.Double,
  },
  ibrBidPct: {
    field: 'ibrBidPct',
    headerName: 'ih.ibrBidPct', // IBR Bid %
    ...ColumnTypes.Double,
  },
  ibrBidDiffPct: {
    field: 'ibrBidDiffPct',
    headerName: 'ih.ibrBidDiffPct', // IBR Bid %
    ...ColumnTypes.Double,
  },
  bidQtyLocation: {
    field: 'bidQtyLocation',
    headerName: 'ih.bidQtyLocation', // Bid Qty-Loc
    ...ColumnTypes.Long,
  },
  locQtiCode: {
    field: 'locQtiCode',
    headerName: 'ih.locQtiCode', // Loc/QTI
  },
  locQtiCodeName: {
    field: 'locQtiName',
    headerName: 'ih.locQtiCode', // Loc/QTI
  },
  trnsSetPurpCd: {
    field: 'trnsSetPurpCd',
    headerName: 'ih.trnsSetPurpCd', // Status
  },
  awardNumber: {
    field: 'awardNumber',
    headerName: 'ih.awardNo', // Awd No
    //headerName: 'ih.awardNumber', // Award Number
  },
  awardQtyContract: {
    field: 'awardQtyContract',
    headerName: 'ih.awardQtyContract', // Award Qty-K
    ...ColumnTypes.Long,
  },
  awardRates: {
    field: 'awardRates',
    headerName: 'ih.awardRates', // Award Rates
  },
  awdRate: {
    field: 'awdRate',
    headerName: 'ih.awdRate', // Awd Rate
    ...ColumnTypes.Double,
  },
  awardLocations: {
    field: 'awardLocations',
    headerName: 'ih.awardLocations', // Award Locations
  },
  awardValue: {
    field: 'awardValue',
    headerName: 'ih.awardValue', // Award Value
    ...ColumnTypes.Double,
  },
  awdPctMaxTrfRate: {
    field: 'awdPctMaxTrfRate',
    headerName: 'ih.awdPctMaxTrfRate', // Awd Pct Max Trf Rate
  },
  awardQuantityLocation: {
    field: 'awardQuantityLocation',
    headerName: 'ih.awardQuantityLocation', // Awd Qty - Loc
    ...ColumnTypes.Long,
  },
  bidderContactPhone: {
    field: 'bidderContactPhone',
    headerName: 'ih.bidderContactPhone', // Bidder Phone
  },
  bidderContactEmail: {
    field: 'bidderContactEmail',
    headerName: 'ih.bidderContactEmail', // Bidder E-mail
  },
  rfqfOffrNbr: {
    field: 'rfqfOffrNbr',
    headerName: 'ih.rfqOffrNbr', // Offer No
  },

  preApprovedBidderName: {
    field: 'bidderName',
    headerName: 'ih.preApprovedBidderName', // PAB Name
  },
  preApprovedBidder: {
    field: 'preApprovedBidder',
    headerName: 'ih.preApprovedBidder', // PAB or PAB Prop
  },
  statementDateTime: {
    field: 'statementDateTime',
    headerName: 'ih.statementDateTime', // Stmt D/T
  },
  withdrawalTypeCode: {
    field: 'withdrawalTypeCode',
    headerName: 'ih.withdrawalTypeCode', // W/D Type
  },
  withdrawalTypeCodeDesc: {
    field: 'withdrawalTypeCodeDesc',
    headerName: 'ih.withdrawalTypeCode', // W/D Type
  },
  withdrawalComments: {
    field: 'withdrawalComments',
    headerName: 'ih.withdrawalComments', // W/D Comments
  },
}

export default NameModelMap;