import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import SharedColDefs from 'asset/SharedColDefs';
import QuickConfig from "../../header/QuickConfig";


const AllUnsubbedCap = (props) => {
    const gadgetId = 101;
    const { configs, searchDateSettings } = useSelector((state) => state.info)
    const searchDateSetting = searchDateSettings[gadgetId];

    const location = useLocation();
    const { message } = useSelector((state) => state.message);
    
    const datePeriodStrings = T.getDatePeriodFromToday(searchDateSetting.from || 0, true, searchDateSetting.to, searchDateSetting.type);
    const datePeriod = T.getDatePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
    
    const [postData, setPostData] = useState({
        'startDate': location.state?.startDate || datePeriodStrings.startDate,
        'endDate': location.state?.endDate || datePeriodStrings.endDate,
    });

    const [refreshCount, setRefresh] = useState(0);
    const queryForm = useRef();
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getAllUnsubbedCap', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    const processColumns = (cols) => {
        let groupingCols = [
            // 'pipelineName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        groupingCols.forEach(colModel => {
            if (colModel) {
                colModel.rowGroup = true;
                colModel.hide = true;
            }
        });

        let sortingCols = [
            'effGasDay',
            'effEndGasDay',
            'pipelineName',
            'loc',
            'locName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });
        sortingCols.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    };

    // if from widget do grouping, if from catch all don't group
    let columnDefs = processColumns(getNameModelMap(SharedColDefs.Capacity.UnsubbedCap.All));
    const [pipelineOptions, setPipelineOptions] = useState([]);
    const onPipelineSelect = () => {
        QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
    }

    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
                values: pipelineOptions, otherProps: {
                    showSearch: true,
                    options: pipelineOptions,
                    onFocus: onPipelineSelect,
                    style: { width: '100%' }
                }
            },
            { id: 2, name: 'dateRange', type: 'datepicker', label: message['ih.effDate.full'] }],
        initialValues: {
            'dateRange': [
                location.state?.startDate ? T.getDate(location.state?.startDate) : datePeriod.startDate,
                location.state?.endDate ? T.getDate(location.state?.endDate) : datePeriod.endDate
            ]
        },
        submit:  (prevState) => {
            if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
                prevState.pipelines = [];
            }
            setPostData(prevState);
        },
        settings: { colSpan: 2 },
        formRef: queryForm,
        reset: (e) => {
            queryForm.current?.resetFields();
        }
    });

    const gadgetItem = { grid: <InfoGrid id="AllUnsubbedCap" columnDefs={columnDefs} data={output}></InfoGrid> };
    const gadget = {
        id: gadgetId,
        name: 'AllUnsubbedCap',
        loading,
        title: message['ih.widget.capacity.allUnsubbedCap'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        width: 100
    };

    return (
        <Gadget {...gadget} />
    );
};

export default AllUnsubbedCap;
