import { BackTop } from 'antd';
import Tooltip from '../tooltip/Tooltip';

import './BackTop.css';
import { useSelector } from 'react-redux';

const Backtop = () => {
  const { message } = useSelector((state) => state.message);
  return (<Tooltip title={message['ih.goToTop']}>
    <BackTop />
  </Tooltip>
  )
}
export default Backtop;