import { TUtil as T } from '@trellisenergy/common-ui-core';
import React, { useRef } from 'react'
import { Drawer, Divider, Button } from "antd"
import CheckboxListControl from '../control/CheckboxListControl'
import { useSelector } from 'react-redux'
import './QuickConfig.css'

const QuickConfig = ({ saveConfig, close, visible, pipelineData }) => {
  const { message } = useSelector((state) => state.message)
  let myPipelineRef = useRef(this);
  const { activePipelines } = pipelineData;
  /**
   * 
   * Return only the favorite pipeline data
   */
  const getQuickConfigData = () => {
    let list = [];
    Object.entries(activePipelines).forEach(([key, p]) => {
      list.push({
        "id": p.id + '-checkbox',
        "name": 'favorite-pipelines',
        "label": p.name,
        "type": "checkbox",
        "defaultValue": p.active,
        "val": p.id,
        "filtered": false,
      });
    });

    return list;
  }

  QuickConfig.getPipelines = function (pipelineOptions, setPipelineOptions) {
    if (!T.isEmpty(pipelineOptions)) {
      return
    }
    const pipelines = getQuickConfigData().filter((it) => {
      return it.defaultValue;
    }).map((i) => ({
      label: i.label,
      value: i.val
    })).sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
    setPipelineOptions(pipelines)
  };

  let list = getQuickConfigData();

  const save = (e) => {
    let checkboxListState = myPipelineRef.current.state;
    let checkboxData = {};
    Object.entries(checkboxListState.checkboxList).forEach(([key, p]) => {
      checkboxData[p.val * 1] = { active: p.defaultValue }
    });

    saveConfig({
      pipelineData:
        { activePipelines: checkboxData }
    });
  }

  return (<Drawer
    title={message['ih.menu.quickConfig']}
    placement='right'
    closable={true}
    onClose={close}
    width={T.isSmallVerticalScreen ? T.getScreenWidth() : 500}
    visible={visible}
    footer={
      <div style={{
        textAlign: 'right',
      }}>
        <Button type="primary" style={{ marginRight: 8 }} onClick={save}>{message['ih.apply']}</Button>
        <Button onClick={close}>{message['ih.cancel']}</Button>
      </div>
    }
  >
    <CheckboxListControl checkboxList={list} ref={myPipelineRef} />
    {/*<div id='quickconfigbutton'>*/}
    {/*  <Divider style={{ margin: '1px 0' }} />*/}
    {/*  <div style={{ paddingLeft: '100px' }}>*/}
    {/*    <Button className='info-btn-primary' type="link" onClick={save}>{message['ih.apply']}</Button>*/}
    {/*    <Button className='info-btn-secondary' type="link" onClick={close}>{message['ih.cancel']}</Button>*/}
    {/*  </div>*/}
    {/*</div>*/}
  </Drawer>)
}

export default QuickConfig;