import React, { useState } from 'react';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { Modal } from 'antd';
import About from '../page/about/About'
import moment from 'moment'

import './Footer.css'

import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
function Footer() {
  // TODO: use for all links
  const [isAboutModalVisible, setAboutIsModalVisible] = useState(false);
  const { configs } = useSelector((state) => state.info);

  const showAboutModal = () => {
    setAboutIsModalVisible(true);
  };

  const handleAboutOk = () => {
    setAboutIsModalVisible(false);
  };

  const handleCancel = () => {
    setAboutIsModalVisible(false);
  };
  return (<>
    <Modal className={'footer-modal'} width={800} title="" visible={isAboutModalVisible} onOk={handleAboutOk}
      footer={null}
      onCancel={handleCancel}>
      <About serverRestartTime={moment(configs?.serverRestartTime).format('ddd, DD MMMM yyyy hh:mm A')} />
    </Modal>
    <div className='footer'>
      © {T.getYear()} TRELLIS ENERGY. All rights reserved.
      <div>
        <Link id="about_link" to='#' onClick={showAboutModal}>About</Link> |
        <a href='http://www.trellisenergy.com'>www.trellisenergy.com</a>
      </div>
    </div>
  </>
  );
}

export default Footer;