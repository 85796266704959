import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from "infogrid";
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import { useDispatch, useSelector } from 'react-redux'
import { CacheApi } from 'api/v1/index'
import { message as AntdMessage, Modal, Form, Space, Button } from 'antd';
import { setPageSuccessAlert, setPageErrorAlert, clearPageAlert } from 'redux/reducer/info';

const CacheStatistics = (props) => {

  //const caller = location.state?.caller;
  //const datasetShrtNm = location.state?.datasetShrtNm;

  const gridRef = useRef(this);
  const dispatch = useDispatch();
  const { message } = useSelector((state) => state.message)
  const { accountData } = useSelector((state) => state.info);
  //const { cacheStats } = useSelector((state) => state.cache);
  const [postData] = useState({});
  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData('api/v1/cache/getAllCacheStats', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  }

  const isManageEnabled = T.checkAuthority(['MANAGE_INFOHUB_CACHE'], accountData.authorities);

  let nameMap = [
    'cacheName',
    'cacheStatsQueryTime',
    'cacheHit',
    'cacheMisses',
    'cacheHitPercentage',
    'cacheMissPercentage',
    'cacheEvictions',
    'cachePuts',
    'cacheRemovals',
    'avgPutTime',
    'avgGetTime',
    'avgRemoveTime',
    'lastCacheClearTime',
  ];

  let columnDefs = getNameModelMap(nameMap);

  const performQuery = () => {
    dispatch(clearPageAlert({}));
    refresh()
  }

  const performClearCache = () => {
    dispatch(clearPageAlert({}));
    const postData = getSelectedData();
    if (T.isEmpty(postData.cacheNames)) {
      Modal.warn({
        content: 'Please select at least one Cache'
      });
    } else {
      AntdMessage
        .loading('Please wait...', 2.5)
        .then(() => {
          CacheApi.clearCache(postData)
            .then(() => performQuery())
            .then(() => dispatch(setPageSuccessAlert({ show: true, message: 'Cache(s) cleared successfully' })))
            .catch(e => {
              const msg = ApiResult.getMessages(e);
              if (msg) {
                // TODO: change the alerts to infer from the resultType
                if (e.resultType === 'ERROR') {
                  dispatch(setPageErrorAlert({ show: true, message: msg }))
                }
              }
            })
        })
    }
  }



  const getSelectedData = (action) => {
    const postData = {
      cacheNames: [],
    };
    if (gridRef.current) {
      let selectedNodes = gridRef.current.gridApi.getSelectedNodes();
      if (selectedNodes) {
        let selectedData = selectedNodes.map(node => node.data);
        postData.cacheNames = selectedData.map(r => r.cacheName);
      }
    }
    return postData;
  }

  const controls =
    <Form layout='inline' style={{ justifyContent: 'flex-end' }}>
      <Form.Item style={{ textAlign: 'right' }}>
        <Space>
          {isManageEnabled ?
            <Button type="primary" name='clearCache' htmlType="submit" /*disabled={!state.isApproveEnabled}*/ onClick={performClearCache}>Clear Cache</Button>
            : ''}
          <Button type="primary" name='query' htmlType="submit" onClick={performQuery}>Query</Button>
        </Space>
      </Form.Item>
    </Form >

  const gadgetItem = {
    grid: <InfoGrid id="CacheStatistics" gridRef={gridRef}
      gridOptions={{
        headerCheckbox: isManageEnabled,
      }}
      columnDefs={columnDefs} data={output}></InfoGrid>
  }
  const gadget = {
    loading,
    //refresh,
    //id: 'Gadget1',
    title: message['ih.widget.cache.statistics'],
    views: ['grid'], // grid, chart
    gadgetItem,
    loadTimestamp,
    controls,
    //dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget}></Gadget>
  );
}

export default CacheStatistics;
