import React, { useRef, useState } from 'react';
import Gadget from 'infogadget'
import { BarChart } from 'infochart'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useHistory } from 'react-router-dom'
import { IocChartConfig } from "../../chart/config";

const MDQsByCustomer = (props) => {
    // const { configs } = useSelector((state) => state.info)

    const { message } = useSelector((state) => state.message)
    const [postData] = useState({
        mdq: 'TRANSPORT'
    });
    const [refreshCount, setRefresh] = useState(0);

    // const [postDataMaxCalendarQuarter] = useState({});

    const [loading, output, loadTimestamp] = useFetchData('api/v1/ioc/getMDQsByCustomer', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };
    const history = useHistory();

    const convertData = (data = []) => {
        return data.filter((item) => {
            return item.type === message['ih.transportMdq'];
        }).map(d => {
            return {
                entityId: d.entityId,
                customer: d.customer,
                type: d.type,
                value: d.sum
            }
        }).sort((a, b) => { return b.value - a.value }); // desc order
    };

    const chartRef = useRef();

    let chartData = convertData(output.mdqSumByCustomers);

    let customConfig = {
        ...IocChartConfig,
        xField: 'value',
        yField: 'customer',
        seriesField: 'value',
        height: 300,
        legend: false,
        rail: false,
        meta: {
            value: {
                formatter: (item) => {
                    return item.toLocaleString();
                }
            }
        },
    };

    const gadgetItem = {
        chart: <BarChart chartRef={chartRef} customChartConfig={customConfig} data={chartData} />
    };

    T.registerChartListener(chartRef, (event) => {
        if (event.data) {
            history.push(T.buildURL('/ioc/MDQsByCustomerDetail'), {
                entityId: event.data.data.entityId,
                mdqColumnFilter: 'transportMdqUom',
                mdq: 'TRANSPORT'
            });
        }
    });

    const gadget = {
        loading: loading,
        refresh,
        id: 'ioc1',
        views: ['chart'],
        gadgetItem,
        title: output.firstDayCalendarQuarter ? message['ih.widget.ioc.mdqsByCustomerTransport'] + ' Q' + output.quarterOfYear + ' ' + output.quarterYear : message['ih.widget.ioc.mdqsByCustomerTransport'] + " N/A",
        navToolTip: message['ih.widget.nav.ioc.tooltip'],
        loadTimestamp: loadTimestamp,
        dataSourceId: 'getMDQsByCustomer',
        width: 50
    };
    return (
        <Gadget {...gadget} {...props}> </Gadget>
    )
};

export default MDQsByCustomer;