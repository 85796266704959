import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

/**
 * To do specific tasks
 */
export default class CacheApi {
  static getAllCacheStats(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/cache/getAllCacheStats');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get getAllCacheStats');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static clearCache(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/cache/clearCache', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t clear cache');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

    static clearAllCache(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/cache/clearAllCache', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                return error;

            });
    }
}