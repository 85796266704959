import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

export default class LocationApi {

  static transform(_data) {
    return _data.map(d => ({
      id: d.id,
      value: d.id || d.value,
      label: d.name,
    }))
  }

  /* @deprecated, use global configs instead */
  static getDownloadOptions(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/locations/getLocationDownloadOptions', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get DownloadOptions');
          return [];
        } else {
          //
        }
        let ret = {};
        for (const [key, value] of Object.entries(responseJson.data)) {
          ret[key] = this.transform(value)
        }
        return ret;
      })
      .catch(error => {
          return {};
      });
  }

    static async callOptionsRequest(optionsLoadingSetter, optionsSetter, getOptionsRequest) {
        optionsLoadingSetter(true);

        let response = await fetch(getOptionsRequest)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                return responseJson?.data;
            })
            .catch(error => {
                return null;
            });

        optionsLoadingSetter(false);

        let opts = [];

        if (response) {
            let mappedOptions = response.map((i) => ({
                label: i.name,
                value: i.id
            }));
            opts = mappedOptions;
        }
        optionsSetter(opts);
    }

    static fetchLocStAbbrevOptions(optionsLoadingSetter, optionsSetter) {
        let getOptionsRequest = ApiRequest.composePostJsonRequest('api/v1/locations/getLocStAbbrevOptions',
            null, {});

        LocationApi.callOptionsRequest(optionsLoadingSetter, optionsSetter, getOptionsRequest)

    }

    static fetchLocTypeOptions(optionsLoadingSetter, optionsSetter) {
        let getOptionsRequest = ApiRequest.composePostJsonRequest('api/v1/locations/getLocTypeOptions',
            null, {});

        LocationApi.callOptionsRequest(optionsLoadingSetter, optionsSetter, getOptionsRequest)
    }

}