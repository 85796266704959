import { Route } from 'react-router-dom';
import {
  GadgetShortcut,
  AllLocations,
  LocationDetail,
  State,
  LocationType,
  Direction,
} from 'gadget';

import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from "react-redux";

function Locations() {
  const { message } = useSelector((state) => state.message)
  const path = '/locations';

  return (
    <div>
      <Route exact path={T.buildURL(path)}>
        <AllLocations />
        {/*
        <State drilldown={{ link: T.buildURL(path + '/StateDetail'), title: 'State', level: 1, state: { caller: 'state' } }} />
        <LocationType drilldown={{ link: T.buildURL(path + '/LocationTypeDetail'), level: 1, state: { caller: 'type' } }} />
        */}
        {/*<ActiveInactive drilldown={{ link: T.buildURL(path + '/ActiveInactiveDetail'), title: 'Active/Inactive', level: 1, state: { caller: 'active' } }} />*/}
          {/*<Direction drilldown={{ link: T.buildURL(path + '/DirectionDetail'), title: 'Direction', level: 1, state: { caller: 'direction' } }} />*/}
        {/*<RecentChanges drilldown={{ link: T.buildURL(path + '/RecentChangesDetail'), title: 'Recent Changes', level: 1, state: { caller: 'recent' } }} />*/}
          {/*<GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllLocations'), title: message['ih.widget.locations.allLocations'], level: 1 }} />*/}
      </Route>
      <Route path={T.buildURL(path + '/DirectionDetail')}>
        <LocationDetail />
      </Route>
      <Route path={T.buildURL(path + '/LocationTypeDetail')}>
        <LocationDetail />
      </Route>
      <Route path={T.buildURL(path + '/ActiveInactiveDetail')}>
        <LocationDetail />
      </Route>
      <Route path={T.buildURL(path + '/StateDetail')}>
        <LocationDetail />
      </Route>
      <Route path={T.buildURL(path + '/RecentChangesDetail')}>
        <LocationDetail />
      </Route>
      <Route path={T.buildURL(path + '/AllLocations')}>
        <AllLocations />
      </Route>
    </div >
  );
}

export default Locations;