import React, { useRef, useState } from 'react';
import { InfoGrid } from 'infogrid'
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { ImbalancesApi } from 'api/v1/index'
import { useSelector } from 'react-redux'
import SharedColDefs from 'asset/SharedColDefs';
import QuickConfig from "../../header/QuickConfig";

function ImbalanceDetail(props) {
  const gadgetId = 400;
  const { configs, searchDateSettings } = useSelector((state) => state.info);
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const { message } = useSelector((state) => state.message);
  const [postData, setPostData] = useState({
    // 'startDate': T.getLastDaysDateStr(configs?.dateRangeFilterMap.POSTED_IMBALANCE_ALL_PC, 'MM/YYYY'), // 20years ago
    'startDate': datePeriod.startDate.format('MM/YYYY'),
    'endDate': T.getTodayDateStr('MM/YYYY'),
  });


  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData(ImbalancesApi.getImbalancesUrl, postData, refreshCount);
  const queryForm = useRef();

  const refresh = () => {
    setRefresh(Math.random())
  };

  const getInitialDateRange = () => {
    return [T.getLastDaysDate(configs?.dateRangeFilterMap.POSTED_IMBALANCE_ALL_PC), T.getDate()];
  };

  let columnDefs = getNameModelMap(SharedColDefs.PostedImbalance.All);
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const onPipelineSelect = () => {
    QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
  }
  
  const processColumns = (cols) => {
    let sortingCols = [
      'imbPeriod',
      'pipelineName',
      'svcReqName',
      'ctrctHolderName',
      'svcReqAcct',
      'svcReqCtrct',
      'oia',
      'imbType'
    ].map(field => {
      return T.find(cols, (c) => c.field === field)
    });
    sortingCols.forEach((colModel, index) => {
      if (colModel) {
        if (colModel.field === 'imbPeriod') colModel.sort = 'desc';
        else colModel.sort = 'asc';
        colModel.sortIndex = index;
      }
    });
    
    return cols;
  };

  const controls = ControlFactory.createControls({
    controls: [
      { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
        values: pipelineOptions, otherProps: {
          showSearch: true,
          options: pipelineOptions,
          onFocus: onPipelineSelect,
          style: { width: '100%' }
        }
      },
        { id: 2, name: 'dateRange', type: 'datepicker', label: 'Imbalance Period', format: 'MM/YYYY', picker: 'month' }],
    initialValues: {
      'dateRange': [datePeriod.startDate, datePeriod.endDate]
    },
    submit:  (prevState) => {
      if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
        prevState.pipelines = [];
      }
      setPostData(prevState);
    },
    settings: { colSpan: 2 },
    formRef: queryForm,
    reset: (e) => {
      queryForm.current?.resetFields();
    }
  });

  const gadgetItem = { grid: <InfoGrid id="ImbalanceDetail" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> }
  const gadget = {
    loading: loading,
    id: 'Gadget1',
    title: message['ih.widget.imbalance.imbalanceAvailableAll'],
    views: ['grid'], // grid
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget} />
  );
}

export default ImbalanceDetail;
