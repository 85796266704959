/**
 * 
 * 
 * Get the height of the supplied element whenever window resize occurs.
 * If an element is not supplied, then return the window height.
 * 
 * original source: https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
 * 
 */
import { useState, useEffect } from 'react';

const getHeight = (element) => {
  return element ? (element.style.height || element.offsetHeight) : window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;
}

function useCurrentHeight(element) {

  // save current height in the state object
  let [height, setHeight] = useState(getHeight(element));

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change height from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setHeight(getHeight(element)), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [element])

  return height;
}

export default useCurrentHeight;