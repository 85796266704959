import moment from 'moment-timezone';
import { BASEURL_DEV } from "../../config";
import React from "react";
import { TUtil } from '@trellisenergy/common-ui-core';

/**
 * Basic custom cell renders for grid
 * If needed more custom render, register react renderer component under frameworkComponents grid options in InfoGrid.js
 */
const ColumnTypes = (() => {
    // *important* we want to display dates as is from server, no conversion
    // remember to use moment.utc(.. to ignore timezone formatting 
    function dateFormatter(params) {
        if (params.value)
            return moment.utc(params.value).format('MM/DD/YYYY');
    }

    /**
     * This method does same thing as the dateFormatter.
     * It was needed because the cells with values in format YYYY-MM-DD
     * were being converted to GMT-8 dates. This method reformats the cell value
     * during the get operation.
     */
    function dateValueGetter(params) {
        let colId = params?.column?.colId;
        let value = params?.data && params.data[colId];
        if (value)
            return moment.utc(value).format('MM/DD/YYYY');
    };

    function dateTimeFormatter(params) {
        if (params.value)
            return moment.utc(params.value).format('MM/DD/YYYY HH:mm');
    }
    function dateTimeFormatterMonthDay(params) {
        if (params.value)
            return moment.utc(params.value).format('MM/DD');
    }
    function dateTimeFormatterMonthYear(params) {
        if (params.value)
            return moment.utc(params.value).format('MM/YYYY');
    }

    function dateComparator(date1, date2) {
        let date1Number = date1 && new Date(date1).getTime();
        let date2Number = date2 && new Date(date2).getTime();

        if (date1Number == null && date2Number == null) {
            return 0;
        }

        if (date1Number == null) {
            return -1;
        } else if (date2Number == null) {
            return 1;
        }

        return date1Number - date2Number;
        // return moment.utc(a).diff(moment.utc(b)) // causing perf problems
    }

    function percentageFormatter(params) {
        if (!TUtil.isEmpty(params.value))
            return params.value + '%'
    }

    function integerPercentageFormatter(params) {
        if (!TUtil.isEmpty(params.value)) {
            if (params.value === "-Infinity" || params.value === null || params.value === 'NaN') {
                return "";
            } else {
                return Math.round(params.value) + '%';
            }
        }
    }

    function milliSecondFormatter(params) {
        if (!TUtil.isEmpty(params.value))
            return params.value + ' ms'
    }

    /*
    function currencyFromatter(params) {
      return '(' + params.value + ')';
    }
    function dateTimeFormatter(params) {
      if (params.value)
        return moment(params.value).format('MM/DD/YYYY h:mm:ss');
    }
    */
    function longFormatter(params) {
        if (!TUtil.isEmpty(params.value))
            return Math.floor(params.value)
                .toString()
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return params.value;
    }

    /**
     * No precision for now
     */
    function doubleFormatter(params) {
        if (!TUtil.isEmpty(params.value)) {
            var parts = params.value.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
            //return params.value.toLocaleString()
        }
        //return Number(params.value.toFixed(params.data.samplePrecision));
    }

    const commonFileDownloadLinkRenderer = (params, props, returnUrl = false, linkText) => {
        const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
        const skipLinking = props.skipBlanks && cellValue === 'skip-linking';
        if (skipLinking) {
            return '';
        }
        let url = TUtil.isDevEnv() ? BASEURL_DEV + params : TUtil.buildURL(params);
        if (returnUrl) {
            return (<a href={url} target={"_blank"} rel="noreferrer">{cellValue || linkText || 'View'}</a>);
        }
        var link = document.createElement('a');
        link.href = url;
        link.innerText = cellValue || linkText || 'View';
        link.style.textDecoration = 'underline';
        link.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(url);
        });
        return link;
    };

    const sourceFileDownloadLinkRenderer = (props) => {
        if (!props.node.group) {
            let params = 'api/v1/admin/downloadSourceFile?sourceFileId=' + props?.data?.sourceFileId;
            return commonFileDownloadLinkRenderer(params, props);
        }
        return '';
    };

    const attachmentDownloadLinkRenderer = (props) => {
        let params = 'api/v1/admin/downloadAttachment?sourceFileId=' + props?.data?.sourceFileId;
        props.skipBlanks = true;
        return commonFileDownloadLinkRenderer(params, props);
    };

    return {
        DatasetGroupAndNameRenderer: {
            cellRenderer: (props) => {
                return props?.data?.datasetGrpNm + ' - ' + props?.data?.datasetNm;
            }
        },
        Long: {
            type: "numberColumn",
            valueFormatter: longFormatter,
            cellClass: 'ag-right-aligned-cell',
        },
        Double: {
            type: "numberColumn",
            valueFormatter: doubleFormatter,
            cellClass: 'ag-right-aligned-cell',
        },
        Currency: {},
        Date: {
            type: "dateColumn",
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            comparator: dateComparator
        },
        DateTime: {
            type: "dateColumn",
            valueFormatter: dateTimeFormatter,
            comparator: dateComparator
        },
        DateMonthDay: {
            type: "dateColumn",
            valueFormatter: dateTimeFormatterMonthDay,
            comparator: dateComparator
        },
        DateMonthYear: {
            type: "dateMonthYearColumn",
            valueFormatter: dateTimeFormatterMonthYear,
            comparator: dateComparator
        },
        SourceFileDownloadLink: {
            cellRenderer: sourceFileDownloadLinkRenderer, filter: ''
        },
        CapacityCommentDownloadLink: {
            cellRenderer: "capCommentDownloadLinkRenderer", filter: '' // see InfoGrid.js frameworkComponents
        },
        NoticeFileTxtDownloadLink: {
            cellRenderer: "noticeTxtFileDownloadLinkRenderer", filter: '' // see InfoGrid.js frameworkComponents
        },
        NoticeFileBlobDownloadLink: {
            cellRenderer: "noticeBlobFileDownloadLinkRenderer", filter: '' // see InfoGrid.js frameworkComponents
        },
        NoticeAttachmentsDownloadLink: {
            cellRenderer: "noticeDownloadAttachmentsRenderer", filter: '' // see InfoGrid.js frameworkComponents
        },
        LocationCommentDownloadLink: {
            cellRenderer: "locationCommentDownloadLinkRenderer", filter: '' // see InfoGrid.js frameworkComponents
        },
        PreviewInPopup: {
            cellRenderer: "previewInPopupRenderer",// see InfoGrid.js frameworkComponents
            filter: 'agTextColumnFilter',
            width: 125,
            minWidth: 125,
            preventAutoSize: true,
        },
        PreviewInPopupFixedWidth500: {
            cellRenderer: "previewInPopupRenderer",// see InfoGrid.js frameworkComponents
            filter: 'agTextColumnFilter',
            width: 500,
            minWidth: 500,
            preventAutoSize: true,
        },
        TriggerScheduledTaskButton: {
            cellRenderer: "triggerScheduledTaskButtonRenderer", filter: ''
        },
        TermsLink: {
            cellRenderer: "termsLinkRenderer", filter: ''
        },
        AttachmentDownloadLink: {
            cellRenderer: attachmentDownloadLinkRenderer, filter: ''
        },
        Percentage: {
            type: "numberColumn",
            valueFormatter: percentageFormatter,
            cellStyle: function (params) {
                return {'text-align': 'right' };
            },
        },
        IntegerPercentage: {
            type: "numberColumn",
            valueFormatter: integerPercentageFormatter,
            cellStyle: function (params) {
                return {'text-align': 'right' };
            },
        },
        Millisecond: {
            type: "numberColumn",
            valueFormatter: milliSecondFormatter,
        },
        FixedWidth100: {
            type: "textColumn",
            width: 100,
            minWidth: 100,
            preventAutoSize: true,
        },
    }
})();

export default ColumnTypes;
