import React, { useEffect, useRef, useState } from 'react';
import { InfoGrid } from 'infogrid'
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { ImbalancesApi } from 'api/v1/index'
import { useSelector } from 'react-redux'

function ImbalanceAvailableDetail(props) {

  const { message } = useSelector((state) => state.message)
  const [postData, setPostData] = useState({ startDate: null, endDate: null });
  const [noPostData] = useState({});

  const [loadInit, setLoadInit] = useState(false);

  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData(ImbalancesApi.getImbalancesUrl, postData, refreshCount, loadInit, setLoadInit);
  const [loadingMaxImbPeriod, outputMaxImbPeriod, loadTimestampMaxImbPeriod] = useFetchData(ImbalancesApi.getMaxImbPeriodUrl, noPostData, refreshCount);
  const queryForm = useRef();

  const refresh = () => {
    setRefresh(Math.random())
  }

  const getInitialDateRange = () => {
    if (outputMaxImbPeriod.maxImbPeriod) {
      return [T.getDate(outputMaxImbPeriod.maxImbPeriod, 'MM/YYYY'), T.getDate(outputMaxImbPeriod.maxImbPeriod, 'MM/YYYY')];
    }
    return [];
  };

  useEffect(() => {
    if (outputMaxImbPeriod.maxImbPeriod) {
      queryForm.current.setFieldsValue({
        'dateRange': getInitialDateRange(),
      });

      setPostData({
        startDate: outputMaxImbPeriod.maxImbPeriod, endDate: outputMaxImbPeriod.maxImbPeriod
      });
    }
  }, [outputMaxImbPeriod]);

  let nameMap = [
    'imbPeriod',
    'pipelineName',
    // 'rateSchedule',
    // 'svcReqName',
    // 'ctrctHolderName',
    'svcReqAcct',
    'svcReqCtrct',
    'oia',
    // 'imbType',
    // 'imbDirDesc',
    'postedImbQty',
    // 'svcReqContact',
    // 'svcReqPhone',
    // 'pipelineCode',
    // 'pipelinePropName',
    // 'stmtDt',
    // 'svcReq',
    // 'svcReqProp',
    // 'ctrctHolder',
    // 'ctrctHolderPropCode',
    'imbDirInd',
    'dupRow'
    // 'prodMonth',
    // 'imbTypeCode'
  ];

  let columnDefs = getNameModelMap(nameMap);
  // add groupings
  const processColumns = (cols) => {
    // let groupColumns = ['pipelineName', 'prodMonth', 'rateSchedule', 'contract', 'oia', 'imbalanceDir'];
    // let groupColumns = ['managedPipeline', 'prodMonth', 'rateSchedule', 'contract', 'oia', 'imbalanceDir'];
    let groupColumns = ['imbPeriod', 'pipelineName', 'imbDirInd'];

    groupColumns.forEach(name => {
      let groupColumn = T.find(cols, (c) => c.field === name);
      groupColumn.rowGroup = groupColumn.hide = true;

      if (name === 'imbPeriod') {
        groupColumn.rowGroupIndex = 0;
      } else if (name === 'pipelineName') {
        groupColumn.rowGroupIndex = 1;
      } else if (name === 'imbDirInd') {
        groupColumn.rowGroupIndex = 2;
      }
    });
    let pipelineNameColumn = T.find(cols, (c) => c.field === 'pipelineName');
    pipelineNameColumn.aggFunc = 'countUnique';
    let imbQtyColumn = T.find(cols, (c) => c.field === 'postedImbQty');
    imbQtyColumn.aggFunc = 'sum';


    return cols;
  };

  const controls = ControlFactory.createControls({
    controls: [{ id: 1, name: 'dateRange', type: 'datepicker', label: 'Posted Date Range', format: 'MM/YYYY', picker: 'month' }],
    initialValues: {
      'dateRange': getInitialDateRange(),
    },
    submit: setPostData,
    formRef: queryForm
  });

  const onGridReady = (params) => {
    var defaultSortModel = [
      { colId: 'rateSchedule', sort: 'asc', sortIndex: 0 },
      { colId: 'svcReqCtrct', sort: 'asc', sortIndex: 1 },
      { colId: 'oia', sort: 'asc', sortIndex: 2 },
      { colId: 'postedImbQty', sort: 'asc', sortIndex: 3 }
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });
  };

  const gadgetItem = { grid: <InfoGrid id="ImbalanceAvailableDetail" gridOptions={{ events: { onGridReady: onGridReady } }} columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> }
  const gadget = {
    loading: loadingMaxImbPeriod || loading,
    refresh,
    //id: 'Gadget1',
    //title: message['ih.widget.imbalance.imbalanceAvailableDetail'],
    title: outputMaxImbPeriod.maxImbPeriod ? message['ih.widget.imbalance.imbalanceAvailable'] + ' ' + outputMaxImbPeriod.maxImbPeriod : message['ih.widget.imbalance.imbalanceAvailableEmpty'],
    views: ['grid'], // grid
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    //dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget} />
  );
}

export default ImbalanceAvailableDetail;
