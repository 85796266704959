/**
 * Wrapper for AgGridColumn
 */
import React from "react";
import { AgGridColumn } from "ag-grid-react";

function InfoGridColumn(props) {
  return (
    <AgGridColumn {...props}> </AgGridColumn>
  );
}

export default InfoGridColumn;
