import { createSlice } from '@reduxjs/toolkit'

export const infoSlice = createSlice({
  name: 'info',
  initialState: {
    inputLimit: {},
    accountData: {},
    configs: {},
    pageAlert: {
      error: { show: false, messages: '' },
      info: { show: false, messages: '' },
      success: { show: false, messages: '' },
      warning: { show: false, messages: '' }
    },
    searchDateSettings: [],
    userinfo: {},
  },
  reducers: {
    setConfigs: (state, action) => {
      state.configs = action.payload
    },

    setInputLimits: (state, action) => {
      state.inputLimit = action.payload
    },

    setAccountData: (state, action) => {
      state.accountData = action.payload
    },

    setPageAlert: (state, action) => {
      state.pageAlert = action.payload
    },

    setSearchDateSettings: (state, action) => {
      state.searchDateSettings = action.payload
    },
    
    clearPageAlert: (state, action) => {
      state.pageAlert = {
        error: { show: false, messages: '' },
        info: { show: false, messages: '' },
        success: { show: false, messages: '' },
        warning: { show: false, messages: '' }
      }
    },

    setPageWarningAlert: (state, action) => {
      state.pageAlert.warning = action.payload
    },
    setPageErrorAlert: (state, action) => {
      state.pageAlert.error = action.payload
    },
    setPageInfoAlert: (state, action) => {
      state.pageAlert.info = action.payload
    },
    setPageSuccessAlert: (state, action) => {
      state.pageAlert.success = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setInputLimits,
  setAccountData,
  setConfigs,
  setSearchDateSettings,
  // page level messages
  setPageAlert,
  setPageWarningAlert,
  setPageErrorAlert,
  setPageInfoAlert,
  setPageSuccessAlert,
  clearPageAlert
} = infoSlice.actions;

export default infoSlice.reducer