import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

/**
 * To do specific tasks
 */
export default class PreferencesApi {

  static getPreferences(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/user/getUserPreference', null,
      fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson) || T.isEmpty(responseJson.data)) {
          console.warn('Error getting Preferences');
          return {};
        }
        // SUCCESS
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static savePreferences(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/user/saveUserPreference',
      null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        return true;
        // do nothing
      }).catch(console.err);
  }
}