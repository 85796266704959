import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

/**
 * To do specific tasks
 */
export default class LogApi {
    // downloadCurrentLog
    static getDownloadUrl(method) {
        if (T.isDevEnv()) {
            return 'https://localhost:8090/api/v1/logs/' + method;
        }
        return T.buildURL('api/v1/logs/' + method);
    };

    static getAllLogs(postData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/logs/getAllLogs', null, postData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                return responseJson;
            })
            .catch(error => {
                // ERROR - rethrow
                return error;
            });
    }

    static downloadCurrentLog() {
        window.location.assign(this.getDownloadUrl('downloadCurrentLog'));
    }

    static downloadAllLogs(postData = []) {
        const request = ApiRequest.composePostJsonRequest('api/v1/logs/downloadLogs', null, postData);
        return fetch(request)
            .then(response => response.blob())
            .then(blob => {
                var file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            })
            .catch(error => {
                return error;
            });
    }
}