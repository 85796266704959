import { AutoComplete } from 'antd';
import { TUtil as T } from '@trellisenergy/common-ui-core';
const colors = [
    "#89d2da",
    "#5b5656",
    "#c48152",
    "#bdbec0",
    "#bbffff",
    "#f3f1e3",
    "#2a8476",
    "#052b25",
    "#54a2a9",
    "#f7ae7c",
    "#8d5227",
    "#818181",
    "#505050",
    "#bbbdae",
    "#834001",
    "#3f1c06",
    "#77004c",
    "#1a163e",
    "#89406D",
    "#4A4866",
];
function reverse(array) {
    var output = [];
    const length = array?.length;
    for (let i = 0; i < length; i++)
        output.push(array[length - i - 1]);
    return output;
}
const reverseColors = reverse(colors);

const ChartConfig = {
    height: 275/*250*/,
    theme: {
        /* 
        Refer for color themes specifications: TIH-879
        Refer for how to specify color:
        https://charts.ant.design/demos/general#%E8%87%AA%E5%AE%9A%E4%B9%89%E4%B8%BB%E9%A2%98
        */
        colors10: colors,
        colors20: colors,
        colors30: colors
    },

    //theme: 'default', // 'dark'
    yAxis: {
        label: {
            //carEllipsis: true,
            formatter: function (text/*: string*/, item/*: ListItem*/, index/*: numbe*/) {
                // if it's larger screen maintain the current funtionality
                if (!T.isSmallScreen) {
                    let maxWidth = 40;
                    if (text.length > maxWidth) {
                        return text.substring(0, maxWidth) + '...'
                    }
                    return text;
                }
                /**
                 * If the width is greater than maxWidth then do line wrap around word
                 * * if possible do wrap before the maxWidth
                 */
                let maxWidth = 25;
                if (text.length <= maxWidth) return text;

                let firstLine = text.substring(0, maxWidth);
                let leftSpace = firstLine.lastIndexOf(' ');
                let firstFitLine = text.substring(0, leftSpace);
                // check if it fits
                if (text.length + (maxWidth - leftSpace) <= 2 * maxWidth) {
                    return firstFitLine + '\n' + text.substring(leftSpace, text.length);
                } else {
                    return firstFitLine + '\n' + text.substring(leftSpace, maxWidth * 2 - 3) + '...';
                }
            },
            style: {
                fontSize: 12,
                fontFamily: 'Poppins',
                fontWeight: 600,
                shadowColor: 'white',
                shadowBlur: 4,
            }
        },
    },
    /*
    Adding this causes top portion of xAxis to be cut off, so commenting out until a solution is found. 
    xAxis: {
        label: {
            style: {
                fontSize: 11,
                fontFamily: 'Poppins',
                fontWeight1: 600,
            }
        }
    }*/
};

/**
 * Maximum items to show before scroll bar appears
 */
export const MAX_ITEM_COUNT = 6;

export const IocChartConfig = {
    xField: 'Count',
    yField: 'type',
    // if set to 'Count' shows slider to filter value
    seriesField: 'Count',
    // label: {
    //     position: "middle",
    //     layout: [
    //         { type: "interval-adjust-position" },
    //         { type: "interval-hide-overlap" },
    //         { type: "adjust-color" }
    //     ]
    // },
    legend: {
        layout: "horizontal",
        position: "top",
        rail: {
            type: 'color',
            size: 10,
            defaultLength: 150,
        },
    },
    scrollbar: { type: "vertical" },
    columnWidthRatio: 0.75,
    barWidthRatio: 0.75,
    height: 250,
    theme: {
        colors10: reverseColors,
        colors20: reverseColors,
        colors30: reverseColors
    },
};

export default ChartConfig;
