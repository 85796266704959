import { Progress } from 'antd';
import ChartConfig from './config';

const CircleChart = (props) => {
  var data = props.data || [];
  var customChartConfig = props.customChartConfig || {};
  var onChartClick = props.onChartClick;
  var config = {
    chartRef: props.chartRef,
    data: data,
    ...ChartConfig,
    ...customChartConfig
  }

  const handleClick = (event) => {
    if (onChartClick) {
      onChartClick(event); // Trigger the provided callback function
    }
  };
  return <Progress type="circle" className={'progress-text-color'} strokeColor={"rgb(137,210,218)"} percent={100} width={275} format={() => data} onClick={handleClick} />;
};
export default CircleChart ;
