import { TUtil as T } from '@trellisenergy/common-ui-core';

const NoticesUtil = {
    setSortingCols: (cols) => {
        let sortingCols = [
            'noticeStartDate',
            'noticeEndDate',
            'datasetNm',
            'pipelineName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });
        sortingCols.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    }
};

export default NoticesUtil;
