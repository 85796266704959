import React, {useEffect, useState, useRef} from 'react';
import {InfoGrid} from 'infogrid';
import {getNameModelMap} from 'asset/GridColumnNameMap';
import useFetchData from 'api/service'

import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import {useSelector} from 'react-redux'

import {Collapse, Skeleton} from 'antd'
import {CaretRightOutlined} from "@ant-design/icons";
import BackTop from "../../backtop/BackTop";
import QuickConfig from "../../header/QuickConfig";

const {Panel} = Collapse;

function AllContracts() {

    const {message} = useSelector((state) => state.message)
    //const [postData, setPostData] = useState({ startDate: '03/23/2020', endDate: '04/14/2020' });
    const [postData, setPostData] = useState({});
    const [refreshCount] = useState(0);

    const [postDataMaxCalendarQuarter] = useState({});
    const [, outputMaxCalendarQuarter] = useFetchData('api/v1/ioc/getMostRecentFirstDayCalendarQuarter', postDataMaxCalendarQuarter, refreshCount);
    const [loadInit, setLoadInit] = useState(false);

    const [loading, output] = useFetchData('api/v1/ioc/getContracts', postData, refreshCount, loadInit, setLoadInit);
    const [pointLoading, pointOutput] = useFetchData('api/v1/ioc/getPointQuantity', postData, refreshCount, loadInit, setLoadInit);
    const [filteredPointOutput, setFilteredData] = useState([]);
    const queryForm = useRef();
    let firstAndLastQuarter = T.getFirstAndLastDayOfQuarter(outputMaxCalendarQuarter.firstDayCalendarQuarter);

    useEffect(() => {
        if (outputMaxCalendarQuarter.firstDayCalendarQuarter) {
            queryForm.current.setFieldsValue({
                'dateRange': [firstAndLastQuarter.first, firstAndLastQuarter.last],
            });

            setPostData({
                startDate: firstAndLastQuarter.first.format("MM/DD/YYYY"),
                endDate: firstAndLastQuarter.last.format("MM/DD/YYYY")
            });
        }
    }, [outputMaxCalendarQuarter]);


    let nameMap = [
        'firstDateCalendarQuarter',
        'pipelineName',
        'shipperName',
        'rateSchedule',
        'contractNumber',
        'transportMdq',
        'storageMdq',
        'transportMdqUom',
        'storageMdqUom',
        'contactPerson',
        'contactPhone',
        'companyId',
        'shipperId',
        'shipperAffiliateIndicator',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'daysUntilExpire',
        'negativeRatesIndicator',
        // 'pipelineFootnoteItemList',
        // 'footnoteItemList',
    ];

    let columnDefs = getNameModelMap(nameMap);

    const [pipelineOptions, setPipelineOptions] = useState([]);
    const onPipelineSelect = () => {
        QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
    }

    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
                values: pipelineOptions, otherProps: {
                    showSearch: true,
                    options: pipelineOptions,
                    onFocus: onPipelineSelect,
                    style: { width: '100%' }
                }
            },
            {id: 2, name: 'dateRange', type: 'datepicker', label: 'Posted Quarter'}
        ],
        initialValues: {'dateRange': [firstAndLastQuarter.first, firstAndLastQuarter.last]},
        submit: (prevState) => {
            if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
                prevState.pipelines = [];
            }
            setFilteredData([]);
            setPostData(prevState);
        },
        formRef: queryForm,
        settings: { colSpan: 2 },
        reset: () => {
            queryForm.current?.resetFields();
        }
    });

    const processColumns = (cols) => {
        let col1 = T.find(cols, (c) => c.field === 'firstDateCalendarQuarter');
        col1.sort = 'desc';
        col1.sortIndex = 0;
        let col2 = T.find(cols, (c) => c.field === 'pipelineName');
        col2.sort = 'asc';
        col2.sortIndex = 1;
        let col3 = T.find(cols, (c) => c.field === 'shipperName');
        col3.sort = 'asc';
        col3.sortIndex = 2;
        let col4 = T.find(cols, (c) => c.field === 'rateSchedule');
        col4.sort = 'asc';
        col4.sortIndex = 3;
        let col5 = T.find(cols, (c) => c.field === 'contractNumber');
        col5.sort = 'asc';
        col5.sortIndex = 4;

        return cols;
    };

    const performPointFilter = (params) => {
        if (params.data && params.data.contractNumber) {
            const _filtered = pointOutput.filter((row) => {
                return params.data.contractNumber === row.contractNumber &&
                    params.data.pipelineName === row.pipelineName;
            });
            setFilteredData(_filtered);
        }
    };

    const pointProcessColumns = (cols) => {
        let col1 = T.find(cols, (c) => c.field === 'firstDateCalendarQuarter');
        col1.sort = 'desc';
        col1.sortIndex = 0;

        let ascSortCols = [
            'pipelineName',
            'shipperName',
            'rateSchedule',
            'contractNumber',
            'pointIdentifierCode',
            'pointName'
        ];

        ascSortCols.forEach((field, index) => {
            let col = T.find(cols, (c) => c.field === field);
            col.sort = 'asc';
            col.index = index;
        });

        return cols;
    };

    let pointNameMap = [
        'firstDateCalendarQuarter',
        'pipelineName',
        'shipperName',
        'rateSchedule',
        'contractNumber',
        'pointIdentifierCode',
        'pointName',
        'transportMdq',
        'storageMdq',
        'transportMdqUom',
        'storageMdqUom',
        'shipperId',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'daysUntilExpire',
        'negativeRatesIndicator',
        'pointCodeQualifier',
        'pointCode',
        'zoneName',
    ];

    let pointColumnDefs = getNameModelMap(pointNameMap);

    const shipperGrid = <InfoGrid id="AllContracts"
                                  columnDefs={processColumns(columnDefs)}
                                  data={output}
                                  gridOptions={{
                                      visibleRows: 8,
                                      highlightOnClick: true,
                                      events: { onCellClicked: performPointFilter }
                                  }}></InfoGrid>
    const _shipperGrid = (shipperGrid)
    const pointGrid = <InfoGrid id="PointQuantityDetail" columnDefs={pointProcessColumns(pointColumnDefs)}
                                data={filteredPointOutput} gridOptions={{visibleRows: 4}}></InfoGrid>

    return (
        <div className='gadget full-width'>
            <BackTop/>
            <div className='gadget-container'>
                <div className="gadget-header">
                    <div className="gadget-title"> {message['ih.widget.ioc.allContracts']}</div>
                </div>
                <div className='gadget-controls'>
                    {controls}
                </div>
                <div className="gadget-item-content" style={{padding: '0px', marginLeft: '-6px', marginRight: '-6px'}}>
                    <Collapse ghost={true}
                              expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                              defaultActiveKey={['1', '2']}>
                        <Panel header={message['ih.widget.ioc.shipperContracts']} key='1'>
                            <Skeleton active loading={loading}>
                                {_shipperGrid}
                            </Skeleton>
                        </Panel>
                        <Panel header={message['ih.widget.ioc.pointQuantities']} key='2'>
                            <Skeleton active loading={pointLoading}>
                                {pointGrid}
                            </Skeleton>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </div>
    );
}

export default AllContracts;
