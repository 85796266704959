/**
 * Configure for different endpoints
 *
 * Use like
 *    import * as Config from './../../settings.js';
 *
 */
export const AUTH_DEV_URLS = {
    loginUrl: 'https://localhost:8090/inform/api/v1/auth/login',
    logoutUrl: 'https://localhost:8090/inform/api/v1/auth/logout'
};

export const BASEURL_DEV = 'https://localhost:8090/inform/';
export const AGGRID_LICENCE_KEY = 'CompanyName=Blackstone Technology Group,LicensedApplication=Trellis Agora,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-027153,ExpiryDate=25_May_2023_[v2]_MTY4NDk2OTIwMDAwMA==c5ad5b2f1ff4aebc95ac36c460cf3a7f'