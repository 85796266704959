/**
 * 
 * 
 * Get the width of the supplied element whenever window resize occurs.
 * If an element is not supplied, then return the window width.
 * 
 * original source: https://dev.to/vitaliemaldur/resize-event-listener-using-react-hooks-1k0c
 * 
 */
import { useState, useEffect } from 'react';

const getWidth = (element) => {
  return element ? (element.style.width || element.offsetWidth) : window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;
}

function useCurrentWidth(element) {

  // save current width in the state object
  let [width, setWidth] = useState(getWidth(element));

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth(element)), 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    }
  }, [element])

  return width;
}

export default useCurrentWidth;