import { TUtil as T, ApiRequest, ApiResult, Config } from '@trellisenergy/common-ui-core';

export default class TrellisAIApi {
  
  static root_context = 'api/v1/tariffSearch/sources/1/';
  static context = TrellisAIApi.root_context + 'rooms/';
  
  static getAllRooms(fetchData = {}) {
    const request = ApiRequest.composeGetRequest(TrellisAIApi.context);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get Rooms');
          return [];
        } else {
          //
        }
        return JSON.parse(responseJson.data);
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
  
  static getRoomChat(roomId) {
    const request = ApiRequest.composeGetRequest(TrellisAIApi.context + roomId + '/chats');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn(`Can\'t get chats for room: ${roomId}`);
          return [];
        } else {
          //
        }
        return JSON.parse(responseJson.data);
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
  
  static getAllPipelines(fetchData = {}) {
    const request = ApiRequest.composeGetRequest(TrellisAIApi.root_context + 'pipelines');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get Pipelines');
          return [];
        } else {
          //
        }
        return JSON.parse(responseJson.data);
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
  
  static askQuestion(roomId, text, pipelines) {
    const pipelineParam = pipelines?.map(p => 'pipeline_id=' + p).join('&');
    const request = ApiRequest.composePostJsonRequest(TrellisAIApi.context + roomId + '/chats',
      pipelineParam, {text: text});
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn(`Can\'t get chats for room: ${roomId}`);
          return [];
        } else {
          //
        }
        return JSON.parse(responseJson.data);
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
  
  // TODO: Remove this when inform uses updated common-ui-core
  static composeDeleteJsonRequest(path = "", params = "", fetchData = {}, options = {}) {
    // conditionally handle relative or absolute path
    const url = ApiRequest.composeUrl(path, params, options);
    
    // add pc/mobile info to request data
    let deviceTypeFlag = 'PC'; //isMobile ? 'MOBILE' : 'PC';
    if (fetchData.body) {
      fetchData.body.browseMode = deviceTypeFlag;
    } else {
      fetchData.browseMode = deviceTypeFlag;
    }
    
    var csrfTokenHeader = {
      "X-CSRF-TOKEN": localStorage.getItem("csrfToken")
    };
    
    // conditionally handle auth for application & web
    const appData = {
      method: "DELETE",
      headers: {
        ...Config.REQUEST_HEADERS,
        ...fetchData.headers,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json; charset=utf-8",
        ...csrfTokenHeader
      },
      body: JSON.stringify(fetchData.body || {})
    };
    const webData = {
      method: "DELETE",
      credentials: "same-origin",
      headers: {
        ...Config.REQUEST_HEADERS,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json; charset=utf-8",
        "X-Requested-With": "XMLHttpRequest",
        ...csrfTokenHeader
      },
      body: JSON.stringify(fetchData || {})
    };
    let w = typeof window !== 'undefined' ? window : {};
    const data = w.isReactNativeApp ? appData : webData;
    
    // treat redirect as error for handling timeout
    // see https://fetch.spec.whatwg.org/#concept-filtered-response-opaque-redirect
    // returns An opaque-redirect filtered response is a filtered response whose type is "opaqueredirect", status is 0,
    // status message is the empty byte sequence, header list is empty, and body is null.
    data.redirect = 'manual';
    
    // That's all folks!
    return new Request(url, data);
  }
  
  static deleteRoom(roomId) {
    /*
    const url = TrellisAIApi.context + roomId;
    const fetchUrl  = ApiRequest.composeUrl(url)
    return fetch(fetchUrl, { method: 'DELETE' })
     */
    const request = TrellisAIApi.composeDeleteJsonRequest(TrellisAIApi.context + roomId);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t delete room');
          return [];
        } else {
          //
        }
        return JSON.parse(responseJson.data);
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }
}
