import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

export default class DatasetStatusApi {

    static updateDatasetFiles(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/dc/updateDatasetStatuses', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t update source files');
                    return null;
                } else {
                    //
                }
                return responseJson?.data;
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }

    static createMatchedDuns(fetchData = {}) {
        const request = ApiRequest.composePostJsonRequest('api/v1/dc/createMatchedDuns', null, fetchData);
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t update source files');
                    return null;
                } else {
                    //
                }
                return responseJson?.data;
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }
}
