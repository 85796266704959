import './Home.css';
import { useEffect, useState } from 'react';
import { HubIconRenderer } from '../../menu/Menu';

const HomePage = (props) => {
  const getGreetingText = () => {
    const ret = 'Welcome';
    const username = props.accountData?.name;
    return (username ? ret + ', ' + username : ret) + '!'
  }
  const [state, setState] = useState({ left_width: '60%', right_width: '40%' });

  /**
   * if width > 890
   *  Set the width of left to be 3/5(=60%) of the entire width on load
   * else 
   *  Set width of left = 890 and rest to right
   */
  useEffect(() => {
    const { innerWidth: width, } = window;
    let leftWidth = 890, rightWidth = 0;
    if (width > leftWidth * 2) {
      leftWidth = rightWidth = (width * 3 / 5)
    } else if (width > leftWidth) {
      rightWidth = width - leftWidth
    }
    setState({ left_width: leftWidth + 'px', right_width: rightWidth + 'px' });
  }, [])

  return (
    <div className='main'>
      <div style={{ flexBasis: state.left_width }} className='left-content'>
        <div className='greeting'>{getGreetingText()}</div>
        <HubIconRenderer  {...props} />
      </div>
      <div className='right-content'></div>
    </div>
  )
}

export default HomePage;