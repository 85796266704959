import React, { useRef, useState } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';

function PipelinesByCustomerDetail(props) {
    const location = useLocation();
    const { message } = useSelector((state) => state.message);
    const [postData, setPostData] = useState({ 'entityId': location.state?.entityId });

    const [refreshCount, setRefresh] = useState(0);

    const [postDataMaxCalendarQuarter] = useState({});
    const [loadingMaxCalendarQuarter, outputMaxCalendarQuarter] = useFetchData('api/v1/ioc/getMostRecentFirstDayCalendarQuarter', postDataMaxCalendarQuarter, refreshCount);

    const [loading, output, loadTimestamp] = useFetchData('api/v1/ioc/getPipelinesByCustomerDetail', postData, refreshCount);
    const queryForm = useRef();

    const refresh = () => {
        setRefresh(Math.random())
    };

    let nameMap = [
        'firstDateCalendarQuarter',
        'shipperName',
        'pipelineName',
        'shipperAffiliateIndicator'
    ];

    let columnDefs = getNameModelMap(nameMap);

    const processColumns = (cols) => {
        let groupingCols = [
            'firstDateCalendarQuarter',
            'shipperName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        groupingCols.forEach(colModel => {
            if (colModel) {
                colModel.rowGroup = true;
                colModel.hide = true;
            }
        });

        let aggColumn = T.find(cols, (c) => c.field === 'pipelineName');
        aggColumn.aggFunc = 'countUnique';
        aggColumn.sort = 'desc';

        return cols;
    };

    const controls = ControlFactory.createControls({
        controls: [],
        submit: setPostData,
        formRef: queryForm
    });

    const gadgetItem = { grid: <InfoGrid id="PipelinesByCustomerDetail" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> };

    const getTitle = () => {
        return message['ih.widget.ioc.pipelinesByCustomer'] + " " + (outputMaxCalendarQuarter.firstDayCalendarQuarter ? outputMaxCalendarQuarter.firstDayCalendarQuarter : "N/A");
    };

    const gadget = {
        loading: loading || loadingMaxCalendarQuarter,
        refresh,
        id: 'Gadget1',
        title: getTitle(),
        views: ['grid'], // grid
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        dataSourceId: 1,
        width: props.width || 100
    };

    return (
        <Gadget {...gadget} />
    );
}

export default PipelinesByCustomerDetail;
