import React, { useRef } from 'react';
import Gadget from 'infogadget'
import { useDispatch, useSelector } from 'react-redux'
import { CacheApi } from 'api/v1/index'
import { message } from 'antd';

import { StackChart } from 'infochart'
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import { setPageErrorAlert, setPageSuccessAlert } from "../../../redux/reducer/info";
import { clearClientCache } from "../../../redux/reducer/cache";

const CacheHit = (props) => {
  const dispatch = useDispatch();

  const messageBundle = useSelector((state) => state.message).message;
  const { cacheStats } = useSelector((state) => state.cache);
  const loadTimestamp = new Date();
  const chartRef = useRef();

  const getChartData = () => {
    let ret = [];
    if (T.isArray(cacheStats))
      ret = cacheStats.flatMap(d => {
        return [{
          entityId: d.cacheName,
          type: 'Hit',
          value: d.cacheHit
        }, {
          entityId: d.cacheName,
          type: 'Miss',
          value: d.cacheMisses
        }]
      });
    return ret;
  }

  const gadgetItem = {
    chart: <StackChart chartRef={chartRef} data={getChartData()} customChartConfig={{ type: 'bar' }} />
  };

  const gadget = {
    loading: !cacheStats,
    //refresh,
    //id: 'ioc2',
    views: ['chart'],
    gadgetItem,
    title: messageBundle['ih.widget.cache.cacheHit'],
    loadTimestamp: loadTimestamp,
    //dataSourceId: 'getPipelinesByCustomer',
    width: props.width || 100,
    customLinks: [{
      linkText: "Clear All Server Cache",
      action: () => {
        const hideLoadingMessageFunc = message.loading('Clearing all server cache...', 1);
        setTimeout(async () => {
          const response = await CacheApi.clearAllCache();
          if (ApiResult.isSuccess(response)) {
            dispatch(setPageSuccessAlert({ show: true, message: 'Server Cache(s) cleared successfully' }))
          } else {
            dispatch(setPageErrorAlert({ show: true, message: ApiResult.getMessages(response) }))
          }
          hideLoadingMessageFunc();
        }, 1000);
      }
    }, {
      linkText: "Clear All Client Cache",
      action: () => {
        const hideLoadingMessageFunc = message.loading('Clearing all client cache...', 1);
        setTimeout(async () => {
          dispatch(clearClientCache())
          dispatch(setPageSuccessAlert({ show: true, message: 'Client Cache(s) cleared successfully' }))
          hideLoadingMessageFunc();
        }, 1000);
      }
    }],
    /*customLink: {
      linkText: "Clear All Cache",
      action: () => {
        const hideLoadingMessageFunc = message.loading('Clearing all cache...', 1);
        setTimeout(async () => {
          const response = await CacheApi.clearAllCache();
          if (ApiResult.isSuccess(response)) {
            dispatch(setPageSuccessAlert({ show: true, message: 'Cache(s) cleared successfully' }))
          } else {
            dispatch(setPageErrorAlert({ show: true, message: ApiResult.getMessages(response) }))
          }
          hideLoadingMessageFunc();
        }, 1000);
      }
    }*/
  };
  return (
    <Gadget {...gadget} {...props}> </Gadget>
  )
};

export default CacheHit;