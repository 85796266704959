import React, { useRef, useState } from 'react';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';

import { StackChart } from 'infochart'

const OperAvailCap = (props) => {
    const { configs } = useSelector((state) => state.info);

    const { message } = useSelector((state) => state.message)
    const { downloadOptions } = useSelector((state) => state.location);

    const [postData] = useState({});
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getOperAvailCapForGasDay', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };
    const history = useHistory();
    const chartRef = useRef();

    const getChartData = () => {
        return output.map(d => {
            return {
                entityId: d.entityId,
                type: d.type,
                value: d.count,
                id: d.what
            }
        });
    };

    const getIdFromType = (type, arr = []) => {
        let found = arr.find(a => a.label === type)
        return found?.id;
    };

    const gadgetItem = {
        topDisplayItems: [<div className='gadget-item-top'>{message['ih.widget.title.inner.totalCapAllCycles'] + " " + getChartData().reduce((a, b) => a + b.value, 0).toLocaleString()}</div>],
        chart: <StackChart chartRef={chartRef} data={getChartData()} customChartConfig={{ height: 290, scrollbar: { type: "horizontal" } }} />
    };

    T.registerChartListener(chartRef, (event) => {
        if (event.data) {
            let datePeriodStrings = T.getDateTimePeriodFromToday(0, true);

            let locState = {
                entityId: event.data.data.id,
                // percentTier: event.data.data.type,
                startDate: datePeriodStrings.startDate,
                endDate: datePeriodStrings.endDate,
                title: message['ih.widget.capacity.operAvailCap.title'] + ' ' + T.getTodayDateStr()
            };
            console.log("clicked oper avail graph item");
            console.log(locState);
            history.push(T.buildURL('/capacity/AllOperAvailCap'), locState);
        }
    });

    const gadget = {
        loading,
        views: ['chart'],
        gadgetItem,
        title: message['ih.widget.capacity.operAvailCap.title'] + ' ' + T.getTodayDateStr(),
        loadTimestamp: loadTimestamp,
        width: 50
    };
    return (
        <Gadget {...gadget} {...props}> </Gadget>
    )
};

export default OperAvailCap;