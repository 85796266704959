import {Alert, Button, message as AntdMessage} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'

import { setPageWarningAlert, setPageErrorAlert, setPageInfoAlert, setPageSuccessAlert } from 'redux/reducer/info';

/**
 * TODO: support addition to exising error rather than replacing.
 * 
 * @param {*} props 
 * @returns 
 */
const PageAlert = (props) => {
  const dispatch = useDispatch();
  const { pageAlert } = useSelector((state) => state.info)

  const clearWarning = () => dispatch(setPageWarningAlert({ show: false }));
  const clearError = () => dispatch(setPageErrorAlert({ show: false }));
  const clearInfo = () => dispatch(setPageInfoAlert({ show: false }));
  const clearSuccess = () => dispatch(setPageSuccessAlert({ show: false }));
  const MAX_LENGTH_ERROR = 1000;
  const truncate = (input) => input?.length > MAX_LENGTH_ERROR ? `${input.substring(0, MAX_LENGTH_ERROR)}...` : input;
  
  const [errorMessage, setErrorMessage] = useState(null);
  
  const isExpandedError = () => {
    // take into account the ellipsis
    return errorMessage?.length > MAX_LENGTH_ERROR + 3;
  };

  const onErrorDetailClick = () => {
    if (isExpandedError()) { 
      // shrink it back
      setErrorMessage(truncate(pageAlert.error.message));
    } else {
      // expand
      setErrorMessage(pageAlert.error.message);
    }
  };

  const copyToClipboard = function(code) {
    const tempInput = document.createElement("input");
    tempInput.style = "position: absolute; left: -1000px; top: -1000px";
    tempInput.value = code;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    AntdMessage.success('Message contents is copied to clipboard.', 3.0);
  }

  const onCopyErrorDetailClick = () => {
    copyToClipboard(pageAlert.error.message);
  };

  const onCopyWarnDetailClick = () => {
    copyToClipboard(pageAlert.warning.message);
  };

  const showCopyButton = (error) => {
    return <Button onClick={error ? onCopyErrorDetailClick : onCopyWarnDetailClick} size="small" danger title={"Copy message contents to clipboard."}>Copy</Button>
  };

  const isExpandableError = () => {
    // if msg is string and above max length
    return typeof pageAlert.error.message === 'string' && pageAlert.error.message?.length > MAX_LENGTH_ERROR;
  };

  useEffect(() => {
    if (isExpandableError()) {
      setErrorMessage(truncate(pageAlert.error.message));
    } else {
      setErrorMessage(pageAlert.error.message);
    }
  }, [pageAlert.error.message]);

  return <div className='messageContainer'>
    {pageAlert.warning.show ? <Alert message={pageAlert.warning.message} type="warning" banner onClose={clearWarning} closable action={
      <span>{showCopyButton(false)}</span>
    }/> : null}
    {pageAlert.error.show ? <Alert message={errorMessage} type="error" banner onClose={clearError} closable action={ isExpandableError() ?
      <span>
        {showCopyButton(true)} &nbsp;
        <Button onClick={onErrorDetailClick} size="small" danger>{isExpandedError() ? 'Collapse' : 'Expand'}</Button>
      </span> : <span>{showCopyButton(true)}</span>
    }/> : null}
    {pageAlert.info.show ? <Alert message={pageAlert.info.message} type="info" showIcon banner onClose={clearInfo} closable /> : null}
    {pageAlert.success.show ? <Alert message={pageAlert.success.message} type="success" showIcon banner onClose={clearSuccess} closable /> : null}
  </div>
};

export default PageAlert;