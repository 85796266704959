import { useState, useEffect } from 'react';
import { ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';
import ShowRedirectDialog from '../component/dialog/RedirectLoginPageDialog'
import { setPageErrorAlert, setPageWarningAlert } from "../redux/reducer/info";
import { setServerStartupTime, clearClientCache } from "../redux/reducer/cache";
import { useDispatch, useSelector } from 'react-redux'
// import moment from 'moment'

const useFetchData = (url, postData, refreshCount, loadInit, setLoadInit) => {
  const dispatch = useDispatch();
  const { configs } = useSelector((state) => state.info);
  const { serverStartupTime } = useSelector((state) => state.cache);
  const { showRedirectModal } = ShowRedirectDialog();

  let [loading, setLoading] = useState(false);
  let [output, setOutput] = useState([]);
  let [loadTimestamp, setLoadTimestamp] = useState(new Date());
  let [messages, setMessages] = useState(null);

  useEffect(() => {
    // TODO: cleanup
    const noticesKeyword = [
      {
        type: 'Fuel',
        value: 27,
      },
      {
        type: 'Restriction',
        value: 25,
      },
      {
        type: 'Overflow',
        value: 18,
      },
      {
        type: 'Price',
        value: 15,
      },
      {
        type: 'Value',
        value: 10,
      },
      {
        type: 'Transport',
        value: 15,
      },
    ];

    // const x = [{
    //   "pipelineName": "ANR PIPELINE COMPANY", "firstDateCalendarQuarter": "10/01/2021", "rateSchedule": "FTS-1", "contractNumber": "126028", "shipperName": "Centerpoint Energy Resources Corp.", "contractEffectiveDate": "11/01/2015", "contractPrimeTermExpirationDate": "10/31/2022", "shipperAffiliateIndicator": "N", "daysUntilExpire": null, "negativeRatesIndicator": "N",
    //   "transportMdq": 2090.00890, "storageMdq": 0, "footnoteItemList": "0", "transportMdqUom": "Dth", "storageMdqUom": "Dth", "contactPerson": null, "contactPhone": null, "companyId": null, "pipelineFootnoteItemList": null, "shipperId": null
    // },]

    const delay = (f) => {
      setTimeout(f, 1500);
    }

    // option to prevent load initially when useFetchData is called 
    if (loadInit === false) {
      setLoadInit(null);
      return;
    }

    console.log(" fetching data for: ", url, postData, refreshCount)
    setLoading(true);
    if (url === '/noticesKeyword') {
      delay(() => {
        setOutput(noticesKeyword);
        setLoading(false);
        setLoadTimestamp(new Date());
      });
    }/* else if (url === 'api/v1/ioc/getMDQsByCustomerDetail') {
      delay(() => {
        setOutput(x);
        setLoading(false);
        setLoadTimestamp(new Date());
        //setLoadTimestamp(false);
        setMessages(['Error messages']);
      });
    }*/
    else {
      const request = ApiRequest.composePostJsonRequest(url,
        null, postData);
      return fetch(request)
        .then(response => ApiResult.getJson(response))
        .then(responseJson => {
          // SUCCESS
          setOutput(responseJson.data);
          setLoading(false);
          setLoadTimestamp(new Date());

          if (responseJson.messages) {
            if (ApiResult.isWarning(responseJson)) {
              dispatch(setPageWarningAlert({ show: true, message: ApiResult.getMessages(responseJson) }));
            } else {
              setMessages(responseJson.messages);
            }
          }

          // TODO: improve this
          // multiple access to localStorage happens since it's inside asynchronous call
          // without one knowing that another has changed the localStorage

          // if there is no serverRestartTime in state then 
          //    - look at localStorage
          //    - if found in localStorage
          //        - update the state from localStorage
          //    - else
          //        - set set state with latest time
          // else if difference of old and new is greater 
          //    - then invalidate cache
          const newServerStartupTime = responseJson.serverStartupTime;
          if (!serverStartupTime) {
            console.log(" serverStartupTime not found in state ");
            let localServerStartupTimeStr = localStorage.getItem("serverStartupTime");
            let localServerStartupTimeObj = null;
            if (localServerStartupTimeStr) {
              console.log(" localServerStartupTimeStr found ");
              try {
                localServerStartupTimeObj = JSON.parse(localServerStartupTimeStr);
              } catch (e) { console.warn(e) }
              dispatch(setServerStartupTime(localServerStartupTimeObj))
            } else if (newServerStartupTime) {
              console.log(" localServerStartupTimeStr not found. setting up new time:  ", newServerStartupTime);
              try {
                localStorage.setItem("serverStartupTime", JSON.stringify(newServerStartupTime))
              } catch (e) {
                console.warn(e)
              }
              dispatch(setServerStartupTime(newServerStartupTime))
            }
          } else {
            console.log(" serverStartupTime found in state ");
            if (!configs.serverRestartMaxStorageTime) {
              console.log(" configs.serverRestartMaxStorageTime not found ");
              return;
            }
            const diff = newServerStartupTime - serverStartupTime;
            const max = configs.serverRestartMaxStorageTime * 60 * 1000;
            console.log(" serverStartupTime found. old: ", serverStartupTime, " new: ", newServerStartupTime, " diff: ", diff, " max: ", max)
            if (max < diff) {
              dispatch(clearClientCache())
              dispatch(setServerStartupTime(newServerStartupTime))
            }
          }
        })
        .catch(error => {
          // if session is invalid, will return these error codes
          if (error.code === 401 || error.code === 403 || error.code === 405 || error.code === 0) {
            showRedirectModal();
          }
          console.error(error)
          setOutput([]);
          setLoading(false);
          setLoadTimestamp(false);

          //Gather error messages and display them.
          if (error.messages) {
            let errorMsg = "";

            for (let i=0; i<error.messages.length; i++) {
              errorMsg += error.messages[i] + " ";
            }

            dispatch(setPageErrorAlert({ show: true, message: errorMsg }));
          }

          setMessages(error.messages);
        });
    }
  }, [url, postData, refreshCount])

  return [loading, output, loadTimestamp, messages];
}

export default useFetchData;