import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import Gadget from 'infogadget'
import {InfoGrid} from "infogrid";
import ColumnTypes from "../../infogrid/ColumnTypes";
import useFetchData from 'api/service';

const ManagedScheduledTasks = (props) => {
    const {message} = useSelector((state) => state.message);
    const gridRef = useRef();
    const [refreshCount, setRefresh] = useState(0);
    const [postData, setPostData] = useState({});

    const [loading, output, loadTimestamp] = useFetchData('api/v1/admin/getScheduledTasks', postData, refreshCount);

    const refresh = () => {
        setRefresh(Math.random())
    };

    let columnDefs = [{
        field: 'taskName',
        headerName: message['ih.scheduledTasks.taskName'],
    }, {
        field: 'lastTriggeredUser',
        headerName: message['ih.scheduledTasks.lastTriggeredUser'],
    }, {
        field: 'lastTriggerTime',
        headerName: message['ih.scheduledTasks.lastTriggerTime'],
        ...ColumnTypes.DateTime
    }, {
        field: 'triggerTask',
        headerName: message['ih.scheduledTasks.triggerTask'],
        ...ColumnTypes.TriggerScheduledTaskButton,
        cellRendererParams: {
            refresh: refresh
        },
        cellStyle: {textAlign: 'center'}
    }];

    const gadgetItem = {
        grid: <InfoGrid id="ManagedScheduledTasks" columnDefs={columnDefs} data={output} gridRef={gridRef}></InfoGrid>
    };
    const gadget = {
        loading,
        refresh,
        title: message['ih.menu.manageScheduledTasks'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        width: props.width || 100
    };

    return (<Gadget {...gadget}></Gadget>);
};

export default ManagedScheduledTasks;