import { Column, Bar } from '@ant-design/charts';
import ChartConfig from './config';

const StackChart = (props) => {
  var data = props.data || [];
  var customChartConfig = props.customChartConfig || {};
  var isBar = customChartConfig?.type === 'bar';
  var config = {
    chartRef: props.chartRef,
    data: data,
    xField: isBar ? 'value' : 'entityId',
    yField: isBar ? 'entityId' : 'value',
    seriesField: 'type',

    //legend: { position: 'top-left' },
    isStack: true,
    // label: {
    //   position: "middle",
    //   layout: [
    //     { type: "interval-adjust-position" },
    //     { type: "interval-hide-overlap" },
    //     { type: "adjust-color" }
    //   ]
    // },
    legend: {
      layout: "horizontal",
      position: "top",
      itemValue: {
        formatter: (text, item) => {
          const items = data.filter((d) => d.type === item.value);
          const v = items.length ? items.reduce((a, b) => a + b.value, 0) : '-';
          return v.toLocaleString();
        }
      },
      itemName: {
        formatter: (text, item) => {
          return text + ": ";
        }
      },
    },
    scrollbar: { type: "vertical" },
    columnWidthRatio: 0.8,

    ...ChartConfig,
    ...customChartConfig,
    tooltip: {
      formatter: (data) => {
        return { name: data.type, value: data.value.toLocaleString() };
      },
    }
  };
  if (isBar)
    return <Bar {...config} />;
  return <Column {...config} />;
};

export default StackChart;