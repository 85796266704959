import { LogApi } from 'api/v1/index'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import { setPageErrorAlert, setPageInfoAlert, setPageWarningAlert, clearPageAlert } from 'redux/reducer/info';
import Gadget from 'infogadget'
import { InfoGrid } from "infogrid";
import { Button, DatePicker, Form, Space, TimePicker } from 'antd';
import { RangeDatePickerAutoAccept } from "../../control/DatePickerAutoAccept";
import ColumnTypes from "../../infogrid/ColumnTypes";

const { RangePicker } = DatePicker;

const ManageLogs = (props) => {
    const { message } = useSelector((state) => state.message);
    const { configs } = useSelector((state) => state.info);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadTimestamp, setLoadTimestamp] = useState(null);
    const [output, setOutput] = useState([]);
    const gridRef = useRef();
    const isManageLogEnabled = configs?.manageLogEnabled;

    const getAllLogs = async (postData = {
        startDate: T.getLast30DaysDateHHmmStr(),
        endDate: T.getTodayDateTimeHHmmStr(),
    }) => {
        if (isManageLogEnabled) {
            setLoading(true);
            const getAllLogsResponse = await LogApi.getAllLogs(postData);
            if (!ApiResult.isSuccess(getAllLogsResponse)) {
                const msg = ApiResult.getMessages(getAllLogsResponse);
                dispatch(setPageErrorAlert({ show: !!ApiResult.getMessages(getAllLogsResponse), message: msg }))
            } else {
                setOutput(getAllLogsResponse.data);
                setLoadTimestamp(new Date());
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllLogs();
    }, []);

    const getSelectedLogs = () => {
        let selectedNodes = gridRef.current?.gridApi.getSelectedNodes();
        if (selectedNodes) {
            let selectedData = selectedNodes.map(node => node.data);
            return selectedData.map(r => r.filePathName);
        }
        return [];
    };

    const onDownloadSelectedClick = () => {
        let filePaths = getSelectedLogs();
        if (!T.isEmpty(filePaths)) {
            LogApi.downloadAllLogs(filePaths);
        } else {
            dispatch(setPageInfoAlert({ show: true, message: "Select at least 1 log file." }))
        }
    };

    const onDownloadCurrentClick = () => {
        LogApi.downloadCurrentLog();
    };

    const onFinish = (e) => {
        let postData = {
            startDate: T.getDateTimeHHmmStr(e.startEndDate[0]),
            endDate: T.getDateTimeHHmmStr(e.startEndDate[1])
        };
        if (!postData.startDate && !postData.endDate) {
            dispatch(clearPageAlert());
            dispatch(setPageWarningAlert({ show: true, message: "Please enter either Start date or End date." }))
            return;
        }
        getAllLogs(postData);
    };

    const getForm = () => {
        return <>
            <Form
                size={'small'}
                layout="inline"
                initialValues={{
                    startEndDate: [T.getLast30DaysDate(), T.getTodayDate()]
                }}
                onFinish={onFinish}
            >
                <Form.Item name="startEndDate" label={message['ih.lastModifiedTimeStr']}>
                    <RangeDatePickerAutoAccept format={'MM/DD/YYYY HH:mm'} showTime={true} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" name='query' htmlType="submit" onClick={null}>{message['ih.query']}</Button>
                </Form.Item>
            </Form>
            <br />
            <Form
                size={'small'}>
                <Form.Item>
                    <Space>
                        <Button name='approve' htmlType="submit" disabled={false} onClick={onDownloadSelectedClick}>Download
                            Selected Log(s)</Button>
                        <Button type="primary" name='publish' htmlType="submit" disabled={false} onClick={onDownloadCurrentClick}>Download
                            Current Log</Button>
                    </Space>
                </Form.Item>

            </Form>
        </>;
    };

    let columnDefs = [{
        field: 'lastModifiedTimeStr',
        headerName: message['ih.lastModifiedTimeStr'],
        ...ColumnTypes.DateTime
    }, {
        field: 'createTimeStr',
        headerName: message['ih.createTimeStr'],
        ...ColumnTypes.DateTime
    }, {
        field: 'fileName',
        headerName: message['ih.filename'],
    }, {
        field: 'directory',
        headerName: message['ih.directory=Directory'],
    }];

    const gadgetItem = {
        grid: <InfoGrid id="ManageLogs" columnDefs={columnDefs} data={output} gridRef={gridRef} gridOptions={{
            headerCheckbox: isManageLogEnabled,
        }}></InfoGrid>
    };
    const gadget = {
        loading,
        title: message['ih.manageLog.widget.title'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        customForm: getForm(),
        width: props.width || 100
    };

    return (isManageLogEnabled ? <Gadget {...gadget}></Gadget> : null);
};

export default ManageLogs;