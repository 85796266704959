import React, {useRef, useState} from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import QuickConfig from "../../header/QuickConfig";


import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import useFetchData from "../../../api/service";
import SharedColDefs from 'asset/SharedColDefs';
import {Spin} from "antd";

const OperAvailCapDetail = (props) => {
    const gadgetId = 100;
    const { configs, searchDateSettings } = useSelector((state) => state.info)
    const searchDateSetting = searchDateSettings[gadgetId];
    const location = useLocation();
    const { message } = useSelector((state) => state.message);

    const customTitle = location.state?.title;
    const queryForm = useRef();

    // default to current day from 00:00 to 23:59
    const datePeriodStrings = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, true, searchDateSetting.to, searchDateSetting.type);
    const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);

    const [postData, setPostData] = useState({
        'startDate': location.state?.startDate || datePeriodStrings.startDate,
        'endDate': location.state?.endDate || datePeriodStrings.endDate,
        //'percentTier': location.state?.percentTier?.split('- ')[1].slice(0, -1),
        'cycleInd': location.state?.entityId
    });

    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getAllOperAvailCap',
      postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    let nameMap_custom = [
        'effGasDtTm',
        'cycleName',
        'actualAvalCapPct',
        'pipelineName',
        'locName',
        'flowIndDesc',
        'locPurpDesc',
        'locQtiDesc',
        'designCapacity',
        'operatingCapacity',
        'tsq',
        'operAvailCap',
        'measBasisDesc',
        'reportDt',
        'cycleInd',
        'pipelinePropName',
        'measBasis',
        'duns',
        'loc',
        'locPurp',
        'flowInd',
        'locQti',
        'locZn',
        'allQtyAvailInd',
        'itInd',
        'itIndDesc',
        'nomCapExceedInd',
        'qtyReason',
        'authOverrunInd',
        'authOverrunIndDesc',
        // 'locComments',
        'pressBase',
        'pressBaseInd',
        'pressBaseDesc',
        'refCmntId',
        'n9CycleCmntId',
        'n9DtlCmntId'
    ];


    let nameMap = [...SharedColDefs.Capacity.OperAvailCap.All];

    const processColumns = (cols) => {
        let groupingCols = [
            // 'pipelineName', 'effGasDtTm', 'cycleInd', 'locName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        groupingCols.forEach(colModel => {
            if (colModel) {
                colModel.rowGroup = true;
                colModel.hide = true;
            }
        });

        let sortColumns = [
            'effGasDtTm',
            'cycleName',
            'actualAvalCapPct',
            'pipelineName',
            'locName',
            'flowIndDesc'].map(field => {
                return T.find(cols, (c) => c.field === field)
            });

        sortColumns.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    };

    const processColumns_custom = (cols) => {
        let groupingCols = [
            'effGasDtTm', 'cycleName', 'pipelineName',
            'locName',
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        groupingCols.forEach(colModel => {
            if (colModel) {
                colModel.rowGroup = true;
                colModel.hide = true;
            }
        });

        let sortColumns = ['flowIndDesc'].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        sortColumns.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        let aggColumn = T.find(cols, (c) => c.field === 'locName');
        aggColumn.aggFunc = 'countUnique';

        return cols;
    };
    let columnDefs = customTitle ? processColumns_custom(getNameModelMap(nameMap_custom)) : processColumns(getNameModelMap(nameMap));

    const [pipelineOptions, setPipelineOptions] = useState([]);
    const onPipelineSelect = () => {
        QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
    }

    const cycleOptions = [
        {id: "Timely", name:"Timely"},
        {id: "Evening Cycle", name:"Evening Cycle"},
        {id: "Intraday 1", name:"Intraday 1"},
        {id: "Intraday 2", name:"Intraday 2"},
        {id: "Intraday 3", name:"Intraday 3"}
    ]

    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
                values: pipelineOptions, otherProps: {
                    showSearch: true,
                    options: pipelineOptions,
                    onFocus: onPipelineSelect,
                    style: { width: '100%' }
                }
            },
            { id: 2, name: 'dateRange', type: 'datepickerAsTime', label: message['ih.widget.effDateRange'] },
            { id: 3, name: 'availablePercent',
                type: 'integer', label: message['ih.actualAvalCapPct.greaterEqual'],
                max: 0, min: 100, style: { width: '30%' }
            },
            { id: 4, name: 'cycleInd', type: 'select', label: message['ih.cycleIndicator'],
                values: T.createSelectOptionFromIdNameArray(cycleOptions), style: { width: '30%' }
            }
        ],
        initialValues: {
            'dateRange': [
                location.state?.startDate ? T.getDateTimeHHmm(location.state?.startDate) : datePeriod.startDate,
                location.state?.endDate ? T.getDateTimeHHmm(location.state?.endDate) : datePeriod.endDate
            ]
        },
        submit: (prevState) => {
            if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
                prevState.pipelines = [];
            }
            if (prevState.endDate) {
                prevState.endDate = prevState.endDate.replace("00:00", "23:59");
            }
            setPostData(prevState);
        },
        settings: { colSpan: 2 },
        formRef: queryForm,
        reset: (e) => {
            queryForm.current?.resetFields();
        }
    });

    const gadgetItem = { grid: <InfoGrid id="OperAvailCapDetail" columnDefs={columnDefs} data={output}></InfoGrid> };
    const gadget = {
        id: gadgetId,
        name: 'OperAvailCapDetail',
        loading,
        title: customTitle || message['ih.widget.capacity.allOperAvailCap'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        width: 100
    };

    return (
        <Gadget {...gadget} />
    );
};

export default OperAvailCapDetail;
