import React, {useEffect, useRef, useState} from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  SettingOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import {Dropdown, Menu} from 'antd';

import {AgGridReact} from "ag-grid-react";
import InfoGridColumn from './InfoGridColumn';
import {ApiRequest, TUtil as T} from '@trellisenergy/common-ui-core';
// import { useLocation } from 'react-router-dom';
import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import "./InfoGrid.css";
import {useSelector} from "react-redux";
import NoticeTxtFileDownloadLinkRenderer from "./NoticeTxtFileDownloadLinkRenderer";
import NoticeBlobFileDownloadLinkRenderer from "./NoticeBlobFileDownloadLinkRenderer";
import NoticeDownloadAttachmentsRenderer from "./NoticeDownloadAttachmentsRenderer";
import TermsLinkRenderer from "./TermsLinkRenderer";
import PreviewInPopupRenderer from "./PreviewInPopupRenderer";
import useCurrentHeight from "../../hook/useCurrentHeight";
import TriggerScheduledTaskButtonRenderer from "./TriggerScheduledTaskButtonRenderer";
import CapCommentDownloadLinkRenderer from "./CapCommentDownloadLinkRenderer";
import {PreferencesApi} from "../../api/v1";
import LocationCommentDownloadLinkRenderer from "./LocationCommentDownloadLinkRenderer";

/**
 * 
 * Properties of props.gridOptions.
 * 
 * gridOptions = {
 *  isSmallScreen,
 *  headerCheckbox, 
 *  highlightOnClick,
 *  events,
 * }
 * @param {*} props 
 * @returns 
 */

const InfoGrid = (props) => {
  const gridRef = useRef();
  const divRef = useRef();
  const { message } = useSelector((state) => state.message);

  const { accountData } = useSelector((state) => state.info);
  const isExportEnabled = T.checkAuthority(['EXPORT_INFOHUB_DATA'], accountData.authorities);

  const addDefaultColumns = (_colDefs) => {
    const colDefs = _colDefs || {};
    const ret = [...colDefs];
    // to highlight row on click
    // hide this column in table and tool panels
    ret.push({ field: 'isClicked', hide: true, suppressColumnsToolPanel: true, suppressFiltersToolPanel: true })
    return ret;
  };

  const getGroupCount = (_colDefs) => {
    if (!Array.isArray(_colDefs)) return 0;
    let count = 0;
    _colDefs.forEach(col => {
      if (col.rowGroup)
        count++;
    })
    return count;
  }

  const setGroupDefaultExpanded = (gridOptions, colDefs) => {
    if (!T.isNil(gridOptions.groupDefaultExpanded))
      return gridOptions.groupDefaultExpanded;
    let count = getGroupCount(colDefs);
    if (count === 0) return 0;
    if (count === 1) return -1;
    return count - 1;
  }

  const rowData = props.data;
  const gridDivId = props.id;
  const gridDiv = document.getElementById(gridDivId);
  const columnDefs = addDefaultColumns(props.columnDefs)
  const gridOptions = props.gridOptions || {};
  gridOptions.groupDefaultExpanded = setGroupDefaultExpanded(gridOptions, columnDefs);//T.isNil(gridOptions.groupDefaultExpanded) ? getGroupCount(columnDefs) - 1 : gridOptions.groupDefaultExpanded; // all groups except last should be expanded
  gridOptions.frameworkComponents = props.gridOptions?.frameworkComponents || { // have to register cell renderer in order to show custom ui in cells
    noticeTxtFileDownloadLinkRenderer: NoticeTxtFileDownloadLinkRenderer,
    noticeBlobFileDownloadLinkRenderer: NoticeBlobFileDownloadLinkRenderer,
    noticeDownloadAttachmentsRenderer: NoticeDownloadAttachmentsRenderer,
    previewInPopupRenderer: PreviewInPopupRenderer,
    triggerScheduledTaskButtonRenderer: TriggerScheduledTaskButtonRenderer,
    capCommentDownloadLinkRenderer: CapCommentDownloadLinkRenderer,
    termsLinkRenderer: TermsLinkRenderer,
    locationCommentDownloadLinkRenderer : LocationCommentDownloadLinkRenderer
  };

  const isSmallScreen = T.isSmallScreen || gridOptions.isSmallScreen
  const [isConcise, setConcise] = useState(isSmallScreen);
  const [isSortSequence, setSortSequence] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const autoSizeAllColumns = (params, skipHeader) => {
    let cols = params.columnApi.getAllColumns();
    let arr = [];
    cols.forEach(c => {
      if (!c.colDef.preventAutoSize)
        arr.push(c.colId);
    });
    params.columnApi.autoSizeColumns(arr, skipHeader);
  };

  const getContextMenuItems = (params) => {
    let menuItem = [
      //'csvExport',
      //'copy',
      //'separator',
      "chartRange",
    ];
    if (isExportEnabled) {
      menuItem = params.defaultItems;
    }
    if (T.isUndefined(menuItem)) return menuItem;

    // pass params to the action of custom menu items
    if (T.isArray(gridOptions.contextMenuItems)) {
      const addition = [];
      let obj = {}
      gridOptions.contextMenuItems.forEach(row => {
        if (!T.isObject(row))
          obj = row;
        else
          obj = { ...row };
        if (obj.action) {
          obj = { ...obj, action: () => row.action(params) }
        }
        addition.push(obj);
      });
      menuItem = [...menuItem, ...addition];
    }

    return menuItem;
  };

  const doesExternalFilterPass = (node) => {
    return !(node?.data?.isHidden === true);
  }

  const onGridSizeChanged = (params) => {
    autoSizeAllColumns(params);
  }

  const onColumnEverythingChanged = (params) => {
    autoSizeAllColumns(params);
  }

  // const setGridHeight = (params) => {
  //   const rowHeight = 28;
  //   const filteredRows = params.api.paginationGetRowCount();
  //   let finalHeight = document.querySelector(gridClass).style.height;
  //   if (!T.isSmallScreen && filteredRows > 0 && filteredRows <= 15) {
  //     finalHeight = (filteredRows * rowHeight + 180) + 'px'; // 164 - header + footer
  //   }
  //   const elm = params.api.gridCore?.eGridDiv?.parentElement;
  //   //document.querySelector(gridClass).style.height = finalHeight;
  //   if (!T.isEmpty(elm))
  //     elm.style.height = finalHeight;
  // }

  let pageHeight = useCurrentHeight();
  const gridClass = '.dummy-grid-css'

  const getGridHeight = () => {
    if (gridOptions.height) return gridOptions.height + 'px';
    if (gridOptions.visibleRows){
      const rowHeight = 28;
      return rowHeight * gridOptions.visibleRows + 144; // gridOptions.visibleRows + header and footer
    }

    const elm = document.querySelector(gridClass);
    if (!elm) return '100%';

    const yPosition = elm.getBoundingClientRect().y;
    const windowHeight = pageHeight;
    const height = windowHeight - yPosition - 54;
    const finalHeight = T.isSmallScreen ? Math.max(600, height) : height;
    // console.log('yPosition: ', yPosition, 'windowHeight: ', windowHeight,
    //   ' footer-height: ', 32, ' bottom-margin: ', 36, ' computed height: ', height, ' finalHeight: ', finalHeight)
    return finalHeight + 'px';
  }

  // const getGridAutoHeight = () => {
  //   const rowHeight = 28;
  //   if (!gridApi) return;
  //   const filteredRows = gridApi.paginationGetRowCount();
  //   let finalHeight = document.querySelector(gridClass).style.height;
  //   if (!T.isSmallScreen && filteredRows > 0 && filteredRows <= 15) {
  //     finalHeight = (filteredRows * rowHeight + 180); // 164 - header + footer
  //   }
  //   return finalHeight + 'px';
  // }

  useEffect(() => {
    document.querySelector(`#${gridDivId}`).style.height = getGridHeight();
  });

  useEffect(() => {
    if (divRef.current) divRef.current.hidden = true;
  }, []);

  const prefPromiseQueue = [];

  /**
   * Pick up the request at the end of queue and delete the rest.
   * This is meant to reduce rate & number of API calls made to save preference.
   * like in the scenario when user moves the column.
   */
 const executePrefPromise = () => {
   const queue = prefPromiseQueue;
   if (queue.length < 1) return;

   const request = queue.pop();
    if(queue.length > 0) {
      // console.log("PromiseQueueBefore: ", queue.length);
      queue.splice(0, queue.length - 1);
      // console.log("PromiseQueueAfter: ", queue.length);
    }
   fetch(request).then(function(result) {
     // do nothing
   }).catch(console.error);

 }

  useEffect(() => {
    setInterval(executePrefPromise, 2000);
  });

  const savePreference = function(params) {
    const gridPreferenceId = getGridPreferenceId(gridDivId, gridOptions);
    const columnState = params.columnApi.getColumnState();
    const filterModel = params.api.getFilterModel();
    saveGridPreference(gridPreferenceId, {columnState, filterModel});
  }
/*
  useEffect(()=> {
    console.log('Fetching Grid Preference');
    if (!gridRef.current || !gridRef.current.columnApi)
      return;

    const gridPreferenceId = getGridPreferenceId(gridDivId, gridOptions);
    const request = getGridPreference(gridPreferenceId);
    request.then(function(result) {
      if (!gridRef.current || !gridRef.current.columnApi) {
        return;
      }
      const columnApi = gridRef.current.columnApi;
      const preferenceValue = result.preferenceValue;
      if (preferenceValue) {
        if (preferenceValue.columnState)
          columnApi.applyColumnState({state: preferenceValue.columnState,  applyOrder: true});
        if (preferenceValue.filterModel)
          gridRef.current.api.setFilterModel(preferenceValue.filterModel);
      }
      console.log(result)
    }).catch(e => console.warn('Error fetching grid preferences for ', gridPreferenceId, e))
  },[gridRef.current && gridRef.current.columnApi])
*/
  const onGridReady = (params) => {
    // console.log('onGridReady', params);
    // console.log('Fetching Grid Preference');

    const gridPreferenceId = getGridPreferenceId(gridDivId, gridOptions);
    const request = getGridPreference(gridPreferenceId);
    request.then(function(result) {
      const columnApi = gridRef.current.columnApi;
      const preferenceValue = result.preferenceValue;
      if (preferenceValue) {
        if (preferenceValue.columnState)
          columnApi.applyColumnState({state: preferenceValue.columnState,  applyOrder: true});
        if (preferenceValue.filterModel) {
          gridRef.current.api.setFilterModel(preferenceValue.filterModel);
        }
      }
      // console.log(result)
    }).catch(e => console.warn('Error fetching grid preferences for ', gridPreferenceId, e))
    .finally(() => {
      if (divRef.current) divRef.current.hidden = false;
    });

    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // set ref = gridApi if available
    if (props.gridRef) {
      props.gridRef.current = { gridApi: params.api };
    }

    // call the passed event
    if (gridOptions.events && gridOptions.events.onGridReady)
      gridOptions.events.onGridReady(params);
    //params.api.addAggFunc("countUnique", countUnique);
    if (gridOptions.autoResizeHeight) {
      //setGridHeight(params);
      const filteredRows = params.api.paginationGetRowCount();
      if (filteredRows > 0 && filteredRows <= 15) {
        params.api.setDomLayout('autoHeight');
        const elm = params.api.gridCore?.eGridDiv?.parentElement;
        if (!T.isEmpty(elm)) {
          // auto height will get the grid to fill the height of the contents,
          // so the grid div should have no height set, the height is dynamic.
          elm.style.height = ''
        }
      }
    }
  };

  let selectStarted = false,
    rowIndex,
    columnName;

  const isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  const onCellClicked = (params) => {
    if (selectStarted) {
      gridApi?.clearRangeSelection();
      gridApi?.addCellRange({
        rowStartIndex: rowIndex, //0,
        rowEndIndex: params.rowIndex, //10,
        columnStart: columnName, //'Location',
        columnEnd: params.column.colId, // 'Transport MDQ'
      });
    }
    selectStarted = !selectStarted;
    rowIndex = params.rowIndex;
    columnName = params.column.colId;

    // call the passed event
    if (gridOptions.events && gridOptions.events.onCellClicked)
      gridOptions.events.onCellClicked(params);
  };

  const onCellClickedForHighlight = (params) => {
    gridApi.forEachNode(function (rowNode) {
      if (rowNode.data && rowNode.data.isClicked) {
        rowNode.setDataValue('isClicked', false);
      }
    });
    params.node.setDataValue('isClicked', true);

    // call the passed event
    if (gridOptions.events && gridOptions.events.onCellClicked)
      gridOptions.events.onCellClicked(params);
  };

  const rowClassRules = {
    'info-grid-row-highlight': 'data.isClicked == true',
    'info-grid-row-highlight-dupe': (params) => {
      return params.data?.dupRow === message['ih.yes'];
    }
  }

  /**
   *
   * Clear the queue before adding a new save request.
   *
   * @param name
   * @param gridPreference
   */
  const saveGridPreferenceThrottled = function (name, gridPreference) {
    const request = setGridPreference({ "preferenceTypeName": "GRID_"+ name, preferenceValue: gridPreference});
    prefPromiseQueue.length = 0;
    prefPromiseQueue.push(request);
  }
  const saveGridPreference = function (name, gridPreference) {
    saveGridPreferenceThrottled(name, gridPreference);
    /*
    const request = setGridPreference({ "preferenceTypeName": "GRID_"+ name, preferenceValue: gridPreference});
    request.then(function(result) {
      // do nothing
    }).catch(console.error);
     */
  }

  const buildSetGridPreferenceCriteria = function (json) {
    return json;
  }

  const setGridPreference = function (json, async) {
      const request = ApiRequest.composePostJsonRequest('api/v1/user/saveUserPreference',
          null, buildSetGridPreferenceCriteria(json))
      return request;
  }

  const getGridPreferenceId = function (_gridId, colOptions) {
    const suffix = (colOptions && colOptions.gridPreferenceIdSuffix) || "_v1";
    let gridId = _gridId.replace("_jqg", "_");
    gridId = gridId.replace(/_[0-9]+/g, "_");
    //const location = useLocation();
    //return location.pathname.replace(contextPath + "/", "") + "?" + gridId + suffix;
    return gridId + suffix;
  }

  const buildGridPreferenceCriteria = function (name) {
    return { "preferenceTypeName": "GRID_"+ name};
  }

  // TODO: First check if page grid preferences are set, else call getGridPreference.do for grid.
  // This is used to consolidate the number of getGridPreference calls to the server for the page.
  const getGridPreference = function (name) {
    return T.delay(100).then(function(f) {
      return PreferencesApi.getPreferences(buildGridPreferenceCriteria(name));
    })
  }

  const onColumnRowGroupChanged = (params) => {
    // console.log('onColumnRowGroupChanged', params);
    setTimeout(function () {
      autoSizeAllColumns(params);
    }, 200);
    savePreference(params);
  }

  const onFirstDataRendered = (params) => {
  }

  const onRowGroupOpened = (params, a) => {
  }

  const onSortChanged = (params) => {
    // console.log('onSortChanged', params);
    savePreference(params);
  }
  const onFilterChanged = (params) => {
    // console.log('onFilterChanged', params);
    savePreference(params);
    const filters = params.api.getFilterModel();
    const columnDefs = params.api.getColumnDefs();

    if (!gridDiv) return;
    const filterRow = gridDiv.getElementsByClassName('ag-header-container')[0].querySelector('[aria-rowindex="2"]');

    // Add className to column header
    columnDefs.forEach((columnDef, index) => {
      const filterCell = filterRow.querySelector( `[aria-colindex='${index+1}']` );

      if (filterCell)
        if (columnDef.field in filters) {
          filterCell.classList.add('ag-header-cell-filtered');
        } else {
          filterCell.classList.remove('ag-header-cell-filtered');
        }
    });
  }
  const onColumnVisible = (params) => {
    // console.log('onColumnVisible', params);
    savePreference(params);
  }
  const onColumnMoved = (params) => {
    // console.log('onColumnMoved', params);
    savePreference(params);
  }
  const onColumnValueChanged = (params) => {
    // console.log('onColumnValueChanged', params);
    savePreference(params);
  }
  const onColumnPivotChanged = (params) => {
    // console.log('onColumnPivotChanged', params);
    savePreference(params);
  }
  const onColumnPinned = (params) => {
    // console.log('onColumnPinned', params);
    savePreference(params);
  }
  const onColumnGroupOpened = (params) => {
    // console.log('onColumnGroupOpened', params);
    savePreference(params);
  }

  const defaultColDef = {
    flex: 1,
    //minWidth: 100,
    sortable: true,
    resizable: true,
    enableValue: true, // allows aggregation
    enableRowGroup: true,
    enablePivot: true,
    //filter: true,
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    floatingFilter: !isSmallScreen,

    headerCheckboxSelection: gridOptions.headerCheckbox && isFirstColumn,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: gridOptions.headerCheckbox && isFirstColumn,
  };

  // define a column type (you can define as many as you like)
  const columnTypes = {
    nonEditable: { editable: false },
    dateColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        browserDatePicker: true,
        comparator: function (b, a) {
          a = new Date(a);
          a.setHours(0);
          a.setMinutes(0);
          a.setSeconds(0);
          return a.getTime() - b.getTime();
        }
      },
      //suppressMenu: true
    },
    dateMonthYearColumn: {
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: function (b, a) {
          a = new Date(a);

          //Set dates to the first day of the month so they are always compared by month.
          a.setDate(1);
          b.setDate(1);

          a.setHours(0);
          a.setMinutes(0);
          a.setSeconds(0);
          return a.getTime() - b.getTime();
        }
      },
      //suppressMenu: true
    },
    numberColumn: {
      filter: 'agNumberColumnFilter'
    }
  };

  const sideBar = {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
      },
      {
        id: "filters",
        labelDefault: "Filters",
        labelKey: "filters",
        iconKey: "filter",
        toolPanel: "agFiltersToolPanel",
      },
    ],
    position: "right",
    defaultToolPanel: "none",
    hiddenByDefault: isSmallScreen,
  };

  const autoGroupColumnDef = {
    //headerCheckboxSelection: true,
    pinned: "left",
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      suppressCount: false,
      //checkbox: true,
    },
    //maxWidth: 150,
    minWidth: 150,
    sortable: true,
    //minWidth: 200
  };

  /* This overrides autoGroupColumnDef*/
  /*
            groupUseEntireRow={true} 
          groupRowRendererParams= {{
            suppressCount: true,
    checkbox: true // puts a checkbox onto each group row
    }}*/

  const statusBar = {
    statusPanels: [
      // { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
      //{statusPanel: 'agTotalRowCountComponent', align: 'left', },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
        statusPanelParams: {
          aggFuncs: ["sum", "avg"],
        },
      },
    ],
  };

  const pagination = {
    paginationPageSize: 30,
    pagination: true,
    //paginationAutoPageSize={true} // overrides above
  };

  const charts = {
    enableCharts: true,
    popupParent: document.body,
  };

  const countUniqueFunc = function (params) {
    var result = 0,
      map = {};
    params.values.forEach(function (value) {
      if (!map[value]) {
        result++;
      }
      map[value] = 1;
    });
    return result;
  };

  const GearIcon = () => {
    const menuItemStyle = { fontSize: '13px', lineHeight: '13px' };

    return <Dropdown overlay={
      <Menu>
        <Menu.Item style={menuItemStyle} onClick={switchGroupExpansion} key={11}
          icon={isConcise ? <MenuFoldOutlined /> : < MenuUnfoldOutlined />}>
          Column Grouping Style ({isConcise ? "Full" : "Concise"})
        </Menu.Item >
        <Menu.Divider key={Math.random()} />
        <Menu.Item style={menuItemStyle} onClick={(e) => switchSortSequence(e, gridApi)} key={12}
          icon={isSortSequence ? <UnorderedListOutlined /> : <OrderedListOutlined />}>
          Column Multi-Sort Sequence ({isSortSequence ? "Off" : "On"})
        </Menu.Item>
        <Menu.Divider key={Math.random()} />
        <Menu.Item onClick={(e) => resetGrid(e, gridApi)} key={13}
        icon={<UndoOutlined />}>Reset</Menu.Item>
      </Menu >}>
      <SettingOutlined style={{
        fontSize: '1.5em', position: "absolute",
        marginTop: "8px", marginLeft: "12px",
        zIndex: 200, background: "#f5f7f7", cursor: "pointer"
      }} />
    </Dropdown >
  }

  const switchSortSequence = (e, gridApi) => {
    gridApi.gridCore?.eGridDiv?.querySelectorAll('span[ref=eSortOrder]').forEach(e => e.style.display = isSortSequence ? 'none' : 'inline');
    //document.querySelectorAll('span[ref=eSortOrder]').forEach(e => e.style.display = isSortSequence ? 'none' : 'inline');
    setSortSequence(!isSortSequence);
  }

  const switchGroupExpansion = (event) => {
    setConcise(!isConcise);
    const columnState = gridColumnApi.getColumnState();
    setTimeout(() => {
      gridColumnApi.applyColumnState({
        state: columnState,
      });
      gridApi.eventService.dispatchEvent({
        type: "columnRowGroupChanged",
        columns: [],
        column: null,
        api: gridApi,
        columnApi: gridColumnApi,
        source: "toolPanelUi"
      });
    }, 100);
  };

  const resetGrid = (e, gridApi) => {
    gridRef.current.columnApi.resetColumnState();
    gridRef.current.api.setFilterModel(null);

    const gridPreferenceId = getGridPreferenceId(gridDivId, gridOptions);
    saveGridPreference(gridPreferenceId, null)
  }

  const general = {
    debug: false,
    animateRows: false,
    enableRangeSelection: true,
    //rowGroupPanelShow: isSmallScreen ? undefined : "always",
    rowGroupPanelShow: "always",
    suppressMenuHide: isSmallScreen,
    rowSelection: "multiple", // needed
    groupMultiAutoColumn: !isConcise,
    groupDisplayType: isConcise ? 'singleColumn' : 'multipleColumns',
    //groupDisplayType: 'singleColumn', // default is 'multipleColumns', other are 'groupRows'

    // https://ag-grid.com/react-data-grid/grouping-sorting/
    // preserves current group order when sorting on non-group columns
    //groupMaintainOrder: true,

    groupSelectsChildren: true,
    suppressRowClickSelection: true,
    suppressAggFuncInHeader: true,
    //suppressDragLeaveHidesColumns: true,//TODO
    //suppressMakeColumnVisibleAfterUnGroup: true,//TODO
    suppressColumnVirtualisation: true,
    aggFuncs: {
      countUnique: countUniqueFunc
    },
    rowClassRules,
    // function
    getContextMenuItems: getContextMenuItems,

    // exclusively use for isHidden parameter
    // if isHidden is defined, external filter is present
    isExternalFilterPresent: () => {
      if (!T.isEmpty(rowData) && !T.isUndefined(rowData[0]?.isHidden)) {
        return true;
      }
      return false;
    },
    doesExternalFilterPass: doesExternalFilterPass,

    //events
    onGridReady,
    onGridSizeChanged,
    onColumnEverythingChanged,
    onColumnRowGroupChanged,
    onSortChanged,
    onFilterChanged,
    onColumnVisible,
    onColumnMoved,
    onColumnValueChanged,
    onColumnPivotChanged,
    onColumnPinned,
    onColumnGroupOpened,

    onCellClicked: gridOptions.highlightOnClick ? onCellClickedForHighlight : onCellClicked,
    onFirstDataRendered,
    onRowGroupOpened,
  };

  const allGridProps = {
    ...general,
    ...pagination,
    ...charts,
    defaultColDef,
    autoGroupColumnDef,
    columnTypes,
    sideBar,
    statusBar,
  };

  /*
  const gridPreferenceId = getGridPreferenceId(gridDivId, gridOptions);
  const request = getGridPreference(gridPreferenceId);
  request.then(function(result) {
    const columnApi = gridRef.current.columnApi;
    const preferenceValue = result.preferenceValue;
    if (preferenceValue) {
      if (preferenceValue.columnState)
        columnApi.applyColumnState({state: preferenceValue.columnState,  applyOrder: true});
      if (preferenceValue.filterModel)
        gridRef.current.api.setFilterModel(preferenceValue.filterModel);
    }
    console.log(result)
  }).catch(e => console.warn('Error fetching grid preferences for ', gridPreferenceId, e))
  */
  return (
    <div ref={divRef}>
      <div className="dummy-grid-css ag-theme-balham" id={gridDivId} style={{ height: getGridHeight(), width: gridOptions.width || "100%", fontFamily: "Poppins" }}>
        <GearIcon />
        <AgGridReact
          ref={gridRef}
          {...allGridProps}
          {...gridOptions}
          rowData={rowData}
          columnDefs={columnDefs}
        >
        </AgGridReact>
      </div>
    </div>
  );
};

export { InfoGrid, InfoGridColumn };
