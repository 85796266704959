import { createSlice } from '@reduxjs/toolkit'

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    downloadOptions: {},
  },
  reducers: {
    setDownloadOptions: (state, action) => {
      state.downloadOptions = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDownloadOptions } = locationSlice.actions

export default locationSlice.reducer