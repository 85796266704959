/**
 * Data mapping class
 */
export default class DataMapper {
    /**
     * Gets common data mapping across ioc gadgets
     *
     * @param {Array} data
     * @returns {Array} mapped entries from source data
     */
    static convertIocEntityCountData = (data = []) => {
        return data.map(d => {
            return {
                entityId: d.entityId,
                type: d.name,
                Count: d.count
            }
        });
    }
}
