import React, {useState} from "react";
import {ApiRequest, TUtil} from '@trellisenergy/common-ui-core';
import {Col, Popover, Row, Skeleton, Tooltip} from "antd";
import {BASEURL_DEV} from "../../config";
import {CloseOutlined, DownloadOutlined, EyeOutlined} from '@ant-design/icons';
// import {useSelector} from "react-redux";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";

const NoticeBlobFileDownloadLinkRenderer = (props) => {
    //TODO: setting default to 50 as setting 0 or 1 doesn't allow display pdfs in initial state
    const [numPages, setNumPages] = useState(50);
    // const {message} = useSelector((state) => state.message);
    let [loading, setLoading] = useState(false);
    let [noticeTextContent, setNoticeTextContent] = useState("");
    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });

    if (!props?.data?.id) {
        return null;
    }


    //for blob file download
    let downloadBlobFiletUrl = 'api/v1/notices/downloadNoticeBlob?noticeId=' + props?.data?.id + "&viewType=view";
    let downloadBlobFileFullUrl = TUtil.isDevEnv() ? BASEURL_DEV + downloadBlobFiletUrl : TUtil.buildURL(downloadBlobFiletUrl);
    let downloadBlobFileLink = <a href={downloadBlobFileFullUrl} target={"_blank"} rel="noreferrer">{<DownloadOutlined/>}</a>;
    let noticeKeywords = props?.data?.noticeKeywords || [];

    let previewBlobUrl = 'api/v1/notices/downloadNoticeBlob?noticeId=' + props?.data?.id + "&viewType=view";
    let previewBlobFileFullUrl = TUtil.buildURL(previewBlobUrl);
    // let previewBlobFileLink = <a href={previewBlobFileFullUrl} target={"_blank"}>{<DownloadOutlined/>}</a>;

    let getNoticeContentBlob = async () => {
        let contentType;
        let getNoticeText = () => {
            const request = ApiRequest.composeGetRequest(previewBlobUrl);
            return fetch(request)
                .then(response => {
                    contentType = response.headers.get("Content-Type");
                    if(!response.ok){
                        return "Not able to Display.";
                    }
                    return response.text();
                })
                .catch(error => {
                    return null;
                });
        };

        setLoading(true);
        let noticeTextResponse = await getNoticeText();

        if (contentType && (!contentType.isNaN) && contentType.includes("text/html")) {
            noticeTextResponse = <div dangerouslySetInnerHTML={{__html: noticeTextResponse}}/>;
        }

        if (contentType && (!contentType.isNaN) && contentType.includes("text/plain")) {
            noticeTextResponse = <pre>{noticeTextResponse}</pre>;
        }

        if (contentType && (!contentType.isNaN) && contentType.includes("application/pdf")) {
            function onDocumentLoadSuccess({ numPages }) {
                setNumPages(numPages);
            }
            const renderPages = () => {
                const pages = [];
                console.log("pdf");
                for(let i=1; i <= numPages; i++) {
                    pages.push(<Page
                        key={`page_${i}`}
                        pageNumber={i}
                        width={600}
                    />);
                }
                return <div>
                    {pages}
                </div>
            }
            // const loadDocument = (<Document file={previewBlobFileFullUrl} onLoadSuccess={onDocumentLoadSuccess}>
            //     {renderPages()}
            // </Document>);
            noticeTextResponse = (
                <div>
                    <Document file={previewBlobFileFullUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {numPages>0 && renderPages()}
                    </Document>
                </div>
            );
        }


        setLoading(false);

        if (noticeTextResponse) {
            if (noticeKeywords && noticeKeywords.length) {
                setNoticeTextContent(noticeTextResponse);
            } else {
                setNoticeTextContent(noticeTextResponse);
            }
        } else {
            setNoticeTextContent("No data available.");
        }
    };

    let handleHoverChange = (visible) => {
        if (visible) {
            getNoticeContentBlob();
        }
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    }

    let handleClickChangeBlob = visible => {
        if (visible) {
            getNoticeContentBlob();
        }

        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };

    let hide = () => {
        setPopoverState({
            hovered: false,
            clicked: false
        });
    };

    let content = <Skeleton active loading={loading}>
        {noticeTextContent}
    </Skeleton>;

    let title = <>
        <Row>
            <Col span={20}>{props?.data?.subject}</Col>
            <Col span={4} style={{textAlign: 'right'}}>
                <a onClick={hide}><CloseOutlined/></a>
            </Col>
        </Row>
    </>;

    return <>
        <div className="flex-center-justify-left">
            <Popover mouseEnterDelay={.5} trigger="hover"
                     visible={popoverState.hovered}
                     overlayStyle={{
                         width: "50vw"
                     }}
                     onVisibleChange={handleHoverChange} content={content} title={title}>
                <Popover mouseEnterDelay={.5} trigger="click"
                         visible={popoverState.clicked}
                         overlayStyle={{
                             width: "50vw"
                         }}
                         onVisibleChange={handleClickChangeBlob} content={content} title={title}>
                    <EyeOutlined style={{fontSize: '16px'}}/>
                </Popover>
            </Popover>
            {/*&nbsp;&nbsp;&nbsp;<Tooltip title="Click to view file in nex tab.">{previewBlobFileLink}</Tooltip>*/}
            &nbsp;&nbsp;&nbsp;<Tooltip title="Click to download the notice text.">{downloadBlobFileLink}</Tooltip>
        </div>
    </>;
};

export default NoticeBlobFileDownloadLinkRenderer;
