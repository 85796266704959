import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';

const CheckboxControl = ({ control, ...rest }) => {
  const [checked, setState] = useState(control.defaultValue);

  const onChange = (event) => {
    setState({ checked: !checked });
    if (control.onChange)
      control.onChange(event);
  }

  useEffect(() => {
    console.log('useEffect, counter updated, control.defaultValue=', control.defaultValue);
    if (control.defaultValue !== checked)
      setState(control.defaultValue);
  }, [checked, control.defaultValue]);

  return (
    <Checkbox {...rest} id={control.id} disabled={control.disabled}
      name={control.name} onChange={onChange} value={control.val} checked={checked} >
      <span style={{ color: checked && !control.disabled ? 'inherit' : '#BDBDBD' }}>{control.label}</span>
    </Checkbox >
  )
}

export default CheckboxControl;