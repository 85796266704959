//import Gadget from '../../gadget/Gadget'
import {
  NoticesSummary, ImbalanceHistory,
  CustomerPerRateSchedule,
    MDQsByCustomer,
    MDQsByCustomerStorage,
    RecentChanges,
    LocationType
} from '../../gadget/index';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from 'react-redux'
import BiddableOffers from "../../gadget/capacityRelease/BiddableOffers";
import CriticalNotices from "../../gadget/notices/CriticalNotices";

function Dashboard(props) {
  const { message } = useSelector((state) => state.message)

  //const gadget1 = { id: 'Gadget1', title: 'Gadget1', dataSourceId: 1, width: 50 };
  //const gadget2 = { id: 'Gadget2', title: 'Gadget2', dataSourceId: 2, width: 50 };

  return (
    <div>
      <NoticesSummary drilldown={{ link: T.buildURL('/notices'), title: message['ih.widget.notices.goto'], level: 2 }} />
      <CriticalNotices drilldown={{ link: T.buildURL('/notices'), title: message['ih.widget.criticalNotices.goto'], level: 2 }} />
      <BiddableOffers drilldown={{ link: T.buildURL('/capacityRelease/AllOffer'), title: message['ih.widget.offers.goto'], level: 2 }} />
      {/*<NoticesKeyword drilldown={{ link: T.buildURL('/secure/notices'), title: 'Go to Notices', level: 2 }} />*/}
      {/*<CustomerPerRateSchedule drilldown={{ link: T.buildURL('/ioc'), title: message['ih.widget.ioc.goto'], level: 2 }} />*/}
        {/*<ImbalanceHistory drilldown={{ link: T.buildURL('/imbalance'), title: message['ih.widget.imbalance.goto'], level: 2 }} />*/}
        <MDQsByCustomer drilldown={{ link: T.buildURL('/ioc'), title: message['ih.widget.ioc.goto'], level: 2 }} />
        {/*<MDQsByCustomerStorage drilldown={{ link: T.buildURL('/ioc'), title: message['ih.widget.ioc.goto'], level: 2 }} />*/}
        {/*<LocationType drilldown={{ link: T.buildURL('/locations'), level: 2 }} />*/}

        {/*<RecentChanges drilldown={{ link: T.buildURL('/locations'), title: message['ih.widget.locations.goto'], level: 2 }} />*/}

        {/*<Gadget {...gadget1} />*/}
      {/*<Gadget {...gadget2} />*/}
      {/*<NoticesAll />
        <CustomerPerRateScheduleDetail />
        */}
    </div>
  );
}

export default Dashboard;