import React, { useEffect, useRef, useState } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'

function PointQuantityDetail(props) {
    const { message } = useSelector((state) => state.message)
    const [postData, setPostData] = useState({});
    const [refreshCount, setRefresh] = useState(0);
    const [postDataMaxCalendarQuarter] = useState({});
    const [loadingMaxCalendarQuarter, outputMaxCalendarQuarter] = useFetchData('api/v1/ioc/getMostRecentFirstDayCalendarQuarter', postDataMaxCalendarQuarter, refreshCount);
    const [loadInit, setLoadInit] = useState(false);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/ioc/getPointQuantity', postData, refreshCount, loadInit, setLoadInit);

    const refresh = () => {
        setRefresh(Math.random())
    };

    const queryForm = useRef();

    useEffect(() => {
        if (outputMaxCalendarQuarter.firstDayCalendarQuarter) {
            let firstAndLastQuarter = T.getFirstAndLastDayOfQuarter(outputMaxCalendarQuarter.firstDayCalendarQuarter);

            queryForm.current.setFieldsValue({
                'dateRange': [firstAndLastQuarter.first, firstAndLastQuarter.last],
            });

            setPostData({
                startDate: firstAndLastQuarter.first.format("MM/DD/YYYY"),
                endDate: firstAndLastQuarter.last.format("MM/DD/YYYY")
            });
        }
    }, [outputMaxCalendarQuarter]);


    let nameMap = [
        'firstDateCalendarQuarter',
        'pipelineName',
        'shipperName',
        'rateSchedule',
        'contractNumber',
        'pointIdentifierCode',
        'pointName',
        'transportMdq',
        'storageMdq',
        'transportMdqUom',
        'storageMdqUom',
        'shipperId',
        'contractEffectiveDate',
        'contractPrimeTermExpirationDate',
        'daysUntilExpire',
        'negativeRatesIndicator',
        'pointCodeQualifier',
        'pointCode',
        'zoneName',
    ];

    let columnDefs = getNameModelMap(nameMap);

    const controls = ControlFactory.createControls({
        controls: [{ id: 1, name: 'dateRange', type: 'datepicker', label: message['ih.postedQuarter'] }],
        initialValues: { 'dateRange': [] },
        submit: setPostData,
        formRef: queryForm
    });

    const processColumns = (cols) => {
        let col1 = T.find(cols, (c) => c.field === 'firstDateCalendarQuarter');
        col1.sort = 'desc';
        col1.sortIndex = 0;

        let ascSortCols = [
            'pipelineName',
            'shipperName',
            'rateSchedule',
            'contractNumber',
            'pointIdentifierCode',
            'pointName'
        ];

        ascSortCols.forEach((field, index) => {
            let col = T.find(cols, (c) => c.field === field);
            col.sort = 'asc';
            col.index = index;
        });

        return cols;
    };

    const gadgetItem = { grid: <InfoGrid id="PointQuantityDetail" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> };
    const gadget = {
        loading: loading || loadingMaxCalendarQuarter,
        refresh,
        title: message['ih.widget.ioc.pointQuantity'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        width: props.width || 100
    };

    return (
        <Gadget {...gadget} />
    );
}

export default PointQuantityDetail;
