import React, { useEffect, useReducer, useState, useRef } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import { setPageErrorAlert } from 'redux/reducer/info';

import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useDispatch, useSelector } from 'react-redux'
import CapacityReleaseApi from 'api/v1/CapacityReleaseApi';
import { Skeleton, Collapse } from 'antd'
import BackTop from '../../backtop/BackTop';
import { CaretRightOutlined } from '@ant-design/icons';
import ColumnTypes from "../../infogrid/ColumnTypes";

const { Panel } = Collapse;

const AllBid = (props) => {
  const gadgetId = 202;
  const { configs, searchDateSettings } = useSelector((state) => state.info);
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const dispatch = useDispatch();
  const formRef = useRef(this);

  const messageBundle = useSelector((state) => state.message).message;

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [bidGeneralLoading, setBidGeneralLoading] = useState(false);
  const [bidRateLoading, setBidRateLoading] = useState(false);
  const [bidLocationLoading, setBidLocationLoading] = useState(false);

  const [bidGeneralData, setBidGeneralData] = useState([]);
  const [bidRateData, setBidRateData] = useState([]);
  const [bidLocationData, setBidLocationData] = useState([]);

  const [fullRangeOnlyDisabled, setFullRangeOnlyDisabled] = useState(false);
    const { message } = useSelector((state) => state.message);

  useEffect(() => {
    initalQueryAll();
  }, [])

  let bidGeneralNameMap = [
    //1
    'tspName',
    'releaserName',
    'rfqOffrNbr',
    'bidNumber',
    'bidQtyContract',
    'bidderReleaseTermStartDate',
    'bidderReleaseTermEndDate',
    'postingDateTime',
    'trnsSetPurpCd',
    'rtSchedName',
    'rtFormTypCode',
    'prearrangedBidCode',
    'bidderLesserQtyCode',
    'bidMinQtyContract',
    'measBasis',
    'pressureBase',
    'pressBaseInd',
    'ibrIndCapRelInd',
    'storageInvCondReleaseInd',
    // 'termsNotesStorage',
    'bidderContingencyInd',
    // 'bidderContngTerms',
    'standAlnBidCode',
    'noStandAlnBidTc',
    'bidPackageIdentifier',
    // 'termsNotes',
    'duns',
    'pipelinePropName',
    'releaser',
    'releaserContactName',
    'releaserContactPhone',
    'releaserContactFax',
    'releaserContactEmail',
    'afflCode',
    // 'termsNotesAma',
    'minVolCommitmentQty',
    'minVolPct',
    'cycleIndicator',
    'bidReceiptDate',
    'bidRates',
    'bidLocations',
  ];

  let bidRateNameMap = [
    // 1
    'tspName',
    'rfqOffrNbr',
    'bidNumber',
    'bidRates',
    'rtFormTypCode',
    'rateId',
    'relAcptBidBasis',
    'bidValue',
    'reservationRtBasis',
    'bidQtyContract',
    'seasonalStartDate',
    'seasonalEndDate',
    'rateBid',
    'pctMaxTrfRateBid',
    'ibrIndCapRelInd',
    'ibrBidDiff',
    'ibrBidDiffFloor',
    'ibrBidDiffPct',
    'minVolCommitmentQty',
    'minVolPct',
    'surchargeCode',
    'surchgId',
    'totSurchg',
    'duns',
    'pipelinePropName',
    'releaserName',
    'releaser',
    'rtSchedName',
    'bidderReleaseTermStartDate',
    'bidderReleaseTermEndDate',
    'prearrangedBidCode',
    'postingDateTime',
    'trnsSetPurpCd',
  ];

  let bidLocationNameMap = [
    //1
    'tspName',
    'rfqOffrNbr',
    'bidNumber',
    'bidLocations',
    //'locQtiCode',
    'locQtiName',
    //'locPurpose',
    'purposeName',
    'locName',
    'loc',
    'bidQtyLocation',
    'locZone',
    'capacityTypeLocationCode',
    'route',
    'bidderLesserQtyCode',
    'bidMinQtyLocation',
    'systemMgtServiceQty',
    'bidQtyContract',
    'measBasis',
    'pressBase',
    'pressBaseInd',
    'duns',
    'pipelinePropName',
    'releaserName',
    'releaser',
    'rtSchedName',
    'bidderReleaseTermStartDate',
    'bidderReleaseTermEndDate',
    'prearrangedBidCode',
    'postingDateTime',
    'trnsSetPurpCd',
  ];

  const processOfferGeneralNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['releaserName', 'asc'],
      ['rfqOffrNbr', 'asc'],
      ['bidNumber', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    cols.push({
      field: 'terms',
      headerName: message['ih.terms'],
      ...ColumnTypes.TermsLink,
      cellRendererParams: {
        url: 'api/v1/crlsBid/getBidTerms?bidId='
      }
    });

    return cols;
  }

  const processRateNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['rfqOffrNbr', 'asc'],
      ['bidNumber', 'asc'],
      ['rateId', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  const processLocationNameMap = (cols) => {
    [
      ['postingDate', 'desc'],
      ['tspName', 'asc'],
      ['rfqOffrNbr', 'asc'],
      ['bidNumber', 'asc'],
      ['locQtiCode', 'asc'],
      ['locPurpose', 'desc'],
      ['locName', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  let bidGeneralColumnDefs = processOfferGeneralNameMap(getNameModelMap(bidGeneralNameMap));
  let bidRateColumnDefs = processRateNameMap(getNameModelMap(bidRateNameMap));
  let bidLocationColumnDefs = processLocationNameMap(getNameModelMap(bidLocationNameMap));

  const reset = (e) => {
    formRef.current?.resetFields();
    setResultFiltered(false);
  }

  const initalQueryAll = () => {
    const data = formRef.current?.getFieldsValue(true);
    data['bidderTermStartDate'] = T.getDateTimeHHmmStr(data.bidderTermDateRange[0]);
    data['bidderTermEndDate'] = T.getDateTimeHHmmStr(data.bidderTermDateRange[1]);
    queryAll(data);
  }

  const queryAll = (data) => {
    function extractPostAfterDate (data) {
      if(data.postedEndDateTime == null || data.postedEndDateTime === undefined){
        return  null;
      }
      else{
        return data.postedEndDateTime + " 00:00";
      }
    }

    let postData = {
      bidderTermStartDate: data.bidderTermStartDate,
      bidderTermEndDate: data.bidderTermEndDate.replace("00:00", "23:59"),
      //bidReceivedAfter: data.bidReceivedAfter,
      postedAfter: extractPostAfterDate(data),
      highestRateOnly: data.highestRateOnly,
      offerNo: data.rfqOffrNbr,
      bidNo: data.bidNumber,
      fullRangeOnly: data.fullRangeOnly,
      //latestPostOnly: data.latestPostOnly,
    };

    setBidGeneralLoading(true);
    setBidRateLoading(true);
    setBidLocationLoading(true);
    setResultFiltered(false);

    // CapacityReleaseApi.getBidHeaders(postData).then(data => {
    //   setBidGeneralData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setBidGeneralLoading(false);
    // });
    //
    // CapacityReleaseApi.getBidLocations(postData).then(data => {
    //   setBidLocationData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setBidLocationLoading(false);
    // });
    //
    // CapacityReleaseApi.getBidRates(postData).then(data => {
    //   setBidRateData(data)
    // }).catch(e => {
    //   if (!ApiResult.isSuccess(e)) {
    //     const msg = ApiResult.getMessages(e);
    //     if (msg) {
    //       dispatch(setPageErrorAlert({ show: true, message: msg }))
    //     }
    //   }
    // }).finally(() => {
    //   setBidRateLoading(false);
    // });

    Promise.allSettled([
      CapacityReleaseApi.getBidHeaders(postData),
      CapacityReleaseApi.getBidRates(postData),
      CapacityReleaseApi.getBidLocations(postData)
    ]).then(result => {
      const data = result.map(r => r.status === 'fulfilled' ? r.value : [])
      setBidGeneralData(data[0])
      setBidRateData(data[1]);
      setBidLocationData(data[2]);
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    }).finally(() => {
      setBidGeneralLoading(false);
      setBidRateLoading(false);
      setBidLocationLoading(false);
    });
  };

  const controls = ControlFactory.createControls({
    controls: [
      {
        id: 1, name: 'bidderTermDateRange', type: 'datepickerAsTime',
        label: messageBundle['ih.bidderTermDateRange'],
        rules: [{ required: true, message: message['ih.pleaseInputDateRange'] }]
      },
      {
        id: 2, name: 'fullRangeOnly', type: 'singleCheckbox',
        disabled: fullRangeOnlyDisabled,
        label: messageBundle['ih.fullRangeOnly'],
        tooltip: messageBundle['ih.tooltip.fullRangeOnly'],
      },
      {
        id: 3, name: 'postedEndDateTime', type: 'singleDatePicker', format: 'MM/DD/YYYY',
        label: messageBundle['ih.postedEndDateTime']
      },
      /*{
        id: 4, name: 'latestPostOnly', type: 'singleCheckbox',
        label: messageBundle['ih.latestPostOnly'],
        tooltip: messageBundle['ih.tooltip.latestPostOnly'],
      },
      {
        id: 5, name: 'bidReceivedAfter', type: 'singleDateTimePicker', format: 'MM/DD/YYYY HH:mm',
        label: messageBundle['ih.bidReceivedAfter']
      },*/
      {
        id: 6, name: 'highestRateOnly', type: 'singleCheckbox',
        tooltip: messageBundle['ih.tooltip.highestRateOnly'],
        label: messageBundle['ih.highestRateOnly'],
      },
      {
        id: 7, name: 'rfqOffrNbr', type: 'string',
        label: messageBundle['ih.rfqOffrNbr']
      },
      {
        id: 8, name: 'bidNumber', type: 'string',
        label: messageBundle['ih.bidNumber']
      },
    ],
    initialValues: {
      'bidderTermDateRange': [datePeriod.startDate, datePeriod.endDate],
      'highestRateOnly': false,
      'fullRangeOnly': false,
      //'latestPostOnly': false,
    },
    onValuesChange: (changedValues, allValues) => {
      if (!T.isUndefined(changedValues['bidderTermDateRange'])) {
        setFullRangeOnlyDisabled(
          T.isEmpty(changedValues['bidderTermDateRange']) ||
          T.isEmpty(changedValues['bidderTermDateRange'][0]) ||
          T.isEmpty(changedValues['bidderTermDateRange'][1])
        )
      }
    },
    settings: { colSpan: 2, },
    formRef,
    reset,
    submit: queryAll
  });

  const [isResultFiltered, setResultFiltered] = useState(false);

  const filterResults = (row, isFilter) => {
    const filterFunction = r => {
      if (!isFilter) {
        r.isHidden = false
      }
      else {
        r.isHidden = !(r.rfqOffrNbr === row.rfqOffrNbr && r.tsp === row.tsp);
      }
      return r;
    }

    setBidGeneralLoading(true);
    setBidRateLoading(true);
    setBidLocationLoading(true);

    let generalData = bidGeneralData?.map(filterFunction);
    let rateData = bidRateData?.map(filterFunction);
    let locationData = bidLocationData?.map(filterFunction);

    setBidGeneralData(generalData);
    setBidRateData(rateData);
    setBidLocationData(locationData);

    setResultFiltered(isFilter);
    forceUpdate();

    setBidGeneralLoading(false);
    setBidRateLoading(false);
    setBidLocationLoading(false);
  }

  const menuItems = configs.focusOfferRows ? [
    'separator',
    {
      name: messageBundle['ih.focusOfferRows.on'],
      disabled: isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, true)
      }
    }, {
      name: messageBundle['ih.focusOfferRows.off'],
      disabled: !isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, false)
      }
    },
  ] : null;

  const gridOptions = { height: 600, contextMenuItems: menuItems, autoResizeHeight: true };
  const bidGeneralGrid = <InfoGrid id="AllBid_General" key={_ + 1} columnDefs={bidGeneralColumnDefs} gridOptions={gridOptions} data={bidGeneralData} ></InfoGrid>;
  const bidRateGrid = <InfoGrid id="AllBid_General" key={_ + 2} columnDefs={bidRateColumnDefs} gridOptions={gridOptions} data={bidRateData} ></InfoGrid>;
  const bidLocationGrid = <InfoGrid id="AllBid_General" key={_ + 3} columnDefs={bidLocationColumnDefs} gridOptions={gridOptions} data={bidLocationData} ></InfoGrid>;

  return (
    <div className='gadget full-width'>
      <BackTop />
      <div className='gadget-container'>
        <div className="gadget-header">
          <div className="gadget-title"> {messageBundle['ih.widget.capacityRelease.allBid']}</div>
        </div>
        <div className='gadget-controls'>
          {controls}
        </div>
        <div className="gadget-item-content" style={{padding: '0px', marginLeft: '-6px', marginRight: '-6px'}}>
          <Collapse ghost={true}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />} defaultActiveKey={['1', '2', '3']}>
            <Panel header={messageBundle['ih.bid.general']} key='1'>
              <Skeleton active loading={bidGeneralLoading}>
                {bidGeneralGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.bid.location']} key='2'>
              <Skeleton active loading={bidLocationLoading}>
                {bidLocationGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.bid.rate']} key='3'>
              <Skeleton active loading={bidRateLoading}>
                {bidRateGrid}
              </Skeleton>
            </Panel>
          </Collapse >
        </div>
      </div>
    </div >
  );

};

export default AllBid;
