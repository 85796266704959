import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { LocationApi } from 'api/v1/index'

import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { processColumns } from './AllLocations';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import SharedColDefs from 'asset/SharedColDefs';
import { Spin } from 'antd';

/**
 * 
 * Based on the caller change the controls, gadget title.
 * 
 * @param {*} props 
 * @returns 
 */
const LocationDetail = (props) => {
  const { configs } = useSelector((state) => state.info);

  const getMultiSelectPostValue = value => {
    return T.isNil(value) ? [] : Array.isArray(value) ? value : [value];
  };

  const location = useLocation();
  const { message } = useSelector((state) => state.message);

  const [locStAbbrevOptions, setLocStAbbrevOptions] = useState([]);
  const [locTypeOptions, setLocTypeOptions] = useState([]);

  const [fetchingLocStAbbrevOptions, setFetchingLocStAbbrevOptions] = useState(false);
  const [fetchingLocTypeOptions, setFetchingLocTypeOptions] = useState(false);

  const transformedLocFilters = {
    inactiveActiveOptions: LocationApi.transform(configs.locationDownloadOptions?.inactiveActiveOptions),
    directionOfFlowOptions: LocationApi.transform(configs.locationDownloadOptions?.directionOfFlowOptions),
    categoryOptions: LocationApi.transform(configs.locationDownloadOptions?.categoryOptions)
  };

  const getValuesFromOptions = (options) => {
    return options.map(i => i.id);
  };

  const [postData, _setPostData] = useState({
    'activeInactiveFilters': getValuesFromOptions(transformedLocFilters.inactiveActiveOptions),
    'categoryFilters': getMultiSelectPostValue(location.state?.status),
    'stateFilters': getMultiSelectPostValue(location.state?.state),
    'locTypeFilters': getMultiSelectPostValue(location.state?.type),
    'directionOfFlowFilters': getMultiSelectPostValue(location.state?.direction),
  });

  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData('api/v1/locations/getLocationsForDetail', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  };
  const caller = location.state?.caller;
  const setPostData = (e) => {
    console.log('setPostData, ', e);
    _setPostData({
      'activeInactiveFilters': getMultiSelectPostValue(e.active),
      'categoryFilters': getMultiSelectPostValue(e.status),
      'stateFilters': getMultiSelectPostValue(e.state),
      'locTypeFilters': getMultiSelectPostValue(e.type),
      'directionOfFlowFilters': getMultiSelectPostValue(e.direction),
    });
  };

  let title = '';
  let controls = [];

  let detailNameMap = [...SharedColDefs.LocationDownload.All];

  const onFocusLocStAbbrevSelect = () => {
    if (!T.isEmpty(locStAbbrevOptions)) {
      return;
    }
    LocationApi.fetchLocStAbbrevOptions(setFetchingLocStAbbrevOptions, setLocStAbbrevOptions);
  };

  const onFocusLocTypeSelect = () => {
    if (!T.isEmpty(locTypeOptions)) {
      return;
    }
    LocationApi.fetchLocTypeOptions(setFetchingLocTypeOptions, setLocTypeOptions);
  };

  switch (caller) {
    case 'active':
      title = message['ih.widget.locations.activeInactive'];
      controls = ControlFactory.createControls({
        controls: [{
          id: 1, name: 'active', type: 'checkbox', label: message['ih.status'],
          values: transformedLocFilters.inactiveActiveOptions,
        }],
        initialValues: { 'active': location.state['active'] },
        submit: setPostData
      });
      break;
    case 'state':
      title = message['ih.widget.locations.state'];
      controls = ControlFactory.createControls({
        controls: [{
          id: 2, name: 'active', type: 'checkbox', label: message['ih.status'],
          values: transformedLocFilters.inactiveActiveOptions,
        }, {
          id: 3, name: 'state', type: 'multiselect', label: message['ih.state'],
          values: null, otherProps: {
            showSearch: true,
            options: locStAbbrevOptions,
            onFocus: onFocusLocStAbbrevSelect,
            notFoundContent: fetchingLocStAbbrevOptions ? <Spin size='small' /> : null
          }
        }],
        initialValues: {
          'active': getValuesFromOptions(transformedLocFilters.inactiveActiveOptions),
          'state': location.state['state']
        },
        submit: setPostData
      });
      detailNameMap = [
        'locStAbbrev',
        'locStatInd',
        'locName',
        'pipelineName',
        'effDate',
        'inactDate',
        'updateDate',
        'dirFlo',
        'locTypeInd',
        'pipelineCode',
        'pipelinePropName',
        'pipelineFercId',
        'locCnty',
        'loc',
        'locLoctr',
        'locLoctrId',
        'locZone',
        'locFuelArea',
        'upDnName',
        'upDnId',
        'upDnIdProp',
        'upDnFercId',
        'upDnFercIdInd',
        'upDnInd',
        'upDnLoc',
        'upDnLocName',
        'comments',
      ];
      break;
    case 'recent':
      title = message['ih.widget.locations.recent'];
      controls = ControlFactory.createControls({
        controls: [{
          id: 4, name: 'status', type: 'checkbox', label: message['ih.status'],
          values: transformedLocFilters.categoryOptions,
        },],
        initialValues: { 'status': location.state['status'] },
        submit: setPostData
      });
      break;
    case 'type':
      title = message['ih.widget.locations.locationType'];
      controls = ControlFactory.createControls({
        controls: [{
          id: 5, name: 'active', type: 'checkbox', label: message['ih.status'],
          values: transformedLocFilters.inactiveActiveOptions,
        }, {
          id: 6, name: 'type', type: 'multiselect', label: message['ih.type'],
          values: null, otherProps: {
            showSearch: true,
            options: locTypeOptions,
            onFocus: onFocusLocTypeSelect,
            notFoundContent: fetchingLocTypeOptions ? <Spin size='small' /> : null
          }
        }],
        initialValues: {
          'active': getValuesFromOptions(transformedLocFilters.inactiveActiveOptions),
          'type': location.state['type']
        },
        submit: setPostData
      });
      detailNameMap = [
        'locTypeInd',
        'locStatInd',
        'locName',
        'pipelineName',
        'effDate',
        'inactDate',
        'updateDate',
        'dirFlo',
        'pipelineCode',
        'pipelinePropName',
        'pipelineFercId',
        'locStAbbrev',
        'locCnty',
        'loc',
        'locLoctr',
        'locLoctrId',
        'locZone',
        'locFuelArea',
        'upDnName',
        'upDnId',
        'upDnIdProp',
        'upDnFercId',
        'upDnFercIdInd',
        'upDnInd',
        'upDnLoc',
        'upDnLocName',
        'comments',
      ];
      break;
    case 'direction':
      title = message['ih.widget.locations.direction'];
      controls = ControlFactory.createControls({
        controls: [{
          id: 7, name: 'direction', type: 'checkbox', label: message['ih.direction'],
          values: transformedLocFilters.directionOfFlowOptions,
        }, {
          id: 8, name: 'active', type: 'checkbox', label: message['ih.status'],
          values: transformedLocFilters.inactiveActiveOptions,
        },],
        initialValues: {
          'active': getValuesFromOptions(transformedLocFilters.inactiveActiveOptions),
          'direction': location.state['direction']
        },
        submit: setPostData
      });
      detailNameMap = [
        'dirFlo',
        'locStatInd',
        'locName',
        'pipelineName',
        'effDate',
        'inactDate',
        'updateDate',
        'locTypeInd',
        'pipelineCode',
        'pipelinePropName',
        'pipelineFercId',
        'locStAbbrev',
        'locCnty',
        'loc',
        'locLoctr',
        'locLoctrId',
        'locZone',
        'locFuelArea',
        'upDnName',
        'upDnId',
        'upDnIdProp',
        'upDnFercId',
        'upDnFercIdInd',
        'upDnInd',
        'upDnLoc',
        'upDnLocName',
        'comments',
      ];
      break;
    default:
      title = 'Title'
  }

  const processCols = (caller, cols) => {
    let groupColumns = [];
    switch (caller) {
      case 'state':
        groupColumns = ['locStAbbrev', 'locStatInd'];
        break;
      case 'type':
        groupColumns = ['locTypeInd', 'locStatInd'];
        break;
      case 'direction':
        groupColumns = ['dirFlo', 'locStatInd'];
        break;
      default:
        break;
    }

    groupColumns.forEach((name, index) => {
      let groupColumn = T.find(cols, (c) => c.field === name);
      groupColumn.rowGroup = groupColumn.hide = true;
    });
    return cols;
  };

  let columnDefs = processCols(caller, getNameModelMap(detailNameMap));

  const gadget = {
    loading: loading,
    refresh,
    //id: 'Gadget1',
    //dataSourceId: 1,
    title: title,
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="LocationDetail" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid>
    },
    loadTimestamp: loadTimestamp,
    controls: controls,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget} {...props}> </Gadget>
  );
};

export default LocationDetail;
