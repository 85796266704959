import React from "react";
import {Button, Modal, Spin} from "antd";
import './NoticeDownloadAttachmentsRenderer.css';
import {useDispatch} from "react-redux";
import {setPageErrorAlert, setPageSuccessAlert} from "../../redux/reducer/info";
import {AdminApi} from 'api/v1/index';
import { ApiResult } from '@trellisenergy/common-ui-core';

const TriggerScheduledTaskButtonRenderer = (props) => {
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(true);
    const dispatch = useDispatch();

    const runTask = async () => {
        let callResponse = await AdminApi.triggerScheduledTask(props?.data?.taskId);
        setVisible(false);
        setConfirmLoading(false);

        if (ApiResult.isSuccess(callResponse)) {
            dispatch(setPageSuccessAlert({show: true, message: props?.data?.taskName + " ran successfully."}));
            // query data again after successful run
            props?.refresh?.();
        } else {
            dispatch(setPageErrorAlert({show: true, message: ApiResult.getMessages(callResponse)}));
        }
    };

    const showModal = () => {
        setVisible(true);
        setConfirmLoading(true);
        // for fast tasks, prevent modal from closing too fast
        // for longer running tasks adding 1 sec doesn't really matter
        setTimeout(() => {
            runTask();
        }, 1000);
    };

    return (
        <>
            <Button type="primary" size={'small'} onClick={showModal}>
                Run
            </Button>
            <Modal
                title={'Running ' + props?.data?.taskName}
                visible={visible}
                confirmLoading={confirmLoading}
                maskClosable={false}
                closable={false}
                footer={null}
            >
                <div style={{textAlign: 'center'}}>
                    <Spin size="large"/>
                </div>
            </Modal>
        </>
    );
};

export default TriggerScheduledTaskButtonRenderer;
