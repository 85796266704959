import React from 'react'
import Highlighter from "react-highlight-words";
import './NoticeDetails.css';

const NoticeDetails = (props) => {
    const {searchWords, noticeTextContent} = props;

    const getMaxColumns = (data) => {
        let max = 1
        data.forEach((row) => {
            if (max < row.length) max = row.length;
        })
        return max
    }

    function createNoticeColumns(col, index) {
        if (col === "Priority % Restricted") {
            return <td colSpan="7" className="noticeTableCol" key={index}>{col}</td>;
        } else if (col === "IT") {
            return <>
                <td colSpan="2"/>
                <td className="noticeTableCol" key={index}>{col}</td>
            </>;
        }
        return <td className="noticeTableCol" key={index}>{col}</td>;
    }

    function createNoticeRow(row, index, maxColumns) {
        let numberOfColumns = row.length
        if (numberOfColumns === 1) {
            return <tr className="noticeFullRow" key={index}>
                <td colSpan={maxColumns}>{row[0]}</td>
            </tr>;
        }
        const noticeColumns = row.map((col, index) => createNoticeColumns(col, index))

        return <tr className="noticeTableRow" key={index}>{noticeColumns}</tr>;
    }

    function parseBlock(blockText, index) {
        let noticeArray = [[""]];
        if (blockText && blockText.startsWith('[')) noticeArray = eval(blockText);
        const maxColumns = getMaxColumns(noticeArray)

        if (maxColumns <= 1) {
            let newBlockText=blockText.replaceAll(' amp ', ' & ');
            return (
                <Highlighter
                    searchWords={searchWords}
                    textToHighlight={newBlockText}/>
            )
        } else {
            const noticeRows = noticeArray.map((row, index) => createNoticeRow(row, index, maxColumns))
            return <table key={index}>{noticeRows}</table>
        }
    }

    function getAllBlocks(allText) {
        let contentArray = [];
        if (allText) {
            if (allText.indexOf("[[") === -1 || allText.startsWith('[')) {
                contentArray.push(allText);
                return contentArray;
            }

            let plainText = true;
            let blocks = allText.split("[[");
            blocks.forEach(function(block) {
                if (plainText) {
                    contentArray.push(block);
                    plainText = !plainText
                } else {
                    let parts = block.split("]]");
                    contentArray.push("[[" + parts[0] + "]]");
                    if (parts.length > 1) {
                        contentArray.push(parts[1])
                    }
                    plainText = false;
                }
            });
        }
        return contentArray;
    }

    const allBlocks = getAllBlocks(noticeTextContent)
    const allContent = allBlocks.map((row, index) => parseBlock(row, index))
    return <>{allContent}</>;
}

export default NoticeDetails