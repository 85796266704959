import React, {useState} from "react";
import {Col, Popover, Row, Skeleton, Tooltip} from "antd";
import Highlighter from "react-highlight-words";
import { EyeOutlined, CloseOutlined, DownloadOutlined} from '@ant-design/icons';
import {TUtil} from "@trellisenergy/common-ui-core";
import {BASEURL_DEV} from "../../config";

const LocationCommentDownloadLinkRenderer  = (props) =>{

    let [loading] = useState(false);
    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });
    let noticeKeywords = props?.data?.noticeKeywords || [];

    if (!props?.value ) {
        return null;
    }
    let handleHoverChange = (visible) => {
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    };
    let viewTextUrl = 'api/v1/locations/downloadCommentText?locationId=' + props?.data?.id;
    let fullViewTextUrl = TUtil.isDevEnv() ? BASEURL_DEV + viewTextUrl : TUtil.buildURL(viewTextUrl);
    let viewTextLink = <a href={fullViewTextUrl} target={"_blank"} rel="noreferrer">{<DownloadOutlined />}</a>;

    let handleClickChange = visible => {
        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };
    let hide = () => {
        setPopoverState({
            hovered: false,
            clicked: false
        });
    };
    let content = <Skeleton active loading={loading}>
          <pre>
            <Highlighter
                searchWords={noticeKeywords}
                textToHighlight={props?.value}/>
          </pre>
    </Skeleton>;

    let title = <>
        <Row>
            <Col span={20}>Location Comment</Col>
            <Col span={4} style={{textAlign: 'right'}}>
                <a onClick={hide}><CloseOutlined/></a>
            </Col>
        </Row>
    </>;

    return <>
        <div className="flex-center-justify-left">
            <Popover mouseEnterDelay={.5} trigger="hover"
                     visible={popoverState.hovered}
                     overlayStyle={{
                         width: "50vw"
                     }}
                     onVisibleChange={handleHoverChange} content={content} title={title}>
                <Popover mouseEnterDelay={.5} trigger="click"
                         visible={popoverState.clicked}
                         overlayStyle={{
                             width: "50vw"
                         }}
                         onVisibleChange={handleClickChange} content={content} title={title}>
                    <EyeOutlined style={{fontSize: '16px'}}/>
                </Popover>
            </Popover>&nbsp;&nbsp;<Tooltip title="Click to download the notice text.">{viewTextLink}</Tooltip>
        </div>
    </>;
};

export default LocationCommentDownloadLinkRenderer;

