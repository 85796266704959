import { Route } from 'react-router-dom';
import {
    GadgetShortcut,
    AllOffer,
    AllAward,
    AllBid,
    AllWithdrawal,
    AllPreApprovedBidder,

} from 'gadget';

import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from "react-redux";

function CapacityRelease() {
    const { message } = useSelector((state) => state.message);
    const path = '/capacityRelease';

    return (
        <div>
            <Route exact path={T.buildURL(path + '')}>
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllOffer'), title: message['ih.widget.capacityRelease.allOffer'], level: 1 }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllBid'), title: message['ih.widget.capacityRelease.allBid'], level: 1 }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllAward'), title: message['ih.widget.capacityRelease.allAward'], level: 1 }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllWithdrawal'), title: message['ih.widget.capacityRelease.allWithdrawal'], level: 1 }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllPreApprovedBidder'), title: message['ih.widget.capacityRelease.allApprovedBidder'], level: 1 }} />
            </Route >
            <Route path={T.buildURL(path + '/AllOffer')}>
                <AllOffer />
            </Route>
            <Route path={T.buildURL(path + '/AllAward')}>
                <AllAward />
            </Route>
            <Route path={T.buildURL(path + '/AllBid')}>
                <AllBid />
            </Route>
            <Route path={T.buildURL(path + '/AllWithdrawal')}>
                <AllWithdrawal />
            </Route>
            <Route path={T.buildURL(path + '/AllPreApprovedBidder')}>
                <AllPreApprovedBidder />
            </Route>

        </div >
    );
}

export default CapacityRelease;