import React, { useRef, useState } from 'react';
import { InfoGrid } from 'infogrid'
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { ImbalancesApi } from 'api/v1/index'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

function ImbalanceHistoryDetail(props) {
  const location = useLocation();
  const gadgetId = 401;
  const { configs, searchDateSettings } = useSelector((state) => state.info);
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDatePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const { message } = useSelector((state) => state.message);
  const [postData, setPostData] = useState({
    'startDate': location.state?.startMonth ? location.state?.startMonth : T.getLastDaysDateStr(configs?.dateRangeFilterMap.POSTED_IMBALANCE_ALL_PC, 'MM/YYYY'),
    'endDate': location.state?.endMonth ? location.state?.endMonth : T.getTodayDateStr('MM/YYYY'),
    'forUser': !!location.state?.forUser,
    'limitResultsToMostRecentImbPeriods': !!location.state?.limitResultsToMostRecentImbPeriods
  });

  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData(ImbalancesApi.getImbalancesUrl, postData, refreshCount);
  const queryForm = useRef();

  const refresh = () => {
    setRefresh(Math.random())
  };

  const getInitialDateRange = () => {
    return [
      location.state?.startMonth ? T.getDate(location.state?.startMonth, 'MM/YYYY') : T.getLastDaysDate(configs?.dateRangeFilterMap.POSTED_IMBALANCE_ALL_PC),
      location.state?.endMonth ? T.getDate(location.state?.endMonth, 'MM/YYYY') : T.getTodayDate()
    ];
  };

  let nameMap = [
    'imbPeriod',
    'imbDirDesc',
    'pipelineName',
    // 'rateSchedule',
    'svcReqName',
    'ctrctHolderName',
    'svcReqAcct',
    'svcReqCtrct',
    'oia',
    'imbType',
    'postedImbQty',
    'dupRow'
    // 'svcReqContact',
    // 'svcReqPhone',
    // 'pipelineCode',
    // 'pipelinePropName',
    // 'stmtDt',
    // 'svcReq',
    // 'svcReqProp',
    // 'ctrctHolder',
    // 'ctrctHolderPropCode',
    // 'imbDirInd',
    // 'prodMonth',
    // 'imbTypeCode'
  ];

  const onGridReady = (params) => {
    var defaultSortModel = [
      { colId: 'pipelineName', sort: 'asc', sortIndex: 3 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });
  };

  let columnDefs = getNameModelMap(nameMap);

  const processColumns = (cols) => {

    let groupingCols = [
      'imbPeriod',
      'imbDirDesc',
      'pipelineName'
    ].map(field => {
      return T.find(cols, (c) => c.field === field)
    });

    groupingCols.forEach((colModel) => {
      if (colModel) {
        colModel.rowGroup = true;
        colModel.hide = true; // so it wouldn't show same column again when grouped
        if (colModel.field === 'imbPeriod') {
          colModel.rowGroupIndex = 0;
        } else if (colModel.field === 'imbDirDesc') {
          colModel.rowGroupIndex = 1;
        } else if (colModel.field === 'pipelineName') {
          colModel.rowGroupIndex = 2;
        }
      }
    });


    let sortingCols = [
      'imbPeriod',
      'imbDirDesc',
    ].map(field => {
      return T.find(cols, (c) => c.field === field)
    });
    sortingCols.forEach((colModel, index) => {
      if (colModel) {
        colModel.sort = 'asc';
        colModel.sortIndex = index;
      }
    });

    let imbQtyColumn = T.find(cols, (c) => c.field === 'postedImbQty');
    imbQtyColumn.aggFunc = 'sum';

    return cols;
  };

  const controls = ControlFactory.createControls({
    controls: [{
      style: { width: 200 },
      id: 1, name: 'forUser', type: 'select', label: message['ih.imbalances'],
      values: [{ label: message['ih.myImbal'], value: true }, { label: message['ih.allImbal'], value: false }]
    }, { id: 1, name: 'dateRange', type: 'datepicker', label: message['ih.postedDateRange'], format: 'MM/YYYY', picker: 'month' }],
    initialValues: {
      'forUser': !!location.state?.forUser,
      'dateRange': [datePeriod.startDate, datePeriod.endDate]
    },
    submit: setPostData,
    formRef: queryForm
  });

  const gadgetItem = { grid: <InfoGrid id="ImbalanceHistoryDetail" gridOptions={{ events: { onGridReady: onGridReady } }} columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> }
  const gadget = {
    loading: loading,
    refresh,
    id: 'Gadget1',
    title: message['ih.widget.imbalance.imbalanceHistoryDetail'],
    views: ['grid'], // grid
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget} />
  );
}

export default ImbalanceHistoryDetail;
