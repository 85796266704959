import { ApiRequest } from '@trellisenergy/common-ui-core';

export default class CapacityApi {
    static getCapacityComment(params) {
        const request = ApiRequest.composeGetRequest('api/v1/capacity/getCapacityComment', params);
        return fetch(request)
            .then(response => response.text())
            .then(response => {
                return response;
            })
            .catch(error => {
                return null;
            });
    }
}