import React, { useRef, useState } from 'react';
import Gadget from 'infogadget'
import { PieChart } from 'infochart'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';

const UnsubbedCap = (props) => {
    const { configs } = useSelector((state) => state.info);

    const { message } = useSelector((state) => state.message)
    const [postData] = useState({});
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getUnsubbedCapForGasDay', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    const history = useHistory();
    const chartRef = useRef();

    const getChartData = () => {
        return [
            { type: message['ih.widget.capacity.unsubbedCap'], value: output.unsubbedCap },
            { type: message['ih.widget.capacity.subbedCap'], value: output.subbedCap },
        ]
    }

    /*const annotations = [
        {
            type: "html",
            position: ["100%", "1%"],
            html: output && output.total ? "Total: " + output.total : null
        }
    ]*/

    const gadgetItem = {
        topDisplayItems: [<div className='gadget-item-top'>{output && output.total ? "Total: " + output.total.toLocaleString() : null}</div>],
        chart: <PieChart customChartConfig={{
            label: {
                type: 'outer',
                content: '{name} {percentage}',
            }, /*annotations*/
        }} chartRef={chartRef} data={getChartData()} />
    }
    const gadget = {
        loading, loadTimestamp, gadgetItem, refresh,
        //id: 'Gadget3',
        views: ['chart'],
        title: message['ih.widget.capacity.unsubbedCap.title'] + ' ' + T.getTodayDateStr(),
        width: 50

        //dataSourceId: 3,
    };

    T.registerChartListener(chartRef, (event) => {
        history.push(T.buildURL('/capacity/UnsubbedCapDetail'), {
            startDate: T.getTodayDateStr(),
            endDate: T.getTodayDateStr(),
            title: message['ih.widget.capacity.unsubbedCap.title'] + ' ' + T.getTodayDateStr(),
        });
    });


    return (
        <Gadget {...gadget} {...props}></Gadget>
    )
};

export default UnsubbedCap;