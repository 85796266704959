import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { DatePicker } from 'antd';
import './DatePickerAutoAccept.css'


const { RangePicker } = DatePicker;

/**
 * Workaround to bypass requirement for user to press Ok to save picked date and time
 * Monitor https://github.com/ant-design/ant-design/issues/21189
 */
export const RangeDatePickerAutoAccept = (props) => {
  const rangePickerRef = useRef();

  const onOpenChange = (open) => {
    if (!open) {
      let rangePickerDomNode = ReactDOM.findDOMNode(rangePickerRef.current);
      let [startInput, endInput] = rangePickerDomNode.querySelectorAll('.ant-picker-input input');
      let startValueStr = startInput.value;
      let endValueStr = endInput.value;
      let startValue = moment(startValueStr, props.format);
      let endValue = moment(endValueStr, props.format);

      if (endValue.isBefore(startValue)) {
        let startValueClone = startValue.clone();
        startValue = endValue;
        endValue = startValueClone;
      }

      if (!startValue.isValid()) {
        startValue = null;
      }

      if (!endValue.isValid()) {
        endValue = null;
      }

      if (props.onChange) {
        props.onChange([startValue, endValue]);
      }
    }
  };

  return <RangePicker {...props} ref={rangePickerRef} onOpenChange={onOpenChange} />;
};

export const DatePickerAutoAccept = (props) => {
  const datePickerRef = useRef();

  const onOpenChange = (open) => {
    if (!open) {
      let rangePickerDomNode = ReactDOM.findDOMNode(datePickerRef.current);
      let [startInput] = rangePickerDomNode.querySelectorAll('.ant-picker-input input');
      let startValueStr = startInput.value;
      let startValue = moment(startValueStr, props.format);

      if (!startValue.isValid()) {
        startValue = null;
      }

      if (props.onChange) {
        props.onChange(startValue);
      }
    }
  };

  return <DatePicker {...props} ref={datePickerRef} onOpenChange={onOpenChange} />;
};