import { Link } from 'react-router-dom'
import FaIcon from '../../component/icon/FaIcon'
import Tooltip from '../../component/tooltip/Tooltip'
//import { Tooltip } from 'antd'
import './GadgetShortcut.css'
import { useHistory } from 'react-router-dom'

const LinkIcon = ({ level, link, title }) => {
  return (level > 0 ?
    <div className='gadget-action-button'>
      <Tooltip title={'View ' + title}>
        <Link to={link}>
          <FaIcon name="angle-double-right" />
        </Link>
      </Tooltip>
    </div> : '')
};

const GadgetShortcut = (props) => {
  const history = useHistory();

  const onGadgetTitleClick = (e) => {
    // only register click on parent
    if (e.target === e.currentTarget) {
      if (props.drilldown && props.drilldown.link) {
        history.push(props.drilldown.link);
      }
    }
  };
  return (
    <div className="gadget half-width">
      <div className='gadget-container'>
        <div onClick={onGadgetTitleClick} className='gadget-shortcut'>
          {props.drilldown.title}
          <LinkIcon {...props.drilldown} />
        </div>
      </div>
    </div>
  )
}

export default GadgetShortcut;