import { Bar } from '@ant-design/charts';
import ChartConfig from './config';
import { MAX_ITEM_COUNT } from './config';


const BarChart = (props) => {
  var data = props.data || [];
  var customChartConfig = props.customChartConfig || {};
  var config = {
    chartRef: props.chartRef,
    data: data,
    xField: 'value',
    yField: 'type',
    seriesField: 'type',
    legend: { position: 'top-left' },
    ...ChartConfig,
    ...customChartConfig
  };

  if (data.length <= MAX_ITEM_COUNT) {
    delete config['scrollbar']
  } else if (config['scrollbar']) {
    config['scrollbar']['style'] = {
      trackColor: '#f0f3fa',
    };
  }
  return <Bar {...config} />;
};

export default BarChart;