import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';
import { Config } from '@trellisenergy/common-ui-core';
const { CONTEXT } = Config;

const PageNotFound = () => {
  let history = useHistory();
  return (
    < Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        < Button type="primary" onClick={() => history.push(CONTEXT)}>
          Back Home
      </Button >
      }
    />)
}

export default PageNotFound;
