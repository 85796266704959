import React, { useState } from 'react';
import { InfoGrid } from "infogrid";
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'

const CacheDetail = (props) => {

  const { message } = useSelector((state) => state.message)
  const { cacheStats } = useSelector((state) => state.cache);
  const [isChecked, setChecked] = useState(false);

  const [postData, setPostData] = useState({cacheName: cacheStats && cacheStats.length ? cacheStats[0].cacheName : "GENERAL_SHORT_ARRAYLIST_OBJECT" });
  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData('api/v1/cache/getCacheDetail', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  }

  let nameMap = [
    'cacheKey',
    'cacheKeyTypes',
    'cacheValue',
  ];

  let columnDefs = getNameModelMap(nameMap);

  const getCacheNames = () => {
    let ret = [];
    if (T.isArray(cacheStats))
      ret = cacheStats.map(d => {
        return {
          label: d.cacheName,
          value: d.cacheName
        }
      });
    return ret;
  }
  const cacheNames = getCacheNames();

  const controls = ControlFactory.createControls({
    controls: [{
      id: 1, name: 'cacheName', type: 'select', label: 'Cache Name',
      values: getCacheNames()
    }, {
      id: 3, name: 'includeCacheValue', type: 'checkbox', label: 'Show Value',
      values: [{ value: 'true', label: '' }],
      onClick: function (elm) {
        setChecked(elm?.target.checked);
      }
    }, {
      id: 'maxCacheValueChar', name: 'maxCacheValueChar', type: 'integer', label: 'Max Value length', /*required: true,*/
      max: 100000000, style: { width: '30%' },
      placeholder: 'Unspecified Value means no limit', disabled: !isChecked
    },],
    initialValues: { 'cacheName': !T.isEmpty(cacheNames) ? cacheNames[0].label : null },
    submit: setPostData
  })

  const gadgetItem = {
    grid: <InfoGrid id="CacheDetail" columnDefs={columnDefs} data={output}></InfoGrid>
  }
  const gadget = {
    loading,
    refresh,
    //id: 'Gadget1',
    title: message['ih.widget.cache.detail'],
    views: ['grid'], // grid, chart
    gadgetItem,
    loadTimestamp,
    controls,
    //dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget}></Gadget>
  );
}

export default CacheDetail;
