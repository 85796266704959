import { Route } from 'react-router-dom';
import {
  GadgetShortcut,
  NoticesSummary, AllNotices,
  NoticesKeywordSearch,
  NoticesTop,
  NoticesFullTextKeywordSearch
} from 'gadget';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from 'react-redux';

function Notices() {
  const { message } = useSelector((state) => state.message)
  const path = '/notices';

  return (
    <div>
      <Route exact path={T.buildURL(path)}>
        <NoticesFullTextKeywordSearch />
        {/*
        <NoticesSummary drilldown={{ link: T.buildURL(path + '/AllNotices'), level: 1, state: { caller: 'summary' } }} />
        <NoticesTop drilldown={{ link: T.buildURL(path + '/NoticesKeywordSearch'), level: 1, state: { caller: 'top' } }} />
        <GadgetShortcut drilldown={{ link: T.buildURL(path + '/NoticesKeywordSearch'), title: 'Notices Keyword Search', level: 1 }} />
        <GadgetShortcut drilldown={{ link: T.buildURL(path + '/NoticesKeywordGlobalSearch'), title: 'Notices Global Search', level: 1 }} />
        <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllNotices'), title: message['ih.widget.notices.all'], level: 1 }} />
        */}
      </Route >
      <Route exact path={T.buildURL(path + '/NoticesKeywordSearch')}>
        <NoticesKeywordSearch />
      </Route>
      <Route exact path={T.buildURL(path + '/NoticesKeywordGlobalSearch')}>
        <NoticesFullTextKeywordSearch />
      </Route>

      <Route exact path={T.buildURL(path + '/AllNotices')}>
        <AllNotices />
      </Route>
    </div >
  );
}

export default Notices;
