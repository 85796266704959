import React, { useState } from 'react';
import { InfoGrid } from 'infogrid'
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import SharedColDefs from 'asset/SharedColDefs';


const CapNoNoticeServiceDetail = (props) => {
    const { configs } = useSelector((state) => state.info)

    const { message } = useSelector((state) => state.message);

    const [postData, setPostData] = useState({
        'startDate': T.getLastDaysDateHHmmStr(configs?.dateRangeFilterMap.CAP_NO_NOTICE_CAP_PC),
        'endDate': T.getTodayDateTimeHHmmStr(),
    });

    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getAllCapNoNoticeSvc', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    const processColumns = (cols) => {
        let sortColumns = ['pipelineName',
            'effBegGasDtTm',
            'locName'].map(field => {
                return T.find(cols, (c) => c.field === field)
            });

        sortColumns.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    };

    let columnDefs = processColumns(getNameModelMap(SharedColDefs.Capacity.CapNoNotice.All));

    const controls = ControlFactory.createControls({
        controls: [{ id: 1, name: 'dateRange', type: 'dateTimepicker', label: message['ih.widget.effDateRange'] }],
        initialValues: {
            'dateRange': [
                T.getLastDaysDate(configs?.dateRangeFilterMap.CAP_NO_NOTICE_CAP_PC),
                T.getTodayDate()
            ]
        },
        submit: setPostData
    });

    const gadgetItem = { grid: <InfoGrid id="CapNoNoticeServiceDetail" columnDefs={columnDefs} data={output}></InfoGrid> };
    const gadget = {
        loading,
        refresh,
        title: message['ih.widget.capacity.allNoNoticeService'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        width: 100
    };

    return (
        <Gadget {...gadget} />
    );
};

export default CapNoNoticeServiceDetail;
