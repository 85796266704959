import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

/**
 * To do specific tasks
 */
export default class InfoApi {

  static getConfigs(fetchData = {}) {
    const request = ApiRequest.composeGetRequest('api/v1/getConfigs');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get Configs');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getInputLimits(fetchData = {}) {
    const request = ApiRequest.composeGetRequest('api/v1/getInputLimits');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get input limits');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return null;
      });
  }

  static getPublicUrls(fetchData = {}) {
    const request = ApiRequest.composeGetRequest('api/v1/getPublicUrls');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get PublicUrls');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAllMessages(fetchData = {}) {
    const request = ApiRequest.composeGetRequest('api/v1/messages/getAllMessages');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get messages');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static getAccessRights(fetchData = {}) {
    const request = ApiRequest.composeGetRequest('api/v1/auth/getAccessRights');
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        return responseJson;
        //throw ({ code: 401 }); // Add to simulate logged-out mode
      }).catch(error => {
        if (error.code === 401) {
          return null;
        }
        return error;
      });
  }

  static getManagedPipelines(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/managedpipeline/getAllManagedPipelines', null,
      fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        let pipelineData = {};
        responseJson.data.forEach(r => pipelineData[r.id] = {
          id: r.id,
          name: r.description,
          code: r.code,
          active: true
        });
        return pipelineData;
      }).catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

    static getSearchDateSettings(fetchData = {}) {
        const request = ApiRequest.composeGetRequest('api/v1/getSearchDateSettings');
        return fetch(request)
            .then(response => ApiResult.getJson(response))
            .then(responseJson => {
                if (T.isEmpty(responseJson)) {
                    console.warn('Can\'t get search date settings.');
                    return null;
                }
                try {
                  return JSON.parse(responseJson?.data && responseJson?.data.ihWidgetSearch);
                } catch(e) {
                  console.warn(e);
                  return [];
                }
            })
            .catch(error => {
                // ERROR - rethrow
                return Promise.reject(error);
            });
    }
}