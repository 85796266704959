import { InfoGrid } from 'infogrid';
import React, { useRef, useState, useEffect } from 'react';
import { Modal, message, DatePicker, Form, Select, Radio, Space, Button, Skeleton, Input, Row, Col, Popover, Typography, Statistic } from 'antd';
import { AdminApi } from 'api/v1/index';
import useFetchData from 'api/service'
import { TUtil as T, ApiResult, ApiRequest} from '@trellisenergy/common-ui-core';
import { InfoDialog } from 'util/trellisUI'
import Gadget from 'infogadget'
import { useDispatch, useSelector } from 'react-redux'
import { setPageSuccessAlert, setPageErrorAlert } from 'redux/reducer/info';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  getColumnDefs
} from './ManageDataTableColDefs';
import { getNameModelMap } from "../../../asset/GridColumnNameMap";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const ManageData = ({ accountData }) => {
  const authorities = (accountData && accountData.authorities) || [];
  const messageBundle = useSelector((state) => state.message).message;
  const gridRef = useRef(this);
  const dialogRef = useRef(this);
  const isApproveAllowed = authorities.indexOf('APPROVE_INFOHUB_DATA') > -1;
  const isPublishAllowed = authorities.indexOf('PUBLISH_INFOHUB_DATA') > -1;
  const isRejectedAllowed = authorities.indexOf('REJECT_INFOHUB_DATA') > -1;

  const dispatch = useDispatch();

  // todo get ids from redux configs
  const DATASET_LIST_ID_IOC = 1; // IOC
  const DATASET_LIST_ID_CAP_NO_NOTICE_SVC = 30;
  const CAPACITY_OPER_AVAILABLE_CAP = 2;
  const CAPACITY_UNSUBSCRIBED_CAP = 3;
  const POSTED_IMBALANCES = 4;
  const TRANSACTIONAL_REPORTING_FIRM = 5;
  const TRANSACTIONAL_REPORTING_INTERRUPTIBLE = 6;
  const LOCATIONS = 17;
  const NOTICES = [62, 63, 64, 65, 66, 161, 170, 171];

  const [state, setState] = useState({
    dataSetId: 0, datasetList: null,
    action: null,
    isApproveEnabled: false,
    isPublishEnabled: false,
    isRejectEnabled: false,
    datasetListLoading: true,
    datasetStatsLoading: false,
    datasetStatsContent: null
  });
  const [refreshCount] = useState(0);

  const [queryPostData, setQueryPostData] = useState({ startDate: null, endDate: null });
  const [loadInit_getSourceFiles, setLoadInit_getSourceFiles] = useState(false);

  const [loading, queryResultData, queryResultTimestamp]
    = useFetchData('api/v1/admin/getSourceFiles', queryPostData, refreshCount, loadInit_getSourceFiles, setLoadInit_getSourceFiles);

  const [loadInit_getSourceFileData, setLoadInit_getSourceFileData] = useState(false);
  const [sourcePostData, setSourcePostData] = useState({ datasetListId: null, sourceFileId: null });
  const [sourcePostLoading, sourcePostResultData, sourcePostTimestamp]
    = useFetchData('api/v1/admin/getSourceFileData', sourcePostData, refreshCount, loadInit_getSourceFileData, setLoadInit_getSourceFileData);

  function buildParentTable() {
    const gadgetItem = {
      grid: <InfoGrid id="ManageData_Parent"
        columnDefs={getColumnDefs('parent')}
        data={queryResultData} gridRef={gridRef}
        gridOptions={{
          highlightOnClick: true, headerCheckbox: true, height: 450,
          events: { onCellClicked: performSourceQuery }
        }}>
      </InfoGrid >
    }
    const gadget = {
      sourcePostLoading,
      title: messageBundle['ih.sourceFiles'],
      views: ['grid'], // grid
      gadgetItem,
      loadTimestamp: queryResultTimestamp,
      width: 100
    };
    return <Gadget {...gadget} />
  }

  const buildChildTables = () => {
    const height = 450;
    function getGenericChildData() {
      if (sourcePostResultData && sourcePostResultData.items) {
        return sourcePostResultData.items;
      }
      return [];
    }

    function buildGenericChildData(colDefName = 'genericChild') {
      const gadgetItem = {
        grid: <InfoGrid id="ManageData_Child"
          columnDefs={getColumnDefs(colDefName)}
          gridOptions={{
            height: height
          }}
          data={getGenericChildData()}>
        </InfoGrid >
      }
      const gadget = {
        sourcePostLoading,
        title: getQueriedDatasetName(),
        views: ['grid'], // grid
        gadgetItem,
        loadTimestamp: sourcePostTimestamp,
        width: 100
      };
      return <Gadget {...gadget} />
    }

    function buildSpecialChildData() {
      function getGrid(columnDefs, data, title = 'Title') {

        const gadgetItem = {
          grid: <InfoGrid id="ManageData_SpecialChild" columnDefs={columnDefs}
            data={data}
            gridOptions={{
              height: height
            }}>
          </InfoGrid>
        }
        const gadget = {
          sourcePostLoading,
          title: title,
          views: ['grid'], // grid
          gadgetItem,
          loadTimestamp: sourcePostTimestamp,
          width: 100
        };

        return <Gadget {...gadget} />
      }

      let ret = [];
      Object.entries(sourcePostResultData).forEach(([key, value]) => {
        const columnDefs = getColumnDefs(key)
        if (columnDefs) {
          let grid = getGrid(columnDefs, value, messageBundle['ih.' + key])
          ret.push(grid);
        }
      });

      return ret;
    }

    function isSpecialDataSet() {
      return [
        DATASET_LIST_ID_IOC,
        TRANSACTIONAL_REPORTING_FIRM,
        TRANSACTIONAL_REPORTING_INTERRUPTIBLE
      ].some(e => e === sourcePostData.datasetListId);
    }

    if (sourcePostData.datasetListId == null) return null;
    if (!isSpecialDataSet()) {
      //if (sourcePostData.datasetListId !== DATASET_LIST_ID_IOC) { // not IOC
      if (sourcePostData.datasetListId === DATASET_LIST_ID_CAP_NO_NOTICE_SVC) {
        return buildGenericChildData('capNoNoticeSvc');
      } else if (sourcePostData.datasetListId === CAPACITY_OPER_AVAILABLE_CAP) {
        return buildGenericChildData('operAvailCap');
      } else if (sourcePostData.datasetListId === CAPACITY_UNSUBSCRIBED_CAP) {
        return buildGenericChildData('unsubbedCap');
      } else if (sourcePostData.datasetListId === LOCATIONS) {
        return buildGenericChildData('loc');
      } else if (sourcePostData.datasetListId === POSTED_IMBALANCES) {
        return buildGenericChildData('postedImbal');
      } else if (T.includes(NOTICES, sourcePostData.datasetListId)) {
        return buildGenericChildData('notices');
      }

      return buildGenericChildData();
    }
    return buildSpecialChildData();
  }

  const performQuery = (e) => {
    form.validateFields()
      .then((values) => {
        console.log(values)

        // add queryData
        let postData = { ...values };
        postData['startDate'] = values.sourceDateRange ? T.getDateStr(values.sourceDateRange[0], 'MM/DD/YYYY') : null;
        postData['endDate'] = values.sourceDateRange ? T.getDateStr(values.sourceDateRange[1], 'MM/DD/YYYY') : null;
        if (values.action) {
          if (values.action === 'approvedAndUnpublished') {
            postData['approved'] = true;
            postData['unpublished'] = true;
          } else {
            postData[values.action] = true;
          }
          let isApproveEnabled = false;
          let isPublishEnabled = false;
          let isRejectEnabled = false;
          if (values.action === 'unapproved') {
            if (isApproveAllowed) {
              isApproveEnabled = true;
            }
            if (isRejectedAllowed) {
              isRejectEnabled = true;
            }
            // only allow publish on approved
          } else if (isPublishAllowed && (values.action === 'approved' || values.action === 'approvedAndUnpublished')) {
            isPublishEnabled = true;
          }
          setState({
            ...state, isApproveEnabled: isApproveEnabled, isPublishEnabled: isPublishEnabled, isRejectEnabled: isRejectEnabled
          });
        }
        console.log(postData)
        setQueryPostData(postData);
      })
      .catch((errorInfo) => { });
  }

  const performSourceQuery = (e) => {
    const { sourceFileId, datasetListId } = e.node.data;
    if (sourceFileId && datasetListId) {
      setSourcePostData({ sourceFileId, datasetListId })
    }
  }

  const getSelectedSourceData = (action) => {
    const postData = {
      sourceFileIds: [],
    };

    if (gridRef.current) {
      let selectedNodes = gridRef.current.gridApi.getSelectedNodes();
      if (selectedNodes) {
        let selectedData = selectedNodes.map(node => node.data);
        postData.sourceFileIds = selectedData.map(r => r.sourceFileId);
        postData[action] = true;
        if (action === 'publish' || action === 'approve' || action === 'reject') {
          let noteFromForm = form.getFieldValue('note');
          if (!T.isEmpty(noteFromForm)) {
            postData.note = noteFromForm;
          }

          if (action === 'publish') {
            postData.publishDate = T.getDateStr(form.getFieldValue('publishDate'));
          }
        }
      }
    }
    return postData;
  }

  const performApproval = async (e) => {
    const postData = getSelectedSourceData('approve');
    if (T.isEmpty(postData.sourceFileIds)) {
      Modal.warn({
        content: messageBundle['ih.error.minSourceFileSelect']
      });
    } else {
      // show dialog
      setState({ ...state, action: 'approve' })
      dialogRef.current.showModal();
    }
  }

  const performPublish = async (e) => {
    const postData = getSelectedSourceData('publish');
    if (T.isEmpty(postData.sourceFileIds)) {
      Modal.warn({
        content: messageBundle['ih.error.minSourceFileSelect']
      });
    } else if (T.isEmpty(postData.publishDate)) {
      Modal.warn({
        content: messageBundle['ih.error.missingPublishStartDate']
      });
    } else {
      // show dialog
      setState({ ...state, action: 'publish' })
      dialogRef.current.showModal();
    }
  }

  const performReject = async (e) => {
    const postData = getSelectedSourceData('reject');
    if (T.isEmpty(postData.sourceFileIds)) {
      Modal.warn({
        content: messageBundle['ih.error.minSourceFileSelect']
      });
    } else if (T.isEmpty(postData.note)) {
      Modal.warn({
        content: messageBundle['ih.error.missingNotes']
      });
    } else {
      // show dialog
      setState({ ...state, action: 'reject' })
      dialogRef.current.showModal();
    }
  }

  const performAction = () => {
    const hideLoadingMessageFunc = message.loading(messageBundle['ih.pleaseWait'], 0);
    const postData = getSelectedSourceData(state.action);
    AdminApi.updateSourceFiles(postData).then(() =>
      performQuery()
    ).then(() => {
      // reset note field after each successful update
      form.resetFields(['note']);

      dispatch(setPageSuccessAlert({
        show: true,
        message: (state.action === 'publish' ? messageBundle['ih.published'] : state.action === 'approve' ? messageBundle['ih.approved'] : messageBundle['ih.rejected']) + ' successfully'
      }));
      hideLoadingMessageFunc();
    }).catch(e => {
      hideLoadingMessageFunc();
      const msg = ApiResult.getMessages(e);
      if (msg) {
        if (e.resultType === 'ERROR') {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    })
  };

  const [form] = Form.useForm();

  const onLoad = async () => {
    console.log('onLoad');
    const options = await AdminApi.getDatasetOptions();
    setState({
      ...state, datasetList: options, datasetListLoading: false
    })
  };

  const datasetStatsCols = [
    'datasetGroupAndName',
    'unAppCnt',
    'rejectedCnt',
    'appvdUnPublishedCnt',
    'appvdCnt',
    'publishedCnt'
  ];

  const getDatasetStats = async () => {
    let callDatasetStats = () => {
      const request = ApiRequest.composePostJsonRequest('api/v1/dc/getDatasetStatusesStats');
      return fetch(request)
        .then(response => response.json())
        .then(responseText => {
          return responseText;
        }).catch(error => {
          return null;
        });
    };

    setState({
      ...state, datasetStatsLoading: true
    });

    let resp = await callDatasetStats();

    if (resp && resp.data) {
      setState({
        ...state, datasetStatsLoading: false,
        datasetStatsContent: <InfoGrid id="ManageData_Stats" gridOptions={{ height: 400 }} columnDefs={getNameModelMap(datasetStatsCols)} data={resp.data}></InfoGrid>,
      });
    } else {
      setState({
        ...state, datasetStatsLoading: false, datasetStatsContent: ApiResult.getMessages(resp)
      });
    }
  };

  let handleHoverDatasetStatsChange = (visible) => {
    if (visible) {
      getDatasetStats();
    }
  };

  const createSelectOptions = (control, values) => {
    if (T.isNil(values)) return null;
    const ret = values.map(i => <Select.Option value={i.datasetListId}>{i.datasetGrpNm} - {i.datasetNm}</Select.Option>)
    return <Form.Item label={control.label} required>
      <Space>
        <Form.Item noStyle name={control.name}
          rules={[{ required: true, message: 'Please input ' + control.label + ' !' }]}
        >
          <Select style={{ minWidth: 400 }} >
            {ret}
          </Select>
        </Form.Item>

        <Popover overlayClassName={'datasetStatsPopover'} placement='bottom' mouseEnterDelay={.5}
          onVisibleChange={handleHoverDatasetStatsChange}
          trigger="hover"
          content={<Skeleton active loading={state.datasetStatsLoading}>{state.datasetStatsContent}</Skeleton>} title={messageBundle['ih.datasetStatsPopupTitle']}>
          <Typography.Link><InfoCircleOutlined /></Typography.Link>
        </Popover>

      </Space>
    </Form.Item>

  };

  const getQueriedDatasetName = () => {
    const id = queryPostData.datasetListId;
    if (T.isEmpty(id) || T.isEmpty(state.datasetList)) return '';
    const found = state.datasetList.find(i => i.datasetListId === id);
    return found ? `${found.datasetGrpNm} - ${found.datasetNm}` : '';
  }

  const getInfoDialogMessage = () => {
    const name = getQueriedDatasetName();
    const postData = getSelectedSourceData('publish');

    const count = postData && postData.sourceFileIds && postData.sourceFileIds.length;
    // todo find text formatting lib that can use preformatted strings and format like sprintf('%2$s %3$s a %1$s', 'cracker', 'Polly', 'wants')
    return `You are about to ${state.action} ${count} source records for ${name}.  Do you want to proceed?`;
  }

  useEffect(() => {
    onLoad();
  }, []);

  const isDisableActionNoteField = () => {
    return !state.isApproveEnabled && !state.isRejectEnabled && !state.isPublishEnabled;
  };

  useEffect(() => {
    if (isDisableActionNoteField()) {
      form.resetFields(['note']);
    }
  }, [state.isRejectEnabled, state.isApproveEnabled, state.isPublishEnabled]);

  // called when datasetlist call is done 
  const getForm = () => {
    return (
      <Form
        style={{ marginBottom: 16, maxWidth: 1440 }}
        form={form}
        size='small'
        initialValues={{ action: 'approvedAndUnpublished', datasetListId: state.datasetList?.[0]?.datasetListId }}
      >

        <Row type="flex" justify={'start'}>

          <Col xs={24} sm={24} md={24} lg={12} xl={12}>

            {createSelectOptions({ name: 'datasetListId', label: messageBundle['ih.dataset'] }, state.datasetList)}

            <Form.Item
              label={messageBundle['ih.sourceCreateDate']}
              name='sourceDateRange'
            >
              <RangePicker format={'MM/DD/YYYY'} />
            </Form.Item>
            <Form.Item name="action" label={messageBundle['ih.searchAction']}>
              <Radio.Group>
                <Row type="flex">
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Space direction="vertical">
                      <Radio value='unapproved'>{messageBundle['ih.unapproved']}</Radio>
                      <Radio value='rejected'>{messageBundle['ih.rejected']}</Radio>
                      <Radio value='approvedAndUnpublished'>{messageBundle['ih.approvedAndUnpublished']}</Radio>
                      <Radio value='unprocessed'>{messageBundle['ih.unprocessed']}</Radio>
                    </Space>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Space direction="vertical">
                      <Radio value='approved'>{messageBundle['ih.approved']}</Radio>
                      <Radio value='published'>{messageBundle['ih.published']}</Radio>
                    </Space>
                  </Col>
                </Row>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="publishDate" label={messageBundle['ih.publishStartDt']}>
              <DatePicker format={'MM/DD/YYYY'} />
            </Form.Item>

          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item name="note" label={messageBundle['ih.actionNotes']}>
              <TextArea disabled={isDisableActionNoteField()} showCount maxLength={4000} allowClear rows={4} defaultValue={''} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button type="primary" name='approve' htmlType="submit" disabled={!state.isApproveEnabled}
              onClick={performApproval}>Approve</Button>
            <Button type="primary" name='reject' htmlType="submit" disabled={!state.isRejectEnabled} onClick={performReject}>Reject</Button>
            <Button type="primary" name='publish' htmlType="submit" disabled={!state.isPublishEnabled}
              onClick={performPublish}>Publish</Button>
            <Button type="primary" name='query' htmlType="submit" onClick={performQuery}>Query</Button>
          </Space>
        </Form.Item>
      </Form>);
  };

  return (
    <div className='gadget full-width'>
      <InfoDialog
        message={getInfoDialogMessage()}
        handler={performAction}
        dialogRef={dialogRef} />
      <div className='gadget-container'>
        <div className="gadget-header">
          <div className="gadget-title"> Manage Data Approval and Publish </div>
        </div>
        <div className='gadget-controls'>
          <Skeleton active loading={state.datasetListLoading}>
            {getForm()}
          </Skeleton>
        </div>
        <div className="gadget-item-content">
          <Skeleton active loading={loading}>
            {buildParentTable()}
            <div style={{ margin: '15px 0px' }} />
            <Skeleton active loading={sourcePostLoading}>
              {buildChildTables()}
            </Skeleton>
          </Skeleton>
        </div>
      </div>
    </div >
  )
};

export default ManageData;