import React, {useState} from "react";
import { ApiRequest } from '@trellisenergy/common-ui-core';
import {Popover, Skeleton} from "antd";
import { EyeOutlined } from '@ant-design/icons';

const TermsLinkRenderer = (props) => {
    // const {message} = useSelector((state) => state.message);
    let [loading, setLoading] = useState(false);
    let [termsList, setTermsList] = useState([]);
    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });
    
    if (!props?.url || !props?.data?.id) {
        return null;
    }
    
    let dataUrl = props.url + props.data.id;
    let getTermsContent = async () => {
        let getTerms = () => {
            const request = ApiRequest.composeGetRequest(dataUrl);
            return fetch(request)
                .then(response => response.json())
                .then(json => {
                    return json;
                }).catch(error => {
                    return null;
                });
        };

        setLoading(true);
        let resp = await getTerms();
        setLoading(false);

        setTermsList(resp?.data || []);
    };

    let handleHoverChange = (visible) => {
        if (visible) {
            getTermsContent();
        }
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    };

    let handleClickChange = visible => {
        if (visible) {
            getTermsContent();
        }

        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };

    // let hide = () => {
    //     setPopoverState({
    //         hovered: false,
    //         clicked: false
    //     });
    // };

    let content = <Skeleton active loading={loading}>
        {termsList.length > 0 ? termsList.map(item => (
            <div>
                <h3><b>{item.name}</b></h3>
                <pre>{item.text}</pre>
            </div>
        )) : 'No terms available.'}
    </Skeleton>;

    return <>
        <div className="flex-center-justify-center">
            <Popover mouseEnterDelay={.5} trigger="hover"
                     visible={popoverState.hovered}
                     overlayStyle={{
                         width: "50vw"
                     }}
                     onVisibleChange={handleHoverChange} content={content}>
                <Popover mouseEnterDelay={.5} trigger="click"
                         visible={popoverState.clicked}
                         overlayStyle={{
                             width: "50vw"
                         }}
                         onVisibleChange={handleClickChange} content={content}>
                    <EyeOutlined style={{fontSize: '16px'}}/>
                </Popover>
            </Popover>
        </div>
    </>;
};

export default TermsLinkRenderer;
