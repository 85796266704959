import React, { useState } from "react";
import { Col, Popover, Row } from "antd";
import { EyeOutlined, CloseOutlined } from '@ant-design/icons';
import { TUtil as T } from '@trellisenergy/common-ui-core';

const PreviewInPopupRenderer = (props) => {
    const content = props?.value;

    let hide = () => {
        setPopoverState({
            hovered: false,
            clicked: false
        });
    };

    const title =
        <Row>
            <Col span={20}>{props?.colDef?.headerName}</Col>
            <Col span={4} style={{ textAlign: 'right' }}>
                <a onClick={hide}><CloseOutlined /></a>
            </Col>
        </Row>;

    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });

    let handleHoverChange = (visible) => {
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    };

    let handleClickChange = visible => {
        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };

    return !T.isEmpty(content) ? <div className="flex-center-justify-left">
        <Popover mouseEnterDelay={.5} trigger="hover"
            visible={popoverState.hovered}
            onVisibleChange={handleHoverChange} content={content} title={title}>
            <Popover mouseEnterDelay={.5} trigger="click"
                visible={popoverState.clicked}
                onVisibleChange={handleClickChange} content={content} title={title}>
                <EyeOutlined style={{ fontSize: '16px' }} />
            </Popover>
        </Popover>&nbsp;&nbsp;{content}
    </div> : '';
};

export default PreviewInPopupRenderer;