// share col definitions between manage ih data and other screens

const SharedColDefs = {
    LocationDownload: {
        All: [
            'pipelineName',
            'locStatInd',
            'locTypeInd',
            'dirFlo',
            'locName',
            'effDate',
            'inactDate',
            'updateDate',
            'duns',
            'pipelinePropName',
            'pipelineFercId',
            'locStAbbrev',
            'locCnty',
            'loc',
            'locLoctr',
            'locLoctrId',
            'locZone',
            'locFuelArea',
            'upDnName',
            'upDnId',
            'upDnIdProp',
            'upDnFercId',
            'upDnFercIdInd',
            'upDnInd',
            'upDnLoc',
            'upDnLocName',
            'comments'
        ]
    },
    NoticesData: {
        All: [
            'noticeStartDate',
            'noticeEndDate',
            'noticeCategory',
            'pipelineName',
            'criticalNoticeDescription',
            'subject',
            'noticeTextClob',
            'downloadAttachmentsText',
            'criticalIndicator',
            'noticeIdentifier',
            'pipelineShortName',
            'noticeStatusDescription',
            'postingDate',
            'priorNoticeIdentifier',
            'responseRequiredIndicatorDescription',
            'responseRequiredByDate',
            'pipelinePropName',
            'noticeStat',
            'noticeTypeDesc',
            'noticeType',
            'reqrdRespInd',
        ]
    },
    PostedImbalance: {
        All: [
            'imbPeriod',
            'pipelineName',
            // 'rateSchedule',
            'svcReqName',
            'ctrctHolderName',
            'svcReqAcct',
            'svcReqCtrct',
            'oia',
            'imbType',
            'imbDirDesc',
            'postedImbQty',
            'svcReqContact',
            'svcReqPhone',
            'duns',
            'pipelinePropName',
            'stmtDt',
            'svcReq',
            'svcReqProp',
            'ctrctHolder',
            'ctrctHolderPropCode',
            'imbDirInd',
            // 'prodMonth',
            'imbTypeCode',
            'dupRow'
        ]
    },
    Capacity: {
        UnsubbedCap: {
            All: [
                'effGasDay',
                'effEndGasDay',
                'pipelineName',
                'loc',
                'locName',
                'locPurpDesc',
                'locQtiDesc',
                'unsubscribedCap',
                'measBasisDesc',
                'locSegment',
                'measBasis',
                'reportDt',
                'locPurp',
                'locQti',
                'locZn',
                'pressBase',
                'pressBaseInd',
                'pressBaseDesc',
                // 'commentDtl',
                // 'locComments',
                // 'pipelineCode',
                'duns',
                'pipelinePropName',
                'refCmntId',
                'n9CycleCmntId',
                'n9DtlCmntId'
                // 'totalDesignCapacity'
            ]
        },
        CapNoNotice: {
            All: [
                'pipelineCode',
                'pipelineName',
                'effBegGasDtTm',
                'effEndGasDtTm',
                'loc',
                'locName',
                'dirFlow',
                'locPurpDesc',
                'locQtiDesc',
                'noNoticeQty',
                'reportDt',
                'pipelinePropName',
                'measBasisDesc',
                'locPurp',
                'locCity',
                'locStAbbrev',
                'comments',
                'accntPhyInd',
                'locQti',
                'variance'
            ]
        },
        OperAvailCap: {
            All: [
                'effGasDtTm',
                'cycleName',
                'actualAvalCapPct',
                'pipelineName',
                'locName',
                'flowIndDesc',
                'locPurpDesc',
                'locQtiDesc',
                'designCapacity',
                'operatingCapacity',
                'tsq',
                'operAvailCap',
                'measBasisDesc',
                'reportDt',
                'cycleInd',
                'pipelinePropName',
                'measBasis',
                'duns',
                'loc',
                'locPurp',
                'flowInd',
                'locQti',
                'locZn',
                'allQtyAvailInd',
                'itInd',
                'itIndDesc',
                'nomCapExceedInd',
                'qtyReason',
                'authOverrunInd',
                'authOverrunIndDesc',
                // 'locComments',
                'pressBase',
                'pressBaseInd',
                'pressBaseDesc',
                'refCmntId',
                'n9CycleCmntId',
                'n9DtlCmntId'
            ]
        }
    }
};

export default SharedColDefs;