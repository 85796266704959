import { Route, Redirect } from 'react-router-dom';
import {
  GadgetShortcut,
  ImbalanceHistory,
  ImbalanceAvailable, ImbalanceAvailableDetail, ImbalanceHistoryDetail, ImbalanceDetail, PotentialTraderInfo
} from 'gadget';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from 'react-redux';

function Imbalance() {
  const { message } = useSelector((state) => state.message);
  const path = '/imbalance';

  return (
    <div>
      <Route exact path={T.buildURL(path)}>
        <ImbalanceDetail />
          {/*
        <ImbalanceAvailable drilldown={{ link: T.buildURL(path + '/ImbalanceAvailableDetail'), level: 1 }} />
        <ImbalanceHistory drilldown={{ link: T.buildURL(path + '/ImbalanceHistoryDetail'), level: 1, state: { limitResultsToMostRecentImbPeriods: true } }} />
        <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllPostedImbalances'), title: message['ih.widget.imbalance.imbalanceAvailableAll'], level: 1 }} />
        */}
        {/*<GadgetShortcut drilldown={{ link: T.buildURL(path + '/potentialTraderInfo'), title: message['ih.widget.imbalance.potentialTraderInfo'], level: 1 }} />*/}
      </Route >
      <Route path={T.buildURL(path + '/ImbalanceAvailableDetail')}>
        <ImbalanceAvailableDetail />
      </Route>
      <Route path={T.buildURL(path + '/ImbalanceHistoryDetail')}>
        <ImbalanceHistoryDetail />
      </Route>
      <Route path={T.buildURL(path + '/AllPostedImbalances')}>
        <ImbalanceDetail />
      </Route>
      <Route path={T.buildURL(path + '/potentialTraderInfo')}>
        <PotentialTraderInfo />
      </Route>
    </div>
  );
}

export default Imbalance;
