import React, {useRef, useState} from 'react';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import {useSelector} from 'react-redux'
import {BarChart} from 'infochart'
import {IocChartConfig} from "chart/config";
import {useHistory} from 'react-router-dom'
import {TUtil as T} from '@trellisenergy/common-ui-core';
import {Form, Select} from "antd";

const BiddableOffers = (props) => {
    const {configs} = useSelector((state) => state.info)
    const {message} = useSelector((state) => state.message)
    const [postData, setPostData] = useState({pastDays: configs?.noticesSummaryDuration})
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/crls/getBiddableOffers?pastDays=' + postData?.pastDays, postData, refreshCount);
    const history = useHistory();
    const chartRef = useRef();

    const refresh = () => {
        setRefresh(Math.random())
    };

    const customConfig = {
        ...IocChartConfig,
        xField: 'count',
        yField: 'name',
        legend: false,
        seriesField: 'count'
    };

    const onWidgetSelectionChange = (value) => {
        setPostData({pastDays: value});
    };

    const hourOptions = configs?.summaryPastDaysOptionsNode.map(i => <Select.Option value={i}>{i}</Select.Option>);

    const gadgetItem = {
        topDisplayItems: [
            <Form
                labelCol={{
                    span: 21,
                }} wrapperCol={{span: 3}}>
                <Form.Item key={1} name={'pastDays'} label={message['ih.dayCount']}>
                    <Select
                        style={{textAlign: 'left'}}
                        onChange={onWidgetSelectionChange}
                        defaultValue={postData.pastDays ? postData.pastDays : configs?.noticesSummaryDuration}>
                        {hourOptions}
                    </Select>
                </Form.Item>
            </Form>,
            <div className='gadget-item-top'>
                {message['ih.widget.title.inner.totalOffers'] + ' ' + output.reduce((a, b) => a + b.count, 0)}
            </div>
        ],
        chart: <BarChart chartRef={chartRef} customChartConfig={customConfig} data={output}/>
    };

    T.registerChartListener(chartRef, (event) => {
        if (event.data) {
            history.push(T.buildURL('/capacityRelease/AllOffer'), {
                datasetNm: event.data.data.name,
                caller: 'summary',
                startDate: T.getLastDateByHoursHHmmStr(postData?.pastDays * 24, true),
                endDate: T.getTodayDateTimeHHmmStr(),
                title: message['ih.widget.biddableOffers']
            });
        }
    });

    const gadget = {
        refresh,
        loading, loadTimestamp,
        gadgetItem,
        views: ['chart'],
        title: message['ih.widget.biddableOffers'],
        width: props.width || 50,
        drilldown: {
            ...props.drilldown,
            state: {
                caller: 'summary',
                startDate: T.getLastDateByHoursHHmmStr(postData?.pastDays * 24, true),
                endDate: T.getTodayDateTimeHHmmStr(),
                title: message['ih.widget.biddableOffers']
            }
        }
    };
    return (
        <Gadget {...props} {...gadget} />
    )
};

export default BiddableOffers;