import { TUtil as T } from '@trellisenergy/common-ui-core';
import FaIcon from '../../component/icon/FaIcon'
import React, { useRef, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Skeleton, } from 'antd'
import { useSelector } from 'react-redux'
import Tooltip from '../../component/tooltip/Tooltip'
import useDoubleClick from 'use-double-click';

import "./Gadget.css";

function onDragStart() { }

function onDrop() { }

function onDragOver() { }

function Gadget(props) {
  const { message } = useSelector((state) => state.message);
  const controls = props.controls;
  const customForm = props.customForm;
  const drilldown = props.drilldown || {};
  const getCustomLinks = () => {
    let ret = [];
    if (T.isArray(props.customLinks)) {
      ret = props.customLinks.map(link => {
        let l = {
          ...link, onClick: (e) => {
            e.preventDefault();
            if (typeof link.action === 'function') {
              link.action();
            }
          }
        }
        return l;
      })
    }
    return ret;
  }
  const customLinks = getCustomLinks();
  /*
  const customLink = props.customLink ? {
    ...props.customLink,
    onClick: (e) => {
      e.preventDefault();
      if (typeof props.customLink.action === 'function') {
        props.customLink.action();
      }
    }
  } : null;
  */
  const views = props.views || [];

  const widths = { /*25: 'onefourth-width',*/ 50: 'half-width', 75: 'threefourth-width', 100: 'full-width' };
  const [mode, setMode] = useState(views[0]);
  const [width, setWidth] = useState(props.width || 50);
  const history = useHistory();
  const gadgetHeaderRef = useRef();

  const gadgetHeaderClass = 'gadget-header';
  const gadgetTitleClass = 'gadget-title';

  const hasSingleClickableClass = (e) => {
    return e.target.classList.contains(gadgetHeaderClass) || e.target.classList.contains(gadgetTitleClass);
  };

  const onGadgetTitleClick = (e) => {
    if (drilldown && drilldown.link) {
      history.push(drilldown.link, drilldown.state);
    }
  };

  // goes to same place as if they click on title
  const onGadgetItemClick = (e) => {
    if (props.triggerBodyClick) {
      onGadgetTitleClick(e);
    }
  };

  useDoubleClick({
    onSingleClick: e => {
      if (hasSingleClickableClass(e)) {
        //Do nothing.
      }
    },
    onDoubleClick: e => {
      changeGadgetWidth();
    },
    ref: gadgetHeaderRef,
    latency: 250
  });

  function getNextMode() {
    let curIndex = views.indexOf(mode);
    let nextIndex = (curIndex + 1) % views.length;
    console.log(nextIndex)
    return views[nextIndex];
  }

  function getModeItem() {
    if (mode)
      return props.gadgetItem[mode];
  }

  // shift up
  function onModeChange() {
    console.log('onModeChange');
    setMode(getNextMode());
  }

  const onRefresh = () => {
    if (props.refresh) {
      props.refresh();
    }
  }

  const changeGadgetWidth = () => {
    let widthsMap = Object.keys(widths),
      currentIndx = widthsMap.indexOf(width + ''),
      nextWidth = widthsMap[(currentIndx + 1) % widthsMap.length];
    setWidth(nextWidth * 1);
  }


  let setDraggable = props.draggable ? {
    draggable: "true",
    onDragStart: onDragStart,
    onDrop: onDrop,
    onDragOver: onDragOver,
  } : {};

  const loadTimestamp = T.getTime(props.loadTimestamp);
  const serverMessages = props.serverMessages;

  const titleText = <div className='gadget-title-text'>{props.title}</div>
  const refreshIcon = !props.loading && mode !== 'shortcut' && props.refresh ?
    < Tooltip title={message["ih.widget.refresh"]} >
      <div className='gadget-action-button' onClick={onRefresh}>
        <FaIcon name="sync-alt" />
      </div>
    </Tooltip >
    : '';

  const navToolTip = props.navToolTip || drilldown.navToolTip || message['ih.widget.nav.tooltip']
  const linkIcon = drilldown.level > 0 ?
    <div className='gadget-action-button'>
      <Tooltip title={navToolTip}>
        <Link to={{ pathname: drilldown.link, state: drilldown.state }}>
          <FaIcon name="angle-double-right" />
        </Link>
      </Tooltip>
    </div> : '';

  //const customLinkElement = customLink ? <a href='' onClick={customLink.onClick} className='gadget-action-link'>{customLink.linkText}</a> : null;
  const customLinkElements = customLinks.map((link, i) => {
    const separater = i < customLinks.length - 1 ? <span className='gadget-action-link'>|</span> : null;
    const def = <a href='' onClick={link.onClick} className='gadget-action-link'>{link.linkText}</a>;
    return [def, separater];
    //return def + (i < customLinks.length - 1 ? separater : '');
  })

  const getModeIcon = (nextMode) => {
    switch (nextMode) {
      case 'grid': return { icon: 'table', title: message['ih.widget.showData'] };
      case 'chart': return { icon: 'chart-bar', title: message['ih.widget.showChart'] };
      default: return { icon: 'chart-pie', title: message['ih.widget.showChart'] };
    }
  }

  const nextModeIcon = !props.loading && views.length > 100 ?
    <Tooltip title={getModeIcon(getNextMode()).title}>
      <div className='gadget-action-button' onClick={onModeChange}>
        <FaIcon name={getModeIcon(getNextMode()).icon} />
      </div>
    </Tooltip >
    : '';

  const footer =
    loadTimestamp ?
      <>
        <FaIcon name="info-circle" />
        <div className='gadget-footer-message'>{message['ih.widget.lastUpdated']} {loadTimestamp} {serverMessages} </div>
      </> :
      <>
        <FaIcon name="exclamation-triangle" />
        <div className='gadget-footer-message'>{message['ih.widget.error']} {serverMessages}</div>
      </>;

  return (
    <div
      {...setDraggable}
      data-source-id={props.dataSourceId}
      data-gadget-id={props.id}
      className={`gadget ${widths[width]}`}
    >
      <div className="gadget-container">
        <div ref={gadgetHeaderRef} className={gadgetHeaderClass}>
          <div className={gadgetTitleClass}>
            {titleText}
            <div className='gadget-action-buttons'>
              {linkIcon}
              {refreshIcon}
              {nextModeIcon}
              {customLinkElements}
            </div>
          </div>
        </div>
        {/*<Dialog id={this.props.id + '-dialog'} dialog={this.state.dialog} />*/}
        {controls ? <div className='gadget-controls'>{controls}</div> : ""}
        {customForm ? <div className='gadget-controls'>{customForm}</div> : ""}

        <div className={'gadget-item-content'}>
          <Skeleton active loading={props.loading}>
            {props.gadgetItem.topDisplayItems ? <div className={props.gadgetItem.topDisplayItemsClassNames || 'center-text-align'}>{props.gadgetItem.topDisplayItems}</div> : null}
            <div onClick={onGadgetItemClick} className={'gadget-item ' + (props.gadgetItem.contentClassNames ? props.gadgetItem.contentClassNames : '')} >
              {getModeItem()}
            </div>
          </Skeleton>

        </div>
        <div className="gadget-footer">
          <span style={{ visibility: props.loading ? 'hidden' : 'visible' }}>{mode !== 'shortcut' && footer}</span>
        </div>
      </div>
    </div >
  );
}

export default Gadget;
