import React, { useEffect, useReducer, useState, useRef } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import { setPageErrorAlert } from 'redux/reducer/info';

import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useDispatch, useSelector } from 'react-redux'
import TransactionalReportingApi from 'api/v1/TransactionalReportingApi';
import { Skeleton, Collapse, InputNumber, Select, Input, Form } from 'antd'
import BackTop from '../../backtop/BackTop';
import { CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const AllTransactionalReporting = (props) => {
  const { configs } = useSelector((state) => state.info);
  const dispatch = useDispatch();
  const formRef = useRef(this);

  const messageBundle = useSelector((state) => state.message).message;
  const [postTypeOptions, setPostTypeOptions] = useState([]);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [contractGeneralLoading, setContractGeneralLoading] = useState(false);
  const [contractRateLoading, setContractRateLoading] = useState(false);
  const [contractLocationLoading, setContractLocationLoading] = useState(false);

  const [contractGeneralData, setContractGeneralData] = useState([]);
  const [contractRateData, setContractRateData] = useState([]);
  const [contractLocationData, setContractLocationData] = useState([]);

  const [fullRangeOnlyDisabled, setFullRangeOnlyDisabled] = useState(false);

  useEffect(() => {
    // load options for dropdown
    TransactionalReportingApi.getPostTypeOptions().then(data => {
      setPostTypeOptions(data)
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    });
    initalQueryAll();
  }, [])

  let contractGeneralNameMap = [
    //1
    'tspName',
    'postType',
    'rtSchedName',
    'ctrctHolderName',
    'svcReqCtrct',
    'kQtyK',
    'kBegDate',
    'kEndDate',
    'kRoll',
    'postingDateTime',
    'kStat',
    'amendRptg',
    'kEntBegDate',
    'kEntEndDate',
    'seasonalStartDate',
    'seasonalEndDate',
    'affiliateIndicator',
    'marketBasedRateInd',
    'negotiatedRateInd',
    'minVolCommitmentPctNonCapRel',
    'minVolCommitmentQtyNonCapRel',
    'measBasis',
    'pressureBase',
    'pressureBaseCode',
    'openSeason',
    'termsNotes',
    'tsp',
    'tspProp',
    'ctrctHolder',
    'ctrctHolderProp',
    'releaserName',
    'releaser',
    'releaserProp',
    'relK',
    'rfqOffrNbr',
    'prearrangedDealCode',
    'replShipperRoleCode',
    'termsNotesAma',
    'permanentReleaseCode',
    'allowableReReleaseInd',
    'previouslyReleasedCode',
    'ibrIndCapRelInd',
    'rightToAmendPrimPtsCode',
    'rappLimit',
    'rappTerms',
    'storageInvCondReleaseInd',
    'termsNotesStorage',
    'recallReputCode',
    'recallReputTerms',
    'businessDayInd',
    'recallNotifPerTimInd',
    'recallNotifPerEeveInd',
    'recallNotifPerEveInd',
    'recallNotifPerId1Ind',
    'recallNotifPerId2Ind',
    'recallNotifPerId3Ind',
  ];

  let contractRateNameMap = [
    'tspName',
    'postType',
    'svcReqCtrct',
    'kBegDate',
    'kEndDate',
    'rtFormTypCode',
    'rateId',
    'rateChgd',
    'rateChgdIbr',
    'rateChgdRef',
    'maxTrfRate',
    'maxTrfRateRef',
    'reservationRtBasis',
    'surchargeCode',
    'surchgId',
    'totSurchg',
    'rptLvl',
    'locName',
    'loc',
    'locZn',
    'locName2',
    'loc2',
    'locZn2',
    'kQtyLoc',
    'kQtyK',
    'marketBasedRateInd',
    'negotiatedRateInd',
    'minVolCommitmentPctNonCapRel',
    'minVolCommitmentQtyNonCapRel',
    'discBegDate',
    'discEndDate',
    'seasonalStartDate',
    'seasonalEndDate',
    'termsNotes',
    'tsp',
    'tspProp',
    'ctrctHolderName',
    'ctrctHolder',
    'ctrctHolderProp',
    'rtSchedName',
    'kRateBegDate',
    'kRateEndDate',
    'kEntBegDate',
    'kEntEndDate',
    'openSeason',
    'releaserName',
    'releaser',
    'releaserProp',
    'relK',
    'rfqOffrNbr',
    'postingDateTime',
    'kStat',
    'amendRptg',
  ];

  let contractLocationNameMap = [
    'tspName',
    'postType',
    'svcReqCtrct',
    'kLocBegDate',
    'kLocEndDate',
    'locName',
    'loc',
    'locZn',
    'locPurp',
    'locQti',
    'kQtyLoc',
    'locName2',
    'loc2',
    'locZn2',
    'capacityTypeInd',
    'capacityTypeLocationCode',
    'locInd',
    'route',
    'measBasis',
    'locKMaxPress',
    'locKMinPress',
    'pressureBase',
    'pressureBaseCode',
    'seasonalStartDate',
    'seasonalEndDate',
    'termsNotes',
    'tsp',
    'tspProp',
    'ctrctHolderName',
    'ctrctHolder',
    'ctrctHolderProp',
    'rtSchedName',
    'kBegDate',
    'kEndDate',
    'kEntBegDate',
    'kEntEndDate',
    'openSeason',
    'releaserName',
    'releaser',
    'releaserProp',
    'relK',
    'rfqOffrNbr',
    'postingDateTime',
    'kStat',
    'amendRptg',
  ];

  const processGeneralNameMap = (cols) => {
    [
      ['tspName', 'asc'],
      ['postType', 'asc'],
      ['rtSchedName', 'asc'],
      ['ctrctHolderName', 'asc'],
      ['svcReqCtrct', 'asc'],
      ['postingDate', 'asc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  const processRateNameMap = (cols) => {
    return processGeneralNameMap(cols);
  }

  const processLocationNameMap = (cols) => {
    return processGeneralNameMap(cols);
  }

  let contractGeneralColumnDefs = processGeneralNameMap(getNameModelMap(contractGeneralNameMap));
  let contractRateColumnDefs = processRateNameMap(getNameModelMap(contractRateNameMap));
  let contractLocationColumnDefs = processLocationNameMap(getNameModelMap(contractLocationNameMap));

  const reset = (e) => {
    formRef.current?.resetFields();
    setResultFiltered(false);
  }

  const initalQueryAll = () => {
    const data = formRef.current?.getFieldsValue(true);
    data['contractStartDate'] = T.getDateTimeHHmmStr(data.contractDateRange[0]);
    data['contractEndDate'] = T.getDateTimeHHmmStr(data.contractDateRange[1]);
    queryAll(data);
  }

  const queryAll = (data) => {
    let postData = {
      // data range
      contractStart: data.contractStartDate,
      contractEnd: data.contractEndDate,

      postedAfter: data.postedEndDateTime,
      fullRangeOnly: data.fullRangeOnly,
      kHolder: data.kHolder,
      latestPostsOnly: data.latestPostOnly,
      locationName: data.locationName,
      postType: data.postType,

      // Max Trf Rate
      maxTrfRate: data.maxTrfRateGroup?.maxTrfRate,
      maxTrfRateOption: data.maxTrfRateGroup?.reservationRtBasis,

      minKQtyK: data.minKQtyK,
      svcReqK: data.svcReqCtrct,
    };

    setContractGeneralLoading(true);
    setContractRateLoading(true);
    setContractLocationLoading(true);
    setResultFiltered(false);

    Promise.allSettled([
      TransactionalReportingApi.getTrnsRptHeaders(postData),
      TransactionalReportingApi.getTrnsRptRates(postData),
      TransactionalReportingApi.getTrnsRptLocations(postData)
    ]).then(result => {
      const data = result.map(r => r.status === 'fulfilled' ? r.value : [])
      setContractGeneralData(data[0])
      setContractRateData(data[1]);
      setContractLocationData(data[2]);
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    }).finally(() => {
      setContractGeneralLoading(false);
      setContractRateLoading(false);
      setContractLocationLoading(false);
    });

  };

  const controls = ControlFactory.createControls({
    controls: [
      {
        id: 1, name: 'contractDateRange', type: 'dateTimepicker',
        label: messageBundle['ih.contractDateRange']
      },
      {
        id: 2, name: 'fullRangeOnly', type: 'singleCheckbox',
        disabled: fullRangeOnlyDisabled,
        label: messageBundle['ih.fullRangeOnly'],
        tooltip: messageBundle['ih.tooltip.fullRangeOnly.contract'],
      },
      {
        id: 3, name: 'postedEndDateTime', type: 'singleDateTimePicker', format: 'MM/DD/YYYY HH:mm',
        label: messageBundle['ih.postedEndDateTime']
      },
      {
        id: 4, name: 'latestPostOnly', type: 'singleCheckbox',
        values: [{ label: '', value: true }],
        label: messageBundle['ih.latestPostOnly'],
        tooltip: messageBundle['ih.tooltip.latestPostOnly.contract'],
      },
      {
        id: 5, name: 'postType', type: 'select',
        style: { width: 334 },
        values: T.createSelectOptionFromIdNameArray(postTypeOptions),
        label: messageBundle['ih.postType'],
      },
      {
        id: 6, name: 'maxTrfRateGroup', type: 'customField',
        customField: <Input.Group compact>
          <Form.Item
            name={['maxTrfRateGroup', 'maxTrfRate']}
            noStyle
          >
            <InputNumber placeholder={messageBundle['ih.maxTrfRate']} max={Infinity} />
          </Form.Item>
          <Form.Item
            name={['maxTrfRateGroup', 'reservationRtBasis']}
            noStyle
          >
            <Select defaultValue="Daily" placeholder="Select Res Rate Basis" style={{ width: 150, marginLeft: 10 }}>
              <Select.Option value='daily' >{messageBundle['ih.daily']}</Select.Option>
              <Select.Option value='monthly'>{messageBundle['ih.monthly']}</Select.Option>
            </Select>
          </Form.Item>
        </Input.Group>,
        label: messageBundle['ih.maxTrfRate'],
        tooltip: messageBundle['ih.tooltip.maxTrfRate.contract'],
      },
      {
        id: 7, name: 'kHolder', type: 'string',
        label: messageBundle['ih.kHolder']
      },
      {
        id: 8, name: 'svcReqCtrct', type: 'string',
        label: messageBundle['ih.svcReqCtrct']
      },
      {
        id: 9, name: 'locationName', type: 'string',
        label: messageBundle['ih.locationName'],
        tooltip: messageBundle['ih.tooltip.locationName.contract'],
      },
      {
        id: 10, name: 'minKQtyK', type: 'integer',
        label: messageBundle['ih.minKQtyK']
      },

    ],
    initialValues: {
      'contractDateRange': [T.getDateTimeHHmm(), T.getFutureDaysDate(),],
      'fullRangeOnly': false,
      'latestPostOnly': false,
      'locationName': null,
    },
    onValuesChange: (changedValues, allValues) => {
      if (!T.isUndefined(changedValues['contractDateRange'])) {
        setFullRangeOnlyDisabled(
          T.isEmpty(changedValues['contractDateRange']) ||
          T.isEmpty(changedValues['contractDateRange'][0]) ||
          T.isEmpty(changedValues['contractDateRange'][1])
        )
      } else if (!T.isUndefined(changedValues['maxTrfRateGroup'])) {
        // convert maxTrfRate between daily/monthly
        const maxTrfRateGroup = changedValues['maxTrfRateGroup'];
        const reservationRtBasis = maxTrfRateGroup.reservationRtBasis;
        const maxTrfRate = allValues?.maxTrfRateGroup?.maxTrfRate;
        let newMaxTrfRate = null;
        const conversionConst = 30.41667
        if (!T.isUndefined(reservationRtBasis)) {
          if (!T.isEmpty(maxTrfRate)) {
            if (reservationRtBasis === 'Daily') {
              newMaxTrfRate = maxTrfRate / conversionConst;
            } else if (reservationRtBasis === 'Monthly') {
              newMaxTrfRate = maxTrfRate * conversionConst;
            }
            formRef.current.setFieldsValue({ 'maxTrfRateGroup': { 'maxTrfRate': newMaxTrfRate } })
          }

        }
      }
    },
    settings: { colSpan: 2, },
    formRef,
    reset,
    submit: queryAll
  });

  const [isResultFiltered, setResultFiltered] = useState(false);

  const filterResults = (row, isFilter) => {
    const filterFunction = r => {
      if (!isFilter) {
        r.isHidden = false
      }
      else {
        // Contract Code + TSP + Posted Date
        r.isHidden = !(r.svcReqCtrct === row.svcReqCtrct
          && r.tsp === row.tsp
          && r.postingDate === row.postingDate
        );
      }
      return r;
    }
    setContractGeneralLoading(true);
    setContractRateLoading(true);
    setContractLocationLoading(true);

    let generalData = contractGeneralData?.map(filterFunction);
    let rateData = contractRateData?.map(filterFunction);
    let locationData = contractLocationData?.map(filterFunction);

    setContractGeneralData(generalData?.map(filterFunction));
    setContractRateData(rateData?.map(filterFunction));
    setContractLocationData(locationData?.map(filterFunction));

    setResultFiltered(isFilter);
    forceUpdate();
    //setTimeout(() => {
    setContractGeneralLoading(false);
    setContractRateLoading(false);
    setContractLocationLoading(false);
    //}, 1000);
  }

  const menuItems = configs.focusTrnsRptRows ? [
    'separator',
    {
      name: messageBundle['ih.focusContractRows.on'],
      disabled: isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, true)
      }
    }, {
      name: messageBundle['ih.focusContractRows.off'],
      disabled: !isResultFiltered,
      action: function (params) {
        filterResults(params.node?.data, false)
      }
    },
  ] : null;

  const gridOptions = { height: 600, contextMenuItems: menuItems, autoResizeHeight: true };
  const contractGeneralGrid = <InfoGrid id="AllTransactionalReporting_General" key={_ + 1} columnDefs={contractGeneralColumnDefs} gridOptions={gridOptions} data={contractGeneralData} ></InfoGrid>;
  const contractRateGrid = <InfoGrid id="AllTransactionalReporting_Rate"  key={_ + 2} columnDefs={contractRateColumnDefs} gridOptions={gridOptions} data={contractRateData} ></InfoGrid>;
  const contractLocationGrid = <InfoGrid id="AllTransactionalReporting_Location"  key={_ + 3} columnDefs={contractLocationColumnDefs} gridOptions={gridOptions} data={contractLocationData} ></InfoGrid>;

  return (
    <div className='gadget full-width'>
      <BackTop />
      <div className='gadget-container'>
        <div className="gadget-header">
          <div className="gadget-title"> {messageBundle['ih.widget.transactionalReporting.all']}</div>
        </div>
        <div className='gadget-controls'>
          {controls}
        </div>
        <div className="gadget-item-content">
          <Collapse ghost={true}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            defaultActiveKey={['1', '2', '3']}>
            <Panel header={messageBundle['ih.transactionalReporting.general']} key='1'>
              <Skeleton active loading={contractGeneralLoading}>
                {contractGeneralGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.transactionalReporting.location']} key='2'>
              <Skeleton active loading={contractLocationLoading}>
                {contractLocationGrid}
              </Skeleton>
            </Panel>
            <Panel header={messageBundle['ih.transactionalReporting.rate']} key='3'>
              <Skeleton active loading={contractRateLoading}>
                {contractRateGrid}
              </Skeleton>
            </Panel>
          </Collapse >
        </div>
      </div>
    </div >
  );

};

export default AllTransactionalReporting;
