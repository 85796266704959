import React, { useEffect, useRef, useState } from 'react';
import { InfoGrid } from 'infogrid'
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { ImbalancesApi } from 'api/v1/index'
import { useSelector } from 'react-redux'
import ControlFactory from 'infocontrol';

function PotentialTraderInfo(props) {
  const { message } = useSelector((state) => state.message);
  const [postData, setPostData] = useState({});
  const [noPostData] = useState({});

  const [loadInit, setLoadInit] = useState(false);

  const [refreshCount, setRefresh] = useState(0);
  const [loadingGetImbPeriods, outputGetImbPeriods] = useFetchData(ImbalancesApi.getImbPeriods, noPostData, refreshCount);
  const [loading, output, loadTimestamp] = useFetchData(ImbalancesApi.getPotentialTraderInfo, postData, refreshCount, loadInit, setLoadInit);

  const queryForm = useRef();

  const refresh = () => {
    setRefresh(Math.random())
  };

  useEffect(() => {
    if (outputGetImbPeriods && outputGetImbPeriods.length) {
      let mappedData = outputGetImbPeriods.map(item => {
        return {
          label: item.month,
          value: item.month
        }
      });
      queryForm.current.setFieldsValue({
        'month': mappedData[0].value,
      });

      setPostData({
        month: mappedData[0].value
      });
    }
  }, [outputGetImbPeriods]);

  let nameMap = [
    'svcReqName',
    'postedImbQty',
    'traderEligibleSvcReqName',
    'tradeEligibleImbQty',
    'pipelineCode',
    'pipelineName',
    'pipelinePropName',
    'imbPeriod',
    // 'prodMonth',
    'oia',
    'traderOia',
    'svcReqCtrct',
    'traderSvcReqCtrct'
  ];

  let columnDefs = getNameModelMap(nameMap);

  const processColumns = (cols) => {
    let groupColumns = ['svcReqName', 'traderEligibleSvcReqName'];

    groupColumns.forEach(name => {
      let groupColumn = T.find(cols, (c) => c.field === name);
      groupColumn.rowGroup = groupColumn.hide = true;
    });

    return cols;
  };

  const getMappedMonths = (input) => {
    return input?.map(item => {
      return {
        label: item.month,
        value: item.month
      }
    })
  };

  const controls = ControlFactory.createControls({
    controls: [{
      style: { width: 200 },
      id: 1, name: 'month', type: 'select', label: message['ih.imbalPeriod'],
      values: getMappedMonths(outputGetImbPeriods)
    }],
    submit: setPostData,
    formRef: queryForm
  });

  const gadgetItem = { grid: <InfoGrid id="PotentialTraderInfo" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid> };
  const gadget = {
    loading: loading || loadingGetImbPeriods,
    refresh,
    id: 'Gadget1',
    title: message['ih.widget.imbalance.potentialTraderInfo'],
    views: ['grid'], // grid
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget} />
  );
}

export default PotentialTraderInfo;
