import React, {useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { setPageErrorAlert } from 'redux/reducer/info';
import { useDispatch, useSelector } from 'react-redux'

import ControlFactory from 'infocontrol';
import { DatePicker, Form, Button, Select, Row, Col, Spin, Collapse } from 'antd';
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import moment from 'moment';
import './NoticesKeywordSearch.css'
import { RangeDatePickerAutoAccept } from '../../control/DatePickerAutoAccept';
import NoticesUtil from './noticesUtil';
import { NoticesApi } from 'api/v1/index'

const { RangePicker } = DatePicker;

const NoticesKeywordSearch = (props) => {

  /*const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };*/
  const gadgetId = 300;
  const formRef = useRef(this);
  const {searchDateSettings} = useSelector((state) => state.info);
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const dispatch = useDispatch();
  const location = useLocation();
  const caller = location.state?.caller;
  const keywords = location.state?.keywords;
  const pastHours = location.state?.pastHours;

  const { message } = useSelector((state) => state.message);
  const messageBundle = useSelector((state) => state.message).message;
  const { configs } = useSelector((state) => state.info);

  const formItemLayout = {
    labelCol: { style: { width: 200 } }

    // labelCol: { span: 12 },
    // wrapperCol: { span: 12 },
  };
  const formTailLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const [keywordSearchOptions, setKeywordSearchOptions] = useState({ timestamp: '', values: [] })

  const getDefaultKeywordSearchOptions = () => {
    if (T.isNil(postData.keywords)) return [];
    if (T.isArray(postData.keywords)) return postData.keywords;
    return postData.keywords.trim().split(/s+/);
  }

  const getKeywordSearchOptions = () => {
    return keywordSearchOptions?.values.map((a, i) => ({ label: a, value: a }))
  }

  // if found in localStorage
  //    -check for expiration
  //    -if expired 
  //      -fetch recent and update cache
  //    -else
  //      -use from localStorage
  // else
  //    -fetch recent and update cache
  const onLoad = () => {
    const cacheName = 'cache-KeywordGlossary';

    // fetch Data and store in localstorage
    const setNewData = async function () {
      const newValues = await NoticesApi.getKeywordGlossary();
      const newData = { timestamp: moment(), values: newValues };
      setKeywordSearchOptions(newData);
      localStorage.setItem(cacheName, JSON.stringify(newData));
    }

    // look in localStorage
    let cacheData = localStorage.getItem(cacheName);
    if (T.isEmpty(cacheData)) {
      setNewData();
    } else {
      const newData = JSON.parse(cacheData);
      const cacheTime = moment(newData.timestamp);
      const expirationTime = (configs.keywordGlossaryMaxStorageTime || 1440);
      const diff = moment().diff(cacheTime, 'minutes');
      console.log('cacheTime: ', cacheTime, ' now: ', moment(), ' diff: ', diff, ' expirationTime: ', expirationTime)
      if (diff > expirationTime) {
        console.log(' cache expired! fetching new data')
        setNewData();
      } else {
        setKeywordSearchOptions(newData)
      }
    }

    // load options for notice type dropdown
    NoticesApi.getNoticeTypes().then(data => {
      setNoticeTypeOptions(data)
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    });

    // load options for notice category dropdown
    NoticesApi.getNoticeCategories().then(data => {
      setNoticeCategoryOptions(data)
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    });
  }
  useEffect(() => {
    onLoad();
  }, [])

  const [postData, setPostData] = useState({
    postedStartDate: pastHours ? T.getLastDateByHoursHHmmStr(pastHours, true) : caller ? T.getYesterdayDateTimeHHmmStr() : T.getLast30DaysDateHHmmStr(),
    postedEndDate: T.getTodayDateTimeHHmmStr(),
    noticeStartDate: null,
    noticeEndDate: null,
    'criticalInd': null,
    'noticeType': null,
    'noticeCategory': null,
    'keywords': keywords,
  });

  const [loadInit, setLoadInit] = useState(!!keywords); // do load if keyword passed in from location.state
  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp, messages] = useFetchData('api/v1/notices/noticesKeywordSearch', postData, refreshCount, loadInit, setLoadInit);

  const [noticeCategoryOptions, setNoticeCategoryOptions] = useState([]);
  const [noticeTypeOptions, setNoticeTypeOptions] = useState([]);

  const reset = (e) => {
    formRef.current?.resetFields();
  }

  const convertData = (data = []) => {
    return data.map(item => {
      // tack on new column for list of keywords so that the notice preview can use to highlight
      let splitBySpaceOrNewline = postData.keywords.split(/\s+/);
      item.noticeKeywords = splitBySpaceOrNewline;
      return item;;
    });
  };

  let newNameMap = [
    'noticeStartDate',
    'noticeEndDate',
    'noticeCategory',
    'pipelineName',
    'criticalNoticeDescription',
    'subject',
    'noticeTextClob',
    'downloadAttachmentsText',
    'criticalIndicator',
    'noticeIdentifier',
    'pipelineShortName',
    'noticeStatusDescription',
    'postingDate',
    'priorNoticeIdentifier',
    'responseRequiredIndicatorDescription',
    'responseRequiredByDate',
    'pipelinePropName',
    'noticeStat',
    'noticeTypeDesc',
    'reqrdRespInd',
  ];

  let columnDefs = getNameModelMap(newNameMap);
  const controls = ControlFactory.createControls({
    controls: [
      {
        id: 1, name: 'criticalInd', type: 'select',
        style: { width: 290 },
        values: T.createSelectOptionFromArray(["Y","N"]),/*Need value of Y and N?*/
        label: messageBundle['ih.criticalInd'],
      },
      {
        id: 2, name: 'postedDateRange', type: 'dateTimepicker', format: 'MM/DD/YYYY HH:mm',
        style: { width: '100%' },
        label: messageBundle['ih.postedDateRange'],
        rules: [{ required: false, message: message['ih.pleaseInputDateRange'] }],
      },
      {
        id: 3, name: 'noticeCategory', type: 'select',
        style: { width: 290 },
        values: T.createSelectOptionFromArray(noticeCategoryOptions),
        otherProps: {
          showSearch: true,
          /*notFoundContent: fetchingNoticeCategoryOptions ? <Spin size='small' /> : null*/
        },
        label: messageBundle['ih.category'],
        rules: [{ required: false }]
      },
      {
        id: 4, name: 'noticeDateRange', type: 'dateTimepicker', format: 'MM/DD/YYYY',
        style: { width: '100%' },
        label: messageBundle['ih.noticeDateRange'],
        rules: [{ required: false, message: message['ih.pleaseInputDateRange'] }],
      },
      {
        id: 5, name: 'noticeType', type: 'select',
        style: { width: 290 },
        values: T.createSelectOptionFromArray(noticeTypeOptions),
        otherProps: {
          showSearch: true,
          /*notFoundContent: fetchingNoticeTypeOptions ? <Spin size='small' /> : null*/
        },
        label: messageBundle['ih.type'],
        rules: [{ required: false }]
      },
      {
        id: 6, name: 'keywords', type: 'select', mode: 'multiple',
        style: { width: '100%' },
        values: T.createSelectOptionFromArray(getKeywordSearchOptions),
        label: messageBundle['ih.widget.notices.keyword.search.short'],
        rules: [{ required: false, message: message['ih.pleaseInput'] + ' ' + message['ih.keywords'].toLocaleLowerCase() }],
        showSearch: true,
        allowClear: true,
      },
    ],
    initialValues: {
      'postedDateRange': [pastHours ? T.getPastDate(pastHours, 'hours', true) : caller ? T.getYesterdayDate() : datePeriod.startDate, datePeriod.endDate],
      'noticeDateRange': null,
      'keywords': keywords,
    },
    settings: { colSpan: 2, },
    formRef,
    reset,
    submit: setPostData,
  });

  const submit = (e) => {
    const getKeywords = (keywords) => {
      if (T.isNil(keywords)) return '';
      if (T.isArray(keywords)) return keywords.join(' ');
      return keywords;
    }

    let updated = {
      criticalInd: e.criticalInd,
      noticeType: e.noticeType,
      noticeCategory: e.noticeCategory,
      keywords: getKeywords(e.keywords),
      postedStartDate: e.postedDateRange ? T.getDateTimeHHmmStr(e.postedDateRange[0]) : null,
      postedEndDate: e.postedDateRange ? T.getDateTimeHHmmStr(e.postedDateRange[1]) : null,
      noticeStartDate: e.noticeDateRange ? T.getDateStr(e.noticeDateRange[0]) : null,
      noticeEndDate: e.noticeDateRange ? T.getDateStr(e.noticeDateRange[1]) : null,
    };
    setPostData(updated)
  }

  const gadgetItem = { grid: <InfoGrid id="NoticesKeywordSearch" columnDefs={NoticesUtil.setSortingCols(columnDefs)} data={convertData(output)}></InfoGrid> }
  const gadget = {
    loading,
    //id: 'Gadget1',
    title: message['ih.widget.notices.keyword.search'],
    views: ['grid'], // grid, chart
    gadgetItem,
    loadTimestamp: loadTimestamp,
    serverMessages: messages ? messages.join(' ') : null,
    controls,
    //dataSourceId: 1,
    width: props.width || 100
  };

  return (
    <Gadget {...gadget}></Gadget>
  );
}

export default NoticesKeywordSearch;
