import React, { Component } from 'react';
import CheckboxControl from './CheckboxControl';
import { Radio, Input, Checkbox, Divider } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import './CheckboxListControl.css'

/**
 * 
 * This is used in quick config and preferences.
 * Later we need to break it down.
 * 
 */
class CheckboxListControl extends Component {

  constructor(props) {
    super(props);
    //sort by pipeline name ascending
    this.state = {
      checkboxList: this.createCheckboxListState(this.props.checkboxList),
      indeterminate: true,
      checkAll: false,
      filter: false,
    };
    this.state.allCheckboxList = { ...this.state.checkboxList };
  }

  createCheckboxListState(checkboxList) {
    let result = {};
    checkboxList.forEach((checkbox) => { result[checkbox.id] = checkbox });
    return result;
  }

  getSorted(checkboxList) {
    return checkboxList;/*
    // show checked at top
    let checked = {}, unChecked = {};
    Object.entries(checkboxList).forEach(([key, c]) => {
      if (c.defaultValue)
        checked[c.id] = { ...c }
      else
        unChecked[c.id] = { ...c }
    });


    checked = { ...checked, ...unChecked };
    return checked;*/
  }

  getUpdatedCheckboxList(target) {
    let updated = { ...this.state.checkboxList[target.id], defaultValue: target.checked };
    let checkboxList = { ...this.state.checkboxList, [target.id]: updated };
    return checkboxList;
  }

  checkHandler(event) {
    let checkboxList = this.getUpdatedCheckboxList(event.target);
    let count = 0;
    Object.entries(checkboxList).forEach(([key, c]) => {
      if (c.defaultValue === true)
        count++;
    });
    this.setState({
      checkboxList: checkboxList,
      indeterminate: count > 0 && count !== T.size(checkboxList),
      checkAll: count === T.size(checkboxList),
    });
  }

  disableAll(disable) {
    let updated = {};
    Object.entries(this.state.checkboxList).forEach(([key, c]) => {
      updated[c.id] = { ...c, disabled: disable }
    });
    this.setState({ checkboxList: updated });
  }

  selectAll(defaultValue) {
    let updated = {};
    Object.entries(this.state.checkboxList).forEach(([key, c]) => {
      updated[c.id] = { ...c, defaultValue: defaultValue }
    });
    this.setState({ checkboxList: updated });
  }

  selectAllHanlder() {
    this.selectAll(true);
  }

  clearAllHandler() {
    this.selectAll(false);
  }

  filterData(inputValue) {
    this.setState({ ...this.state, filter: !!inputValue });
    // if cleared out everything
    if (!inputValue) {
      let updated = {};
      Object.entries(this.state.checkboxList).forEach(([key, c]) => {
        updated[c.id] = { ...c, filtered: false };
      });
      //updated = this.getSorted(updated);
      this.setState({ checkboxList: updated });
      return;
    }

    // if something typed and doesn't match -> exclude = filtered = true
    let updated = {};
    Object.entries(this.state.checkboxList).forEach(([key, c]) => {
      updated[c.id] = { ...c, filtered: !c.label.toLowerCase().includes(inputValue.toLowerCase()) }
    });
    this.setState({ checkboxList: updated });
  }

  filter(e) {
    const inputValue = e.currentTarget.value;
    this.filterData(inputValue);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkboxList) {
      //if (nextProps.reset) {
      // use the props
      this.setState({
        checkboxList: this.createCheckboxListState(nextProps.checkboxList)
      });
    }
  }

  render() {
    console.log("CheckboxListControl: ", this.state);

    let result = [];
    Object.entries(this.state.checkboxList).sort((a, b) => (a[1].label > b[1].label) ? 1 : -1).forEach(([key, c]) => {
      if (!c.filtered) {
        c = { ...c, onChange: this.checkHandler.bind(this), disabled: false }
        result.push([<CheckboxControl style={{ minWidth: !this.props.isPreference ? '' : '460px' }} key={c.id} control={c} />, <br />]);
      } else {
        console.log('skipping', c)
      }
    });

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    const checkboxStyle = this.props.isPreference ? {
      marginLeft: '0px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    } : {
      marginLeft: '0px'
    };

    const onCheckAllChange = e => {
      this.setState({ checkAll: e.target.checked, indeterminate: false });
      this.selectAll(e.target.checked);
    };

    let buttons = <div className={`${!this.props.isPreference ? "fixed-position" : ""}`} >
      <Input autoComplete="off"
        allowClear id='filter' disabled={false}
        onChange={this.filter.bind(this)}
        placeholder="Search pipelines" suffix={<SearchOutlined className="site-form-item-icon" />} autoFocus={true} />
    </div >

    return <>
      {buttons}
      <div className={`${!this.props.isPreference ? "pipeline-checkbox-list" : ""}`} >
        {!this.state.filter ?
          <>
            <Checkbox name='selectAll-pipelines' style={{ marginLeft:'0px', }} disabled={false}
              indeterminate={this.state.indeterminate} onChange={onCheckAllChange} checked={this.state.checkAll}>
              Select All
            </Checkbox>
            <Divider style={{ margin: '12px 0' }} />
          </> : ''}
        <div className='checkboxlist' style={checkboxStyle}>{result}</div>
      </div>
    </>
  }

}

export default CheckboxListControl;