import React, { useState } from 'react';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { CircleChart } from 'infochart'
import { useHistory } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { Form, Select } from "antd";

const CriticalNotices = (props) => {
    const { configs } = useSelector((state) => state.info)
    const { message } = useSelector((state) => state.message)
    const [postData, setPostData] = useState({ pastDays: configs?.noticesSummaryDuration })
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/notices/getCriticalNotices?pastDays=' + postData?.pastDays, postData, refreshCount);
    const history = useHistory();

    const refresh = () => {
        setRefresh(Math.random())
    };

    const onWidgetSelectionChange = (value) => {
        setPostData({ pastDays: value });
    };

    const hourOptions = configs?.summaryPastDaysOptionsNode.map(i => <Select.Option value={i}>{i}</Select.Option>);

    const onChartClick = () => {
        if (output) {
                    history.push(T.buildURL('/notices'), {
                        caller: 'critical',
                        pastHours: postData?.pastDays * 24,
                        title: message['ih.widget.criticalNotices']
                    });
                }
    };

    const gadgetItem = {
        topDisplayItems: [
            <Form
                labelCol={{
                    span: 21,
                }} wrapperCol={{ span: 3 }}>
                <Form.Item key={1} name={'pastDays'} label={message['ih.dayCount']}>
                    <Select
                        style={{ textAlign: 'left' }}
                        onChange={onWidgetSelectionChange}
                        defaultValue={postData.pastDays ? postData.pastDays : configs?.noticesSummaryDuration}>
                        {hourOptions}
                    </Select>
                </Form.Item>
            </Form>
        ],
        chart: (
            output
                ? <CircleChart onChartClick={onChartClick} strokeColor={'#10d33d'} data={output} />
                : <div>No Data Available</div>
        ),
        contentClassNames: !(output && output.length) ? 'flex-center' : '',
    };

    const gadget = {
        refresh,
        loading, loadTimestamp,
        gadgetItem,
        views: ['chart'],
        title: message['ih.widget.criticalNotices'], //'Notices Summary From Past ' + postData?.pastDays + ' Day(s)',
        width: props.width || 50,
        drilldown: {
            ...props.drilldown,
            navToolTip: message['ih.widget.nav.notices.tooltip'],
            state: {
                startDate: T.getLastDateByHoursHHmmStr(postData?.pastDays * 24, true),
                endDate: T.getTodayDateTimeHHmmStr(),
            }
        }
    };
    return (
        <Gadget {...props} {...gadget} />
    )
};

export default CriticalNotices;