import React, { useRef, useState } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from '../../../asset/GridColumnNameMap';
import Gadget from '../../gadget/Gadget'
import useFetchData from '../../../api/service'
import { TUtil, ApiResult} from '@trellisenergy/common-ui-core';
import { useDispatch, useSelector } from 'react-redux'
import { setPageSuccessAlert, setPageErrorAlert } from 'redux/reducer/info';
import { message, Collapse, Form, Space, Button, DatePicker, Modal, Input } from 'antd'
import { AdminApi } from 'api/v1/index';
import DatasetStatusApi from '../../../api/v1/DatasetStatusApi';
import DateEditorComponent from "../../date/DateEditorComponent";
import ColumnTypes from "../../infogrid/ColumnTypes";
import SelectorComponent from "../../select/SelectorComponent";

const { Panel } = Collapse;

const DataCollectionDashboard = (props) => {
  const authorities = (props.accountData?.authorities) || [];

  const [postDataCustomers, setPostDataCustomers] = useState({});
  const [refreshCountCustomers, setRefreshCustomers] = useState(0);

  const [loadInitCustomers, setLoadInitCustomers] = useState(TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST'], authorities));
  const [loadingCustomers, customerList, loadTimestampCustomers] = useFetchData('api/v1/dc/getCustomers', postDataCustomers, refreshCountCustomers, loadInitCustomers, setLoadInitCustomers);
  const [changedUnmatched, setChangedUnmatched] = useState([]);

  const messageBundle = useSelector((state) => state.message).message;
  const [postDataDataset, setPostDataDataset] = useState({});
  const [refreshCountDataset, setRefreshDataset] = useState(0);
  const [loadInitDatasetStatuses, setLoadInitDatasetStatuses] = useState(TUtil.checkAuthority(['MANAGE_INFOHUB_DATASET_STATUS', 'VIEW_INFOHUB_DATASET_STATUS'], authorities));
  const [loadingDataset, outputDataset, loadTimestampDataset] = useFetchData('api/v1/dc/getDatasetStatuses', postDataDataset, refreshCountDataset, loadInitDatasetStatuses, setLoadInitDatasetStatuses);

  const gridRefSourceFile = useRef(this);
  const gridRefDataSet = useRef(this);
  const gridRefUnmatchedDuns = useRef(this);
  const [postDataSourceFiles, setPostDataSourceFiles] = useState({ startDate: TUtil.getLast3DaysDateStr(), endDate: TUtil.getTodayDateStr() });
  const [refreshCountSourceFiles, setRefreshSourceFiles] = useState(0);
  const [sourceQueryDateRange, setSourceQueryDateRange] = useState([TUtil.getLast3DaysDateStr(), TUtil.getTodayDateStr()]);
  const [changedDataSet, setChangedDataSet] = useState([]);

  const [loadInitSourceFiles, setLoadInitSourceFiles] = useState(TUtil.checkAuthority(['MANAGE_INFOHUB_SOURCE_FILE', 'VIEW_INFOHUB_SOURCE_FILE'], authorities));
  const [loadingSourceFiles, outputSourceFiles, loadTimestampSourceFiles] = useFetchData('api/v1/dc/getSourceFilesGridView', postDataSourceFiles, refreshCountSourceFiles, loadInitSourceFiles, setLoadInitSourceFiles);

  const [postDataUnmatchedDuns, setPostDataUnmatchedDuns] = useState({});
  const [refreshCountUnmatchedDuns, setRefreshUnmatchedDuns] = useState(0);

  const [loadInitGetUnmatchedDuns, setLoadInitGetUnmatchedDuns] = useState(TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST', 'VIEW_INFOHUB_UNMAPPED_CUST'], authorities));

  const [loadingUnmatchedDuns, outputUnmatchedDuns, loadTimestampUnmatchedDuns] = useFetchData('api/v1/dc/getUnmatchedDuns', postDataUnmatchedDuns, refreshCountUnmatchedDuns, loadInitGetUnmatchedDuns, setLoadInitGetUnmatchedDuns);
  const [finishedLoadingUnmapped, setFinishedLoadingUnmapped] = useState(false);

  Promise.all([loadingUnmatchedDuns, loadingCustomers]).then(() => {
    setFinishedLoadingUnmapped(() => {
      return !loadingUnmatchedDuns && !loadingCustomers
    })
  });
  const dispatch = useDispatch();
  const authChecker = TUtil.checkAuthority
  const refreshDataset = () => {
    setRefreshDataset(Math.random())
  }
  const refreshSourceFiles = () => {
    setRefreshSourceFiles(Math.random())
  }

  const refreshUnmatchedDuns = () => {
    setRefreshUnmatchedDuns(Math.random())
  }
  const [form] = Form.useForm();
  const [state, setState] = useState({
    dataSetId: 0, datasetList: null,
    action: null,
    isApproveEnabled: false,
    isPublishEnabled: false,
    datasetListLoading: true
  });
  const { RangePicker } = DatePicker;
  const yesNoKeys = {
    'Yes': 'Yes',
    'No': 'No',
    'true': 'Yes',
    'false': 'No'
  }
  const datasetGridSelectValues = {
    'Active': true,
    'Inactive': false,
    'Yes': true,
    'No': false
  }
  const activeInactiveKeys = {
    'Active': 'Active',
    'Inactive': 'Inactive',
    'true': 'Active',
    'false': 'Inactive'
  }
  // </editor-fold>

  let nameMapDataSetStatus = [
    'id', 'sourceUrl', 'parentPipe', 'spiderName', 'datasetListId', 'mngPplId', 'sourcePipelineCode', 'sourcePipelineName', 'datasetName', 'spiderActive', 'transformerActive', 'autoPublish', 'lastScrapedDate', 'lastscrapedCycle', 'active', 'crtDt', 'crtUsr', 'updDt', 'updUsr',
  ];
  let nameMapSourceFilesBeginPortion = [
    'id', 'sourceUrl', 'parentPipe', 'sourcePipelineCode', 'sourcePipelineName', 'gasDay', 'cycleName', 'imbalMon', 'imbalYr', 'locationType', 'locationDir', 'datasetNm', 'filtType', 'actualFileName', 'attachmentDownload', 'postDt', 'processedInd', 'errorInd', 'errorMessage', 'approvedInd', 'approvedDate', 'approvedUser', 'publishedInd', 'publishedDate', 'publishedUser', 'autoPublish', 'notes'
  ];
  let nameMapSourceFilesEndPortion = [
    'crtDt', 'crtUsr', 'updDt', 'updUsr', 'publishStartDt', 'datasetListId', 'managedPipeline'
  ];

  let unmatchedSourceFilesGridHeaders = [
    { field: "sourceFileId", headerName: messageBundle["ih.sourceFileId"] },
    {
      field: 'status', headerName: messageBundle['ih.status'], filter: true, editable: function (props) {
        if (!TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST'], authorities)) return false;
        return props.data.status === "Ready for Review" || props.data.status === "Match and Reprocess" || props.data.status === "Reprocess";
      }, cellEditor: 'selectEditor', cellEditorParams: { values: ['Ready for Review', 'Match and Reprocess', 'Reprocess'], placeholder: "Select Status" }, onCellValueChanged: unMatchedCellEdited
    },
    { field: 'datasetNm', headerName: messageBundle["ih.datasetName"], filter: 'agSetColumnFilter'},
    {
      field: 'customerName', headerName: "Customer", minWidth: 400, filter: true, editable: function (props) {
        if (!TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST'], authorities)) return false;
        return props.data.status === "Match and Reprocess";
      }, cellEditor: 'selectEditor', cellEditorParams: { values: customerList, placeholder: "Select Customer" }, onCellValueChanged: unMatchedCellEdited
    },
    { field: 'shipperName', headerName: messageBundle['ih.shipperName'], filter: true }, { field: 'shipperId', headerName: messageBundle['ih.shipperId'] }, { field: 'propertyId', headerName: messageBundle['Prop Id'] },
    { field: 'crtDt', headerName: messageBundle['ih.crtDt'], ...ColumnTypes.DateTime }, { field: 'crtUsr', headerName: messageBundle['ih.crtUsr'] }, { field: 'updDt', headerName: messageBundle['ih.updDt'], ...ColumnTypes.DateTime }, { field: 'updUsr', headerName: messageBundle['ih.updUsr'] }
  ];

  const onUnmatchedGridReady = (params) => {
    let filter = {
      'status': {
        values: ["Ready for Review"],
        type: 'set',
      },
    };
    params.api.filterManager.setFilterModel(filter);
  };

  function cellEdited(props) {
    let alreadyAdded = false
    let addedIndex = 0
    let cDataSet = changedDataSet
    cDataSet.forEach(function (changedData, i) {
      if (changedData.id === props.data.id) {
        alreadyAdded = true
        addedIndex = i
      }
    })
    let rowData = props.data;
    for (const [key, value] of Object.entries(rowData)) {
      rowData[key] = Object.keys(datasetGridSelectValues).includes(rowData[key]) ? datasetGridSelectValues[rowData[key]] : rowData[key]
    }
    if (alreadyAdded) {
      cDataSet[addedIndex] = rowData
    } else {
      cDataSet.push(rowData)
    }
    setChangedDataSet(() => cDataSet)
  }

  let columnDefsDataset = getNameModelMap(nameMapDataSetStatus);
  if (TUtil.checkAuthority(['MANAGE_INFOHUB_DATASET_STATUS'], authorities)) {
    columnDefsDataset.forEach((columnDef) => {
      if (columnDef.field === "autoPublish") {
        columnDef.editable = true
        columnDef.cellEditor = 'agRichSelectCellEditor'
        columnDef.cellEditorParams = {
          values: ['Yes', 'No'],
          cellRenderer: function (params) {
            if (params && params.value !== undefined) {
              if (Object.keys(yesNoKeys).includes(params.value.toString())) {
                return yesNoKeys[params.value];
              }
              if (Object.keys(datasetGridSelectValues).includes(params.value)) {
                return datasetGridSelectValues[params.value];
              }
            }
            return null
          }
        }
        columnDef.cellRenderer = function (params) {
          if (params && params.value !== undefined) {
            return yesNoKeys[params.value];
          }
          return null
        }
        columnDef.onCellValueChanged = cellEdited
      } else if (columnDef.field === "spiderActive" || columnDef.field === "transformerActive" || columnDef.field === "active") {
        columnDef.editable = true
        columnDef.cellEditor = 'agRichSelectCellEditor'
        columnDef.cellEditorParams = {
          values: ['Active', 'Inactive'],
          cellRenderer: function (params) {
            if (params && params.value !== undefined) {
              if (Object.keys(activeInactiveKeys).includes(params.value.toString())) {
                return activeInactiveKeys[params.value];
              }
              if (Object.keys(datasetGridSelectValues).includes(params.value)) {
                return datasetGridSelectValues[params.value];
              }
            }
            return null
          }
        }
        columnDef.cellRenderer = function (params) {
          if (params && params.value !== undefined) {
            return activeInactiveKeys[params.value];
          }
          return null
        }
        columnDef.onCellValueChanged = cellEdited
      } else if (columnDef.field === 'lastScrapedDate') {
        columnDef.editable = true
        columnDef.cellEditor = 'dateEditor'
        columnDef.onCellValueChanged = cellEdited
      } else if (columnDef.field === 'lastscrapedCycle') {
        columnDef.editable = true
        columnDef.cellEditor = 'agTextCellEditor'
        columnDef.onCellValueChanged = cellEdited
      }
    })
  }

  // add groupings
  const addGrouping = (cols) => {
    let groupColumns = ['parentPipe'];
    groupColumns.forEach(name => {
      let groupColumn = TUtil.find(cols, (c) => c.field === name);
      groupColumn.rowGroup = groupColumn.hide = true;
    });
    return cols;
  };

  const getDatasetForm = () => {
    return (
      <Form style={{ justifyContent: 'flex-end' }} form={form} size='small' layout={'inline'}>
        <Form.Item key={1} style={{ textAlign: 'right' }}>
          <Space>
            {TUtil.checkAuthority(['MANAGE_INFOHUB_DATASET_STATUS'], authorities) ? <Button type="primary" name='datasetSave' htmlType="submit" onClick={saveDatasetRows}>Save</Button> : ''}
            <Button type="primary" name='datasetQuery' htmlType="submit" onClick={refreshDataset}>Query</Button>
          </Space>
        </Form.Item>
      </Form>
    );
  };

  const gadgetItemDataset = {
    grid: <InfoGrid id="DataCollectionDashboard" columnDefs={addGrouping(columnDefsDataset)} data={outputDataset} gridRef={gridRefDataSet}
      gridOptions={{ frameworkComponents: { dateEditor: DateEditorComponent }, height: 600, singleClickEdit: true, stopEditingWhenCellsLoseFocus: true }}>
    </InfoGrid>
  }

  const gadgetDataset = {
    loading: loadingDataset, title: messageBundle['ih.datasetStatuses'], views: ['grid'], // grid
    loadTimestamp: loadTimestampDataset,
    gadgetItem: gadgetItemDataset,
    controls: getDatasetForm(),
    dataSourceId: 'getDatasetStatuses',
    width: props.width || 100
  };

  function saveDatasetRows(e) {
    if (changedDataSet.length === 0) {
      Modal.warn({
        content: 'Please make changes before saving'
      })
      return
    }

    let postData = { datasetStatus: changedDataSet }
    message.loading('Please wait...', 2.5).then(() => {
      DatasetStatusApi.updateDatasetFiles(postData)
        .then(() => performSourceQuery())
        .then(() => dispatch(setPageSuccessAlert({
          show: true,
          message: 'Saved successfully'
        })))
        .catch(e => {
          const msg = (ApiResult.getMessages(e) || []).join('<br/>');
          if (msg) {
            if (e.resultType === 'ERROR') {
              dispatch(setPageErrorAlert({ show: true, message: msg }))
            }
          }
        })
    })
  }

  function saveSourceFileRows(postData) {
    message
      .loading('Please wait...', 2.5)
      .then(() => {
        AdminApi.createSourceFiles(postData)
          .then(() => performSourceQuery())
          .then(() => dispatch(setPageSuccessAlert({
            show: true,
            message: 'Reprocessed successfully'
          })))
          .catch(e => {
            const msg = (ApiResult.getMessages(e) || []).join('<br/>');
            if (msg) {
              if (e.resultType === 'ERROR') {
                dispatch(setPageErrorAlert({ show: true, message: msg }))
              }
            }
          })
      })
  }

  function copySelectedRows(e) {
    if (!gridRefSourceFile.current.gridApi) {
      console.error("Info grid is not initialized properly")
    }
    let gridApi = gridRefSourceFile.current.gridApi,
      selectedRows = gridApi.getSelectedNodes(),
      postData = {}

    if (selectedRows.length === 0) {
      Modal.warn({
        content: 'Please select at least one Source File'
      })
      return
    }
    let selectedDataList = selectedRows.map(node => node.data)
    postData.sourceFiles = selectedDataList
    postData.sourceFileIds = selectedDataList.map(r => r.sourceFileId)

    saveSourceFileRows(postData)
  }

  const handleDateChange = value => {
    if (value) {
      setSourceQueryDateRange(prevState => {
        return [value[0].format('MM/DD/YYYY'), value[1].format('MM/DD/YYYY')]
      })
    }
  };

  const getSourceFileForm = () => {
    return (
      <Form
        style={{ justifyContent: 'flex-end' }}
        form={form}
        size='small'
        initialValues={{ action: 'unpublished', datasetListId: state.datasetList?.[0]?.datasetListId, 'dateRange': [TUtil.getLast3DaysDate(), TUtil.getTodayDate()] }}
        layout={'inline'}
      >
        <Form.Item key={1} label={"Created Date Range"} name={'dateRange'} >
          <RangePicker format={"MM/DD/YYYY"} showTime={false} onChange={handleDateChange} />
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Space>
            <Button type="primary" name='query' htmlType="submit" onClick={performSourceQuery}>Query</Button>
            {TUtil.checkAuthority(['MANAGE_INFOHUB_SOURCE_FILE'], authorities) ? <Button type="primary" name='reprocess' htmlType="submit" onClick={copySelectedRows}>Reprocess</Button> : ''}
          </Space>
        </Form.Item>
      </Form >);
  };

  const performSourceQuery = (e) => {
    let postData = {
      startDate: sourceQueryDateRange[0],
      endDate: sourceQueryDateRange[1]
    }
    setPostDataSourceFiles(prevState => {
      return postData
    })
  }

  const performDatasetQuery = (e) => {
    let postData = { t: new Date().getTime() }
    setPostDataDataset(prevState => {
      return postData
    })
  }

  let columnDefsSourceFiles = [
    ...getNameModelMap(nameMapSourceFilesBeginPortion),
    { field: "rejectedInd", headerName: "Rejected In" },
    { field: "rejectedDate", headerName: "Rejected Dt" },
    { field: "rejectedUser", headerName: "Rejected By" },
    { field: "note", headerName: "Rejection Notes" },
    ...getNameModelMap(nameMapSourceFilesEndPortion),
    { field: 'pipelineCode', headerName: "Managed Pipeline Code" },
    { field: 'pipelineName', headerName: "Managed Pipeline Name" },
    { field: 'datasetStatusId', headerName: messageBundle['ih.datasetStatus.id'] },
    { field: "sourceFileId", headerName: messageBundle["ih.sourceFileId"] },
    { field: "originalSourceFileId", headerName: messageBundle["ih.originalSourceFileDataId"] },
    { field: "attachmentInd", hide: true, suppressToolPanel: true },
    { field: "attachmentInd", hide: true, suppressToolPanel: true }
  ];

  const isRowSelectable = function (rowNode) {
    if (rowNode.data) {
      // Allow reprocess when ( error_ind = '1' or reject_ind = '1' ) AND Orig ID is NULL
      if (rowNode.data.originalSourceFileId) {
        if (rowNode.data.errorInd == 1 || rowNode.data.rejectedInd == 1) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  }

  const sourceFileGridOptions = {
    headerCheckbox: true,
    height: 600,
    isRowSelectable: isRowSelectable
  }

  const gadgetItemSourceFiles = {
    grid: <InfoGrid id="DataCollectionDashboard_Source" columnDefs={addGrouping(columnDefsSourceFiles)} data={outputSourceFiles} gridRef={gridRefSourceFile}
      gridOptions={sourceFileGridOptions} ></InfoGrid>
  }
  const gadgetSourceFiles = {
    loading: loadingSourceFiles,
    title: messageBundle['ih.sourceFiles'],
    views: ['grid'], // grid
    gadgetItem: gadgetItemSourceFiles,
    loadTimestamp: loadTimestampSourceFiles,
    controls: getSourceFileForm(),
    dataSourceId: 'getSourceFilesStatuses',
    width: props.width || 100
  };

  const performUnmatchedDunsQuery = (e) => {
    let postData = { t: new Date().getTime() }
    setPostDataUnmatchedDuns(prevState => {
      return postData
    })
  }

  function unMatchedCellEdited(props) {
    let alreadyAdded = false
    let addedIndex = 0
    let cDataSet = changedUnmatched
    cDataSet.forEach(function (changedData, i) {
      if (changedData.id === props.data.id) {
        alreadyAdded = true
        addedIndex = i
      }
    })
    let rowData = props.data;
    if (alreadyAdded) {
      cDataSet[addedIndex] = rowData
    } else {
      cDataSet.push(rowData)
    }
    setChangedUnmatched(() => cDataSet)
  }

  function cancelUnmatchedRows(e) {
    Modal.confirm({
      title: "Cancel changes?",
      content: "Do you want to revert all changes and reload the grid?",
      onOk() {
        refreshUnmatchedDuns();
        setChangedUnmatched(() => [])
      }
    })
  }

  function saveUnmatchedRows(e) {
    if (changedUnmatched.length === 0) {
      Modal.warn({
        content: 'Please make changes before saving',
      })
      return
    }
    let cDataSet = changedUnmatched
    for (let j = 0; j < changedUnmatched.length; j++) {
      const changedData = changedUnmatched[j];
      if (changedData.status === "Match and Reprocess") {
        if (!changedData.customerName) {
          Modal.warn({
            content: 'Please select Customer for Matching',
          })
          return
        }
      }
    }

    let postData = { unmatchedDunsList: changedUnmatched }
    message.loading('Please wait...', 2.5).then(() => {
      DatasetStatusApi.createMatchedDuns(postData)
        .then(() => performUnmatchedDunsQuery())
        .then(() => {
          setChangedUnmatched(() => []);
          dispatch(setPageSuccessAlert({
            show: true,
            message: 'Saved successfully'
          }))
        })
        .catch(e => {
          const msg = ApiResult.getMessages(e);
          if (msg) {
            if (e.resultType === 'ERROR') {
              dispatch(setPageErrorAlert({ show: true, message: msg }))
            }
          }
        })
    })
  }

  const getUnmatchedDunsForm = () => {
    return (
      <Form
        style={{ justifyContent: 'flex-end' }} form={form} size='small' layout={'inline'}>
        <Form.Item style={{ textAlign: 'right' }}>
          <Space>
            {TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST'], authorities) ?
              <>
                <Button type="primary" name='query' htmlType="submit" onClick={saveUnmatchedRows}>Save</Button>
                <Button type="primary" name='reprocess' htmlType="submit" onClick={cancelUnmatchedRows}>Cancel</Button>
              </>
              : ''}
          </Space>
        </Form.Item>
      </Form >);
  };

  const gadgetItemUnmatchedDuns = {
    grid: <InfoGrid id="DataCollectionDashboard_Unmatched" columnDefs={unmatchedSourceFilesGridHeaders} data={outputUnmatchedDuns} gridRef={gridRefUnmatchedDuns}
      gridOptions={{ events: { onGridReady: onUnmatchedGridReady }, frameworkComponents: { selectEditor: SelectorComponent }, height: 600, singleClickEdit: true, stopEditingWhenCellsLoseFocus: true }}>
    </InfoGrid>
  }
  const unMappedGadgetSourceFiles = {
    loading: !finishedLoadingUnmapped,
    title: messageBundle['ih.sourceFiles.unmapped'],
    views: ['grid'],
    gadgetItem: gadgetItemUnmatchedDuns,
    loadTimestamp: loadTimestampUnmatchedDuns,
    controls: getUnmatchedDunsForm(),
    dataSourceId: 'getUnmatchedDunsStatuses',
    width: props.width || 100
  };

  return (
    <Collapse className={'footnotesCollapseContainer'} defaultActiveKey={['1', '2', '3']}>
      <Panel header={messageBundle['ih.datasetStatuses']} key='1'>
        {TUtil.checkAuthority(['MANAGE_INFOHUB_DATASET_STATUS', 'VIEW_INFOHUB_DATASET_STATUS'], authorities) ? <Gadget {...gadgetDataset} /> :
          <span>{messageBundle['ih.error.missingRequiredWorkflows']} {['MANAGE_INFOHUB_DATASET_STATUS', 'VIEW_INFOHUB_DATASET_STATUS'].join(" or ")}.</span>
        }
      </Panel>
      <Panel header={messageBundle['ih.sourceFiles']} key='2'>
        {TUtil.checkAuthority(['MANAGE_INFOHUB_SOURCE_FILE', 'VIEW_INFOHUB_SOURCE_FILE'], authorities) ? <Gadget {...gadgetSourceFiles} /> :
          <span>{messageBundle['ih.error.missingRequiredWorkflows']} {['MANAGE_INFOHUB_SOURCE_FILE', 'VIEW_INFOHUB_SOURCE_FILE'].join(" or ")}.</span>
        }
      </Panel>

      <Panel header={messageBundle['ih.sourceFiles.unmapped']} key='3'>
        {TUtil.checkAuthority(['MANAGE_INFOHUB_UNMAPPED_CUST', 'VIEW_INFOHUB_UNMAPPED_CUST'], authorities) ? <Gadget {...unMappedGadgetSourceFiles} /> :
          <span>{messageBundle['ih.error.missingRequiredWorkflows']} {['VIEW_INFOHUB_UNMAPPED_CUST', 'MANAGE_INFOHUB_UNMAPPED_CUST'].join(" or ")}.</span>
        }
      </Panel>
    </Collapse >
  );
}

export default DataCollectionDashboard;
