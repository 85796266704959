import React, { useRef, useState } from 'react';
import Gadget from 'infogadget'
import { BarChart } from 'infochart'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { IocChartConfig } from 'chart/config';
import DataMapper from 'util/DataMapper';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useHistory } from 'react-router-dom'

const PipelinesByCustomer = (props) => {
    const { configs } = useSelector((state) => state.info)

    const { message } = useSelector((state) => state.message)
    const [postData] = useState({});
    const [refreshCount, setRefresh] = useState(0);

    const [postDataMaxCalendarQuarter] = useState({});
    const [loadingMaxCalendarQuarter, outputMaxCalendarQuarter] = useFetchData('api/v1/ioc/getMostRecentFirstDayCalendarQuarter', postDataMaxCalendarQuarter, refreshCount);
    
    const [loading, output, loadTimestamp] = useFetchData('api/v1/ioc/getPipelinesByCustomer', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };
    const history = useHistory();

    const chartRef = useRef();

    let chartData = DataMapper.convertIocEntityCountData(output);

    const gadgetItem = {
        topDisplayItems: [<div className='gadget-item-top'>{
            "Total pipelines for top " + configs?.widgetMaxResultsMap?.IOC_PIPELINES_BY_CUSTOMER + " customers: "  + chartData.reduce((a, b) => a + b.Count, 0).toLocaleString()
        }</div>],
        chart: <BarChart chartRef={chartRef} customChartConfig={IocChartConfig} data={chartData} />
    };

    T.registerChartListener(chartRef, (event) => {
        if (event.data) {
            history.push(T.buildURL('/ioc/PipelinesByCustomerDetail'), {
                entityId: event.data.data.entityId
            });
        }
    });

    const getTitle = () => {
        return message['ih.widget.ioc.pipelinesByCustomer'] + " " + (outputMaxCalendarQuarter.firstDayCalendarQuarter ? outputMaxCalendarQuarter.firstDayCalendarQuarter : "N/A");
    };


    const gadget = {
        loading: loading || loadingMaxCalendarQuarter,
        refresh,
        id: 'ioc2',
        views: ['chart'],
        gadgetItem,
        title: getTitle(),
        loadTimestamp: loadTimestamp,
        dataSourceId: 'getPipelinesByCustomer',
        width: 50
    };
    return (
        <Gadget {...gadget} {...props}> </Gadget>
    )
};

export default PipelinesByCustomer;