import {Row, Col, Form, Button, Radio, Space, Select, Checkbox, Input, InputNumber, Collapse} from 'antd';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import { RangeDatePickerAutoAccept, DatePickerAutoAccept } from "./DatePickerAutoAccept";
import {CaretRightOutlined, InfoCircleOutlined} from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import './ControlFactory.css'

const { Panel } = Collapse;

export default class ControlFactory {

  static createControls = ({ controls, settings = {},
    initialValues, layout, submit, reset, formRef, onValuesChange = null }) => {

    const colSpan = settings.colSpan || 1;

    /**
     * 
     * Form is inline if any of these are true
     * 1) all fields are hidden
     * 2) There is only single element
     * 
     */
    const getIsInlineFormLayout = () => {
      if (controls.length <= 1) return true;
      for (let i = 0; i < controls.length; i++) {
        let control = controls[i];
        if (control.type !== 'hidden') return false;
      }
      return true;
    }

    const getFormItemLayout = () => {
      if (isInline) return {};
      if (colSpan > 1) {
        return { labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 24 }, lg: { span: labelSpan } } };
      }
      return { labelCol: { span: labelSpan } }
    }

    const getFormTailLayout = () => {
      if (isInline) return {};
      if (colSpan > 1) {
        return { wrapperCol: { /*span: 8, */offset: colSpan == 1 ? 0 : labelSpan } };
      }
      return { wrapperCol: { span: 8, offset: colSpan == 1 ? 0 : labelSpan } }
    }

    const getLabelSpan = () => {
      if (colSpan == 1) return 12; // half the width.

      if (colSpan > 1) {
        return 9; // hardcoded for now. one-third of the column
      }

      // find length of longest label and adjust span
      let span = 2, maxLengthControl = 0;
      controls.forEach(c => maxLengthControl = Math.max(maxLengthControl, c.label?.length))
      if (maxLengthControl > 20) span = 4;
      else if (maxLengthControl > 10) span = 3;
      return span;
    }

    const isInline = getIsInlineFormLayout();

    const hasNoVisibleControls = isInline && (controls.length === 0 || (controls.length > 0 && controls[0].type === 'hidden'))
    const labelSpan = getLabelSpan();

    const formItemLayout = getFormItemLayout();
    const formTailLayout = getFormTailLayout();

    /**
     * Add defaults
     * @param {} controls 
     * @returns 
     */
    const preprocess = (controls) => {
      return controls.map(control => {
        let ret = { ...control };
        switch (control.type) {
          case 'datepicker': if (!control.format) ret.format = 'MM/DD/YYYY'; break;
          case 'dateTimepicker': if (!control.format) ret.format = 'MM/DD/YYYY HH:mm'; break;
          case 'datepickerAsTime': if (!control.format) ret.format = 'MM/DD/YYYY HH:mm'; break;
          default: break;
        }
        return ret;
      })
    }

    let allControls = preprocess(controls);

    const getControl = (control) => {
      switch (control.type) {
        case 'singleDatePicker': return createSingleDatePicker(control);
        case 'singleDateTimePicker': return createSingleDateTimePicker(control);
        case 'datepicker': return createDatePicker(control);
        case 'datepickerAsTime': return createDatePickerAsTime(control);
        case 'dateTimepicker': return createDateTimePicker(control);
        case 'radio': return createRadioOptions(control);
        case 'singleCheckbox': return createSingleCheckboxOptions(control);
        case 'checkbox': return createCheckboxOptions(control);
        case 'select': return createSelectOptions(control);
        case 'multiselect': return createMultiSelectOptions(control);
        case 'string': return createString(control);
        case 'integer': return createInteger(control);
        case 'hidden': return createHidden(control);
        case 'customField': return createCustomField(control);
        default: return null;
      }
    }

    const createSingleDatePicker = (control) => {
      return <Form.Item key={control.id} label={control.label} name={control.name} >
        <DatePickerAutoAccept format={control.format || 'MM/DD/YYYY'} />
      </Form.Item>
    }

    const createSingleDateTimePicker = (control) => {
      return <Form.Item key={control.id} label={control.label} name={control.name} >
        <DatePickerAutoAccept format={control.format || 'MM/DD/YYYY HH:mm'} showTime placeholder='Select date time' />
      </Form.Item>
    }

    const createDatePicker = (control) => {
      return <Form.Item key={control.id} label={control.label} name={control.name} >
        <RangeDatePickerAutoAccept picker={control.picker || "day"} format={control.format} />
      </Form.Item>
    }

    const createDateTimePicker = (control) => {
      const rules = control.rules || [];
      const options = {rules};
      return <Form.Item key={control.id} label={control.label} name={control.name} {...options} >
        <RangeDatePickerAutoAccept format={control.format} showNow={true} showTime={true}  />
      </Form.Item>
    }

    const createDatePickerAsTime = (control) => {
      const rules = control.rules || [];
      const options = {rules};
      return <Form.Item key={control.id} label={control.label} name={control.name} {...options} >
        <RangeDatePickerAutoAccept format={'MM/DD/YYYY'} showNow={false} showTime={false}  />
      </Form.Item>
    }

    const createRadioOptions = (control) => {
      const ret = control?.values.map((i, j) => <Radio key={j} value={i.value}>{i.label}</Radio>)
      return <Form.Item key={control.id} name={control.name} label={control.label}>
        <Radio.Group>
          <Space direction="horizontal">
            {ret}
          </Space>
        </Radio.Group>
      </Form.Item>
    }

    const createSingleCheckboxOptions = (control) => {
      // TODO: extract the common parts like onclick, styles, rules to outside this function and apply to all.
      const onClick = control.onClick || undefined;
      const tooltip = !T.isUndefined(control.tooltip) ? { tooltip: { title: control.tooltip, icon: <InfoCircleOutlined /> } } : {}
      return <Form.Item key={control.id} valuePropName="checked" {...tooltip} name={control.name} label={control.label} onClick={onClick}>
        <Checkbox disabled={control.disabled} options={control.values} />
      </Form.Item>
    }

    const createCheckboxOptions = (control) => {
      // TODO: extract the common parts like onclick, styles, rules to outside this function and apply to all.
      const onClick = control.onClick || undefined;
      return <Form.Item key={control.id} name={control.name} label={control.label} onClick={onClick}>
        <Checkbox.Group disabled={control.disabled} options={control.values} />
      </Form.Item>
    }

    const createMultiSelectOptions = (control) => {
      const ret = control.values ? control.values.map(i => <Select.Option value={i.value}>{i.label}</Select.Option>) : null;
      return <Form.Item key={control.id} name={control.name} label={control.label} >
        <Select style={{ width: '30%' }}
          mode="multiple"
          filterOption={
            (inputValue, option) => {
              return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
          }
          allowClear
          placeholder={`Please select ${control.name}`} {...(control.otherProps ? control.otherProps : {})}
        >
          {ret}
        </Select>
      </Form.Item>
    }

    const createSelectOptions = (control) => {
      const ret = control.values ? control.values.map(i => <Select.Option value={i.value}>{i.label}</Select.Option>) : null;
      return <Form.Item key={control.id} name={control.name} label={control.label} >
        <Select style={{ width: '90%', ...control.style }}>
          {ret}
        </Select>
      </Form.Item>
    }

    const createString = (control) => {
      const tooltip = !T.isUndefined(control.tooltip) ? { tooltip: { title: control.tooltip, icon: <InfoCircleOutlined /> } } : {}
      return <Form.Item key={control.id} {...tooltip} name={control.name} label={control.label}>
        <Input style={{ width: 168, ...control.style }} placeholder={control.placeholder || control.label} maxLength={control.maxLength} />
      </Form.Item>
    }

    const createInteger = (control) => {
      const tooltip = !T.isUndefined(control.tooltip) ? { tooltip: { title: control.tooltip, icon: <InfoCircleOutlined /> } } : {}
      // TODO: extract the common parts like styles, rules to outside this function and apply to all.
      const style = {
        width: 168,
        ...control.style,
        //"text-align": 'right'
      }

      const rules = [];
      if (control.required)
        rules.push({ required: true, message: 'Please input ' + control.label });

      const options = { style, rules, id: control.id, disabled: control.disabled };
      return <Form.Item key={control.id} {...tooltip} name={control.name} label={control.label}>
        <InputNumber placeholder={control.placeholder || control.label} max={control.max || Infinity}
          maxLength={control.maxLength || null} {...options} />
      </Form.Item>
    }

    const createHidden = (control) => {
      return <Form.Item hidden={true} key={control.id} name={control.name} label={control.label}>
        <Input placeholder={control.label} maxLength={control.maxLength} />
      </Form.Item>
    }

    const createCustomField = (control) => {
      const tooltip = !T.isUndefined(control.tooltip) ? { tooltip: { title: control.tooltip, icon: <InfoCircleOutlined /> } } : {}
      return <Form.Item label={control.label} {...tooltip}>
        {control.customField}
      </Form.Item>
    }

    const onReset = (e) => {
      if (reset) reset(e);
    }

    const getControlsForMultiColumn = () => {
      const ControlsForMulti = () => {
        const breakpoints = useBreakpoint();
        const [isSmallScreen, setIsSmallScreen] = useState(false);

        useEffect(() => {
          //Set as small screen when it is resized to be less than the 'large' breakpoint.
          const handleResize = () => {
            setIsSmallScreen(!breakpoints.lg && !breakpoints.xl && !breakpoints.xxl);
          };

          //Trigger a resize event when the component mounts.
          const initialResizeEvent = new Event('resize');
          window.dispatchEvent(initialResizeEvent);

          //Call handleResize on the initial mount to set the correct style.
          handleResize();

          window.addEventListener('resize', handleResize);

          //Clean up the event listener when the component is unmounted.
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [breakpoints]);

        const [isExpanded, setIsExpanded] = useState(true);
        const handleExpandedToggle = () => {
          setIsExpanded(!isExpanded);
        };

        const queryResetButtons = <Form.Item key='1000' style={{ marginRight: '-5px' }}>
          <Button type="primary" htmlType="submit" style={{ marginBottom: '8px' }}>Query</Button>
          <Button htmlType="button" style={{ marginLeft: '5px' }} onClick={onReset}>Reset</Button>
        </Form.Item>;
        const queryParamCollapseArrow = <Button type="text" style={{marginLeft: '-11px', backgroundColor: 'transparent'}} ghost={true} icon=
            {<CaretRightOutlined style={{ fontSize: '12px', transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)' }} />} onClick={handleExpandedToggle}>
        </Button>;

        let controlMap = allControls.map(getControl);
        //Get the query parameters as two columns if a large screen and one column if a small screen.
        let queryParams = !isSmallScreen ? generateMultiColumnRowsFromMap(controlMap) : controlMap;

        return !isSmallScreen ?
            //Large screen horizontal control layout.
            [<Row className={'query-param'} style={{marginBottom: '-18px'}} gutter={0}>
          <Col span={1}>{queryParamCollapseArrow}</Col>
          <Col span={19}>
            <div>{queryParams[0]}</div>
            {isExpanded && (<div style={{marginBottom: '9px'}}>{queryParams.splice(1)}</div>)}
          </Col>
          <Col span={4} style={{textAlign:'right'}}>{queryResetButtons}</Col>
        </Row>]
            :
            //Small screen vertical control layout.
            [<Row className={'query-param'} gutter={0}>
          <Col span={1}>{queryParamCollapseArrow}</Col>
          <Col span={23} style={{marginBottom: '-16px'}}>{queryParams[0]}{queryParams[1]}{isExpanded && <div>{queryParams.splice(2)}</div>}{queryResetButtons}</Col>
        </Row>]
      }

      return ControlsForMulti;
    }

    function generateMultiColumnRowsFromMap(map) {
      const rows = [];
      let pairCount = 0;
      let currentRow = [];

      for (const [key, value] of map.entries()) {
        currentRow.push(
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={key}>{value}</Col>
        );

        pairCount++;

        if (pairCount == 2) {
          rows.push(
              <Row>{currentRow}</Row>
          );

          currentRow = [];
          pairCount = 0;
        }
      }

      // Add the last row if there are an odd number of key-value pairs
      if (currentRow.length > 0) {
        rows.push(<Row><Col xs={24} sm={24} md={24} lg={12} xl={12}>{}</Col>{currentRow}</Row>);
      }

      return rows;
    }

    const getControlsForSingleColumn = () => {
      const ControlsForSingle = () => {
        const breakpoints = useBreakpoint();
        const [isSmallScreen, setIsSmallScreen] = useState(false);

        useEffect(() => {
          //Set as small screen when it is resized to be less than the 'large' breakpoint.
          const handleResize = () => {
            setIsSmallScreen(!breakpoints.md && !breakpoints.lg && !breakpoints.xl && !breakpoints.xxl);
          };

          //Trigger a resize event when the component mounts.
          const initialResizeEvent = new Event('resize');
          window.dispatchEvent(initialResizeEvent);

          //Call handleResize on the initial mount to set the correct style.
          handleResize();

          window.addEventListener('resize', handleResize);

          //Clean up the event listener when the component is unmounted.
          return () => {
            window.removeEventListener('resize', handleResize);
          };
        }, [breakpoints]);

        let marginBottomAmount = hasNoVisibleControls ? '2px' : '5px';
        const queryButton = <Form.Item key='1000' {...formTailLayout} style={{marginRight: '-5px', marginBottom: marginBottomAmount}}>
          <Button type="primary" htmlType="submit">Query</Button>
        </Form.Item>;

        let controlMap = allControls.map(getControl);
        if(getIsInlineFormLayout) {
          return [<Row className={'query-param'} gutter={0}></Row>];
        }else{
          return !isSmallScreen ?
              //Large screen horizontal control layout.
              [<Row className={'query-param'} gutter={0}>
                <Col span={20}>{controlMap}</Col>
                <Col span={4} style={{textAlign: 'right'}}>{queryButton}</Col>
              </Row>]
              :
              //Small screen vertical control layout.
              [<Row className={'query-param'} gutter={0}>
                <Col span={24}>{controlMap}</Col>
                <Col span={24} style={{marginTop: '8px'}}>{queryButton}</Col>
              </Row>]
        }
      }

      return ControlsForSingle;
    }

    /**
     * Create controls.
     * Add Submit button
     */
    const getControls = () => {
      if (colSpan > 1) return getControlsForMultiColumn();
      return getControlsForSingleColumn();
    }

    const isDate = (type) => {
      switch (type) {
        case 'singleDatePicker':
        case 'datepicker':
        case 'dateTimepicker':
        case 'datepickerAsTime':
        case 'singleDateTimePicker':
          return true;
        default:
          return false;
      }
    }

    const onFinish = (e) => {
      let postData = {};
      allControls.forEach(f => {
        if (isDate(f.type)) {
          if (f.type === 'singleDatePicker' || f.type === 'singleDateTimePicker') {
            postData[f.name] = e[f.name] ? T.getDateStr(e[f.name], f.format) : null;
          } else {
            const regEx = new RegExp('daterange', "ig");
            let result = f.name.replace(regEx, '');
            if (!result.length) {
              result += "s"
            } else {
              result += "S"
            }
            result += "tartDate";
            postData[result] = e[f.name] ? T.getDateStr(e[f.name][0], f.format) : null;
            result = f.name.replace(regEx, '');
            if (!result.length) {
              result += "e"
            } else {
              result += "E"
            }
            result += "ndDate";
            postData[result] = e[f.name] ? T.getDateStr(e[f.name][1], f.format) : null;
          }
        } else if (f.type === 'multiselect') {
          const val = e[f.name];
          let key = f.name;
          if (T.isArray(val)) {
            postData[key] = val;
          } else {
            postData[key] = [val];
          }
        } else if (f.type === 'checkbox') {
          const val = e[f.name];
          let key = f.name;
          if (T.isArray(f.values) && f.values.length < 2 && T.isArray(val)) {
            postData[key] = val[0];
          } else {
            postData[key] = val;
          }
        }
        else {
          postData[f.name] = e[f.name];
        }
      })
      submit(postData)
    }

    let formLayout = 'horizontal';
    if (isInline) {
      formLayout = 'inline';
    }

    let formStyle = { justifyContent: isInline ? 'flex-end' : 'flex-start' };
    if (colSpan == 1 && hasNoVisibleControls) {
      formStyle = { textAlign: 'right' }
    }
    let form = <Form
      style={formStyle}
      size='small'
      ref={formRef}
      onFinish={onFinish}
      initialValues={initialValues}
      {...formItemLayout}
      onValuesChange={onValuesChange}
      layout={layout || formLayout}>
      {getControls()}
    </Form>
    return form;
  }

}