import ManageData from './ManageData';
import ManageCache from './ManageCache';

import DataCollectionDashboard from './DataCollectionDashboard';
import {Route} from 'react-router-dom';
import { TUtil as T } from '@trellisenergy/common-ui-core';
import ManageLogs from "./ManageLogs";
import ManagedScheduledTasks from "./ManagedScheduledTasks";

function Admin(props) {
  return (
    <div>
      <Route exact path={T.buildURL('/admin/dataCollectionDashboard')}>
        <DataCollectionDashboard {...props} />
      </Route>
      <Route exact path={T.buildURL('/admin/manageData')}>
        <ManageData {...props} />
      </Route>
      <Route path={T.buildURL('/admin/cache')}>
        <ManageCache {...props} />
      </Route>
        <Route path={T.buildURL('/admin/logs')}>
            <ManageLogs {...props} />
        </Route>
        <Route path={T.buildURL('/admin/manageScheduledTasks')}>
            <ManagedScheduledTasks {...props} />
        </Route>
    </div>
  );
}

export default Admin;