import React, {useRef, useState} from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'

import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';
import useFetchData from "../../../api/service";
import QuickConfig from "../../header/QuickConfig";
import ControlFactory from 'infocontrol';

const AllPreApprovedBidder = (props) => {
    const { message } = useSelector((state) => state.message);
    const queryForm = useRef();
    const [postData, setPostData] = useState({});
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/crlsPab/getPreApprovedBidders', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    let nameMap = [
        'tspName',
        'preApprovedBidderName',
        'duns',
        'tspProp',
        'preApprovedBidder',
        'statementDateTime',
    ];

    const processColumns = (cols) => {
        let groupingCols = [
            'tspName',
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });

        groupingCols.forEach(colModel => {
            if (colModel) {
                colModel.rowGroup = true;
                colModel.hide = true;
            }
        });

        let sortColumns = [
            'tspName',
            'bidderName'].map(field => {
                return T.find(cols, (c) => c.field === field)
            });

        sortColumns.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    };

    let columnDefs = processColumns(getNameModelMap(nameMap));
    const [pipelineOptions, setPipelineOptions] = useState([]);
    const onPipelineSelect = () => {
        QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
    }
    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
                values: pipelineOptions, otherProps: {
                    showSearch: true,
                    options: pipelineOptions,
                    onFocus: onPipelineSelect,
                    style: { width: '100%'}
                }
            },
            {}
        ],
        submit:  (prevState) => {
            if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
                prevState.pipelines = [];
            }
            setPostData(prevState);
        },
        settings: { colSpan: 2 },
        formRef: queryForm,
        reset: (e) => {
            queryForm.current?.resetFields();
        }
    });

    const gadgetItem = { grid: <InfoGrid id="AllPreApprovedBidder" gridOptions={{
            autoGroupColumnDef: {
                sort: 'asc'
            },
        }} columnDefs={columnDefs} data={output}></InfoGrid> };
    const gadget = {
        loading,
        title: message['ih.widget.capacityRelease.allApprovedBidder'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls: controls,
        width: props.width || 100
    };

    return (
        <Gadget {...gadget} {...props} />
    );
};

export default AllPreApprovedBidder;
