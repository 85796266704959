import { configureStore } from '@reduxjs/toolkit'
import messageReducer from 'redux/reducer/messageResource'
import locationReducer from 'redux/reducer/location'
import infoReducer from "redux/reducer/info";
import cacheReducer from "redux/reducer/cache";

export default configureStore({
  reducer: {
    message: messageReducer,
    location: locationReducer,
    info: infoReducer,
    cache: cacheReducer,
  },
})