import { getNameModelMap } from '../../../asset/GridColumnNameMap';
import SharedColDefs from 'asset/SharedColDefs';

const parentNameMap = [
  'sourceFileId',
  'displayFileName',
  'datasetListId',
  'fileType',
  "parentPipe",
  "sourcePipelineCode",
  "sourcePipelineName",
  "gasDay",
  "cycleName",
  "imbalMon",
  "imbalYr",
  "locationType",
  "locationDir",
  "datasetNm",
  //"actualFileName",
  "attachment",
  "postDt",
  "processedInd",
  "errorInd",
  "errorMessage",
  "approvedInd",
  "approvedDate",
  "approvedUser",
  "publishedInd",
  "publishedDate",
  "publishedUser",
  "rejectedInd",
  "rejectedDate",
  "rejectedUser",
  "note", // custom notes for actions
  "notes",
  "crtDt",
  "crtUsr",
  "updDt",
  "updUsr",
  "publishStartDt",
  "datasetStatusId"
];

//export const parentColumnDefs = getNameModelMap(parentNameMap);

const genericChildNameMap = [
  'sourceFileDataId',
  'pipelineShortName',
  'pipelineName',
  'criticalIndicator',
  'criticalNoticeDescription',
  'noticeStartDate',
  'noticeEndDate',
  'noticeIdentifier',
  'noticeStatusDescription',
  'noticeType',
  'postingDate',
  'priorNoticeIdentifier',
  'responseRequiredIndicatorDescription',
  'responseRequiredByDate',
  'subject',
  'noticeTextClob',
  'createDate',
  'createUser',
];
//export const genericChildColumnDefs = getNameModelMap(genericChildNameMap);

const iocPipelinesNameMap = [
  "pipelineName",
  "companyId",
  "reportDate",
  "originalRevisedIndicator",
  "firstDateCalendarQuarter",
  "uomTransportMdq",
  "uomStorageMdq",
  "contactPerson",
  "footnoteItemList",
  "createDate",
  "createUser",
]
//export const iocPipelinesColumnDefs = getNameModelMap(iocPipelinesNameMap);

const iocContractsNameMap = [
  "pipelineName",
  "rateSchedule",
  "contractNumber",
  "shipperName",
  "contractEffectiveDate",
  "contractPrimeTermExpirationDate",
  "shipperAffiliateIndicator",
  "daysUntilExpire",
  "negativeRatesIndicator",
  'transportMdq',
  'transportMdqUom',
  'storageMdq',
  'storageMdqUom',
  "footnoteItemList",
]
//export const iocContractsColumnDefs = getNameModelMap(iocContractsNameMap);

const iocContractPointsNameMap = ["pipelineName",
  "rateSchedule",
  "contractNumber",
  "shipperName",
  "contractEffectiveDate",
  "contractPrimeTermExpirationDate",
  "shipperAffiliateIndicator",
  "daysUntilExpire",
  "negativeRatesIndicator",
  "transportMdq",
  "storageMdq",
  "footnoteItemList",
  "pointIdentifierCode",
  "pointName",
  "pointCodeQualifier",
  "pointCode",
  "zoneName",]
//export const iocContractPointsColumnDefs = getNameModelMap(iocContractPointsNameMap);

const iocContractAgentsNameMap = [
  "pipelineName",
  "rateSchedule",
  "contractNumber",
  "shipperName",
  "contractEffectiveDate",
  "contractPrimeTermExpirationDate",
  "shipperAffiliateIndicator",
  "daysUntilExpire",
  "negativeRatesIndicator",
  "transportMdq",
  "storageMdq",
  "footnoteItemList",
  "agentAmaName",
  "agentAffiliateIndicator",
];
//export const iocContractAgentsColumnDefs = getNameModelMap(iocContractAgentsNameMap);

const iocFootnotesNameMap = [
  'footnoteNumber',
  'footnoteNumberPrefix',
  'footnoteText'
];

//export const iocFootnotesColumnDefs = getNameModelMap(iocFootnotesNameMap);
const capNoNoticeSvcNameMap = [
  'pipelineCode',
  'pipelineName',
  'pipelinePropName',
  'effBegGasDtTm',
  'effEndGasDtTm',
  'reportDt',
  'comments',
  'loc',
  'locPurp',
  'locPurpDesc',
  'locName',
  'locCity',
  'locStAbbrev',
  'dirFlow',
  'noNoticeQty',
  'accntPhyInd',
  'measBasisDesc',
  'locQtiDesc',
  'locQti',
  'variance'
];

const operAvailCapNameMap = [
  'pipelineCode',
  'pipelineName',
  'pipelinePropName',
  'cycleInd',
  'cycleName',
  'locComments',
  'reportDt',
  'effGasDtTm',
  'measBasis',
  'measBasisDesc',
  'locName',
  'loc',
  'locPurp',
  'locPurpDesc',
  'flowInd',
  'flowIndDesc',
  'locQti',
  'locQtiDesc',
  'locZn',
  'allQtyAvailInd',
  'designCapacity',
  'operatingCapacity',
  'tsq',
  'operAvailCap',
  'itInd',
  'itIndDesc',
  'nomCapExceedInd',
  'qtyReason',
  'authOverrunInd',
  'authOverrunIndDesc',
  'pressBase',
  'pressBaseInd',
  'pressBaseDesc'
];

let unsubbedCapNameMap = [
  'pipelineCode',
  'pipelineName',
  'pipelinePropName',
  'effGasDay',
  'effEndGasDay',
  'loc',
  'locName',
  'locPurpDesc',
  'unsubscribedCap',
  'measBasisDesc',
  'measBasis',
  'reportDt',
  'locComments',
  'locQtiDesc',
  'locPurp',
  'locQti',
  'locZn',
  'pressBase',
  'pressBaseInd',
  'pressBaseDesc',
  // 'commentDtl',
  'totalDesignCapacity'
];

// ------------------------
// Transactional Reporting
// ------------------------

// trnsHeaders
// trnsKs
// trnsKIds
// trnsLimits
// trnsRates
// trnsTerms
// trnsCapRels
const trnsHeadersMap = [
  'id',
  'sourceFileId',
  'sourceFile',
  'datasetListId',
  'managedPipeline',
  'pipelineCode',
  'pipelineName',
  'pipelinePropName',
  'reportDt',
  'reportAmendDesc',
  'reportAmendDescLkupId',
  'osId',
  'footnoteItemList',
  'crtDt',
  'crtUsr',
]

const trnsKsNameMap =
  [
    'id',
    'trnsHeaderId',
    'trnsHeader',
    'rateSchedule',
    'kHolder',
    'kHolderName',
    'kHolderProp',
    'svcReqK',
    'kRoll',
    'kRollDesc',
    'kStatus',
    'kStatusDesc',
    'kBegDate',
    'kEndDate',
    'kEndDateInd',
    'kEntBegDate',
    'kEntEndDate',
    'kEntBegTime',
    'kEntEndTime',
    'affil',
    'affilIndDesc',
    'surchgInd',
    'surchgIndDesc',
    'termNotes',
    'resRateBasis',
    'affilLkupId',
    'affilIndDescLkupId',
    'kEndDateIndLkupId',
    'kRollLkupId',
    'kRollDescLkupId',
    'kStatusLkupId',
    'kStatusDescLkupId',
    'kHolderCbpId',
    'kHolderCbpName',
    'measBasisDesc',
    'measBasisLkupId',
    'pressBase',
    'pressBaseLkupId',
    'pressBaseIndLkupId',
    'pressBaseDesc',
    'crtDt',
    'crtUsr',
  ]
const trnsKIdsNameMap = [
  'id',
  'trnsKId',
  'trnsK',
  'negRatesInd',
  'negRatesIndDesc',
  'marketBasedRateInd',
  'discountInd',
  'discountIndDesc',
  'surchgInd',
  'surchgIndDesc',
  'begDate',
  'endDate',
  'marketBasedRateIndLkupId',
  'negRatesIndLkupId',
  'negRatesIndDescLkupId',
  'surchgIndLkupId',
  'surchgIndDescLkupId',
  'crtDt',
  'crtUsr',
]
const trnsLimitsNameMap =
  [
    'id',
    'trnsKId',
    'trnsK',
    'negRatesInd',
    'negRatesIndDesc',
    'marketBasedRateInd',
    'discountInd',
    'discountIndDesc',
    'surchgInd',
    'surchgIndDesc',
    'begDate',
    'endDate',
    'marketBasedRateIndLkupId',
    'negRatesIndLkupId',
    'negRatesIndDescLkupId',
    'surchgIndLkupId',
    'surchgIndDescLkupId',
    'crtDt',
    'crtUsr',
  ]
const trnsRatesNameMap = [
  'id',
  'trnsKId',
  'trnsK',
  'shrinkageInd',
  'surchgId',
  'surchgIdDesc',
  'chrgId',
  'rateId',
  'rateIdDesc',
  'tierType',
  'begDate',
  'endDate',
  'recLoc',
  'recLocName',
  'delLoc',
  'delLocName',
  'recZn',
  'recZnName',
  'rte',
  'tierLow',
  'tierHigh',
  'indexNm',
  'formula',
  'adjFactor',
  'rateChrgRef',
  'rateChrgRefDesc',
  'rateForm',
  'rateFormDesc',
  'rptLvl',
  'rptLvlDesc',
  'totalSurchg',
  'resRateBasis',
  'rateChrg',
  'qtyUnits',
  'maxRefRate',
  'maxTrfRateRef',
  'maxTrfRateRefDesc',
  'minVolPct',
  'minVolQty',
  'maxTrfRateRefLkupId',
  'maxTrfRateRefDescLkupId',
  'rateChrgRefLkupId',
  'rateFormLkupId',
  'rateFormDescLkupId',
  'rateIdLkupId',
  'rateIdDescLkupId',
  'rptLvlLkupId',
  'rptLvlDescLkupId',
  'resRateBasisLkupId',
  'surchgIdLkupId',
  'surchgIdDescLkupId',
  'crtDt',
  'crtUsr',
]
const trnsTermsNameMap = [
  'id',
  'trnsKId',
  'trnsK',
  'splTermsMiscNotes',
  'termsNotesAma',
  'termsNotesStorage',
  'crtDt',
  'crtUsr',
]
const trnsCapRelsNameMap = [
  'id',
  'trnsKId',
  'trnsK',
  'offerNo',
  'relk',
  'releaser',
  'releaserName',
  'releaserProp',
  'replSrRoleInd',
  'sicrInd',
  'recallNotifTimely',
  'recallNotifEe',
  'recallNotifEve',
  'recallNotifId1',
  'recallNotifId2',
  'recallNotifId3',
  'allRerelDesc',
  'busDayInd',
  'ibrInd',
  'permRelDesc',
  'prearrDealDesc',
  'prevRel',
  'recallReputDesc',
  'recallReputTerms',
  'rappDesc',
  'rappTerms',
  'bidderBidBasisDesc',
  'crtDt',
  'crtUsr',
]

export function getColumnDefs(type) {
  switch (type) {
    case 'parent': return getNameModelMap(parentNameMap);
    case 'genericChild': return getNameModelMap(genericChildNameMap);

    // ioc
    case 'iocPipelines': return getNameModelMap(iocPipelinesNameMap);
    case 'iocContracts': return getNameModelMap(iocContractsNameMap);
    case 'iocContractPoints': return getNameModelMap(iocContractPointsNameMap);
    case 'iocContractAgents': return getNameModelMap(iocContractAgentsNameMap);
    case 'iocFootnotes': return getNameModelMap(iocFootnotesNameMap);

    // capacity 
    case 'capNoNoticeSvc': return getNameModelMap(SharedColDefs.Capacity.CapNoNotice.All);
    case 'operAvailCap': return getNameModelMap(SharedColDefs.Capacity.OperAvailCap.All);
    case 'unsubbedCap': return getNameModelMap(SharedColDefs.Capacity.UnsubbedCap.All);
    case 'loc': return getNameModelMap(SharedColDefs.LocationDownload.All);

    // posted imbalance
    case 'postedImbal': return getNameModelMap(SharedColDefs.PostedImbalance.All);

    // notices
    case 'notices': return getNameModelMap(SharedColDefs.NoticesData.All);

    // transactional reporting
    case 'trnsHeaders': return getNameModelMap(trnsHeadersMap);
    case 'trnsKs': return getNameModelMap(trnsKsNameMap);
    case 'trnsKIds': return getNameModelMap(trnsKIdsNameMap);
    case 'trnsLimits': return getNameModelMap(trnsLimitsNameMap);
    case 'trnsRates': return getNameModelMap(trnsRatesNameMap);
    case 'trnsTerms': return getNameModelMap(trnsTermsNameMap);
    case 'trnsCapRels': return getNameModelMap(trnsCapRelsNameMap);
    default: return null;
  }
}
