import React, { useState, useEffect, useRef } from 'react';
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import { setPageErrorAlert } from 'redux/reducer/info';
import Gadget from 'infogadget'
import QuickConfig from "../../header/QuickConfig";

import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import ControlFactory from 'infocontrol';
import { useDispatch, useSelector } from 'react-redux'
import CapacityReleaseApi from 'api/v1/CapacityReleaseApi';
import useFetchData from "../../../api/service";

const AllWithdrawal = (props) => {
  const gadgetId = 203;
  const {searchDateSettings} = useSelector((state) => state.info)
  const searchDateSetting = searchDateSettings[gadgetId];
  const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);
  const dispatch = useDispatch();
  const formRef = useRef(this);

  const messageBundle = useSelector((state) => state.message).message;
  const [withdrawalIndOptions, setWithdrawalIndOptions] = useState([]);

  const [postData, setPostData] = useState({
    withdrawalType: null,
    postedAfter: T.getDateTimeHHmmStr(datePeriod.startDate)
  });
  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData('api/v1/crlsWithdrawal/getWithdrawals', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  };

  useEffect(() => {
    // load options for dropdown
    CapacityReleaseApi.getWithdrawalIndOptions().then(data => {
      setWithdrawalIndOptions(data)
    }).catch(e => {
      if (!ApiResult.isSuccess(e)) {
        const msg = ApiResult.getMessages(e);
        if (msg) {
          dispatch(setPageErrorAlert({ show: true, message: msg }))
        }
      }
    });
  }, []);

  const reset = (e) => {
    formRef.current?.resetFields();
  }

  let nameMap = [
    'tspName',
    // 'withdrawalTypeCode',
    'withdrawalTypeCodeDesc',
    'offerNo',
    'bidNumber',
    'awardNumber',
    'withdrawalComments',
    'postingDateTime',
    'duns',
    'tspProp',
  ];

  const processColumns = (cols) => {
    [
      ['tspName', 'asc'],
      ['processColumns', 'desc'],
      ['offerNumber', 'asc'],
      ['bidNumber', 'asc'],
      ['awardNumber', 'asc'],
      ['postingDate', 'desc'],
    ].forEach((field, index) => {
      const colModel = T.find(cols, (c) => c.field === field[0]);
      if (colModel) {
        colModel.sort = field[1];
        colModel.sortIndex = index;
      }
    });

    return cols;
  }

  let columnDefs = processColumns(getNameModelMap(nameMap));
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const onPipelineSelect = () => {
    QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
  }

  const controls = ControlFactory.createControls({
    controls: [
      { id: 1, name: 'pipelines', type: 'multiselect', label: messageBundle['ih.preferences.all.pipelines'],
        values: null, otherProps: {
          showSearch: true,
          options: pipelineOptions,
          onFocus: onPipelineSelect,
          style: { width: '100%' }
        }
      },
      {
        id: 2, name: 'offerNo', type: 'string',
        label: messageBundle['ih.rfqOffrNbr']
      },
      {
        id: 3, name: 'withdrawalType', type: 'select',
        values: T.createSelectOptionFromIdNameArray(withdrawalIndOptions),
        label: messageBundle['ih.withdrawalType'],
      },
      {
        id: 4, name: 'bidNo', type: 'string',
        label: messageBundle['ih.bidNumber']
      },      {
        id: 5, name: 'postedAfter', type: 'singleDatePicker', format: 'MM/DD/YYYY',
        label: messageBundle['ih.postedEndDateTime']
      },
      {
        id: 6, name: 'awardNo', type: 'string',
        label: messageBundle['ih.awardNumber']
      },
    ],
    settings: { colSpan: 2 },
    formRef,
    reset,
    initialValues: {
      'postedAfter': datePeriod.startDate
    },
    submit: (prevState) => {
      if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
        prevState.pipelines = [];
      }
      if (prevState.postedAfter) {
        prevState.postedAfter = prevState.postedAfter+" 00:00";
      }
      setPostData(prevState);
    }
  });

  const gadgetItem = { grid: <InfoGrid id="AllWithdrawal" columnDefs={columnDefs} data={output}></InfoGrid> };
  const gadget = {
    id: gadgetId,
    name: 'AllWithdrawal',
    loading,
    title: messageBundle['ih.widget.capacityRelease.allWithdrawal'],
    views: ['grid'],
    gadgetItem,
    loadTimestamp: loadTimestamp,
    controls,
    width: 100
  };

  return (
    <Gadget {...gadget} />
  );
};

export default AllWithdrawal;
