import { Switch, Route, Redirect } from 'react-router-dom';
import InfoPage from './component/layout/InfoPage'
import PageNotFound from './component/page/PageNotFound'
import { useSelector } from 'react-redux'

//import 'antd/dist/antd.less'
import 'antd/dist/antd.css';
import './App.css';
import './theme/theme.trellis.less';
import './theme/theme.dark.less';

import { TUtil as T} from '@trellisenergy/common-ui-core';
import GlobalMessages from './asset/GlobalMessages';
import { InfoApi, PreferencesApi } from 'api/v1/index'
import ShowRedirectDialog from './component/dialog/RedirectLoginPageDialog'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.esm.js'

function App() {
    const { accountData } = useSelector((state) => state.info)
    const { showRedirectModal } = ShowRedirectDialog();
    //Intercepting the fetch function in the browser window and adding a check to show a redirect modal if the response status is 0 (indicating a redirect).
    const { fetch: originalFetch } = window;
    window.fetch = async (...args) => {
        let [resource, options] = args;
        const response = await originalFetch(resource, options);

        if (response.status === 0) {
            showRedirectModal();
        }
        return response;
    };

  let messages = [];
  // TODO: clean up
  // dispatch from here rather than InfoPage
  let fetchMessages = async () => {
    if (messages.length < 1) {
      messages = await InfoApi.getAllMessages();
      GlobalMessages.init(messages);
      localStorage.setItem('messages', JSON.stringify(messages));
    }
  }
  fetchMessages();
  
  const onIdle = () => {
    console.debug('onIdle...')
    performSessionCheck();
  }
  
  /**
   * Make a call to protected resource.
   * If there is a timeout in server side, 4xx would be sent
   * which in turns would be caught and timeout dialog will be shown.
   */
  const performSessionCheck = () => {
      if(!accountData?.name) {
        console.debug('user not logged-in..', Date.now())
        return;
      }
      console.debug('sending heartbeat..', Date.now())
      PreferencesApi.getPreferences({
        "preferenceTypeName": "FAVORITE_PIPELINES"
      }).then(result => {
        console.debug('received heartbeat..', Date.now())
      }).catch(error => {
        // if session is invalid, will return these error codes
        // same as in service.js
        if (error.code === 401 || error.code === 403 || error.code === 405 || error.code === 0) {
          showRedirectModal();
        }
      });
  }
  const onActive = () => {
    console.debug('onActive...')
    performSessionCheck();
  }
  
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 30_000, // 30 seconds
    throttle: 200
  })
  
  return (
    <Switch>
      <Route exact path={T.buildURL('')}>
        <Redirect to={T.buildURL("/agora")} />
      </Route>
        {/*<Route exact path={T.buildURL('infohub')}>*/}
        {/*    <Redirect to={T.buildURL("/dashboard")} />*/}
        {/*</Route>*/}
      <Route path={T.buildURL('')}>
        <InfoPage /*setAccountData={setAccountData} accountData={accountData}*/ />
      </Route>
      {/*<AuthorizedRoute*/}
      {/*  path={T.buildURL('/')}*/}
      {/*  redirectPath={T.buildURL('/login')}*/}
      {/*  accountData={accountData}*/}
      {/*  setAccountData={setAccountData}*/}art
        
      {/*  authority={['registered']}>*/}
      {/*  <InfoPage setAccountData={setAccountData} accountData={accountData} />*/}
      {/*</AuthorizedRoute>*/}
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  );
}

export default App;