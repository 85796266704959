import React, {useState} from "react";
import {Button, Modal, Skeleton, Tooltip} from "antd";
import { TUtil as T, ApiRequest } from '@trellisenergy/common-ui-core';
import './NoticeDownloadAttachmentsRenderer.css';
import {useSelector} from "react-redux";
import {BASEURL_DEV} from "../../config";

const NoticeDownloadAttachmentsRenderer = (props) => {
    const { configs } = useSelector((state) => state.info);
    const { message } = useSelector((state) => state.message);

    let [isModalVisible, setIsModalVisible] = useState(false);
    let [loading, setLoading] = useState(false);

    let [attachmentListDisplay, setAttachmentListDisplay] = useState(null);

    const onClickDownload = () => {
        //debug condition to test dialog
        // if (true) {
        if (props?.data?.attachmentCount > 1) {
            setIsModalVisible(true);
            getNoticeAttachments();
        } else {
            onDownloadAttachment(props?.data?.noticesDataAttachmentId);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onDownloadAttachment = (noticesDataAttachmentId) => {
        let commonUrlPart = '/downloadNoticeAttachment?noticesDataAttachmentId=' + noticesDataAttachmentId;
        let url = BASEURL_DEV + 'api/v1/notices' + commonUrlPart;
        if (!T.isDevEnv()) {
            url = T.buildURL('api/v1/notices' + commonUrlPart)
        }
        window.open(url, '_blank');
    };


    let getNoticeAttachments = async () => {
        let getAttachments = () => {
            const request = ApiRequest.composePostJsonRequest('api/v1/notices/getNoticeAttachments', 'noticeId=' + props?.data?.id);
            return fetch(request)
                .then(response => response.json())
                .then(responseJson => {
                    return responseJson;
                }).catch(error => {
                    return error;
                });
        };

        setLoading(true);
        let noticeTextResponse = await getAttachments();
        setLoading(false);

        if (noticeTextResponse.data && noticeTextResponse.data.length) {
            // debug overflow
            // noticeTextResponse.data = Array.from(Array(100).keys()).map(item => {
            //     return {
            //         id: item,
            //         docNm: 'AGT_NonCriticalPostingSchedulingppppppppppppppppProcess.pdf',
            //         docTypeName: 'pdf'
            //     };
            // });
            setAttachmentListDisplay(
                <>
                    <div>
                        {noticeTextResponse.data.map(item => {
                            return <div className={'flex-center-justify-left'} style={{marginTop: 16, minWidth: 0}} key={item.id}><button style={{flexGrow: 16, textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'}} target="_blank" onClick={() => onDownloadAttachment(item.id)}>{item.docNm}.{item.docTypeName}</button><Button onClick={() => onDownloadAttachment(item.id)} type="primary" style={{ marginLeft: 16 }}>Download</Button></div> })}
                    </div>
                </>);
        }
    };


    const onDownloadAttachmentsAsZip = () => {
        let commonUrlPart = '/downloadZippedNoticeAttachments?noticeId=' + props?.data?.id;
        let url = BASEURL_DEV + 'api/v1/notices' + commonUrlPart;
        if (!T.isDevEnv()) {
            url = T.buildURL('api/v1/notices' + commonUrlPart)
        }
        window.location.assign(url);
    };

    return <>
        <Tooltip title={props?.data?.attachmentCount > 1 ? message['ih.tooltip.downloadAttachmentMultiple'] : message['ih.tooltip.downloadAttachmentSingle']}>
            <a onClick={onClickDownload}>{props.data?.downloadAttachmentsText}</a>
        </Tooltip>
        
        <Modal footer={null} className={'noticeAttachmentsModal'} title={message['ih.downloadNoticeAttachment']} visible={isModalVisible} onCancel={handleCancel}
               onOk={handleCancel}>
            <Skeleton active loading={loading}>
                {configs?.noticesEnableDownloadAttachmentsZip ? <div style={{textAlign: 'right', marginBottom: 16}}>
                    <a onClick={onDownloadAttachmentsAsZip} type="dashed">Download all as a single ZIP file</a>
                    {/*<a onClick={onDownloadAttachmentsAsZip}>Download all as a single ZIP file</a>*/}
                </div> : null} 
                {attachmentListDisplay}
            </Skeleton>
        </Modal>
    </>;
};

export default NoticeDownloadAttachmentsRenderer;
