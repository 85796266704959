import './About.css'
import { useSelector } from 'react-redux'

/**
 * This is a placeholder where ant script fills the values RELEASE_NAME & BUILD_DATE
 * @returns 
 */
const About = (props) => {
  const { message } = useSelector((state) => state.message)
  return (
      <div id='aboutContainer'>
          <div className="ant-row aboutSectionHeader aboutSection">
            <div className='about-logo'></div><div className='about-text'>{message['ih.about.title']}</div>
          </div>
          <div className="ant-row aboutSection">
              <div className="ant-col-12 secondary-color">
                 {message['ih.about.release']}:
              </div>
              <div className="ant-col-12">
                  release-1.1.0.29
              </div>
          </div>
          <div className="ant-row aboutSection">
              <div className="ant-col-12 secondary-color">
                 {message['ih.about.buildTime']}:
              </div>
              <div className="ant-col-12">
                  Tue, 17 September 2024 02:28 PM
              </div>
          </div>
          <div className="ant-row">
              <div className="ant-col-12 secondary-color">
                 {message['ih.about.serverRestartTime']}:
              </div>
              <div className="ant-col-12">
                  {props.serverRestartTime}
              </div>
          </div>
      </div>
  );
};

export default About;