import React, { useRef, useState } from 'react';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TUtil as T } from '@trellisenergy/common-ui-core';

import { StackChart } from 'infochart'

const Direction = (props) => {

  const { message } = useSelector((state) => state.message)
  const { downloadOptions } = useSelector((state) => state.location);

  const [postData] = useState({});
  const [refreshCount, setRefresh] = useState(0);
  const [loading, output, loadTimestamp] = useFetchData('api/v1/locations/getLocationDirectionsOverview', postData, refreshCount);
  const refresh = () => {
    setRefresh(Math.random())
  };
  const history = useHistory();
  const chartRef = useRef();

  const getChartData = () => {
    return output.map(d => {
      return {
        entityId: d.what,
        type: d.type,
        value: d.count
      }
    });
  }

  const getIdFromType = (type, arr = []) => {
    let found = arr.find(a => a.label === type)
    return found?.id;
  }

  const gadgetItem = {
    // topDisplayItems: [<div className='gadget-item-top'>{message['ih.widget.title.inner.totalChangesByDirFlow'] + ' ' + getChartData().reduce((a, b) => a + b.value, 0)}</div>],
    chart: <StackChart chartRef={chartRef} data={getChartData()} customChartConfig={{ height: 320, scrollbar: { type: 'horizontal' } }} />
  };

  T.registerChartListener(chartRef, (event) => {
    if (event.data) {
      history.push(T.buildURL('/locations/LocationTypeDetail'), {
        //direction: event.data.data.entityId,
        active: event.data.data.type === message['ih.activeCount'] ? 'A' : 'I',
        direction: getIdFromType(event.data.data.entityId, downloadOptions.directionOfFlowOptions),
        caller: 'direction'
      });
    }
  });

  const gadget = {
    loading,
    refresh,
    //id: 'ioc2',
    views: ['chart'],
    gadgetItem,
    title: message['ih.widget.locations.direction.summary'],
    loadTimestamp: loadTimestamp,
    //dataSourceId: 'getPipelinesByCustomer',
    width: 50
  };
  return (
    <Gadget {...gadget} {...props}> </Gadget>
  )
};

export default Direction;