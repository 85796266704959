import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import ControlFactory from 'infocontrol';
import { useSelector } from 'react-redux'
import { TUtil as T } from '@trellisenergy/common-ui-core';

const UnsubbedCapDetail = (props) => {
    const { configs } = useSelector((state) => state.info)

    const location = useLocation();
    const { message } = useSelector((state) => state.message);

    const customTitle = location.state?.title;

    const [postData, setPostData] = useState({
        'date': location.state?.gasDay || T.getTodayDateStr()
    });

    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp] = useFetchData('api/v1/capacity/getUnsubbedCapDetail', postData, refreshCount);
    const refresh = () => {
        setRefresh(Math.random())
    };

    let nameMap = [
        'effGasDay',
        'effEndGasDay',
        'pipelineName',
        'loc',
        'locName',
        'locPurpDesc',
        'locQtiDesc',
        'totalDesignCapacity',
        'subscribedCapacity',
        'unsubscribedCap',
        'measBasisDesc',
        'locSegment',
        'measBasis',
        'reportDt',
        'locPurp',
        'locQti',
        'locZn',
        'pressBase',
        'pressBaseInd',
        'pressBaseDesc',
        // 'commentDtl',
        // 'locComments',
        'pipelineCode',
        'pipelinePropName',
        'refCmntId',
        'n9CycleCmntId',
        'n9DtlCmntId'
    ];

    const processColumns = (cols) => {
        // Gas day, pipeline, location, total design capacity (coming from OAC dataset) , unsubscribed capacity, subscribed capacity
        let sortingCols = [
            'effGasDay',
            'effEndGasDay',
            'pipelineName',
            'loc',
            'locName'
        ].map(field => {
            return T.find(cols, (c) => c.field === field)
        });
        sortingCols.forEach((colModel, index) => {
            if (colModel) {
                colModel.sort = 'asc';
                colModel.sortIndex = index;
            }
        });

        return cols;
    };

    // if from widget do grouping, if from catch all don't group
    let columnDefs = processColumns(getNameModelMap(nameMap));

    const controls = ControlFactory.createControls({
        controls: [{ id: 1, name: 'date', type: 'singleDatePicker', label: message['ih.effGasDay'] }],
        initialValues: { 'date': location.state?.gasDay ? T.getDate(location.state?.gasDay) : T.getTodayDate() },
        submit: setPostData
    });

    const gadgetItem = { grid: <InfoGrid id="UnsubbedCapDetail" columnDefs={columnDefs} data={output}></InfoGrid> };
    const gadget = {
        loading,
        refresh,
        title: customTitle || message['ih.widget.capacity.allUnsubbedCap'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        controls,
        width: 100
    };

    return (
        <Gadget {...gadget} />
    );
};

export default UnsubbedCapDetail;
