import { Layout } from 'antd';
import './Header.css';
const { Header } = Layout;

const InfoHeader = (props) => {
  const style = props?.style;

  return (
    <Header style={style}>
      {props.children}
    </ Header>
  )
}
export default InfoHeader;