import ColumnTypes from "../../component/infogrid/ColumnTypes";

// Transactional Reporting

const NameModelMap = {

  postType: {
    field: 'postType',
    headerName: 'ih.postType', // Post Type
    filter: 'agSetColumnFilter', // same as filter: true
  },
  kQtyK: {
    field: 'kQtyK',
    headerName: 'ih.kQtyK', // K Qty - K
    ...ColumnTypes.Double,
  },
  kBegDate: {
    field: 'kBegDate',
    headerName: 'ih.kBegDate', // K Beg Date
    ...ColumnTypes.DateTime,
  },
  kBegDateInd: {
    field: 'kBegDateInd',
    headerName: 'ih.kBegDateInd', // K Beg Date Ind
  },
  kEndDate: {
    field: 'kEndDate',
    headerName: 'ih.kEndDate', // K End Date
    ...ColumnTypes.DateTime,
  },
  kEndDateInd: {
    field: 'kEndDateInd',
    headerName: 'ih.kEndDateInd', // K End Date Ind
    ...ColumnTypes.DateTime,
  },
  kRoll: {
    field: 'kRoll',
    headerName: 'ih.kRoll', // K Roll
  },
  kStat: {
    field: 'kStat',
    headerName: 'ih.kStat', // K Stat
  },
  amendRptg: {
    field: 'amendRptg',
    headerName: 'ih.amendRptg', // Amend Rptg
  },
  kEntBegDate: {
    field: 'kEntBegDate',
    headerName: 'ih.kEntBegDate', // K Ent Beg Date/Time
    ...ColumnTypes.DateTime,
  },
  kEntEndDate: {
    field: 'kEntEndDate',
    headerName: 'ih.kEntEndDate', // K Ent End Date/Time
    ...ColumnTypes.DateTime,
  },
  affiliateIndicator: {
    field: 'affiliateIndicator',
    headerName: 'ih.affiliateIndicator', // Affil
    filter: 'agSetColumnFilter', // same as filter: 
  },
  negotiatedRateInd: {
    field: 'negotiatedRateInd',
    headerName: 'ih.negotiatedRateInd', // Ngtd Rate Ind
  },
  minVolCommitmentPctNonCapRel: {
    field: 'minVolCommitmentPctNonCapRel',
    headerName: 'ih.minVolCommitmentPctNonCapRel', // Min Vol Pct - Non-Cap Rel
  },
  minVolCommitmentQtyNonCapRel: {
    field: 'minVolCommitmentQtyNonCapRel',
    headerName: 'ih.minVolCommitmentQtyNonCapRel', // Min Vol Qty - Non-Cap Rel
  },
  openSeason: {
    field: 'openSeason',
    headerName: 'ih.openSeason', // OS ID
  },
  ctrctHolderProp: {
    field: 'ctrctHolderProp',
    headerName: 'ih.ctrctHolderProp', // K Holder Prop
  },
  releaserProp: {
    field: 'releaserProp',
    headerName: 'ih.releaserProp', // Releaser Prop
  },
  relK: {
    field: 'relK',
    headerName: 'ih.relK', // Rel K
  },


  kQtyLoc: {
    field: 'kQtyLoc',
    headerName: 'ih.kQtyLoc', // K Qty - Loc
    ...ColumnTypes.Double,
  },
  locName2: {
    field: 'locName2',
    headerName: 'ih.locName2', // Loc Name 2
  },
  loc2: {
    field: 'loc2',
    headerName: 'ih.loc2', // Loc 2
  },
  locZn2: {
    field: 'locZn2',
    headerName: 'ih.locZn2', // Loc Zn 2
  },
  kRateBegDate: {
    field: 'kRateBegDate',
    headerName: 'ih.kRateBegDate', // K Rate Beg Date
    ...ColumnTypes.DateTime,
  },
  kRateEndDate: {
    field: 'kRateEndDate',
    headerName: 'ih.kRateEndDate', // K Rate End Date
    ...ColumnTypes.DateTime,
  },
  rateChgd: {
    field: 'rateChgd',
    headerName: 'ih.rateChgd', // Rate Chgd
  },
  rateChgdIbr: {
    field: 'rateChgdIbr',
    headerName: 'ih.rateChgdIbr', // Rate Chgd - IBR
  },
  rateChgdRef: {
    field: 'rateChgdRef',
    headerName: 'ih.rateChgdRef', // Rate Chgd Ref
  },
  maxTrfRateRef: {
    field: 'maxTrfRateRef',
    headerName: 'ih.maxTrfRateRef', // Max Trf Rate
    ...ColumnTypes.Double,
  },
  rptLvl: {
    field: 'rptLvl',
    headerName: 'ih.rptLvl', // Rpt Lvl
  },
  discBegDate: {
    field: 'discBegDate',
    headerName: 'ih.discBegDate', // Disc Beg Date
    ...ColumnTypes.DateTime,
  },
  discEndDate: {
    field: 'discEndDate',
    headerName: 'ih.discEndDate', // Disc End Date
    ...ColumnTypes.DateTime,
  },
  kLocBegDate: {
    field: 'kLocBegDate',
    headerName: 'ih.kLocBegDate', // K Loc Beg Date
    ...ColumnTypes.DateTime,
  },
  kLocEndDate: {
    field: 'kLocEndDate',
    headerName: 'ih.kLocEndDate', // K Loc End Date
    ...ColumnTypes.DateTime,
  },
  locPurp2: {
    field: 'locPurp2',
    headerName: 'ih.locPurp2', // Loc Purp 2
  },
  locQti2: {
    field: 'locQti2',
    headerName: 'ih.locQti2', // Loc/QTI 2
  },
  capacityTypeInd: {
    field: 'capacityTypeInd',
    headerName: 'ih.capacityTypeInd', // Cap Type
  },
  locInd: {
    field: 'locInd',
    headerName: 'ih.locInd', // Loc Ind
  },
  locKMaxPress: {
    field: 'locKMaxPress',
    headerName: 'ih.locKMaxPress', // Loc K Max Press
    ...ColumnTypes.Double,
  },
  locKMinPress: {
    field: 'locKMinPress',
    headerName: 'ih.locKMinPress', // Loc K Min Press
    ...ColumnTypes.Double,
  },
}

export default NameModelMap;