import { TUtil as T, ApiRequest, ApiResult } from '@trellisenergy/common-ui-core';

export default class AdminApi {


  static getDatasetOptions(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/admin/getDatasetOptions', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t get Dataset');
          return [];
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static updateSourceFiles(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/admin/updateSourceFiles', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.warn('Can\'t update source files');
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        // ERROR - rethrow
        return Promise.reject(error);
      });
  }

  static createSourceFiles(fetchData = {}) {
    const request = ApiRequest.composePostJsonRequest('api/v1/admin/createSourceFiles', null, fetchData);
    return fetch(request)
      .then(response => ApiResult.getJson(response))
      .then(responseJson => {
        if (T.isEmpty(responseJson)) {
          console.error("Can't create source files");
          return null;
        } else {
          //
        }
        return responseJson?.data;
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

    static triggerScheduledTask(taskId) {
        const request = ApiRequest.composePostJsonRequest('api/v1/admin/triggerScheduledTask', 'taskId=' + taskId);
        return fetch(request)
            .then(response => response.json())
            .then(responseJson => {
                return responseJson;
            }).catch(error => {
                return error;
            });
    }
}
