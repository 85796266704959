import React, {useRef, useState} from 'react';
import { InfoGrid } from "infogrid";
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'

import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from 'react-redux'

import SharedColDefs from 'asset/SharedColDefs';
import QuickConfig from "../../header/QuickConfig";
import ControlFactory from 'infocontrol';

const processColumns = (cols) => {
  // eff date, pipeline cd, loc_cd, loc name 
  let groupingCols = [

  ].map(field => {
    return T.find(cols, (c) => c.field === field)
  });

  groupingCols.forEach(colModel => {
    if (colModel) {
      colModel.rowGroup = true;
      colModel.hide = true;
    }
  });

  let sortColumns = ['pipelineName',
    'locStatInd',
    'locTypeInd',
    'dirFlo',
    'locName'];

  sortColumns.forEach((name, index) => {
    let sortColumn = T.find(cols, (c) => c.field === name);
    if (sortColumn) {
      sortColumn.sort = 'asc';
      sortColumn.sortIndex = index;
    }
  });

  return cols;
};
const AllLocations = (props) => {
  const { message } = useSelector((state) => state.message)
  const [refreshCount, setRefresh] = useState(0);
  const queryForm = useRef();
  const [postData, setPostData] = useState({});
  const [loading, output, loadTimestamp] = useFetchData('api/v1/locations/getAllLocations', postData, refreshCount);

  const refresh = () => {
    setRefresh(Math.random())
  };


  let columnDefs = getNameModelMap(SharedColDefs.LocationDownload.All);
  const [pipelineOptions, setPipelineOptions] = useState([]);
  const onPipelineSelect = () => {
    QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
  }

  const controls = ControlFactory.createControls({
    controls: [
      { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
        values: pipelineOptions, otherProps: {
          showSearch: true,
          options: pipelineOptions,
          onFocus: onPipelineSelect,
          style: { width: '100%' }
        }
      },
      {id: 2, name: 'effDate', type: 'singleDatePicker', label: message['ih.allLocationEffectiveDate.greaterEqual']}
    ],
    submit:  (prevState) => {
      if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
        prevState.pipelines = [];
      }
      setPostData(prevState);
    },
    settings: { colSpan: 2 },
    formRef: queryForm,
    reset: (e) => {
      queryForm.current?.resetFields();
    }
  });

  const gadgetPipelineFootnotes = {
    loading: loading,
    //id: 'Gadget1',
    title: message['ih.widget.locations.allLocations'],
    views: ['grid'], // grid, chart
    gadgetItem: {
      grid: <InfoGrid id="AllLocations" columnDefs={processColumns(columnDefs)} data={output}></InfoGrid>
    },
    loadTimestamp: loadTimestamp,
    //dataSourceId: 1,
    controls: controls,
    width: props.width || 100
  };

  return (
    <Gadget {...gadgetPipelineFootnotes} {...props}> </Gadget>
  );
};

export { processColumns };
export default AllLocations;
