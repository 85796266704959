import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {DatePicker, Select} from 'antd';
import 'antd/dist/antd.css';

export default forwardRef((props, ref) => {
    let thisRef = ref;
    const [selectValue, setSelectValue] = useState(props.value);

    const { Option } = Select;

    function onChange(value) {
        console.log('changed');
        setSelectValue( () => value)
        setTimeout(function () {
            props.stopEditing();
            props.node.setSelected(false)
        }, 100);
    }

    const createSelectOptions = function() {
        let items = [], optionItem;
        if (props.values) {
            props.values.forEach(function (optionItem) {
                items.push(<option key={optionItem} value={optionItem}>{optionItem}</option>);
            });
        }
        return items;
    }

    useImperativeHandle(ref, () => {
        return {
            // //*********************************************************************************
            // //          METHODS REQUIRED BY AG-GRID
            // //*********************************************************************************
            // the final value to send to the grid, on completion of editing
            getValue() {
                return selectValue
            },

            // Gets called once before editing starts, to give editor a chance to
            // cancel the editing before it even starts.
            isCancelBeforeStart() {
                return false
            },

            // Gets called once when editing is finished (eg if Enter is pressed).
            // If you return true, then the result of the edit will be ignored.
            isCancelAfterEnd() {
                return false
            },
            
            isPopup() {
                return false
            }
        }
    });

    return (
        <Select
            ref={thisRef}
            showSearch
            style={{ width: '100%' }}
            size={"small"}
            listHeight={500}
            placeholder={props.placeholder}
            optionFilterProp="children"
            onChange={onChange}
            filterSort={(optionA, optionB) =>
                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
        >
            {createSelectOptions()}
        </Select>
    );
});
