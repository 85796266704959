import React, {useRef, useState, useEffect} from 'react';
import { useLocation } from 'react-router-dom'
import { InfoGrid } from 'infogrid';
import { getNameModelMap } from 'asset/GridColumnNameMap';
import Gadget from 'infogadget'
import useFetchData from 'api/service'
import { setPageErrorAlert } from 'redux/reducer/info';
import { useDispatch, useSelector } from 'react-redux'

import ControlFactory from 'infocontrol';
import { DatePicker, Form, Button, Select, Row, Col, Spin, Collapse } from 'antd';
import { Input } from 'antd';
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
import './NoticesKeywordSearch.css'
import { RangeDatePickerAutoAccept } from '../../control/DatePickerAutoAccept';
import NoticesUtil from './noticesUtil';
import { NoticesApi } from 'api/v1/index'
import QuickConfig from "../../header/QuickConfig";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const NoticesFullTextKeywordSearch = (props) => {
    const gadgetId = 301;
    const {searchDateSettings} = useSelector((state) => state.info);
    const searchDateSetting = searchDateSettings[gadgetId];
    const datePeriod = T.getDateTimePeriodFromToday(searchDateSetting.from || 0, false, searchDateSetting.to, searchDateSetting.type);

    /*const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };*/

    const formRef = useRef(this);
    const location = useLocation();
    const dispatch = useDispatch();
    const caller = location.state?.caller;
    const datasetNm = location.state?.datasetNm;
    const keywords = location.state?.keywords;
    const pastHours = location.state?.pastHours;
    const customTitle = location.state?.title;

    const { message } = useSelector((state) => state.message);
    const messageBundle = useSelector((state) => state.message).message;
    const { inputLimit } = useSelector((state) => state.info);

    const [noticeTypeOptions, setNoticeTypeOptions] = useState([]);
    const [noticeCategoryOptions, setNoticeCategoryOptions] = useState([]);

    const formItemLayout = {
        labelCol: { style: { width: 200 } }

        // labelCol: { span: 12 },
        // wrapperCol: { span: 12 },
    };
    const formTailLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };


    const [postData, setPostData] = useState({
        postedStartDate: pastHours ? T.getLastDateByHoursHHmmStr(pastHours, true) : caller ? T.getYesterdayDateTimeHHmmStr() : T.getLast30DaysDateHHmmStr(),
        postedEndDate: T.getTodayDateTimeHHmmStr(),
        noticeStartDate: null,
        noticeEndDate: null,
        'criticalInd': null,
        'noticeType': null,
        'noticeCategory': null,
        'keywords': keywords,
    });

    const [loadInit, setLoadInit] = useState(false);
    const [refreshCount, setRefresh] = useState(0);
    const [loading, output, loadTimestamp, messages] = useFetchData('api/v1/notices/noticesKeywordGlobalSearch', postData, refreshCount, loadInit, setLoadInit);
    //const [state, setState] = useState({
    //  outputData: []
    //});
    const refresh = () => {
        setRefresh(Math.random())
    }

    useEffect(() => {
      /*let data = [];
      if (!T.isEmpty(output)) {
        data = output.map(item => {
          item.noticeTextClob = message['ih.viewText'];
          return item;
        });
      }
      setState({
        outputData: data
      });*/

        // load options for notice type dropdown
        NoticesApi.getNoticeTypes().then(data => {
            setNoticeTypeOptions(data.map(noticeTypeName=> noticeTypeName.name))
        }).catch(e => {
            if (!ApiResult.isSuccess(e)) {
                const msg = ApiResult.getMessages(e);
                if (msg) {
                    dispatch(setPageErrorAlert({ show: true, message: msg }))
                }
            }
        });

        // load options for notice category dropdown
        NoticesApi.getNoticeCategories().then(data => {
            setNoticeCategoryOptions(data)
        }).catch(e => {
            if (!ApiResult.isSuccess(e)) {
                const msg = ApiResult.getMessages(e);
                if (msg) {
                    dispatch(setPageErrorAlert({ show: true, message: msg }))
                }
            }
        });
    }, [/*output*/]);

    useEffect(() => {
        if (caller === 'critical' || caller === 'summary') {
            formRef.current?.submit();
        }
    }, []);

    const reset = (e) => {
        formRef.current?.resetFields();
    }

    const convertData = (data = []) => {
        return data.map(item => {
            // tack on new column for list of keywords so that the notice preview can use to highlight
            if (postData.keywords && postData.keywords.length) {
                // split by '+' or '|'
                let splitBySpaceOrNewline = postData.keywords.split(/[+|]/);
                item.noticeKeywords = splitBySpaceOrNewline;
            }

            return item;
        });
    };

    let newNameMap = [
        'noticeStartDate',
        'noticeEndDate',
        'noticeCategory',
        'pipelineName',
        'criticalNoticeDescription',
        'subject',
        'noticeTextClob',
        'noticeBlob',
        'downloadAttachmentsText',
        'criticalIndicator',
        'noticeIdentifier',
        'duns',
        'noticeStatusDescription',
        'postingDate',
        'priorNoticeIdentifier',
        'responseRequiredIndicatorDescription',
        'responseRequiredByDate',
        'pipelinePropName',
        'noticeStat',
        'noticeTypeDesc',
        'reqrdRespInd',
    ];

    let columnDefs = getNameModelMap(newNameMap);

    const [pipelineOptions, setPipelineOptions] = useState([]);
    const onPipelineSelect = () => {
        QuickConfig.getPipelines(pipelineOptions, setPipelineOptions)
    }

    const statusOptions = [
        {id: "Initiate", name:"Initiate"},
        {id: "Supersede", name:"Supersede"},
        {id: "Terminate", name:"Terminate"}
    ]

    const controls = ControlFactory.createControls({
        controls: [
            { id: 1, name: 'pipelines', type: 'multiselect', label: message['ih.preferences.all.pipelines'],
                values: pipelineOptions, otherProps: {
                    showSearch: true,
                    options: pipelineOptions,
                    onFocus: onPipelineSelect,
                    style: { width: '100%' }
                }
            },
            {
                id: 2, name: 'postedDateRange', type: 'datepickerAsTime', format: 'MM/DD/YYYY HH:mm',
                style: { width: '100%' },
                label: messageBundle['ih.postedDateRange'],
                rules: [{ required: false, message: message['ih.pleaseInputDateRange'] }]
            },
            {
                id: 3, name: 'criticalInd', type: 'select',
                values: T.createSelectOptionFromArray(["Y","N"]),
                label: messageBundle['ih.criticalInd']
            },
            {
                id: 4, name: 'noticeDateRange', type: 'dateTimepicker', format: 'MM/DD/YYYY',
                style: { width: '100%' },
                label: messageBundle['ih.noticeDateRange'],
                rules: [{ required: false, message: message['ih.pleaseInputDateRange'] }],
            },
            {
                id: 5, name: 'noticeCategory', type: 'select',
                values: T.createSelectOptionFromIdNameArray(noticeCategoryOptions),
                otherProps: {
                    showSearch: true,
                    /*notFoundContent: fetchingNoticeCategoryOptions ? <Spin size='small' /> : null*/
                },
                label: messageBundle['ih.category'],
                rules: [{ required: false }]
            },
            {
                id: 6, name: 'status', type: 'select', label: message['ih.status'],
                values: T.createSelectOptionFromIdNameArray(statusOptions), style: { width: '30%' }
            },
            {
                id: 7, name: 'noticeType', type: 'select',
                values: T.createSelectOptionFromArray(noticeTypeOptions),
                otherProps: {
                    showSearch: true,
                    /*notFoundContent: fetchingNoticeTypeOptions ? <Spin size='small' /> : null*/
                },
                label: messageBundle['ih.type'],
                rules: [{ required: false }]
            },
            {
                id: 8, name: 'keywords', type: 'string',
                style: { width: '100%' },
                values: T.createSelectOptionFromArray(keywords),
                label: messageBundle['ih.widget.notices.keyword.search.short'],
                rules: [{ required: false, message: message['ih.pleaseInput'] + ' ' + message['ih.keywords'].toLocaleLowerCase() }],
                showSearch: true,
                allowClear: true,
            },
        ],
        initialValues: {
            'postedDateRange': [pastHours ? T.getPastDate(pastHours, 'hours', true) : caller ? T.getYesterdayDate() : datePeriod.startDate, datePeriod.endDate],
            'noticeDateRange': null,
            'keywords': keywords,
            'noticeCategory': caller === 'critical' ? 62 : null,
            'noticeType': caller === 'summary' ? datasetNm : null
        },
        settings: { colSpan: 2, },
        formRef,
        reset,
        submit: (prevState) => {
            if (prevState.pipelines == null || prevState.pipelines[0] === undefined) {
                prevState.pipelines = [];
            }
            if (prevState.postedEndDate) {
                prevState.postedEndDate = prevState.postedEndDate.replace("00:00", "23:59");
            }
            setPostData(prevState);
        }
    });

    const submit = (e) => {
        const getKeywords = (keywords) => {
            if (T.isNil(keywords)) return '';
            if (T.isArray(keywords)) return keywords.join(' ');
            return keywords;
        }

        console.log(e);
        let updated = {
            criticalInd: e.criticalInd,
            noticeType: e.noticeType,
            noticeCategory: e.noticeCategory,
            keywords: getKeywords(e.keywords),
            postedStartDate: e.postedDateRange ? T.getDateTimeHHmmStr(e.postedDateRange[0]) : null,
            postedEndDate: e.postedDateRange ? T.getDateTimeHHmmStr(e.postedDateRange[1]) : null,
            noticeStartDate: e.noticeDateRange ? T.getDateStr(e.noticeDateRange[0]) : null,
            noticeEndDate: e.noticeDateRange ? T.getDateStr(e.noticeDateRange[1]) : null,
        };
        setPostData(updated)
    };

    const gadgetItem = { grid: <InfoGrid id="NoticesKeywordSearch" columnDefs={NoticesUtil.setSortingCols(columnDefs)} data={convertData(output)}></InfoGrid> }
    const gadget = {
        loading,
        title: customTitle || message['ih.widget.notices.keyword.global.search'],
        views: ['grid'],
        gadgetItem,
        loadTimestamp: loadTimestamp,
        serverMessages: messages ? messages.join(' ') : null,
        controls,
        width: props.width || 100
    };

    return (
        <Gadget {...gadget}></Gadget>
    );
}

export default NoticesFullTextKeywordSearch;
