import GlobalMessages from './GlobalMessages';
import ColumnTypes from "../component/infogrid/ColumnTypes";
import CapacityReleaseNameMap from './gridnamemap/CapacityReleaseNameMap';
import TransactionalReportingNameMap from './gridnamemap/TransactionalReportingNameMap';
let messages = [];

/**
 * 
 * Create Column for InfoGrid. Use messages sent by server to display column names.
 * @param {*} nameArr 
 * @returns 
 */
const getNameModelMap = (nameArr) => {
  if (messages.length < 1) {
    messages = GlobalMessages.messages;
  }
  var ret = [];
  nameArr.forEach(function (t) {
    if (t) {
      const item = NameModelMap[t];
      if (item) {
        ret.push({ ...item, headerName: messages[item.headerName] || item.headerName })
      } else {
        console.warn('missing column: ', t);
      }
    }
  });
  return ret;
};

const attachmentDownload = (params) => {
  if (params.data && params.data.attachmentInd && params.data.attachmentInd === 1) {
    return "Download"
  }
  return "skip-linking"
}

const NameModelMap = {
  // dummy
  month: {
    field: 'month',
    headerName: 'ih.month', // Month
  },
  sum: {
    field: 'sum',
    headerName: 'ih.balance', // Balance
  },
  count: {
    field: 'count',
    headerName: 'ih.count', // Count
  },


  // Notices
  noticesDataId: {
    field: 'id',
    headerName: 'ih.noticesDataId', // Notice Indentifier
    hide: true
  },
  pipelineName: {
    field: 'pipelineName',
    headerName: 'ih.pipelineName', // Pipeline Name
    filter: true,
  },
  criticalIndicator: {
    field: 'criticalIndicator',
    headerName: 'ih.criticalIndicator', //Critical Indicator
    filter: 'agSetColumnFilter', // same as filter: true
  },
  criticalNoticeDescription: {
    field: 'criticalNoticeDescription',
    headerName: 'ih.criticalNoticeDescription' // Critical Notices Desc
  },
  noticeStartDate: {
    field: 'noticeStartDate',
    headerName: 'ih.noticeStartDate', // Start Date
    ...ColumnTypes.DateTime,
  },
  noticeEndDate: {
    field: 'noticeEndDate',
    headerName: 'ih.noticeEndDate', // End Date
    ...ColumnTypes.DateTime,
  },
  noticeIdentifier: {
    field: 'noticeIdentifier',
    headerName: 'ih.noticeIdentifier', // Notice Indentifier
  },
  noticeStatusDescription: {
    field: 'noticeStatusDescription',
    headerName: 'ih.noticeStatusDescription', // Status Des
    filter: 'agSetColumnFilter', // same as filter: true
  },
  noticeType: {
    field: 'noticeType',
    headerName: 'ih.noticeType', // Type
    filter: 'agSetColumnFilter',
  },
  postingDate: {
    field: 'postingDate',
    headerName: 'ih.postingDate', // Post Date
    ...ColumnTypes.DateTime,
  },
  responseRequiredIndicatorDescription: {
    field: 'responseRequiredIndicatorDescription',
    headerName: 'ih.responseRequiredIndicatorDescription', // Response Rqd Ind Desc
  },
  responseRequiredByDate: {
    field: 'responseRequiredByDate',
    headerName: 'ih.responseRequiredByDate', // Response Rqd By Date
    ...ColumnTypes.DateTime,
  },
  subject: {
    field: 'subject',
    headerName: 'ih.subject', // Subject
    //...ColumnTypes.FixedWidth100,
    ...ColumnTypes.PreviewInPopupFixedWidth500,
  },
  noticeTextClob: {
    field: 'noticeTextClob',
    headerName: 'ih.noticeTextClob', // Text
    ...ColumnTypes.NoticeFileTxtDownloadLink,
  },
  noticeBlob: {
    field: 'noticeBlob',
    headerName: 'ih.noticeFileBlob', // Text
    ...ColumnTypes.NoticeFileBlobDownloadLink,
  },

  // IOC
  pipelineId: {
    field: 'pipelineId',
    headerName: 'ih.pipelineId', // Pipeline ID
  },
  rateSchedule: {
    field: 'rateSchedule',
    headerName: 'ih.rateSchedule', // Rate Schedule
    filter: 'agSetColumnFilter', // same as filter: true
  },
  shipperId: {
    field: 'shipperId',
    headerName: 'ih.shipperId', // Shipper ID
  },
  shipperName: {
    field: 'shipperName',
    headerName: 'ih.shipperName', // Customer Name
    filter: true,
  },
  shipperAffiliateIndicator: {
    field: 'shipperAffiliateIndicator',
    headerName: 'ih.shipperAffiliateIndicator', // Shipper Affiliate
    filter: 'agSetColumnFilter',
  },
  contractNumber: {
    field: 'contractNumber',
    headerName: 'ih.contractNumber', // Contract Number
  },
  contractEffectiveDate: {
    field: 'contractEffectiveDate',
    headerName: 'ih.contractEffectiveDate', // Contract Effective Date
    ...ColumnTypes.Date,
  },
  contractPrimeTermExpirationDate: {
    field: 'contractPrimeTermExpirationDate',
    headerName: 'ih.contractPrimeTermExpirationDate', // Contract Exp Date
    ...ColumnTypes.Date,
  },
  daysUntilExpire: {
    field: 'daysUntilExpire',
    headerName: 'ih.daysUntilExpire', // Days Until Expire
    ...ColumnTypes.Long,
  },
  negativeRatesIndicator: {
    field: 'negativeRatesIndicator',
    headerName: 'ih.negativeRatesIndicator', // Negative Rates
    filter: 'agSetColumnFilter',
  },
  transportMdq: {
    field: 'transportMdq',
    headerName: 'ih.transportMdq', // Transport MDQ
    ...ColumnTypes.Long,
  },
  storageMdq: {
    field: 'storageMdq',
    headerName: 'ih.storageMdq', // Storage MDQ
    ...ColumnTypes.Long,
  },
  uomTransportMdq: {
    field: 'uomTransportMdq',
    headerName: 'ih.uomTransportMdq', // UOM Storage MDQ
  },
  uomStorageMdq: {
    field: 'uomStorageMdq',
    headerName: 'ih.uomStorageMdq', // UOM Storage MDQ
  },
  companyId: {
    field: 'companyId',
    headerName: 'ih.companyId', // Company Id
  },
  reportDate: {
    field: 'reportDate',
    headerName: 'ih.reportDate', // Report Date'
    ...ColumnTypes.Date,
  },
  firstDateCalendarQuarter: {
    field: 'firstDateCalendarQuarter',
    headerName: 'ih.firstDateCalendarQuarter',
    ...ColumnTypes.Date,
  },
  originalRevisedIndicator: {
    field: 'originalRevisedIndicator',
    headerName: 'ih.originalRevisedIndicator' // Original Revised Indicator
  },
  footnoteItemList: {
    field: 'footnoteItemList',
    headerName: 'ih.footnoteItemList', // Footnote ItemList
  },
  pipelineFootnoteItemList: {
    field: 'footnoteItemList',
    headerName: 'ih.pipelineFootnoteItemList', // Footnote ItemList
  },
  contactPerson: {
    field: 'contactPerson',
    headerName: 'ih.contactPerson', // Contact Person
  },
  contactPhone: {
    field: 'contactPhone',
    headerName: 'ih.contactPhone', // Contact Person
  },
  footnoteText: {
    field: 'footnoteText',
    headerName: 'ih.footnoteText', // Footnote Text
  },
  agentName: {
    field: 'agentName',
    headerName: 'ih.agentName', // Agent Name
  },
  agentAmaName: {
    field: 'agentAmaName',
    headerName: 'ih.agentName', // Agent Name
  },
  pointName: {
    field: 'pointName',
    headerName: 'ih.pointName', // Location
  },
  createDate: {
    field: 'createDate',
    headerName: 'ih.createDate', // Create Date
    ...ColumnTypes.Date,
  },
  createUser: {
    field: 'createUser',
    headerName: 'ih.createUser', // Create User
  },

  // Imbalance
  prodMonth: {
    field: 'prodMonth',
    headerName: 'ih.prodMonth', // Production Month
  },
  contract: {
    field: 'contract',
    headerName: 'ih.contract', // Contract
  },
  oia: {
    field: 'oia',
    headerName: 'ih.oia', // OIA
  },
  imbalanceDir: {
    field: 'imbalanceDir',
    headerName: 'ih.imbalanceDir', // Imbalance Dir
  },
  postedImbQty: {
    field: 'postedImbQty',
    headerName: 'ih.postedImbQty', // Imb Qty
    ...ColumnTypes.Long,
  },

  // Location
  locCnty: {
    field: 'locCnty',
    headerName: 'ih.locCnty', // Location Country
  },
  loc: {
    field: 'loc',
    headerName: 'ih.loc', // Location
  },
  locName: {
    field: 'locName',
    headerName: 'ih.locName', // Location Name
  },
  locStAbbrev: {
    field: 'locStAbbrev',
    headerName: 'ih.locStAbbrev', // Location Abbrev
  },
  locZone: {
    field: 'locZone',
    headerName: 'ih.locZone', // Location Zone
  },
  dirFlo: {
    field: 'dirFlo',
    headerName: 'ih.dirFlo', // Flow Direction
  },
  updateDate: {
    field: 'updateDate',
    headerName: 'ih.updateDate',
    ...ColumnTypes.DateTime,
  },
  upDnName: {
    field: 'upDnName',
    headerName: 'ih.upDnName', // Up Dn Name
  },
  upDnId: {
    field: 'upDnId',
    headerName: 'ih.upDnId',
  },
  upDnIdProp: {
    field: 'upDnIdProp',
    headerName: 'ih.upDnIdProp'
  },
  upDnFercId: {
    field: 'upDnFercId',
    headerName: 'ih.upDnFercId'
  },
  upDnFercIdInd: {
    field: 'upDnFercIdInd',
    headerName: 'ih.upDnFercIdInd'
  },
  upDnInd: {
    field: 'upDnInd',
    headerName: 'ih.upDnInd'
  },
  upDnLoc: {
    field: 'upDnLoc',
    headerName: 'ih.upDnLoc'
  },
  upDnLocName: {
    field: 'upDnLocName',
    headerName: 'ih.upDnLocName'
  },
  inactDate: {
    field: 'inactDate',
    headerName: 'ih.inactDate',
    ...ColumnTypes.Date,
  },
  locTypeInd: {
    field: 'locTypeInd',
    headerName: 'ih.locTypeInd',
    filter: 'agSetColumnFilter',
  },
  locStatInd: {
    field: 'locStatInd',
    headerName: 'ih.locStatInd',
    filter: 'agSetColumnFilter',
  },
  locLoctrId: {
    field: 'locLoctrId',
    headerName: 'ih.locLoctrId',
  },
  locLoctr: {
    field: 'locLoctr',
    headerName: 'ih.locLoctr'
  },
  pipelineFercId: {
    field: 'pipelineFercId',
    headerName: 'ih.pipelineFercId'
  },
  pipelinePropName: {
    field: 'pipelinePropName',
    headerName: 'ih.pipelinePropName'
  },
  effDate: {
    field: 'effDate',
    headerName: 'ih.effDate',
    ...ColumnTypes.Date,
  },

  // Approval
  sourceFileId: {
    field: 'sourceFileId',
    headerName: 'ih.sourceFileId', // Source File Id
  },
  datasetListId: {
    field: 'datasetListId',
    hide: true,
  },
  displayFileName: {
    field: 'displayFileName',
    headerName: 'ih.displayFileName', // File Name
    ...ColumnTypes.SourceFileDownloadLink,
  },
  fileType: {
    field: 'fileType',
    headerName: 'ih.fileType', // File Type
    filter: 'agSetColumnFilter',
  },
  sourceFileDataId: {
    field: 'sourceFileDataId',
    headerName: 'ih.sourceFileDataId', // File Id
  },
  pipelineShortName: {
    field: 'pipelineShortName',
    headerName: 'ih.pipelineShortName', // Pipeline Name
    filter: 'agSetColumnFilter',
  },
  sourceUrl: {
    field: 'sourceUrl',
    headerName: 'ih.sourceUrl', // Source URL 
  },
  spiderName: {
    field: 'spiderName',
    headerName: 'ih.spiderName', // Spider Name
    filter: 'agSetColumnFilter',
  },
  spiderActive: {
    field: 'spiderActive',
    headerName: 'ih.spiderActive', // Spider Active
  },
  transformerActive: {
    field: 'transformerActive',
    headerName: 'ih.transformerActive', // Transformer Active
  },
  autoPublish: {
    field: 'autoPublish',
    headerName: 'ih.autoPublish', // Auto Publish
    filter: 'agSetColumnFilter',
  },
  lastScrapedDate: {
    field: 'lastScrapedDate',
    headerName: 'ih.lastScrapedDate', // Last Scraped Date
    ...ColumnTypes.DateTime,
  },
  active: {
    field: 'active',
    headerName: 'ih.active', // Active
    filter: 'agSetColumnFilter',
  },
  lastscrapedCycle: {
    field: 'lastscrapedCycle',
    headerName: 'ih.lastscrapedCycle', // Last Scraped Cycle
  },
  mngPplId: {
    field: 'mngPplId',
    headerName: 'ih.mngPplId', // Managed Pipeline ID
    filter: 'agSetColumnFilter',
  },
  managedPipeline: {
    field: 'managedPipeline',
    headerName: 'ih.managedPipeline', // Managed Pipeline ID
    filter: 'agSetColumnFilter',
  },
  datasetName: {
    field: 'datasetName',
    headerName: 'ih.datasetName', // Dataset Name
    filter: 'agSetColumnFilter',
  },
  datasetShrtNm: {
    field: 'datasetShrtNm',
    headerName: 'ih.noticeSummary', // Notice Summary
    filter: 'agSetColumnFilter',
  },
  parentPipe: {
    field: 'parentPipe',
    headerName: 'ih.parentPipe', // Parent Pipe
    filter: 'agSetColumnFilter',
  },
  sourcePipelineCode: {
    field: 'sourcePipelineCode',
    headerName: 'ih.sourcePipelineCode', // Source Pipeline Code
  },
  sourcePipelineName: {
    field: 'sourcePipelineName',
    headerName: 'ih.sourcePipelineName', // Source Pipeline Name
  },
  gasDay: {
    field: 'gasDay',
    headerName: 'ih.gasDay', // Gas Day
  },
  cycleName: {
    field: 'cycleName',
    headerName: 'ih.cycleName', // Cycle Name
    filter: 'agSetColumnFilter',
  },
  imbalMon: {
    field: 'imbalMon',
    headerName: 'ih.imbalMon', // Imbalance Month
  },
  imbalYr: {
    field: 'imbalYr',
    headerName: 'ih.imbalYr', // Imbalance Year
  },
  locationType: {
    field: 'locationType',
    headerName: 'ih.locationType', // Location Type
    filter: 'agSetColumnFilter',
  },
  locationDir: {
    field: 'locationDir',
    headerName: 'ih.locationDir', // Location Direction
    filter: 'agSetColumnFilter',
  },
  filtType: {
    field: 'filtType',
    headerName: 'ih.filtType', // Filter Type
    filter: 'agSetColumnFilter',
  },
  actualFileName: {
    field: 'actualFileName',
    headerName: 'ih.actualFileName', // Actual Filename
    ...ColumnTypes.SourceFileDownloadLink
  },
  datasetNm: {
    field: 'datasetNm',
    headerName: 'ih.datasetNm', // Dataset Name
    filter: 'agSetColumnFilter',
  },
  attachmentInd: {
    field: 'attachmentInd',
    colId: "attachmentInd",
    headerName: 'ih.attachmentInd', // Attachment Ind
    filter: 'agSetColumnFilter',
  },
  attachmentDownload: {
    headerName: 'ih.attachment', // Attachment Ind
    valueGetter: attachmentDownload,
    ...ColumnTypes.AttachmentDownloadLink,
  },
  postDt: {
    field: 'postDt',
    headerName: 'ih.postDt', // Post Date
    ...ColumnTypes.Date,
  },
  processedInd: {
    field: 'processedInd',
    headerName: 'ih.processedInd', // Process Ind
    filter: 'agSetColumnFilter',
  },
  errorInd: {
    field: 'errorInd',
    headerName: 'ih.errorInd', // Error Ind
    filter: 'agSetColumnFilter',
  },
  errorMessage: {
    field: 'errorMessage',
    headerName: 'ih.errorMessage', // Error Message
  },
  approvedInd: {
    field: 'approvedInd',
    headerName: 'ih.approvedInd', // Approved Ind
    filter: 'agSetColumnFilter',
  },
  approvedDate: {
    field: 'approvedDate',
    headerName: 'ih.approvedDate', // Approved Date
    ...ColumnTypes.Date,
  },
  approvedUser: {
    field: 'approvedUser',
    headerName: 'ih.approvedUser', // Approved User
  },
  publishedInd: {
    field: 'publishedInd',
    headerName: 'ih.publishedInd', // Published Ind
    filter: 'agSetColumnFilter',
  },
  publishedDate: {
    field: 'publishedDate',
    headerName: 'ih.publishedDate', // Published Date
    ...ColumnTypes.DateTime,
  },
  publishedUser: {
    field: 'publishedUser',
    headerName: 'ih.publishedUser', // Published User
  },
  rejectedInd: {
    field: 'rejectedInd',
    headerName: 'ih.rejectedInd', // Rejected Ind
    filter: 'agSetColumnFilter',
  },
  rejectedDate: {
    field: 'rejectedDate',
    headerName: 'ih.rejectedDate',
    ...ColumnTypes.DateTime,
  },
  rejectedUser: {
    field: 'rejectedUser',
    headerName: 'ih.rejectedUser'
  },
  notes: {
    field: 'notes',
    headerName: 'ih.notes', // Notes
  },
  crtDt: {
    field: 'crtDt',
    headerName: 'ih.crtDt', // Create Date
    ...ColumnTypes.DateTime,
  },
  crtUsr: {
    field: 'crtUsr',
    headerName: 'ih.crtUsr', // Create User
  },
  updDt: {
    field: 'updDt',
    headerName: 'ih.updDt', // Update Date
    ...ColumnTypes.DateTime,
  },
  updUsr: {
    field: 'updUsr',
    headerName: 'ih.updUsr', // Update User
  },
  publishStartDt: {
    field: 'publishStartDt',
    headerName: 'ih.publishStartDt', // Publish Start Date
    ...ColumnTypes.Date,
  },
  datasetStatusId: {
    field: 'datasetStatusId',
    headerName: 'ih.datasetStatus.id', // Dataset Status Id
  },


  // Cache
  cacheName: {
    field: 'cacheName',
    headerName: 'ih.cacheName', // Name
  },
  cacheStatsQueryTime: {
    field: 'cacheStatsQueryTime',
    headerName: 'ih.cacheStatsQueryTime', // Stats Retrieval Time
    ...ColumnTypes.DateTime,
  },
  cacheHit: {
    field: 'cacheHit',
    headerName: 'ih.cacheHit', // Hits
    ...ColumnTypes.Long,
  },
  cacheMisses: {
    field: 'cacheMisses',
    headerName: 'ih.cacheMisses', // Misses
    ...ColumnTypes.Long,
  },
  cacheHitPercentage: {
    field: 'cacheHitPercentage',
    headerName: 'ih.cacheHitPercentage', // Hit Percentage
    ...ColumnTypes.Percentage,
  },
  cacheMissPercentage: {
    field: 'cacheMissPercentage',
    headerName: 'ih.cacheMissPercentage', // Miss Percentage
    ...ColumnTypes.Percentage,
  },
  cacheEvictions: {
    field: 'cacheEvictions',
    headerName: 'ih.cacheEvictions', // Evictions
  },
  cachePuts: {
    field: 'cachePuts',
    headerName: 'ih.cachePuts', // Puts
  },
  cacheRemovals: {
    field: 'cacheRemovals',
    headerName: 'ih.cacheRemovals', // Removals
  },
  avgPutTime: {
    field: 'avgPutTime',
    headerName: 'ih.avgPutTime', // Avg Put Time
    ...ColumnTypes.Millisecond,
  },
  avgGetTime: {
    field: 'avgGetTime',
    headerName: 'ih.avgGetTime', // Avg Get Time 
    ...ColumnTypes.Millisecond,
  },
  avgRemoveTime: {
    field: 'avgRemoveTime',
    headerName: 'ih.avgRemoveTime', // Avg Removal Time
    ...ColumnTypes.Millisecond,
  },
  lastCacheClearTime: {
    field: 'lastCacheClearTime',
    headerName: 'ih.lastCacheClearTime', // Last Cache Clear Time
    ...ColumnTypes.DateTime,
  },
  cacheKey: {
    field: 'cacheKey',
    headerName: 'ih.cacheKey', // Cache Key 
  },
  cacheKeyTypes: {
    field: 'cacheKeyTypes',
    headerName: 'ih.cacheKeyTypes', // Cache Key Types
  },
  cacheTime: {
    field: 'cacheTime',
    headerName: 'ih.cacheTime', // Cache Time 
    ...ColumnTypes.DateTime,
  },
  cacheValue: {
    field: 'cacheValue',
    headerName: 'ih.cacheValue', // Cache Value
  },
  commentDtl: {
    field: 'commentDtl',
    headerName: 'ih.commentDtl'
  },
  effEndGasDay: {
    field: 'effEndGasDay',
    headerName: 'ih.effEndGasDay',
    ...ColumnTypes.Date
  },
  effGasDay: {
    field: 'effGasDay',
    headerName: 'ih.effGasDay',
    ...ColumnTypes.Date
  },
  interfaceFileCtlId: {
    field: 'interfaceFileCtlId',
    headerName: 'ih.interfaceFileCtlId'
  },
  locComments: {
    field: 'locComments',
    headerName: 'ih.locComments',
    ...ColumnTypes.PreviewInPopup,
  },
  locPurp: {
    field: 'locPurp',
    headerName: 'ih.locPurp'
  },
  locPurpDesc: {
    field: 'locPurpDesc',
    headerName: 'ih.locPurpDesc'
  },
  locPurpDescLkupId: {
    field: 'locPurpDescLkupId',
    headerName: 'ih.locPurpDescLkupId'
  },
  locPurpLkupId: {
    field: 'locPurpLkupId',
    headerName: 'ih.locPurpLkupId'
  },
  locQti: {
    field: 'locQti',
    headerName: 'ih.locQti'
  },
  locQtiName: {
    field: 'locQtiName',
    headerName: 'ih.locQti'
  },
  locQtiDesc: {
    field: 'locQtiDesc',
    headerName: 'ih.locQtiDesc'
  },
  locQtiDescLkupId: {
    field: 'locQtiDescLkupId',
    headerName: 'ih.locQtiDescLkupId'
  },
  locQtiLkupId: {
    field: 'locQtiLkupId',
    headerName: 'ih.locQtiLkupId'
  },
  locZn: {
    field: 'locZn',
    headerName: 'ih.locZn'
  },
  measBasis: {
    field: 'measBasis',
    headerName: 'ih.measBasis',
    filter: 'agSetColumnFilter', // same as filter: true
  },
  measBasisDesc: {
    field: 'measBasisDesc',
    headerName: 'ih.measBasisDesc'
  },
  pipelineCode: {
    field: 'pipelineCode',
    headerName: 'ih.pipelineCode'
  },
  duns: {
    field: 'duns',
    headerName: 'ih.tsp', // TSP
  },
  pressBase: {
    field: 'pressBase',
    headerName: 'ih.pressBase',
    ...ColumnTypes.Double,
  },
  pressBaseDesc: {
    field: 'pressBaseDesc',
    headerName: 'ih.pressBaseDesc'
  },
  pressBaseDescLkupId: {
    field: 'pressBaseDescLkupId',
    headerName: 'ih.pressBaseDescLkupId'
  },
  pressBaseInd: {
    field: 'pressBaseInd',
    headerName: 'ih.pressBaseInd'
  },
  pressBaseIndLkupId: {
    field: 'pressBaseIndLkupId',
    headerName: 'ih.pressBaseIndLkupId'
  },
  pressBaseLkupId: {
    field: 'pressBaseLkupId',
    headerName: 'ih.pressBaseLkupId'
  },
  reportDt: {
    field: 'reportDt',
    headerName: 'ih.reportDt',
    ...ColumnTypes.DateTime
  },
  totalDesignCapacity: {
    field: 'totalDesignCapacity',
    headerName: 'ih.totalDesignCapacity',
    ...ColumnTypes.Double,
  },
  unsubscribedCap: {
    field: 'unsubscribedCap',
    headerName: 'ih.unsubscribedCap',
    ...ColumnTypes.Double,
  },
  unsubscribedCapId: {
    field: 'unsubscribedCapId',
    headerName: 'ih.unsubscribedCapId'
  },
  capNoNoticeSvcId: {
    field: 'capNoNoticeSvcId',
    headerName: 'ih.capNoNoticeSvcId'
  },
  comments: {
    field: 'comments',
    headerName: 'ih.comments',
    ...ColumnTypes.LocationCommentDownloadLink,
  },
  refCmntId: {
    field: 'refCmntId',
    headerName: 'ih.comments',
    ...ColumnTypes.CapacityCommentDownloadLink
  },
  n9CycleCmntId: {
    field: 'n9CycleCmntId',
    headerName: 'ih.cycleComments',
    ...ColumnTypes.CapacityCommentDownloadLink
  },
  n9DtlCmntId: {
    field: 'n9DtlCmntId',
    headerName: 'ih.locationComments',
    ...ColumnTypes.CapacityCommentDownloadLink
  },
  noNoticeQty: {
    field: 'noNoticeQty',
    headerName: 'ih.noNoticeQty',
    ...ColumnTypes.Long,
  },

  allQtyAvailInd: {
    field: 'allQtyAvailInd',
    headerName: 'ih.allQtyAvailInd'
  },
  allQtyAvailIndLkupId: {
    field: 'allQtyAvailIndLkupId',
    headerName: 'ih.allQtyAvailIndLkupId'
  },
  authOverrunInd: {
    field: 'authOverrunInd',
    headerName: 'ih.authOverrunInd'
  },
  authOverrunIndDesc: {
    field: 'authOverrunIndDesc',
    headerName: 'ih.authOverrunIndDesc'
  },
  authOverrunIndDescLkupId: {
    field: 'authOverrunIndDescLkupId',
    headerName: 'ih.authOverrunIndDescLkupId'
  },
  authOverrunIndLkupId: {
    field: 'authOverrunIndLkupId',
    headerName: 'ih.authOverrunIndLkupId'
  },
  actualAvalCapPct: {
    field: 'actualAvalCapPct',
    headerName: 'ih.actualAvalCapPct', // Available %
    ...ColumnTypes.IntegerPercentage
  },
  cycleInd: {
    field: 'cycleInd',
    headerName: 'ih.cycleInd'
  },
  cycleIndLkupId: {
    field: 'cycleIndLkupId',
    headerName: 'ih.cycleIndLkupId'
  },
  cycleNameLkupId: {
    field: 'cycleNameLkupId',
    headerName: 'ih.cycleNameLkupId'
  },
  designCapacity: {
    field: 'designCapacity',
    headerName: 'ih.designCapacity',
    ...ColumnTypes.Double,
  },
  effTime: {
    field: 'effTime',
    headerName: 'ih.effTime'
  },
  flowInd: {
    field: 'flowInd',
    headerName: 'ih.flowInd'
  },
  flowIndDesc: {
    field: 'flowIndDesc',
    headerName: 'ih.flowIndDesc'
  },
  flowIndDescLkupId: {
    field: 'flowIndDescLkupId',
    headerName: 'ih.flowIndDescLkupId'
  },
  flowIndLkupId: {
    field: 'flowIndLkupId',
    headerName: 'ih.flowIndLkupId'
  },
  itInd: {
    field: 'itInd',
    headerName: 'ih.itInd'
  },
  itIndDesc: {
    field: 'itIndDesc',
    headerName: 'ih.itIndDesc'
  },
  measBasisDescLkupId: {
    field: 'measBasisDescLkupId',
    headerName: 'ih.measBasisDescLkupId'
  },
  measBasisLkupId: {
    field: 'measBasisLkupId',
    headerName: 'ih.measBasisLkupId'
  },
  nomCapExceedInd: {
    field: 'nomCapExceedInd',
    headerName: 'ih.nomCapExceedInd'
  },
  nomCapExceedIndLkupId: {
    field: 'nomCapExceedIndLkupId',
    headerName: 'ih.nomCapExceedIndLkupId'
  },
  operatingCapacity: {
    field: 'operatingCapacity',
    headerName: 'ih.operatingCapacity',
    ...ColumnTypes.Double,
  },
  operAvailCap: {
    field: 'operAvailCap',
    headerName: 'ih.operAvailCap',
    ...ColumnTypes.Double,
  },
  operAvailCapId: {
    field: 'operAvailCapId',
    headerName: 'ih.operAvailCapId'
  },
  qtyReason: {
    field: 'qtyReason',
    headerName: 'ih.qtyReason'
  },
  tsq: {
    field: 'tsq',
    headerName: 'ih.tsq',
    ...ColumnTypes.Double,
  },
  effEndGasDtTm: {
    field: 'effEndGasDtTm',
    headerName: 'ih.effEndGasDtTm',
    ...ColumnTypes.DateTime,
  },
  locCity: {
    field: 'locCity',
    headerName: 'ih.locCity'
  },
  dirFlow: {
    field: 'dirFlow',
    headerName: 'ih.dirFlow'
  },
  accntPhyInd: {
    field: 'accntPhyInd',
    headerName: 'ih.accntPhyInd'
  },
  lastUpdateDt: {
    field: 'lastUpdateDt',
    headerName: 'ih.lastUpdateDt',
    ...ColumnTypes.DateTime,
  },

  effBegGasDtTm: {
    field: 'effBegGasDtTm',
    headerName: 'ih.effBegGasDtTm',
    ...ColumnTypes.DateTime,
  },

  imbDirIndLkupId: {
    field: 'imbDirIndLkupId',
    headerName: 'ih.imbDirIndLkupId'
  },
  imbDirDescLkupId: {
    field: 'imbDirDescLkupId',
    headerName: 'ih.imbDirDescLkupId'
  },
  ctrctHolderCbpId: {
    field: 'ctrctHolderCbpId',
    headerName: 'ih.ctrctHolderCbpId'
  },
  svcReqCbpId: {
    field: 'svcReqCbpId',
    headerName: 'ih.svcReqCbpId'
  },
  postedImbalanceId: {
    field: 'postedImbalanceId',
    headerName: 'ih.postedImbalanceId'
  },
  ctrctHolder: {
    field: 'ctrctHolder',
    headerName: 'ih.ctrctHolder'
  },
  ctrctHolderName: {
    field: 'ctrctHolderName',
    headerName: 'ih.ctrctHolderName'
  },
  ctrctHolderPropCode: {
    field: 'ctrctHolderPropCode',
    headerName: 'ih.ctrctHolderPropCode'
  },
  stmtDt: {
    field: 'stmtDt',
    headerName: 'ih.stmtDt',
    ...ColumnTypes.Date
  },
  svcReq: {
    field: 'svcReq',
    headerName: 'ih.svcReq'
  },
  svcReqName: {
    field: 'svcReqName',
    headerName: 'ih.svcReqName'
  },
  svcReqProp: {
    field: 'svcReqProp',
    headerName: 'ih.svcReqProp'
  },
  svcReqCtrct: {
    field: 'svcReqCtrct',
    headerName: 'ih.svcReqCtrct'
  },
  svcReqContact: {
    field: 'svcReqContact',
    headerName: 'ih.svcReqContact'
  },
  svcReqPhone: {
    field: 'svcReqPhone',
    headerName: 'ih.svcReqPhone'
  },
  imbDirInd: {
    field: 'imbDirInd',
    headerName: 'ih.imbDirInd'
  },
  imbDirDesc: {
    field: 'imbDirDesc',
    headerName: 'ih.imbDirDesc'
  },
  imbPeriod: {
    field: 'imbPeriod',
    headerName: 'ih.imbPeriod',
    ...ColumnTypes.DateMonthYear
  },
  imbTypeCode: {
    field: 'imbTypeCode',
    headerName: 'ih.imbTypeCode'
  },
  imbType: {
    field: 'imbType',
    headerName: 'ih.imbType'
  },
  segNbr: {
    field: 'segNbr',
    headerName: 'ih.segNbr'
  },
  nomInd: {
    field: 'nomInd',
    headerName: 'ih.nomInd'
  },
  upDnLoc2: {
    field: 'upDnLoc2',
    headerName: 'ih.upDnLoc2'
  },
  reportDtTm: {
    field: 'reportDtTm',
    headerName: 'ih.reportDtTm'
  },
  upDnLocName2: {
    field: 'upDnLocName2',
    headerName: 'ih.upDnLocName2'
  },
  locZoneDel: {
    field: 'locZoneDel',
    headerName: 'ih.locZoneDel'
  },
  effGasDtTm: {
    field: 'effGasDtTm',
    headerName: 'ih.effGasDtTm',
    ...ColumnTypes.DateTime,
  },
  itIndLkupId: {
    field: 'itIndLkupId',
    headerName: 'ih.itIndLkupId'
  },
  noticeStat: {
    field: 'noticeStat',
    headerName: 'ih.noticeStat'
  },
  noticeTypeDesc: {
    field: 'noticeTypeDesc',
    headerName: 'ih.noticeTypeDesc',
    filter: true
  },
  reqrdRespInd: {
    field: 'reqrdRespInd',
    headerName: 'ih.reqrdRespInd'
  },
  transportMdqUom: {
    field: 'transportMdqUom',
    headerName: 'ih.transportMdqUom',
    filter: true
  },
  storageMdqUom: {
    field: 'storageMdqUom',
    headerName: 'ih.storageMdqUom',
    filter: true
  },
  variance: {
    field: 'variance',
    headerName: 'ih.variance'
  },
  traderEligibleSvcReqName: {
    field: 'traderEligibleSvcReqName',
    headerName: 'ih.traderEligibleSvcReqName'
  },
  tradeEligibleImbQty: {
    field: 'tradeEligibleImbQty',
    headerName: 'ih.tradeEligibleImbQty'
  },
  traderOia: {
    field: 'traderOia',
    headerName: 'ih.traderOia'
  },
  traderSvcReqCtrct: {
    field: 'traderSvcReqCtrct',
    headerName: 'ih.traderSvcReqCtrct'
  },
  noticeCategory: {
    field: 'datasetNm',
    headerName: 'ih.noticeCategory', // Dataset Name
    filter: 'agSetColumnFilter',
  },
  pointIdentifierCode: {
    field: 'pointIdentifierCode',
    headerName: 'ih.pointIdentifierCode', // Dataset Name
  },
  pointCodeQualifier: {
    field: 'pointCodeQualifier',
    headerName: 'ih.pointCodeQualifier', // Dataset Name
  },
  pointCode: {
    field: 'pointCode',
    headerName: 'ih.pointCode', // Dataset Name
  },
  zoneName: {
    field: 'zoneName',
    headerName: 'ih.zoneName', // Dataset Name
  },
  subscribedCapacity: {
    field: 'subscribedCapacity',
    headerName: 'ih.subscribedCapacity', // Dataset Name
    ...ColumnTypes.Double,
  },
  priorNoticeIdentifier: {
    field: 'priorNoticeIdentifier',
    headerName: 'ih.priorNoticeIdentifier',
  },
  downloadAttachmentsText: {
    field: 'downloadAttachmentsText',
    headerName: 'ih.downloadAttachmentsText',
    ...ColumnTypes.NoticeAttachmentsDownloadLink
  },
  note: {
    field: 'note',
    headerName: 'ih.actionNotes',
  },
  unAppCnt: {
    field: 'unAppCnt',
    headerName: 'ih.unapproved',
  },
  rejectedCnt: {
    field: 'rejectedCnt',
    headerName: 'ih.rejected',
  },
  appvdUnPublishedCnt: {
    field: 'appvdUnPublishedCnt',
    headerName: 'ih.approvedAndUnpublished',
  },
  appvdCnt: {
    field: 'appvdCnt',
    headerName: 'ih.approved'
  },
  publishedCnt: {
    field: 'publishedCnt',
    headerName: 'ih.published',
  },
  datasetGroupAndName: {
    field: 'datasetGroupAndName',
    headerName: 'ih.dataset',
    ...ColumnTypes.DatasetGroupAndNameRenderer
  },
  locSegment: {
    field: 'locSegment',
    headerName: 'ih.locSegment', // Dataset Name
  },
  dupRow: {
    field: 'dupRow',
    headerName: 'ih.duplicate',
    headerClass: 'info-grid-row-highlight-dupe'
  },
  footnoteNumberPrefix: {
    field: 'footnoteNumberPrefix',
    headerName: 'ih.footnoteNumberPrefix'
  },
  footnoteNumber: {
    field: 'footnoteNumber',
    headerName: 'ih.footnoteNumber'
  },
  begDate: {
    field: 'begDate',
    headerName: 'ih.begDate', // Begin Date
    ...ColumnTypes.DateTime
  },
  endDate: {
    field: 'endDate',
    headerName: 'ih.endDate', // End Date
    ...ColumnTypes.DateTime
  },
  locFuelArea: {
    field: 'locFuelArea',
    headerName: 'ih.locFuelArea'
  },
  svcReqAcct: {
    field: 'svcReqAcct',
    headerName: 'ih.svcReqAcct', // End Date
  },

  ...CapacityReleaseNameMap,
  ...TransactionalReportingNameMap
};

export { NameModelMap, getNameModelMap };
