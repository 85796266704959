import { createSlice } from '@reduxjs/toolkit'

export const cacheSlice = createSlice({
  name: 'cache',
  initialState: {
    cacheStats: {},
    serverStartupTime: null,
  },
  reducers: {
    setCacheStats: (state, action) => {
      state.cacheStats = action.payload
    },
    setServerStartupTime: (state, action) => {
      state.serverStartupTime = action.payload
      localStorage.setItem("serverStartupTime", action.payload);
    },
    // clear cache that start with prefix
    clearClientCache: (state, action) => {
      const cacheNames = action.payload;
      const cacheNamePrefix = 'cache-';
      const allNames = [];
      if (!cacheNames) {
        Object.keys(localStorage).forEach(key => {
          if (key.indexOf(cacheNamePrefix) === 0) {
            allNames.push(key)
          }
        })
      } else {
        cacheNames.forEach(cacheName => {
          if (cacheName.indexOf(cacheNamePrefix) === 0)
            allNames.push(cacheName);
        })
      }
      allNames.forEach(cacheName => {
        localStorage.removeItem(cacheName);
      })
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCacheStats, setServerStartupTime, clearClientCache } = cacheSlice.actions

export default cacheSlice.reducer