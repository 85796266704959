import { Modal } from 'antd';
import { useEffect, useState, useImperativeHandle } from 'react';

export const InfoDialog = ({ message, handler, cancelHandler, dialogRef }) => {
  const [isVisible, setVisible] = useState(false);
  //const [confirmLoading, setConfirmLoading] = useState(false);

  // TODO: refactor this
  useImperativeHandle(dialogRef, () => {
    return {
      showModal: showModal
    };
  });

  /*useEffect(() => {
    setVisible(visible);
  }, [visible])*/

  const showModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
    //setConfirmLoading(false);
  }

  const handleOk = () => {
    if (handler) {
      handler();
    }
    closeModal();
  };

  const handleCancel = () => {
    if (cancelHandler) {
      cancelHandler();
    }
    closeModal();
  };

  return (<Modal visible={isVisible}
    title='Confirm'
    onOk={handleOk}
    onCancel={handleCancel}
  >
    <div>
      {message}
    </div>
  </Modal>)
  /*
  Modal.info({
    visible: isModalVisible,
    title: 'Confirm',
    content: (
      <div>
        {message}
      </div>
    ),
    onOk() {
      oKHandler();
      handleOk();
    },
    onCancel() {
      cancelHandler();
      handleCancel();
    }
  }
  });
  */
}