import { Route } from 'react-router-dom';
import { CacheApi } from 'api/v1/index'
import { useEffect } from 'react'
import { setCacheStats } from 'redux/reducer/cache'
import { useDispatch, useSelector } from 'react-redux'
import { GadgetShortcut, CacheHit, CacheStatistics, CacheDetail, CacheClient } from 'gadget'
import { TUtil as T } from '@trellisenergy/common-ui-core';

const ManageCache = () => {

  const { message } = useSelector((state) => state.message)
  const { configs } = useSelector((state) => state.info)

  const dispatch = useDispatch();
  const onLoad = async () => {
    const cacheStats = await CacheApi.getAllCacheStats()
    dispatch(setCacheStats(cacheStats));
  }
  const isCacheEnabled = configs?.cacheEnabled && configs?.cacheMonitoring;

  useEffect(() => {
    if (isCacheEnabled)
      onLoad();
  }, [])

  return (
    isCacheEnabled ?
      <>
        <Route exact path={T.buildURL('/admin/cache')}>
          <CacheHit />
          <GadgetShortcut drilldown={{
            link: T.buildURL('/admin/cache/CacheStatistics'),
            title: message['ih.widget.cache.statistics'], level: 1
          }} />
          <GadgetShortcut drilldown={{
            link: T.buildURL('/admin/cache/CacheDetail'),
            title: message['ih.widget.cache.detail'], level: 1
          }} />
          <GadgetShortcut drilldown={{
            link: T.buildURL('/admin/cache/CacheClient'),
            title: message['ih.widget.cache.client.detail'], level: 1
          }} />
        </Route>
        <Route path={T.buildURL('/admin/cache/CacheStatistics')}>
          <CacheStatistics />
        </Route>
        <Route path={T.buildURL('/admin/cache/CacheDetail')}>
          <CacheDetail />
        </Route>
        <Route path={T.buildURL('/admin/cache/CacheClient')}>
          <CacheClient />
        </Route>
      </>
      : ''
  )
}

export default ManageCache;