import { Route } from 'react-router-dom';
import {
    GadgetShortcut, OperAvailCap, OperAvailCapDetail,
    UnsubbedCap, UnsubbedCapDetail, CapNoNoticeServiceDetail, AllUnsubbedCap,
} from 'gadget';

import { TUtil as T } from '@trellisenergy/common-ui-core';
import { useSelector } from "react-redux";

function Capacity() {
    const { message } = useSelector((state) => state.message);
    const path = '/capacity';

    return (
        <div>
            <Route exact path={T.buildURL(path)}>
                <OperAvailCap drilldown={{
                    link: T.buildURL(path + '/AllOperAvailCap'),
                    level: 1,
                    state: { title: message['ih.widget.capacity.operAvailCap.title'] + ' ' + T.getTodayDateStr() }
                }} />
                <UnsubbedCap drilldown={{
                    link: T.buildURL(path + '/UnsubbedCapDetail'),
                    level: 1,
                    state: { title: message['ih.widget.capacity.unsubbedCap.title'] + ' ' + T.getTodayDateStr() }
                }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllOperAvailCap'), title: message['ih.widget.capacity.allOperAvailCap'], level: 1 }} />
                <GadgetShortcut drilldown={{ link: T.buildURL(path + '/AllUnsubbedCap'), title: message['ih.widget.capacity.allUnsubbedCap'], level: 1 }} />

            </Route>
            <Route path={T.buildURL(path + '/AllOperAvailCap')}>
                <OperAvailCapDetail />
            </Route>
            <Route path={T.buildURL(path + '/AllUnsubbedCap')}>
                <AllUnsubbedCap />
            </Route>
            <Route path={T.buildURL(path + '/UnsubbedCapDetail')}>
                <UnsubbedCapDetail />
            </Route>
            <Route path={T.buildURL(path + '/AllCapNoNoticeSvc')}>
                <CapNoNoticeServiceDetail />
            </Route>

        </div>
    );
}

export default Capacity;