import { Layout } from 'antd';
import { useEffect, useState, useReducer } from 'react';
import { /*useHistory, */useLocation } from 'react-router-dom';
import InfoFooter from '../footer/Footer'
import './InfoPage.css'
import {
  AppMenuRenderer, AppMobileMenuRenderer, RouteRenderer, AppMenuRouteData,
  UserMenuData, HubIconRenderer, UserDropdownRenderer, getPageTitle, getPageMenuKey, getPageTabName
} from '../menu/Menu'
import HomePage from '../page/home/Home';
import QuickConfig from '../header/QuickConfig'
import { InfoApi, PreferencesApi, LocationApi } from 'api/v1/index'
import { TUtil as T, ApiResult } from '@trellisenergy/common-ui-core';
//import ShowRedirectDialog from '../dialog/RedirectLoginPageDialog'
import { useDispatch, useSelector } from 'react-redux'
import { setMessage } from 'redux/reducer/messageResource'
import { setDownloadOptions } from 'redux/reducer/location'
import { setInputLimits, setConfigs, setAccountData } from "redux/reducer/info";
import Header from '../header/Header';
import PageAlert from '../alert/PageAlert'
import { clearPageAlert, setPageErrorAlert, setSearchDateSettings } from 'redux/reducer/info';
import FullPageLoader from 'react-fullpage-custom-loader'
import { message as antdMessage } from 'antd';
import moment from 'moment-timezone';
import FaIcon from '../icon/FaIcon';
// fontawesome
// import 'util/FontAwesomeLoader'

const { Content, Footer } = Layout;

const InfoPage = () => {
  moment.tz.setDefault("America/Chicago");

  const { message } = useSelector((state) => state.message)
  const dispatch = useDispatch();
  const location = useLocation();
  let pathname = location.pathname;
  let isAgora = false;
  if (pathname.indexOf('agora') > -1)
    isAgora = true;

  const [state, setState] = useState({
    theme: 'trellis',
    collapsed: true, // side menu
    pageTitle: <div className='logo-home-page'></div>,
    pageMenuKey: ['100'], // Home Page
    quickConfig: false,
    accountData: {},
    pipelineData: {},
    accountDataLoading: true
  });

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const savePipelinePreferencesData = (pipelineData) => {
    let favPipelines = [];
    Object.entries(pipelineData.activePipelines).forEach(([key, c]) => {
      favPipelines.push({ id: key, active: c.active });
    });
    let data = {
      "preferenceTypeName": "FAVORITE_PIPELINES",
      "preferenceValue": {
        favPipelines: favPipelines
      },
    };
    localStorage.setItem('favPipelines', JSON.stringify(favPipelines));

    PreferencesApi.savePreferences(data).then(() => {
      forceUpdate();
    });
  };

  const saveThemePreferencesData = (themeData) => {
    let data = {
      "preferenceTypeName": "VISUAL_THEME",
      "preferenceValue": themeData || { currentTheme: 'trellis' },
    };

    PreferencesApi.savePreferences(data);
  }

  const getPipelinePreferenceData = (data) => {
    let activePipelines = {};
    let favPipelines = data.preferenceValue?.favPipelines || [];
    favPipelines.forEach(item => {
      activePipelines[item.id] = { active: item.active };
    });
    return {
      activePipelines
    };
  };

  /***
   * 
   * a) Fetch all pipelines data
   * b) Fetch the preferences
   * c) Merge (a) with (b)
   * d) set (c) to state
   * 
   */
  const onLoad = async () => {
    //console.log('onLoad');
    cleanupCSRFToken();
    const messages = await InfoApi.getAllMessages();
    dispatch(setMessage(messages));

    const accountDataResponse = await InfoApi.getAccessRights();
    // if user is not authorized store the publicUrls and do nothing else
    if (!accountDataResponse) {
      const publicUrls = await InfoApi.getPublicUrls();
      localStorage.setItem('publicUrls', JSON.stringify(publicUrls));
      setState({
        ...state,
        accountDataLoading: false
      });
      return;
    }
    if (!ApiResult.isSuccess(accountDataResponse)) {
      const msg = ApiResult.getMessages(accountDataResponse);
      if (msg) {
        dispatch(setPageErrorAlert({ show: true, message: msg }))
      }
      setState({
        ...state,
        accountDataLoading: false
      });
    } else {
      const accountData = accountDataResponse.data;

      localStorage.setItem('username', accountData.username);
      dispatch(setAccountData(accountData)); // TODO: use this and don't pass it as props

      // TODO: don't wait on this
      const configs = await InfoApi.getConfigs();
      dispatch(setConfigs(configs));

      if (!accountData) return;

      localStorage.setItem('csrfToken', accountData && accountData.csrfToken);

      // retrieve dropdown options for location page
      const downloadOptions = await LocationApi.getDownloadOptions();
      dispatch(setDownloadOptions(downloadOptions));

      // retrieve input limit for capping textareas
      const inputLimits = await InfoApi.getInputLimits();
      dispatch(setInputLimits(inputLimits));
      
      const searchDateSettings = await  InfoApi.getSearchDateSettings();
      dispatch(setSearchDateSettings(searchDateSettings));

      // TODO: check error cases
      const [pipelineData, preferenceData, themeData] = await Promise.allSettled([
        InfoApi.getManagedPipelines(),
        PreferencesApi.getPreferences({
          "preferenceTypeName": "FAVORITE_PIPELINES"
        }),
        PreferencesApi.getPreferences({
          "preferenceTypeName": "VISUAL_THEME"
        }),
        //InfoApi.getAllMessages(),
      ]).then(result => {
        return result.map(r => r.status === 'fulfilled' ? r.value : {})
      }).catch(console.error);

      let data = getPipelinePreferenceData(preferenceData)
      let favPipelines = preferenceData?.preferenceValue?.favPipelines;
      localStorage.setItem('favPipelines', JSON.stringify(favPipelines));
      
      Object.entries(data.activePipelines).forEach(([key, c]) => {
        let d = pipelineData[key];
        if (d) {
          d.active = c.active;
        }
      });
      //dispatch(setMessage(messages));

      //console.log('Updated pipelineData: ', pipelineData);
      let updated = { ...state.pipelineData, ...pipelineData };

      let themeFromSavedPrefs = themeData?.preferenceValue?.currentTheme || 'trellis';

      setState({
        ...state,
        pipelineData: updated,
        accountData: { ...accountData },
        themeData: { currentTheme: themeFromSavedPrefs },
        theme: themeFromSavedPrefs,
        accountDataLoading: false
      });
    }
  };

  useEffect(() => {
    // TODO: don't replace if you add other classes to body
    if (state.theme)
      document.body.className = state.theme;
  }, [state.theme])

  useEffect(() => {
    onLoad();
  }, [])

  useEffect(() => {
    let title = getPageTitle(location.pathname, AppMenuRouteData, message);
    let tabName = getPageTabName(location.pathname, AppMenuRouteData, message);
    document.title = tabName;
    let key = getPageMenuKey(location.pathname, AppMenuRouteData);
    // menu key must be a string value
    if (T.isSmallVerticalScreen) {
      // collapse the menu if mobile
      setState({ ...state, collapsed: true, pageTitle: title, pageMenuKey: ['' + key] });
    } else {
      setState({ ...state, pageTitle: title, pageMenuKey: ['' + key] });
    }

    // hide footer for preference page
    let footer = document.querySelector('.ant-layout-footer');
    footer.style.display = 'none';
    /*
    if (location.pathname?.indexOf('/preferences') !== -1) {
      if (footer) {
        footer.style.display = 'none';
      }
    } else {
      if (footer) {
        footer.style.display = 'block';
      }
    }*/

    // clear the page alert if user navigates away
    dispatch(clearPageAlert({}));

  }, [location.pathname, state.accountData?.authorities]); // selected key may not trigger due to authorities not rendering menu item, apply change when authorities change too

  const onCollapse = (collapse) => {
    setState({ ...state, collapsed: collapse })
  }

  const showLoginPage = () => {
    window.location.assign(T.getAuthenticationUrls().loginUrl);
    const hide = antdMessage.loading(message['ih.loggingIn'], 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 20000);
  }

  const cleanupCSRFToken = () => {
    localStorage.removeItem("csrfToken");
  }

  const onUserDropdownClick = (e) => {
    if (e.key === 'login') {
      showLoginPage();
    } else if (e.key === 'logout') {
      cleanupCSRFToken();
      setState({ ...state, accountData: null });
      window.location.replace(T.getAuthenticationUrls().logoutUrl);
    } else if (e.key === 'quickConfig') {
      setState({ ...state, quickConfig: true });
    }
  }

  /**
   * 
   * Update the state and save preference
   *
   * @param {*} pipelineData
   * @param themeData
   */
  const onQuickConfigClose = ({ pipelineData, themeData }) => {
    let activePipelines = pipelineData.activePipelines;

    let updated = { ...state.pipelineData };
    if (!T.isEmpty(activePipelines)) {
      Object.entries(activePipelines).forEach(([key, c]) => {
        let item = { ...updated[key * 1] };
        if (typeof c.active !== 'undefined')
          item.active = c.active;
        updated[key * 1] = item;
      });
    }
    setState({ ...state,
      pipelineData: updated,
      quickConfig: false,
      theme: (themeData && themeData.currentTheme) || state.theme
    });
    savePipelinePreferencesData({
      activePipelines: updated
    });
    if (themeData && themeData.currentTheme !== state.theme) {
      saveThemePreferencesData(themeData)
    }
  }

  const closeQuickConfig = () => {
    setState({ ...state, quickConfig: false });
  }

  const agoraLayout = <>
    <Header> {state.pageTitle}
      {/*<UserDropdown accountData={state.accountData} data={getUserMenuData()} onClick={onUserDropdownClick} />*/}
      <UserDropdownRenderer accountData={state.accountData} authorities={state.accountData?.authorities}
        data={UserMenuData} onClick={onUserDropdownClick} selectedKeys={state.pageMenuKey} />
      <span className='hubicon-header'><HubIconRenderer accountData={state.accountData} isHeader={true} /></span>
    </Header>
    <QuickConfig
      pipelineData={{
        activePipelines: state.pipelineData
    }}
      visible={state.quickConfig}
      close={closeQuickConfig}
      saveConfig={onQuickConfigClose} />

    <Content className='agora-content'/*style={{ margin: '0 16px' }}*/>
      <PageAlert />
      <HomePage
        isAgora={true}
        accountDataLoading={state.accountDataLoading}
        accountData={state.accountData}
        pipelineData={{
          activePipelines: state.pipelineData
        }}
        themeData={{ currentTheme: state.theme }}
        saveConfig={onQuickConfigClose} />
    </Content>

    <Footer style={{ textAlign: 'center' }}><InfoFooter /></Footer>
  </ >


  const onClickHamburgerMenu = () => {
    console.debug('onClickHamburgerMenu');
    setState({ ...state, collapsed: false });
    /* 
    set 
    .site-layout
      left-margin = 0px or width of the mobile device  
    .ant-layout-sider
      display = none or
      redraw sidemenu with width=width of the mobile device
      */
  }

  const infoLayoutVerticalMobile = <>
    <Layout>
      <AppMobileMenuRenderer accountData={state.accountData} selectedKeys={state.pageMenuKey} authorities={state.accountData?.authorities}
        data={AppMenuRouteData} onCollapse={onCollapse} collapsed={state.collapsed} />
      <Layout className="site-layout" style={{ marginLeft: state.collapsed ? 0 : T.getScreenWidth() }}>
        <Header> <div onClick={onClickHamburgerMenu} style={{ float: 'left' }}><FaIcon name="bars" /></div>{state.pageTitle}
          {/*<UserDropdown accountData={state.accountData} data={getUserMenuData()} onClick={onUserDropdownClick} />*/}
          <UserDropdownRenderer accountData={state.accountData} authorities={state.accountData?.authorities}
            data={UserMenuData} onClick={onUserDropdownClick} selectedKeys={state.pageMenuKey} />
          <span className='hubicon-header'><HubIconRenderer accountData={state.accountData} isHeader={true} /></span>
        </Header>
        <QuickConfig
          pipelineData={{
            activePipelines: state.pipelineData
          }}
          visible={state.quickConfig}
          close={closeQuickConfig}
          saveConfig={onQuickConfigClose} />
        <Content className='info-content' style={{ margin: '0 px' }}>
          <PageAlert />
          <RouteRenderer
            accountDataLoading={state.accountDataLoading}
            accountData={state.accountData}
            pipelineData={{
              activePipelines: state.pipelineData
            }}
            themeData={{ currentTheme: state.theme }}
            saveConfig={onQuickConfigClose} />
        </Content>
        <Footer style={{ textAlign: 'center' }}><InfoFooter /></Footer>
      </Layout>
    </Layout>
  </ >

  const infoLayout = <>
    <AppMenuRenderer accountData={state.accountData} selectedKeys={state.pageMenuKey} authorities={state.accountData?.authorities}
      data={AppMenuRouteData} onCollapse={onCollapse} collapsed={state.collapsed} />

    <Layout className="site-layout" style={{ marginLeft: state.collapsed ? 80 : 250 }} >
      <Header> {state.pageTitle}
        {/*<UserDropdown accountData={state.accountData} data={getUserMenuData()} onClick={onUserDropdownClick} />*/}
        <UserDropdownRenderer accountData={state.accountData} authorities={state.accountData?.authorities}
          data={UserMenuData} onClick={onUserDropdownClick} selectedKeys={state.pageMenuKey} />
        <HubIconRenderer accountData={state.accountData} isHeader={true} key={Math.random()} />
      </Header>
      <QuickConfig
        pipelineData={{
          activePipelines: state.pipelineData
      }}
        visible={state.quickConfig}
        close={closeQuickConfig}
        saveConfig={onQuickConfigClose} />

      <Content className='info-content'>
        <PageAlert />
        <RouteRenderer
          accountDataLoading={state.accountDataLoading}
          accountData={state.accountData}
          pipelineData={{
            activePipelines: state.pipelineData
        }}
          themeData={{ currentTheme: state.theme }}
          saveConfig={onQuickConfigClose} />
      </Content>

      <Footer style={{ textAlign: 'center' }}><InfoFooter /></Footer>
    </Layout >
  </ >

  return (
    <div style={{display: state.accountDataLoading ? 'none' : 'block'}}>
      <Layout key={_} style={{ minHeight: '100vh' }}>
        {isAgora ? agoraLayout : (T.isSmallVerticalScreen ? infoLayoutVerticalMobile : infoLayout)}
      </Layout>
      {state.accountDataLoading && <FullPageLoader loaderType={'ball-fall'} fadeIn={false} sentences={[""]} />}
    </div >
  );
}

export default InfoPage
