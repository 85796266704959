import React, {useState} from "react";
import {Col, Popover, Row, Skeleton, Tooltip} from "antd";
import { TUtil } from '@trellisenergy/common-ui-core';
import {BASEURL_DEV} from "../../config";
import {CloseOutlined, DownloadOutlined, EyeOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import CapacityApi from 'api/v1/CapacityApi';

const CapCommentDownloadLinkRenderer = (props) => {
    const {message} = useSelector((state) => state.message);
    let [loading, setLoading] = useState(false);
    let [content, setContent] = useState("");
    let [popoverState, setPopoverState] = useState({
        hovered: false,
        clicked: false
    });

    if (!props?.value) {
        return null;
    }

    let viewTextUrl = 'api/v1/capacity/getCapacityComment?capCmntId=' + props?.value;
    let fullViewTextUrl = TUtil.isDevEnv() ? BASEURL_DEV + viewTextUrl : TUtil.buildURL(viewTextUrl);
    let viewTextLink = <a href={fullViewTextUrl} target={"_blank"} rel="noreferrer">{<DownloadOutlined />}</a>;
    let noticeKeywords = props?.data?.noticeKeywords || [];

    let getCapComment = async () => {
        setLoading(true);
        // await new Promise(resolve => setTimeout(resolve, 3000));
        let response = await CapacityApi.getCapacityComment('capCmntId=' + props?.value + '&truncateText=true');
        setLoading(false);

        if (response) {
            if (noticeKeywords && noticeKeywords.length) {
                setContent(response);
            } else {
                setContent(response);
            }
        } else {
            setContent(message['ih.widget.error']);
        }
    };

    let handleHoverChange = (visible) => {
        if (visible) {
            getCapComment();
        }
        setPopoverState({
            hovered: visible,
            clicked: false
        });
    };

    let handleClickChange = visible => {
        if (visible) {
            getCapComment();
        }

        setPopoverState({
            hovered: false,
            clicked: visible
        });
    };

    let hide = () => {
        setPopoverState({
            hovered: false,
            clicked: false
        });
    };

    let contentUi = <Skeleton active loading={loading}>
          <pre>
              {content}
          </pre>
    </Skeleton>;

    let title =
        <Row>
            <Col span={20}>{props?.colDef?.headerName}</Col>
            <Col span={4} style={{textAlign: 'right'}}>
                <a onClick={hide}><CloseOutlined/></a>
            </Col>
        </Row>;

    return <div className="flex-center-justify-left">
            <Popover mouseEnterDelay={.5} trigger="hover"
                     visible={popoverState.hovered}
                     onVisibleChange={handleHoverChange} content={contentUi} title={title}>
                <Popover mouseEnterDelay={.5} trigger="click"
                         visible={popoverState.clicked}
                         onVisibleChange={handleClickChange} content={contentUi} title={title}>
                    <EyeOutlined style={{fontSize: '16px'}}/>
                </Popover>
            </Popover>&nbsp;&nbsp;<Tooltip title={message['ih.tooltip.clickToDownloadText']}>{viewTextLink}</Tooltip>
        </div>;
};

export default CapCommentDownloadLinkRenderer;
